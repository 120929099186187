import * as React from "react";
import "./under-construction.style.scss";
import { FontIcon } from "@fluentui/react";
import * as TelemetryProvider from "../../TelemetryProvider";
import { underConstructionPageView } from "./under-construction.telemtetry-constants";

export class UnderConstruction extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
    TelemetryProvider._trackPageView(underConstructionPageView);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="message">
          <FontIcon
            iconName="ConstructionCone"
            className="user-icon"
            aria-label="Under Construction"
          ></FontIcon>
          <span>Under construction!</span>
        </div>
      </>
    );
  }
}

export default UnderConstruction;
