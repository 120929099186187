import { IAgGridPropsCustom } from "./models/IAgGrid";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

const AgGridView = (Props: IAgGridPropsCustom) => {
  return (
    <AgGridReact
      columnDefs={Props.columnDefs}
      rowData={Props.rowData}
      paginationPageSize={Props.paginationPageSize}
      enableRangeSelection={Props.enableRangeSelection}
      pagination={Props.pagination}
      rowHeight={Props.rowHeight}
      onCellKeyDown={Props.onCellKeyDown}
    />
  );
};
export default AgGridView;
