import { getFormattedDateInString } from "./javascript-functions";

export function utcToFormattedDateTime(utcDateTime?: Date | string) {
  if (!utcDateTime) {
    return "";
  }
  return formatDateTime(utcToBrowserTimeZone(utcDateTime));
}

export function utcToBrowserTimeZone(utcDateTime: Date | string) {
  const utcOffset = new Date(utcDateTime).getTimezoneOffset();
  let localDateTime = new Date(utcDateTime);
  localDateTime.setMinutes(localDateTime.getMinutes() - utcOffset);
  return localDateTime;
}

export function browserToUTCTimeZone(localDateTime: Date) {
  let utcDateTime = new Date(localDateTime);
  // const utcOffset =
  //   new Date().getTimezoneOffset() - utcDateTime.getTimezoneOffset();
  // utcDateTime.setMinutes(utcDateTime.getMinutes() + utcOffset);
  return utcDateTime;
}

export function getLocalTimeZone() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}

export function utcToAnyTimeZone(utcDateTime: Date, utcOffset: number) {
  utcDateTime.setMinutes(utcDateTime.getUTCMinutes() + utcOffset);
  return utcDateTime;
}

export function formatDateTime(date?: Date | string, timeZone?: string) {
  if (date) {
    const options: Intl.DateTimeFormatOptions = {
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: timeZone ? timeZone : getLocalTimeZone(),
      timeZoneName: "short",
    };
    return new Date(date).toLocaleTimeString("en-us", options);
  }
  return "";
}

export function formatDateTimeWithoutTimezone(date?: Date | string) {
  if (date) {
    const options: Intl.DateTimeFormatOptions = {
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      // timeZone: getLocalTimeZone(),
      // timeZoneName: "short",
    };
    return new Date(date).toLocaleTimeString("en-us", options);
  }
  return "";
}

export function formatDate(date?: Date) {
  if (date) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-us", options);
  }
  return "";
}

export function formatTime(localDateTime: Date) {
  return new Date(localDateTime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getTimeIn24HourFormat(date: any): string {
  let time = new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  if (time.split(":")[0] === "24") {
    time = "00:" + time.split(":")[1];
  }
  return time;
}

export function getDateAndTimeInLocalTime(date: Date): string {
  return getFormattedDateInString(date) + " " + getTimeIn24HourFormat(date);
}

export function isTodayDaylightSavingDay(date: Date): boolean {
  let dateToLocalDate = getDateAndTimeInLocalTime(date);
  let dateToLocalDateInDate = new Date(dateToLocalDate);

  // check if month is between march and november i.e. Daylight Saving Time is in effect
  const month = dateToLocalDateInDate.getMonth();
  if (month < 2 || month > 10) {
    return false;
  }
  if (month > 2 && month < 10) {
    return true;
  }

  // if month is march, check if date is after second sunday i.e. Daylight Saving Time starts
  const marchFirst = new Date(dateToLocalDateInDate.getFullYear(), 2, 1).getDay();
  const daysUntilSecondSunday = marchFirst === 0 ? 14 : 7 - marchFirst + 7;
  const secondSundayofMarch = new Date(dateToLocalDateInDate.getFullYear(), 2, daysUntilSecondSunday + 1);

  // if month is november, check if date is before first sunday i.e. Daylight Saving Time ends
  const novFirst = new Date(dateToLocalDateInDate.getFullYear(), 10, 1).getDay();
  const daysUntilFirstSunday = novFirst === 0 ? 7 : 7 - novFirst;
  const firstSundayofNovember = new Date(dateToLocalDateInDate.getFullYear(), 10, daysUntilFirstSunday + 1);

  return date >= secondSundayofMarch && date < firstSundayofNovember;
}