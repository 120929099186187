import * as React from "react";
import RunScheduleFilter from "./run-schedule-filters/run-schedule-filters.controller";
import RunScheduleList from "./run-schedule-list/run-schedule-list.controller";
import * as TelemetryProvider from "../../TelemetryProvider";
import { runSchedulePageView } from "./run-schedule.telemtetry-constants";

export class RunSchedule extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
    TelemetryProvider._trackPageView(runSchedulePageView);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <>
        <RunScheduleFilter />
        <RunScheduleList />
      </>
    );
  }
}

export default RunSchedule;
