import { DefaultButton, Dropdown, IColumn, Label, SelectionMode, Stack, Text } from "@fluentui/react";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ITemplateRun } from "../../../models/template-run";
import { fetchTemplateRuns, updateRunListCount } from "../../../redux/actions/run-dashboard-v2-actions";
import { showTemplateRunDetails } from "../../../redux/actions/template-run-details-actions";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import { utcToBrowserTimeZone, utcToFormattedDateTime } from "../../../util/time-zone-util";
import DetailsListController from "../../common/details-list/details-list.controller";
import { config } from "../../Config/runDashboard-config";
interface IRunListLinkStateProps {
  runs: ITemplateRun[],
  isLoaded: boolean,
  isLoading: boolean,
  isDirty: boolean,
  count: number,
  fiscalYear: number,
}
interface IRunListLinkDispatchProps {
  fetchTemplateRuns: (executionStatus: number, count?: number, fiscalYear?: number) => void,
  updateRunListCount: (executionStatus: number, count: number) => void,
  showTemplateRunDetails: (templateRunId: number) => void,
}
interface RunListProps {
  runsToList: RunsToList,
}
type Props = IRunListLinkStateProps
  & IRunListLinkDispatchProps
  & RunListProps;
// This enum represents the propertyName for the separate tabs 
// and the corresponding execution status id.
export enum RunsToList {
  upcomingRuns = 1,
  inProgressRuns = 2,
  previousRuns = 3,
}

export class RunList extends React.Component<Props> {
  private getStatusDisplayText(executionStatus: string, isPaused: boolean): string {
    // If the statusMap doesn't have the executionStatus, or the map entry
    // doesn't have a display text, use the executionStatus as the display text.
    let displayText = this.statusMap[executionStatus]?.displayText || executionStatus;

    // If the template run is paused, append '(Paused)' to status text.
    displayText += isPaused ? " (Paused)": "";
    return displayText;
  }
  private getElapsedRunTime(item: ITemplateRun): string {
    if (!item.StartTime) {
      return "-";
    }
    const startTime = utcToBrowserTimeZone(item.StartTime);
    const endTime = item.EndTime ? utcToBrowserTimeZone(item.EndTime) : new Date();
    // Gets diff in milliseconds and converts to minutes.
    const minutes = Math.round((endTime.getTime() - startTime.getTime()) / (60 * 1000));
    return `${minutes} minutes`;
  }
  private refreshTemplateRuns(): void {
    this.props.fetchTemplateRuns(this.props.runsToList, this.props.count, this.props.fiscalYear);
  }
  private updateCount(newCount?: number | string): void {
    newCount = typeof newCount === "number" ? newCount : 5;
    this.props.updateRunListCount(this.props.runsToList, newCount);
    this.props.fetchTemplateRuns(this.props.runsToList, newCount, this.props.fiscalYear);
  }
  private onRenderNameColumn(item: ITemplateRun): JSX.Element {
    let style: React.CSSProperties = {};
    if (this.statusMap[item.ExecutionStatus]?.color) {
      style.color = this.statusMap[item.ExecutionStatus]?.color;
    }
    return (
      <a
        onClick={(ev) => {
          this.props.showTemplateRunDetails(item.RunTemplateScheduleId);
          ev.preventDefault();
        }}
        style={style}
        href="#"
      >
        {item.RunTemplateName}
      </a>
    );
  }
  private statusMap: { [key: string]: { color: string, displayText?: string } } = {
    "Failed": { color: "#E42C06" },
    "InProgress": { color: "#006CFF", displayText: "In Progress" },
    "New": { color: "#7A7575" },
    "Cancelled": { color: "#7A7575" },
    "Completed": { color: "#42860B" }
  }
  private columns: IColumn[] = [
    {
      key: "RunTemplateName",
      name: "Name",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item) => this.onRenderNameColumn(item),
    },
    {
      key: "ExecutionStatus",
      name: "Status",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: ITemplateRun) => this.getStatusDisplayText(item.ExecutionStatus, item.Paused),
    },
    {
      key: "RunId",
      name: "Run Id",
      fieldName: "RunId",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "StartTime",
      name: "Started",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => utcToFormattedDateTime(item.StartTime) || "-",
    },
    {
      key: "EndTime",
      name: "Ended",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => utcToFormattedDateTime(item.EndTime) || "-",
    },
    {
      key: "ScheduleDateTime",
      name: "Scheduled Start Time",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => utcToFormattedDateTime(item.ScheduleDateTime) || "-",
    },
    {
      key: "ElapsedRunTime",
      name: "Elapsed Run Time",
      minWidth: 75,
      maxWidth: 150,
      isResizable: true,
      onRender: (item) => this.getElapsedRunTime(item),
    },
    {
      key: "LastAction",
      name: "Last Action",
      fieldName: "LastAction",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "LastActionBy",
      name: "Last Action taken by",
      fieldName: "LastActionBy",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
  ];
  // Specifies what columns to use for which RunsToList type.
  private runsToListColumns: { [key in RunsToList]: string[] } = {
    [RunsToList.upcomingRuns]: [
      "RunTemplateName", "ExecutionStatus", "ScheduleDateTime"
    ],
    [RunsToList.inProgressRuns]: [
      "RunTemplateName", "ExecutionStatus", "RunId", "StartTime", "ElapsedRunTime", "LastAction", "LastActionBy"
    ],
    [RunsToList.previousRuns]: [
      "RunTemplateName", "ExecutionStatus", "RunId", "StartTime", "EndTime", "ElapsedRunTime"
    ],
  }
  constructor(props: Props) {
    super(props);
    this.columns = this.columns.filter((col) => this.runsToListColumns[this.props.runsToList].includes(col.key));
    this.getElapsedRunTime = this.getElapsedRunTime.bind(this);
    this.refreshTemplateRuns = this.refreshTemplateRuns.bind(this);
    this.onRenderNameColumn = this.onRenderNameColumn.bind(this);
    this.getStatusDisplayText = this.getStatusDisplayText.bind(this);
  }
  componentDidMount() {
    if (!this.props.isLoaded || this.props.isDirty) {
      this.refreshTemplateRuns();
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.isDirty || this.props.fiscalYear !== prevProps.fiscalYear) {
      this.refreshTemplateRuns();
    }
  }
  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Stack horizontal tokens={{ childrenGap: 's1', padding: 's1' }}>
          <Label>Runs to show</Label>
          <Dropdown
            options={config.runsDropDown.map((e) => ({ text: e.toString(), key: e }))}
            selectedKey={this.props.count}
            onChange={(e, option) => { this.updateCount(option?.key) }}
          />
          <DefaultButton
            text="Refresh"
            ariaLabel="Refresh Runs"
            iconProps={{ iconName: "Refresh" }}
            onClick={this.refreshTemplateRuns}
          />
        </Stack>
        <DetailsListController
          items={this.props.runs}
          selectionMode={SelectionMode.none}
          columns={this.columns}
          enableShimmer={this.props.isLoading}
        />
        {!this.props.runs.length && !this.props.isLoading && (
          <Stack horizontalAlign='center'>
            <Text>NO RUN TEMPLATES TO DISPLAY</Text>
          </Stack>
        )}
      </ React.Fragment>
    )
  } 
}
const mapStateToProps = (
  appState: AppState,
  ownProps: RunListProps
): IRunListLinkStateProps => {
  return {
    ...appState.runDashboardV2.RunData[RunsToList[ownProps.runsToList]],
    fiscalYear: appState.fiscalYear.fiscalYear,
  }
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): IRunListLinkDispatchProps => ({
  fetchTemplateRuns: bindActionCreators(
    fetchTemplateRuns, dispatch
  ),
  updateRunListCount: bindActionCreators(
    updateRunListCount, dispatch
  ),
  showTemplateRunDetails: bindActionCreators(
    showTemplateRunDetails, dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(RunList);
