import {
  IRunScheduleFilters,
  IRunScheduleData,
} from "../../models/run-schedule";

export const GET_RUN_SCHEDULE_FILTERS = "GET_RUN_SCHEDULE_FILTERS";
export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";
export const GET_RUN_TEMPLATE_SCHEDULE_DATA = "GET_RUN_TEMPLATE_SCHEDULE_DATA";
export const RUNSCHEDULE_CHECKBOX_CLICK = "RUNSCHEDULE_CHECKBOX_CLICK";
export const UPDATE_RUN_TEMPLATE_SCHEDULE_COLUMN_DATA =
  "UPDATE_RUN_TEMPLATE_SCHEDULE_COLUMN_DATA";
export const IS_RUN_SCHEDULE_GRID_LOADING = "IS_RUN_SCHEDULE_GRID_LOADING";
export const UPDATE_COMPLETE_DATA = "UPDATE_COMPLETE_DATA";
export const IS_RUN_TEMPLATE_SCHEDULE_SAVING = "IS_RUN_TEMPLATE_SCHEDULE_SAVING";

export interface GetRunScheduleFiltersAction {
  type: typeof GET_RUN_SCHEDULE_FILTERS;
  filters: IRunScheduleFilters;
}

export interface UpdateFilterData {
  type: typeof UPDATE_FILTER_DATA;
  gridViewData: IRunScheduleFilters;
}

export interface GetRunTemplateScheduleData {
  type: typeof GET_RUN_TEMPLATE_SCHEDULE_DATA;
  runTemplateScheduleData: IRunScheduleData;
}

export interface UpdateRunTemplateScheduleColumnData {
  type: typeof UPDATE_RUN_TEMPLATE_SCHEDULE_COLUMN_DATA;
  runTemplateScheduleColumnData: IRunScheduleData;
}

export interface IsRunScheduleGridLoading {
  type: typeof IS_RUN_SCHEDULE_GRID_LOADING;
  loading: boolean;
}

export interface UpdateCompleteData {
  type: typeof UPDATE_COMPLETE_DATA;
  data: any;
}

export interface IsRunTemplateScheduleSaving {
  type: typeof IS_RUN_TEMPLATE_SCHEDULE_SAVING;
  saving: boolean;
}

export type RunScheduleActionTypes =
  | GetRunScheduleFiltersAction
  | UpdateFilterData
  | GetRunTemplateScheduleData
  | UpdateRunTemplateScheduleColumnData
  | IsRunScheduleGridLoading
  | UpdateCompleteData
  | IsRunTemplateScheduleSaving;
