import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../redux/configureStore";
import { AppActions } from "../../../../redux/types/app-actions";
import {
  Checkbox,
  IconButton,
  IDropdownOption,
  Label,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  contentStyles,
  iconButtonStyles,
} from "../../../common/modal/modal.style";
import { ILinkProps } from "../process-dependency-node/models/IProcessDependencyNode";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import "./edit-process-dependency-link.scss";

interface IEditProcessDependencyProps {
  showModal: boolean;
  handleModalDismiss: () => void;
  title: string;
  link?: any;
  nodes?: string[];
  onSaveDependencyButtonClick: (dep: any) => void;
  onDeleteDependencyButtonClick: (dep: any) => void;
  isActivityDependency?: boolean;
}

interface IEditProcessDependencyLinkStateProps {
  dependencyTypesList?: any[];
}

export interface IEditProcessDependencyState {
  id: string;
  source: string;
  target: string;
  sourceName: string;
  targetName: string;
  canBeExcluded: boolean;
  dependencyTypeID: number;
  dependencyTypeName: string;
  dependencyLabel?: string;
  dependencyGroupNumber?: number;
}

type Props = IEditProcessDependencyProps & IEditProcessDependencyLinkStateProps;

class EditProcessDependencyLink extends React.Component<
  Props,
  IEditProcessDependencyState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: this.props.link?.id,
      source: this.props.link?.source,
      target: this.props.link?.target,
      sourceName: this.props.nodes != undefined ? this.props.nodes[0] : "",
      targetName: this.props.nodes != undefined ? this.props.nodes[1] : "",
      canBeExcluded: this.props.link?.data?.canBeExcluded,
      dependencyTypeID: this.props.link?.data?.dependencyTypeId,
      dependencyTypeName: "",
      dependencyLabel: this.props.link?.data?.dependencyLabel,
      dependencyGroupNumber: this.props.link?.data?.dependencyLabel,
    };
  }

  private _onCanBeExcludedValueChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ): void => {
    this.setState({
      canBeExcluded: checked != undefined ? checked : false,
    });
  };

  // private _onDependencyLabelChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, textValue?: string): void => {
  //     this.setState({
  //         dependencyLabel: textValue,
  //         dependencyGroupNumber: Number(textValue == null ? "": textValue)
  //     });

  // }

  private _onDropDownDependencyTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const dependencyTypeID = Number(item == null ? "" : item.key);
    const dependencyTypeName = item == null ? "" : item.text;
    this.setState({
      dependencyTypeID,
      dependencyTypeName,
    });
  };

  public render(): JSX.Element {
    if (this.props.dependencyTypesList !== undefined) {
      return (
        <Modal
          isOpen={this.props.showModal}
          onDismiss={this.props.handleModalDismiss}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <span id="preview">{this.props.title}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={this.props.handleModalDismiss}
            />
          </div>

          <div id="children" className={contentStyles.body}>
            <div className="edit-dependency">
              <div className="edit-buttons-list">
                <PrimaryButton
                  text="Save"
                  onClick={() =>
                    this.props.onSaveDependencyButtonClick(this.state)
                  }
                  className="header-buttons"
                />
                <PrimaryButton
                  text="Delete"
                  onClick={() =>
                    this.props.onDeleteDependencyButtonClick(this.state)
                  }
                  className="header-buttons"
                />
              </div>

              <div className="seperator-cls">
                <Label>Source : {this.state.sourceName} </Label>
              </div>
              <div className="seperator-cls">
                <Label>Target : {this.state.targetName} </Label>
              </div>

              <div className="seperator-cls">
                <Checkbox
                  label={"CanBeExcluded    :"}
                  checked={this.state.canBeExcluded}
                  boxSide={"end"}
                  onChange={this._onCanBeExcludedValueChange}
                />
              </div>

              {/* <div className="seperator-cls">
                    <Label>Dependency Group    : </Label>
                    <TextField
                        value={this.state.dependencyLabel}
                        style={{ width:60 }} 
                        onChange={this._onDependencyLabelChange}
                        type="number"
                        aria-label="Dependency Group"
                        ariaLabel="Dependency Group"
                    />
                    </div> */}

              <div className="seperator-cls">
                {!this.props.isActivityDependency ? (
                  <Dropdown
                    placeholder={"Select a dependency type"}
                    label={"Dependency Type: "}
                    options={
                      this.props.dependencyTypesList != undefined
                        ? this.props.dependencyTypesList
                        : []
                    }
                    selectedKey={this.state.dependencyTypeID?.toString()}
                    onChange={this._onDropDownDependencyTypeChange}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: any
): IEditProcessDependencyLinkStateProps => {
  return {
    dependencyTypesList: state.process.dependencyTypesList,
  };
};

export default connect(mapStateToProps)(EditProcessDependencyLink);
