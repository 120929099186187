import * as types from "../types/run-template-action-types";
import { RunTemplateActionTypes } from "../types/run-template-action-types";

const runTemplateDefaultState: any = {}; // Update any to a type if possible

const runTemplateReducer = (
  state = runTemplateDefaultState,
  action: RunTemplateActionTypes
): any => {
  switch (action.type) {
    case types.GET_RUN_TEMPLATE_LIST:
      return Object.assign({}, state, {
        runTemplateList: action.runTemplateList,
      });
    case types.GET_RUN_TEMPLATE_DETAILS:
      return Object.assign({}, state, {
        runTemplateDetails: action.runTemplateDetails,
      });
    default:
      return state;
  }
};

export default runTemplateReducer;
