import { AppActions } from "../types/app-actions";
import * as types from "../types/process-action-types";
import { IProcessDetails, IProcessMetadata, IProcessSlaDetails } from "../../models/process";
import { IComboBoxOption } from "@fluentui/react";

export const getProcessList = (processList: IProcessDetails[]): AppActions => ({
  type: types.GET_PROCESS_LIST,
  processList,
});

export const getProcessMetadata = (
  processMetadata: IProcessMetadata
): AppActions => ({
  type: types.GET_PROCESS_METADATA,
  processMetadata,
});

export const getProcessGroupList = (
  processGroupList: IComboBoxOption[]
): AppActions => ({
  type: types.GET_PROCESS_GROUP_LIST,
  processGroupList,
});

export const getProcessDetails = (
  processDetails: IProcessDetails
): AppActions => ({
  type: types.GET_PROCESS_DETAILS,
  processDetails,
});

export const getDependencyTypesList = (
  dependencyTypesList: any[]
): AppActions => ({
  type: types.GET_ALL_DEPENDENCY_TYPES,
  dependencyTypesList,
});

export const getProcessSlaDetails = (
	processSlaDetails: IProcessSlaDetails[]
): AppActions => ({
	type: types.GET_PROCESS_SLA_DETAILS,
	processSlaDetails,
});
  
