import {
  ComboBox,
  IComboBox,
  IDropdownOption,
  ITextFieldStyles,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { Component, useEffect } from "react";
import "./process-info.style.scss";
import {
  IProcessInfoLinkDispatchProps,
  IProcessInfoLinkStateProps,
  IProcessInfoProps,
} from "./models/IProcessInfo";
import { DefaultEffects } from "@fluentui/theme";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import Checkbox from "../../../common/checkbox/checkbox.controller";
import { getDeepCopy } from "../../../../util/javascript-functions";
import { IProcessDetails } from "../../../../models/process";
import { getProcessDetails } from "../../../../redux/actions/process-actions";
import { bindActionCreators } from "redux";
import { stackTokens } from "../../../run-schedule/run-schedule.helper";

type Props = IProcessInfoProps &
  IProcessInfoLinkDispatchProps &
  IProcessInfoLinkStateProps &
  any;
const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 350 },
};
export const ProcessInfo: React.FunctionComponent<Props> = (props) => {
  useEffect(() => {}, []);

  const updateProcessName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.ProcessName = newValue ? newValue : "";
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateProcessGroup = (
    event: React.FormEvent<IComboBox>,
    newValue?: {key: any, text: any}
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    let newId = newValue !== undefined ? newValue.key : "";
    let newName = newValue !== undefined ? newValue.text : "";
    if (newId !== "") {
      updatedProcessDetails.ProcessGroupId = newId;
      updatedProcessDetails.ProcessGroupName = newName;
    }
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateInfoGroup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.InfoGroup = newValue ? newValue : "";
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateThresholdLimitInMinutes = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.ThresholdLimitInMinutes = newValue
      ? parseInt(newValue, 10)
      : 0;
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateFailureGroup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.FailureGroup = newValue ? newValue : "";
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateIsInternalCheckbox = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    if (event != undefined) event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.IsInternalProcess = checked ? checked : false;
    props.getProcessDetails(updatedProcessDetails);
  };

  const updateIsSubProcessCheckbox = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    if (event != undefined) event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );

    updatedProcessDetails.IsSubProcess = checked ? checked : false;

    if (checked) {
      updatedProcessDetails.InfoGroup = "";
      updatedProcessDetails.FailureGroup = "";
    }

    props.getProcessDetails(updatedProcessDetails);
  };

  const updateFiscalYear = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedProcessDetails: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    updatedProcessDetails.FiscalYear = newValue ? parseInt(newValue, 10) : 0;
    props.getProcessDetails(updatedProcessDetails);
  };

  if (props.processDetails !== undefined) {
    if (props.isEditMode) {
      let _selectedProcessGroup: any;
      if (props.processDetails.ProcessGroupName) {
        _selectedProcessGroup = props.processGroupList?.find(
          (pg: IDropdownOption) =>
            pg.text === props.processDetails.ProcessGroupName
        );
      }
      return (
        <>
          <div
            style={{
              boxShadow: DefaultEffects.elevation16,
              padding: "10px",
              height: "auto",
            }}
          >
            <Stack
              className="activity-details-modal"
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="info-item">
                <b className="label"> Process Name: </b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <TextField
                    value={props.processDetails.ProcessName}
                    onChange={updateProcessName}
                    ariaLabel="Process Name"
                    disabled={
                      props.processDetails.ProcessId == 0 ? false : true
                    }
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
              <div className="info-item">
                <b className="label"> Process Group: </b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <ComboBox
                    options={props.processGroupList}
                    ariaLabel={"ProcessGroupList"}
                    placeholder={"Select a value"}
                    selectedKey={_selectedProcessGroup?.key}
                    onChange={(
                      event: React.FormEvent<IComboBox>,
                      option: { key: any, text: any } | undefined
                    ) => {
                      updateProcessGroup(event, option);
                    }}
                  />
                </span>
              </div>
              <div className="info-item">
                <b className="label"> Info Group: </b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <TextField
                    value={
                      props.processDetails.InfoGroup
                        ? props.processDetails.InfoGroup
                        : ""
                    }
                    disabled={props.processDetails.IsSubProcess}
                    onChange={updateInfoGroup}
                    ariaLabel="Info Group"
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
              {/* </div> */}
            </Stack>
            <br />
            <Stack
              className="activity-details-modal"
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="info-item">
                <b className="label">Fiscal Year:</b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <TextField
                    value={
                      props.processDetails.FiscalYear
                        ? props.processDetails.FiscalYear
                        : "0"
                    }
                    onChange={updateFiscalYear}
                    contentEditable={false}
                    readOnly={true}
                    ariaLabel="Fiscal Year"
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
              <div className="info-item">
                <b className="label"> Process Threshold(mins): </b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <TextField
                    value={
                      props.processDetails.ThresholdLimitInMinutes
                        ? props.processDetails.ThresholdLimitInMinutes
                        : "0"
                    }
                    onChange={updateThresholdLimitInMinutes}
                    ariaLabel="Process Threshold"
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
              <div className="info-item">
                <b className="label"> DRI Group: </b>
                <span
                  className="text-field"
                  style={{ display: "inline-block" }}
                >
                  <TextField
                    value={
                      props.processDetails.FailureGroup
                        ? props.processDetails.FailureGroup
                        : ""
                    }
                    disabled={props.processDetails.IsSubProcess}
                    onChange={updateFailureGroup}
                    ariaLabel="DRI Group"
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
            </Stack>
            <br />
            <div>
              <div className="info-item">
                <Checkbox
                  onChange={updateIsInternalCheckbox}
                  className="control-style"
                  label={"Internal"}
                  checked={props.processDetails.IsInternalProcess}
                  title={"Internal"}
                />
              </div>
              <div className="info-item">
                <Checkbox
                  onChange={updateIsSubProcessCheckbox}
                  className="control-style"
                  label={"Sub Process"}
                  checked={props.processDetails.IsSubProcess}
                  title={"Sub Process"}
                />
              </div>
            </div>
            <br />
          </div>

        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              boxShadow: DefaultEffects.elevation16,
              padding: "10px",
              height: "auto",
            }}
          >
            <Stack
              className="activity-details-modal"
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="info-item">
                <b>Process Name:</b>
                {" " + props.processDetails.ProcessName}
              </div>
              <div className="info-item">
                <b>Process Group:</b>
                {props.processDetails.ProcessGroupName
                  ? " " + props.processDetails.ProcessGroupName
                  : " N/A"}
              </div>
              <div className="info-item">
                <b>Info Group:</b>
                {props.processDetails.InfoGroup
                  ? " " + props.processDetails.InfoGroup
                  : " N/A"}
              </div>
            </Stack>
            <br />
            <Stack
              className="activity-details-modal"
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="info-item">
                <b>Fiscal Year:</b>
                {props.processDetails.FiscalYear
                  ? " " + props.processDetails.FiscalYear
                  : " 0"}
              </div>
              <div className="info-item">
                <b>Process Threshold:</b>
                {props.processDetails.ThresholdLimitInMinutes
                  ? " " + props.processDetails.ThresholdLimitInMinutes + " mins"
                  : " N/A"}
              </div>
              <div className="info-item">
                <b>DRI Group:</b>
                {props.processDetails.FailureGroup
                  ? " " + props.processDetails.FailureGroup
                  : " N/A"}
              </div>
            </Stack>
            <br />
            <Stack
              className="activity-details-modal"
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="info-item">
                <b>Internal:</b>
                {props.processDetails.IsInternalProcess ? " Yes" : " No"}
              </div>
              <div className="info-item">
                <b>Sub Process:</b>
                {props.processDetails.IsSubProcess ? " Yes" : " No"}
              </div>
              <div className="info-item">{/* empty for alignment */}</div>
            </Stack>
            <br />
          </div>
          <br />
        </>
      );
    }
  } else return <></>;
};

const mapStateToProps = (
  state: AppState,
  ownProps: IProcessInfoProps
): IProcessInfoLinkStateProps => {
  return {
    processDetails: state.process.processDetails,
    processMetadata: state.process.processMetadata,
    processGroupList: state.process.processGroupList,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IProcessInfoProps
): IProcessInfoLinkDispatchProps => ({
  getProcessDetails: bindActionCreators(getProcessDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessInfo);
