import React from "react";
import {
  ISourceOnboardingList2Props,
  SourceOnboardingLinkStateProps,
  SourceOnboardingLinkDispatchProps,
} from "./model/ISourceOnboarding";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import {
  startGetAllConnections1,
  deleteConnectionById,
} from "./source-onboarding.service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NewConnection from "./new-connection";
import { SourceOnboarding } from "./source-onboarding.view";
import { Connection, ConnectionAttributes } from "../../../models/connection";
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { ModalView } from "../../common/modal/modal.view";
import { IconButton } from "@fluentui/react";
import {
  IAzureSqlConnection,
  ISqlServerConnection,
  IAzureDataLakeStorageGen2,
  ISparkConnection,
  IFileSystemConnection,
  IExcelConnection,
  IAzureFunctionConnection,
  IJarConnection,
  ISynapseConnection,
  IParquetConnection,
} from "./model/IConnection";
import { ColDef } from "ag-grid-community";
import { getDeepCopy } from "../../../util/javascript-functions";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  sourceOnboardingDeleteButtonClickEvent,
  sourceOnboardingPageView,
} from "./source-onboarding.telemtetry-constants";

type Props = ISourceOnboardingList2Props &
  SourceOnboardingLinkStateProps &
  SourceOnboardingLinkDispatchProps;

export class SourceOnboardingList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(sourceOnboardingPageView);
    this.state = {
      editModalParams: undefined,
      isEditModalVisible: false,
      isNewModal: false,
    };
    this.getEditControl = this.getEditControl.bind(this);
    this.getDeleteControl = this.getDeleteControl.bind(this);
    this.updateIsEditModalVisible = this.updateIsEditModalVisible.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  componentDidMount() {
    this.props.startGetAllConnections1();
  }

  public render(): JSX.Element {
    if (
      this.props.totalConnections != undefined &&
      this.props.totalConnections != null &&
      this.props.totalConnections.totConnections != undefined &&
      this.props.totalConnections.totConnections != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      var totalConnections = this.props.totalConnections?.totConnections;

      columnDefs = this.getColDefinations(columnDefs);
      rowData = this.getRowData(rowData, totalConnections).filter(
        (c) => c.FiscalYear == this.props.fiscalYear
      );
      var params = {
        totalConnections: this.props.totalConnections,
        //gridOptions: gridOptions,
        columnDefs: columnDefs,
        rowData: rowData,
        tempConns: this.props.startGetAllConnections1,
        editModalParams: this.state.editModalParams,
        showModal: this.state.isEditModalVisible,
        updateIsEditModalVisible: this.updateIsEditModalVisible,
        gridCellKeyPress: this.gridCellKeyPress,
        isNewModal: this.state.isNewModal,
      };
      return <SourceOnboarding {...params} />;
    } else {
      return (
        <>
          <div>Loading...</div>
        </>
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "Edit":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          const results = this.getResults(event);
          const _editParams = {
            ConnectionId: event.data.ConnectionId,
            FiscalYear: event.data.FiscalYear,
            DataSourceName: event.data.ConnectionName,
            ConnectionType: event.data.ConnectionTypeName,
            ActionType: "edit",
            AzureSqlConnection: results.AzureSqlConnection,
            SqlServerConnection: results.SqlServerConnection,
            AzureDataLakeStorageGen2: results.AzureDataLakeStorageGen2,
            FileSystemConnection: results.FileSystemConnection,
            ExcelConnection: results.ExcelConnection,
            SparkConnection: results.SparkConnection,
            JarConnection: results.JarConnection,
            ParquetConnection: results.ParquetConnection,
            SynapseConnection: results.SynapseConnection,            
            AzureFunctionConnection: results.AzureFunctionConnection,
            AuthType: results.AuthType,
            AuthenticationOption: results.AuthenticationOption,
            saveMsg: "",
            validateMsg: "",
            tempConns: this.props.startGetAllConnections1,
          };
          this.setState({
            editModalParams: _editParams,
            isEditModalVisible: true,
          });
          break;
        case "Clone":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          const result = this.getResults(event);
          const _cloneParams = {
            FiscalYear: event.data.FiscalYear,
            DataSourceName: event.data.ConnectionName,
            ConnectionType: event.data.ConnectionTypeName,
            ActionType: "clone",
            AzureSqlConnection: result.AzureSqlConnection,
            SqlServerConnection: result.SqlServerConnection,
            AzureDataLakeStorageGen2: result.AzureDataLakeStorageGen2,
            FileSystemConnection: result.FileSystemConnection,
            ExcelConnection: result.ExcelConnection,
            SparkConnection: result.SparkConnection,
            JarConnection: result.JarConnection,
            ParquetConnection: result.ParquetConnection,
            AzureFunctionConnection: result.AzureFunctionConnection,
            AuthType: result.AuthType,
            AuthenticationOption: result.AuthenticationOption,
            saveMsg: "",
            validateMsg: "",
            tempConns: this.props.startGetAllConnections1,
          };
          this.setState({
            editModalParams: _cloneParams,
            isEditModalVisible: true,
          });
          break;
        case "Delete":
          if (event.data.IsActive) {
            this.deleteButClicked(event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private deleteButClicked = (params: any) => {
    TelemetryProvider._trackEvent(sourceOnboardingDeleteButtonClickEvent);
    var clonedState = getDeepCopy(this.props.totalConnections);
    var tempStr = " \r\nDo you want to delete this connection?";
    var datasets =
      params.TotalDatasetsOnboarded != undefined
        ? params.TotalDatasetsOnboarded
        : 0;
    var tempVal = window.confirm(
      datasets + " Dataset(s) are onboarded for this connection." + tempStr
    );
    if (tempVal == true) {
      this.props.deleteConnectionById(params.ConnectionId, clonedState);
    }
  };

  private updateIsEditModalVisible(isNewModal: boolean = false) {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
      isNewModal: isNewModal,
    });
  }

  private getResults(params: any) {
    var AzureSqlConnection: IAzureSqlConnection = {},
      AzureDataLakeStorageGen2: IAzureDataLakeStorageGen2 = {},
      SparkConnection: ISparkConnection = {},
      FileSystemConnection: IFileSystemConnection = {},
      ExcelConnection: IExcelConnection = {},
      SqlServerConnection: ISqlServerConnection = {},
      JarConnection: IJarConnection = {},
      ParquetConnection: IParquetConnection = {},
      SynapseConnection: ISynapseConnection = {}, 
      AzureFunctionConnection: IAzureFunctionConnection = {};

    var AuthType;
    var AuthenticationOption: string = "";
    var result = {
      AuthType,
      AuthenticationOption,
      AzureSqlConnection,
      SqlServerConnection,
      AzureDataLakeStorageGen2,
      FileSystemConnection,
      ExcelConnection,
      SparkConnection,
      JarConnection,
      ParquetConnection,
      SynapseConnection,
      AzureFunctionConnection
    };
    result = getConnectionDetails(
      AzureSqlConnection,
      SqlServerConnection,
      AzureDataLakeStorageGen2,
      FileSystemConnection,
      ExcelConnection,
      SparkConnection,
      JarConnection,
      ParquetConnection,
      SynapseConnection,
      AzureFunctionConnection,
      params.data.ConnectionTypeName,
      params.data.ConnectionAttributes,
      result
    );

    return result;
  }

  private getEditControl = (params: any) => {
    const result = this.getResults(params);
    const _editParams = {
      ConnectionId: params.data.ConnectionId,
      FiscalYear: params.data.FiscalYear,
      DataSourceName: params.data.ConnectionName,
      ConnectionType: params.data.ConnectionTypeName,
      ActionType: "edit",
      AzureSqlConnection: result.AzureSqlConnection,
      SqlServerConnection: result.SqlServerConnection,
      AzureDataLakeStorageGen2: result.AzureDataLakeStorageGen2,
      FileSystemConnection: result.FileSystemConnection,
      ExcelConnection: result.ExcelConnection,
      SparkConnection: result.SparkConnection,
      JarConnection: result.JarConnection,
      ParquetConnection: result.ParquetConnection,
      SynapseConnection: result.SynapseConnection,
      AzureFunctionConnection: result.AzureFunctionConnection,
      AuthType: result.AuthType,
      AuthenticationOption: result.AuthenticationOption,
      saveMsg: "",
      validateMsg: "",
      tempConns: this.props.startGetAllConnections1,
    };
    return (

        <IconButtonView
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit"
          onClick={() => {
            this.setState({
              editModalParams: _editParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Edit"
        />

    );
  };

  private getCloneControl = (params: any) => {
    const result = this.getResults(params);
    const _cloneParams = {
      FiscalYear: params.data.FiscalYear,
      DataSourceName: params.data.ConnectionName,
      ConnectionType: params.data.ConnectionTypeName,
      ActionType: "clone",
      AzureSqlConnection: result.AzureSqlConnection,
      SqlServerConnection: result.SqlServerConnection,
      AzureDataLakeStorageGen2: result.AzureDataLakeStorageGen2,
      FileSystemConnection: result.FileSystemConnection,
      ExcelConnection: result.ExcelConnection,
      SparkConnection: result.SparkConnection,
      JarConnection: result.JarConnection,
      ParquetConnection: result.ParquetConnection,
      SynapseConnection: result.SynapseConnection,
      AzureFunctionConnection: result.AzureFunctionConnection,
      AuthType: result.AuthType,
      AuthenticationOption: result.AuthenticationOption,
      saveMsg: "",
      validateMsg: "",
      tempConns: this.props.startGetAllConnections1,
    };
    return (

        <IconButtonView
          iconName="Copy"
          fontSize={12}
          title="Clone"
          disabled={!params.data.IsActive}
          onClick={() => {
            this.setState({
              editModalParams: _cloneParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Clone"
        />

    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <IconButton
            iconProps={{
              iconName: "Delete",
              style: { fontSize: 12 },
            }}
            title="Delete"
            disabled={!params.data.IsActive}
            //onClick={() => handlePreviewClick({ params })}
            onClick={(event) => this.deleteButClicked(params.data)}
            ariaLabel="Delete"
          />
        </div>
      </div>
    );
  };

  private getColDefinations(colDefns: ColDef[]) {
    var columnDefs = [
      { field: "ConnectionID", hide: true },
      { field: "ConnectionAttributes", hide: true },
      {
        field: "FiscalYear",
        width: 125,
        sortable: true,
        filter: true,
        flex: 0.5,
      },
      { field: "ConnectionName", width: 230, sortable: true, filter: true, flex: 1 },
      { field: "ConnectionTypeName", width: 230, sortable: true, filter: true, flex: 1 },
      { field: "ServerName[SQL]", width: 230, sortable: true, filter: true, flex: 1 },
      {
        field: "TotalDatasetsOnboarded",
        width: 200,
        sortable: true,
        filter: true,
        flex: 1,
      },
      { field: "CreatedON", width: 160, sortable: true, filter: true, flex: 1 },
      { field: "IsActive", width: 130, sortable: true, filter: true, flex: 0.5 },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
      },
      {
        headerName: "Clone",
        width: 60,
        cellRendererFramework: this.getCloneControl,
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
      },
    ];
    return columnDefs;
  }

  private getRowData(rowData: any[], connections: Connection[]) {
    for (var i = 0; i < connections.length; i++) {
      var obj = connections[i];
      rowData.push({
        ConnectionId: obj.ConnectionId,
        ConnectionAttributes: obj.ConnectionAttributes,
        FiscalYear: obj.FiscalYear,
        ConnectionName: obj.ConnectionName,
        ConnectionTypeName: obj.Connectiontype?.ConnectionTypeName,
        "ServerName[SQL]": obj.ServerName,
        TotalDatasetsOnboarded: obj.DataSetsOnboarded,
        CreatedON: obj.CreatedON,
        IsActive: obj.IsActive,
      });
    }
    return rowData;
  }
}

const getConnectionDetails = (
  AzureSqlConnection: IAzureSqlConnection,
  SqlServerConnection: ISqlServerConnection,
  AzureDataLakeStorageGen2: IAzureDataLakeStorageGen2,
  FileSystemConnection: IFileSystemConnection,
  ExcelConnection: IExcelConnection,
  SparkConnection: ISparkConnection,
  JarConnection: IJarConnection,
  ParquetConnection: IParquetConnection,
  SynapseConnection: ISynapseConnection,
  AzureFunctionConnection: IAzureFunctionConnection,
  ConnectionTypeName: string,
  connObjects: ConnectionAttributes[],
  result: {
    AuthType: any;
    AuthenticationOption: string;
    AzureSqlConnection: IAzureSqlConnection;
    SqlServerConnection: ISqlServerConnection;
    AzureDataLakeStorageGen2: IAzureDataLakeStorageGen2;
    FileSystemConnection: IFileSystemConnection;
    ExcelConnection: IExcelConnection;
    SparkConnection: ISparkConnection;
    JarConnection: IJarConnection;
    ParquetConnection: IParquetConnection;
    SynapseConnection: ISynapseConnection;
    AzureFunctionConnection: IAzureFunctionConnection;
  }
) => {
  var AuthType,
    AuthenticationOption,
    userN = "",
    tempPass = "";
  switch (ConnectionTypeName) {
    case "Azure SQL Database":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Server Name":
            AzureSqlConnection.ServerName = AttributeValue;
            break;
          case "Database Name":
            AzureSqlConnection.DatabaseName = AttributeValue;
            break;
          case "User Name":
            AzureSqlConnection.UserName = AttributeValue;
            userN = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Password":
            AzureSqlConnection.Password = AttributeValue;
            tempPass = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Authentication Type":
            AzureSqlConnection.AuthenticationType = AttributeValue;
            AuthType = AttributeValue;
            break;
          default:
            break;
        }
      }
      addServicePrincipalIdAndKey(AzureSqlConnection, "Azure SQL Database");
      break;
    case "SQL Server":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Server Name":
            SqlServerConnection.ServerName = AttributeValue;
            break;
          case "Database Name":
            SqlServerConnection.DatabaseName = AttributeValue;
            break;
          case "User Name":
            SqlServerConnection.UserName = AttributeValue;
            userN = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Password":
            SqlServerConnection.Password = AttributeValue;
            tempPass = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Authentication Type":
            SqlServerConnection.AuthenticationType = AttributeValue;
            AuthType = AttributeValue;
            break;
          default:
            break;
        }
      }
      break;
    case "Azure Data Lake Storage Gen2":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Lake Storage Account Name":
            AzureDataLakeStorageGen2.LakeStorageAccountName = AttributeValue;
            break;
          case "Container Name":
            AzureDataLakeStorageGen2.ContainerName = AttributeValue;
            break;
          case "Authentication Method":
             AzureDataLakeStorageGen2.AuthenticationMethod = AttributeValue;
             AuthType = AttributeValue;
             break;
          default:
            break;
        }
      }
      addServicePrincipalIdAndKey(AzureDataLakeStorageGen2, "Azure Data Lake Storage Gen2");
      break;
    case "File System":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "File Share Path":
            FileSystemConnection.FilePath = AttributeValue;
            break;
          case "User Name":
            FileSystemConnection.UserName = AttributeValue;
            userN = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Password":
            FileSystemConnection.Password = AttributeValue;
            tempPass = AttributeValue != undefined ? AttributeValue : "";
            break;
          default:
            break;
        }
      }
      break;
    case "Excel":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Host":
            ExcelConnection.Host = AttributeValue;
            break;
          case "filename":
            ExcelConnection.FileName = AttributeValue;
            break;
          case "SheetName":
            ExcelConnection.SheetName = AttributeValue;
            break;
          case "FirstRowAsHeader":
            ExcelConnection.FirstRowAsHeader =
              AttributeValue == "true" ? AttributeValue == "true" : false;
            break;
          case "User Name":
            ExcelConnection.UserName = AttributeValue;
            userN = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Password":
            ExcelConnection.Password = AttributeValue;
            tempPass = AttributeValue != undefined ? AttributeValue : "";
            break;
          case "Lake Storage Account Name":
            ExcelConnection.LakeStorageAccountName = AttributeValue;
            break;
          case "Directory":
            ExcelConnection.Directory = AttributeValue;
            break;
          case "Authentication Method":
            ExcelConnection.AuthenticationMethod = AttributeValue;
            AuthType = AttributeValue;
            break;
          case "row delimeter":
            ExcelConnection.RowDelimeter = AttributeValue;
            break;
          case "column delimeter":
            ExcelConnection.ColumnDelimeter = AttributeValue;
                break;
          default:
            break;
        }
      }
      addServicePrincipalIdAndKey(ExcelConnection, "Excel");
      break;
    case "Spark":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Domain":
            SparkConnection.Domain = AttributeValue;
            break;
          case "Existing Cluster ID":
            SparkConnection.ClusterID = AttributeValue;
            break;
          case "Access Token":
            SparkConnection.AccessToken = AttributeValue;
            break;
          default:
            break;
        }
      }
      break;
    case "JAR":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Domain":
            JarConnection.Domain = AttributeValue;
            break;
          case "Access Token":
            JarConnection.AccessToken = AttributeValue;
            break;
          case "NewClusterNodeType":
            JarConnection.NewClusterNodeType = AttributeValue;
            break;
          case "NewClusterDriverNodeType":
            JarConnection.NewClusterDriverNodeType = AttributeValue;
            break;
          case "NewClusterNumOfWorker":
            JarConnection.NewClusterNumOfWorker = AttributeValue;
            break;
          case "NewClusterVersion":
            JarConnection.NewClusterVersion = AttributeValue;
            break;
          default:
            break;
        }
      }
      break;
    case "Synapse Workspace":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Workspace URL":
            SynapseConnection.WorkspaceURL = AttributeValue;
            break;
          default:
            break;
        }
      }
      break;    
    case "parquet":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Lake Storage Account Name":
            ParquetConnection.LakeStorageAccountName = AttributeValue;
            break;
          case "Container Name":
            ParquetConnection.ContainerName = AttributeValue;
            break;
          case "folderpath":
            ParquetConnection.folderpath = AttributeValue;
            break;
          case "Authentication Method":
            ParquetConnection.AuthenticationMethod = AttributeValue;
            AuthType = AttributeValue;
            break;
          default:
            break;
        }
      }
      addServicePrincipalIdAndKey(ParquetConnection, "parquet");
      break;
    case "Azure Function":
      for (var k = 0; k < connObjects.length; k++) {
        const cAttrObj = connObjects[k];
        var AttributeValue = cAttrObj.Attributevalue;
        var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
        switch (AttributeName) {
          case "Function App URL":
            AzureFunctionConnection.FunctionAppURL = AttributeValue;
            break;
          case "Function Key":
            AzureFunctionConnection.FunctionKey = AttributeValue;
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }

  function addServicePrincipalIdAndKey(connection: any, connectionTypeName: string){
    // Adding the field service principal needs to be in a different for loop compared to the other connection attributes
    // because the Authentication Method attribute needs to be computed before the Service Principal ones.
    var authenticationMethod = connectionTypeName === "Azure SQL Database" ? connection.AuthenticationType : connection.AuthenticationMethod;
    if (authenticationMethod !== "Service Principal"){
      // If the authentication method is not service principal id and key will not be populated into the object.
      return;
    }

    for (var k = 0; k < connObjects.length; k++) {
      const cAttrObj = connObjects[k];
      var AttributeValue = cAttrObj.Attributevalue;
      var AttributeName = cAttrObj.ConnectionTypeAttribute?.AttributeName;
      switch (AttributeName) {
        case "ServicePrincipalId":
          connection.ServicePrincipalId = AttributeValue;
          break;
        case "ServicePrincipalKey":
          connection.ServicePrincipalKey = AttributeValue;
          break;
        default:
          break;
      }
    }
  }

  var stre = 'UserName:"' + userN + '",Password:"' + tempPass + '"';
  result = {
    AuthType: AuthType,
    AuthenticationOption: stre,
    AzureSqlConnection: AzureSqlConnection,
    SqlServerConnection: SqlServerConnection,
    AzureDataLakeStorageGen2: AzureDataLakeStorageGen2,
    FileSystemConnection: FileSystemConnection,
    ExcelConnection: ExcelConnection,
    SparkConnection: SparkConnection,
    JarConnection: JarConnection,
    ParquetConnection: ParquetConnection,
    SynapseConnection: SynapseConnection,
    AzureFunctionConnection: AzureFunctionConnection
  };
  return result;
};

const mapStateToProps = (
  state: AppState
  //ownProps: ISourceOnboardingList2Props
): SourceOnboardingLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    totalConnections: state.sourceOnboardingReducer.totalConnections,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
  //ownProps: ISourceOnboardingList2Props
): SourceOnboardingLinkDispatchProps => ({
  startGetAllConnections1: bindActionCreators(
    startGetAllConnections1,
    dispatch
  ),
  deleteConnectionById: bindActionCreators(deleteConnectionById, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceOnboardingList);
