import { IRunTemplateMsgInfo } from "../../components/run-dashboard-v2/runtemplate-controls/models/IRunTemplateControls";
import { ICurrentRunScheduleList } from "../../models/run-dashboard";
import { IRunScheduleStatus } from "../../models/run-details";

export const GET_CURRENT_RUN_DATA = "GET_CURRENT_RUN_DATA";
export const GET_UPCOMING_RUN_DATA = "GET_UPCOMING_RUN_DATA";
export const GET_PREVIOUS_RUN_DATA = "GET_PREVIOUS_RUN_DATA";
export const GET_RUN_DETAILS = "GET_RUN_DETAILS";
export const SET_RUN_DETAILS = "SET_RUN_DETAILS";
export const PAUSE_RUNTEMPLATE_SCHEDULE = "PAUSE_RUNTEMPLATE_SCHEDULE";
export const CANCEL_RUNTEMPLATE_SCHEDULE = "CANCEL_RUNTEMPLATE_SCHEDULE";
export const START_RUNTEMPLATE_SCHEDULE = "START_RUNTEMPLATE_SCHEDULE";
export const RERUNFROMFAILED_RUNTEMPLATE_SCHEDULE =
  "RERUNFROMFAILED_RUNTEMPLATE_SCHEDULE";
export const UPDATE_RUN_DETAILS_SECTION_STATUS =
  "UPDATE_RUN_DETAILS_SECTION_STATUS";
export const UPDATE_UPCOMING_RUNS_SECTION_STATUS =
  "UPDATE_UPCOMING_RUNS_SECTION_STATUS";
export const UPDATE_PREVIOUS_RUNS_SECTION_STATUS =
  "UPDATE_PREVIOUS_RUNS_SECTION_STATUS";
export const REQUEST_CURRENT_RUNS =
  "REQUEST_CURRENT_RUNS";
export const UPDATE_PROCESS_STATUS = "UPDATE_PROCESS_STATUS";
export const GET_RUN_UPDATE_RESULT = "GET_RUN_UPDATE_RESULT";

export interface GetCurrentRunData {
  type: typeof GET_CURRENT_RUN_DATA;
  currentRunData: ICurrentRunScheduleList;
}

export interface GetUpcomingRunData {
  type: typeof GET_UPCOMING_RUN_DATA;
  upcomingRunData: IRunScheduleStatus[];
}

export interface GetPreviousRunData {
  type: typeof GET_PREVIOUS_RUN_DATA;
  previousRunData: IRunScheduleStatus[];
}

export interface GetRunDetails {
  type: typeof GET_RUN_DETAILS;
  runDetails: IRunScheduleStatus;
}

export interface SetRunDetails {
  type: typeof SET_RUN_DETAILS;
  runDetails: IRunScheduleStatus;
}

export interface PauseRunTemplateSchedule {
  type: typeof PAUSE_RUNTEMPLATE_SCHEDULE;
}

export interface CancelRunTemplateSchedule {
  type: typeof CANCEL_RUNTEMPLATE_SCHEDULE;
}

export interface StartRunTemplateSchedule {
  type: typeof START_RUNTEMPLATE_SCHEDULE;
}

export interface ReRunFromFailedRunTemplateSchedule {
  type: typeof RERUNFROMFAILED_RUNTEMPLATE_SCHEDULE;
}

export interface UpdateRunDetailsStatus {
  type: typeof UPDATE_RUN_DETAILS_SECTION_STATUS;
  isRunDetailsLoading: boolean;
}

export interface UpdateUpcomingRunsStatus {
  type: typeof UPDATE_UPCOMING_RUNS_SECTION_STATUS;
  isUpcomingRunsLoading: boolean;
}

export interface UpdatePreviousRunsStatus {
  type: typeof UPDATE_PREVIOUS_RUNS_SECTION_STATUS;
  isPreviousRunsLoading: boolean;
}

export interface RequestCurrentRuns {
  type: typeof REQUEST_CURRENT_RUNS;
}

export interface UpdateProcessStatus {
  type: typeof UPDATE_PROCESS_STATUS;
}

export interface GetRunUpdateResult {
  type: typeof GET_RUN_UPDATE_RESULT;
  runUpdateResult: IRunTemplateMsgInfo;
}

export type RunDashboardActionTypes =
  | GetCurrentRunData
  | GetUpcomingRunData
  | GetPreviousRunData
  | GetRunDetails
  | SetRunDetails
  | PauseRunTemplateSchedule
  | CancelRunTemplateSchedule
  | StartRunTemplateSchedule
  | ReRunFromFailedRunTemplateSchedule
  | UpdateRunDetailsStatus
  | UpdateUpcomingRunsStatus
  | UpdatePreviousRunsStatus
  | RequestCurrentRuns
  | UpdateProcessStatus
  | GetRunUpdateResult;
