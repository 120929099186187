import * as types from "../types/user-profile-action-types";
import { UserProfileActionTypes } from "../types/user-profile-action-types";
import { UserRoleDetails } from "../../models/user-profile";

interface IUserProfileReducer {
  authControls?: IAuthControls; //UserRoleData;
  userName?: string;
}

export interface IAuthControls {
  canUpdateRunSchedule: boolean;
  canPauseResumeRun: boolean;
  canRestartRetriggerCancel: boolean;
}

const userProfileReducerDefaultState: IUserProfileReducer = {};

const userProfileReducer = (
  state = userProfileReducerDefaultState,
  action: UserProfileActionTypes
): any => {
  switch (action.type) {
    case types.GET_AUTH_DATA:
      return Object.assign({}, state, {
        authControls: getAuthControls(action.userRoleDetails),
        userName: action.userRoleDetails.UserName,
      });

    default:
      return state;
  }
};

const getAuthControls = (userRoleDetails: UserRoleDetails): IAuthControls => {
  //transform data
  let authControls = {
    canUpdateRunSchedule: false,
    canPauseResumeRun: false,
    canRestartRetriggerCancel: false,
  };
  authControls.canUpdateRunSchedule =
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunSchedule:UpdateSchedule"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunSchedule:SaveSchedule"
    ) != -1;

  authControls.canPauseResumeRun =
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplateStart"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplatePause"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplateResume"
    ) != -1;

  authControls.canRestartRetriggerCancel =
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplateCancel"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplateRetrigger"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) => a.FeatureName.toString() == "RunDashboard:TemplateReRunFromFailed"
    ) != -1 &&
    userRoleDetails.Features.findIndex(
      (a) =>
        a.FeatureName.toString() == "RunDashboard:TemplateReRunFromActivity"
    ) != -1;

  return authControls;
};
export default userProfileReducer;
