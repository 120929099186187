import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import "./horizontal-nav-bar.scss";
import { IHorizontalNavigationBar } from "./model/IHorizontalNavigationBar";
import { useHistory } from "react-router-dom";


export class HorizontalNavBarView extends React.Component<any, any> {
  //public state = { selectedKey: "start", horizontal_nav_data: [] };

  constructor(props: any) {
    super(props);
    this.state = {
      selectedKey: "start",
      horizontal_nav_data: [{}],
    };

    props.history.listen((routeData: any) => {
      this.getRootPath();
      let dataItem: any;
      this.props.horizontal_nav_data.forEach((element: any) => {
        if (dataItem === undefined) {
          dataItem = element.routeList.filter((data: any) => {
            return data.url === routeData.pathname;
          })[0];
        }
      });

      this.setState({
        selectedKey: dataItem?.key,
      });
    });
  }

  componentDidMount() {
    this.getRootPath();
  }

  getRootPath() {
    var record = this.props.horizontal_nav_data.filter((a: any) => {
      return this.props.history.location.pathname.includes(a.rootPath);
    });

    if (record.length > 0) {
      this.setState({
        horizontal_nav_data: record[0].routeList,
      });
      var selectedKeyFromPath = record[0].routeList.find((a: any) => a.url === this.props.history.location.pathname)?.key
      this.setState({
        selectedKey: selectedKeyFromPath || record[0].defaultKey,
      });
    } else {
      this.setState({
        horizontal_nav_data: [],
      });
      this.setState({
        selectedKey: "start",
      });
    }
  }

  getPivotItems = () => {
      return this.state.horizontal_nav_data.map((data: any) => {
        return (
        <PivotItem
          key={data.key}
          ariaLabel={data.name}
          headerText={data.name}
          itemKey={data.key}
          tabIndex={-1}
        />
      );
    });
  };

  public render(): React.JSX.Element {
      var selectedKey = this.props.location.pathname === "/"
          ? "rundashboard" : this.state.selectedKey;
    return (
      <div className="horizontal-navigation-bar" tabIndex={-1}>
        <Pivot
          aria-label="Navigation Bar"
          selectedKey={selectedKey}
          key={selectedKey}
          onLinkClick={this._handleLinkClick}
        >
          <PivotItem
            headerButtonProps={{ style: {"display": "none"}}}
            ariaLabel="start"
            headerText=""
            key="start"
            itemKey="start"
          ></PivotItem>
          {this.getPivotItems()}
        </Pivot>
      </div>
    );
  }

  private _handleLinkClick = (item?: PivotItem): void => {
    const data = this.getItem(item?.props.itemKey);
    this.props.history.push(data.url);
    this.setState({
      selectedKey: data.key,
    });
  };

  private getItem = (searchKey: any): any => {
    return this.state.horizontal_nav_data.filter((data: any) => {
      return data.key === searchKey;
    })[0];
  };
}

export default HorizontalNavBarView;
