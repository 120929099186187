import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IRunTemplateDetails } from "../../models/run-template";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import { formatDateTimeWithoutTimezone } from "../../util/time-zone-util";
import Loading from "../loading/loading";
import RunTemplateView from "../run-template/run-template.view";
import { getDate,getFormattedDate } from "../run-dashboard-v2/run-dashboard-v2.helper";
import {
  IRunTemplateLinkDispatchProps,
  IRunTemplateLinkStateProps,
  IRunTemplateProps,
  IRunTemplateState,
} from "../run-template/models/IRunTemplate";
import IconButton from "../common/button/icon-button/icon-button.controller";
import { Link } from "react-router-dom";
import {
  startDeleteRunTemplate,
  startGetRunTemplateList,
} from "../run-template/run-template.service";
import * as TelemetryProvider from "../../TelemetryProvider";
import {
  deleteRunTemplateClickEvent,
  editRunTemplateClickEvent,
  runTemplatePageView,
  viewRunTemplateClickEvent,
} from "./run-template.telemtetry-constants";

type Props = IRunTemplateProps &
  IRunTemplateLinkDispatchProps &
  IRunTemplateLinkStateProps &
  any;

class RunTemplate extends React.Component<Props, IRunTemplateState> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(runTemplatePageView);
    this.gridCellKeyPress = this.gridCellKeyPress.bind(this);
    this.getViewControl = this.getViewControl.bind(this);
    this.getEditControl = this.getEditControl.bind(this);
    this.getDeleteControl = this.getDeleteControl.bind(this);
  }

  componentDidMount() {
    this.props.startGetRunTemplateList(this.props.fiscalYear);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.fiscalYear != this.props.fiscalYear) {
      this.props.startGetRunTemplateList(this.props.fiscalYear);
    }
  }

  public render(): JSX.Element {
    if (
      this.props.runTemplateList !== undefined &&
      this.props.runTemplateList != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      var runTemplateList = this.props.runTemplateList;

      columnDefs = this.getColDefinations();
      rowData = this.getRowData(runTemplateList);
      var params = {
        columnDefs: columnDefs,
        rowData: rowData,
        gridCellKeyPress: this.gridCellKeyPress,
      };
      return <RunTemplateView {...params} />;
    } else {
      return <Loading />;
    }
  }

  private getColDefinations() {
    
    var columnDefs = [
      {
        headerName: "Run Template Name",
        field: "RunTemplateName",
        width: 320,
        sortable: true,
        filter: true,
        lockPinned: true,
        pinned: "left",
      },
      {
        headerName: "Is Master",
        field: "IsMaster",
        width: 140,
        sortable: true,
        filter: true,
      },
      //   {
      //     headerName: "Last Run Date",
      //     field: "LastRunDate",
      //     width: 160,
      //     sortable: true,
      //     filter: true,
      //   },
      //   {
      //     headerName: "Execution Status",
      //     field: "ExecutionStatus",
      //     width: 140,
      //     sortable: true,
      //     filter: true,
      //   },
      {
        headerName: "Created By",
        field: "CreatedBy",
        width: 200,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Created On",
        field: "CreatedOn",
        width: 160,
        sortable: true,
        filter: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },
      {
        headerName: "Modified By",
        field: "ModifiedBy",
        width: 200,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Modified On",
        field: "ModifiedOn",
        width: 160,
        sortable: true,
        filter: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },
      {
        headerName: "Is Active",
        field: "IsActive",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "View",
        width: 60,
        cellRendererFramework: this.getViewControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
        lockPinned: true,
        pinned: "right",
      },
    ];
    return columnDefs;
  }

  private getViewControl = (params: any) => {
    return (
      <Link
        to={{
          pathname: `/orchestration/run-template-details`,
          state: {
            runTemplateId: params.data.RunTemplateId,
            isEditMode: false,
          },
        }}
      >
        <IconButton
          iconName="RedEye"
          fontSize={12}
          title="View Run Template"
          disabled={!(params.data.IsActive === "Yes")}
          onClick={(event) => {
            TelemetryProvider._trackEvent(viewRunTemplateClickEvent);
          }}
          ariaLabel="View Run Template"
        />
      </Link>
    );
  };

  private getEditControl = (params: any) => {
    return (
      <Link
        to={{
          pathname: `/orchestration/run-template-details`,
          state: {
            runTemplateId: params.data.RunTemplateId,
            isEditMode: true,
          },
        }}
      >
        <IconButton
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit Run Template"
          disabled={!(params.data.IsActive === "Yes")}
          onClick={(event) => {
            TelemetryProvider._trackEvent(editRunTemplateClickEvent);
          }}
          ariaLabel="Edit Run Template"
        />
      </Link>
    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <IconButton
        iconName="Delete"
        fontSize={12}
        title="Delete Run Template"
        disabled={!(params.data.IsActive === "Yes")}
        onClick={(event) => this.onDeleteClick(params.data)}
        ariaLabel="Delete Run Template"
      />
    );
  };

  private getRowData(runTemplateList: IRunTemplateDetails[]) {
    let rowData: any[] = [];
    for (let i = 0; i < runTemplateList.length; i++) {
      var obj = runTemplateList[i];

      rowData.push({
        FiscalYear: obj.FiscalYear,
        RunTemplateId: obj.RunTemplateID,
        RunTemplateName: obj.RunTemplateName,
        IsMaster: obj.IsMaster === true ? "Yes" : "No",
        IsActive: obj.IsActive === true ? "Yes" : "No",
        CreatedBy: obj.CreatedBy,
        CreatedOn: obj.CreatedOn,
        ModifiedBy: obj.ModifiedBy,
        ModifiedOn: obj.ModifiedOn,
      });
    }
    return rowData;
  }

  private startDeleteRunTemplateCallback() {
    this.props.startGetRunTemplateList(this.props.fiscalYear);
  }

  private onDeleteClick(data: any) {
    TelemetryProvider._trackEvent(deleteRunTemplateClickEvent);
    const onDeleteConfirmation = window.confirm(
      "This will soft delete the selected Run Template. Click Ok to confirm."
    );
    if (onDeleteConfirmation) {
      startDeleteRunTemplate(
        data.RunTemplateId,
        this.startDeleteRunTemplateCallback
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    const keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "View":
          TelemetryProvider._trackEvent(viewRunTemplateClickEvent);
          this.props.history.push({
            pathname: `/orchestration/run-template-details`,
            state: {
              runTemplateId: event.data.RunTemplateId,
              isEditMode: false,
            },
          });
          break;
        case "Edit":
          TelemetryProvider._trackEvent(editRunTemplateClickEvent);
          this.props.history.push({
            pathname: `/orchestration/run-template-details`,
            state: {
              runTemplateId: event.data.RunTemplateId,
              isEditMode: true,
            },
          });
          break;
        case "Delete":
          if (event.data.IsActive === "Yes") {
            this.onDeleteClick(event.data);
          }
          break;
        default:
          return;
      }
    }
  };
}

const mapStateToProps = (
  state: AppState,
  ownProps: IRunTemplateProps
): IRunTemplateLinkStateProps => {
  return {
    runTemplateList: state.runTemplate.runTemplateList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunTemplateProps
): IRunTemplateLinkDispatchProps => ({
  startGetRunTemplateList: bindActionCreators(
    startGetRunTemplateList,
    dispatch
  ),
  startDeleteRunTemplate: bindActionCreators(startDeleteRunTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunTemplate);
