import {
  IHorizontalNavigationBarList,
} from "./model/IHorizontalNavigationBar";

const horizontalNavigationList: IHorizontalNavigationBarList[] = [
  // {
  //   rootPath: "system",
  //   defaultKey: "sourceonboarding",
  //   routeList: [
  //     {
  //       key: "sourceonboarding",
  //       name: "Source Onboarding",
  //       url: "/system/source-onboarding",
  //     },
      // {
      //   key: "entityonboarding",
      //   name: "Entity Onboarding",
      //   url: "/system/entity-onboarding",
      // },
      // {
      //   key: "domaindefinition",
      //   name: "Domain Definition",
      //   url: "/system/domain-definition",
      // },
      // {
      //   key: "entityrelationship",
      //   name: "Entity Relationship",
      //   url: "/system/entity-relationship",
      // },
      // {
      //   key: "perspectivedefinition",
      //   name: "Perspective Definition",
      //   url: "/system/perspective-definition",
      // },
      // {
      //   key: "perspectivevalidation",
      //   name: "Perspective Validation",
      //   url: "/system/perspective-validation",
      // },
    // ],
  // },
  // {
  //   rootPath: "validation",
  //   defaultKey: "controlsetup",
  //   routeList: [
  //     {
  //       key: "controlsetup",
  //       name: "Control Setup",
  //       url: "/validation/control-setup",
  //     },
  //     {
  //       key: "validateentity",
  //       name: "Validate Entity",
  //       url: "/validation/validate-entity",
  //     }
  //   ],
  // },
  {
    rootPath: "",
    defaultKey: "rundashboard",
    routeList: [
      {
        key: "rundashboard",
        name: "Run Dashboard",
        url: "/orchestration/run-dashboard",
      },
      {
        key: "runschedule",
        name: "Run Schedule",
        url: "/orchestration/run-schedule",
      },
      {
        key: "activity",
        name: "Activity",
        url: "/orchestration/activity",
      },
      {
        key: "process",
        name: "Process",
        url: "/orchestration/process",
      },
      {
        key: "runtemplate",
        name: "Run Template",
        url: "/orchestration/run-template",
      },
      {
        key: "connections",
        name: "Connections",
        url: "/system/source-onboarding",
      }
    ],
  },
];

export default horizontalNavigationList;
