import { IComboBoxOption } from "@fluentui/react";
import { IProcessDetails, IProcessMetadata, IProcessSlaDetails } from "../../models/process";

export const GET_PROCESS_LIST = "GET_PROCESS_LIST";
export const GET_PROCESS_METADATA = "GET_PROCESS_METADATA";
export const GET_PROCESS_DETAILS = "GET_PROCESS_DETAILS";
export const GET_PROCESS_GROUP_LIST = "GET_PROCESS_GROUP_LIST";
export const GET_ALL_DEPENDENCY_TYPES = "GET_ALL_DEPENDENCY_TYPES";
export const GET_SOURCE_REFRESH_DETAILS = "GET_SOURCE_REFRESH_DETAILS";
export const GET_PROCESS_SLA_DETAILS = "GET_PROCESS_SLA_DETAILS";
export interface GetAllProcesses {
  type: typeof GET_PROCESS_LIST;
  processList: IProcessDetails[];
}

export interface GetProcessMetadata {
  type: typeof GET_PROCESS_METADATA;
  processMetadata: IProcessMetadata;
}

export interface GetProcessGroupList {
  type: typeof GET_PROCESS_GROUP_LIST;
  processGroupList: IComboBoxOption[];
}

export interface GetProcessDetails {
  type: typeof GET_PROCESS_DETAILS;
  processDetails: IProcessDetails;
}

export interface GetAllDependencyTypes {
  type: typeof GET_ALL_DEPENDENCY_TYPES;
  dependencyTypesList: any[];
}

export interface GetProcessSlaDetails {
	type: typeof GET_PROCESS_SLA_DETAILS;
	processSlaDetails: IProcessSlaDetails[];
}

export type ProcessActionTypes =
  | GetAllProcesses
  | GetProcessMetadata
  | GetProcessGroupList
  | GetProcessDetails
  | GetAllDependencyTypes
  | GetProcessSlaDetails;
