import toggleImage from "./../../../../assests/image/svg/MintHub_Icon_White.svg";
// import productIcon from "./../../../../assests/image/svg/Product_Icon_Black.svg";
import microsoftLogo from "./../../../../assests/image/Microsoft-logo_rgb_wht.png";
import { ISideNavHeader } from "../model/ISideNavBar";

const sideNavBarHeaderDetails: ISideNavHeader = {
  toggleIcon: toggleImage,
  privacyLink:microsoftLogo,
};

export default sideNavBarHeaderDetails;
