import * as types from "../types/recurrence-rule-action-types";
import { AppActions } from "../types/app-actions";
import { IRecurrenceRule } from "../../models/recurrence-rule";

export const getRecurrenceRules = (rules: IRecurrenceRule[]): AppActions => ({
  type: types.GET_RECURRENCE_RULES,
  rules,
});

export const addOrUpdateRecurrenceRuleAction = (
  rule: IRecurrenceRule
): AppActions => ({
  type: types.ADD_OR_UPDATE_RULE,
  rule,
});
