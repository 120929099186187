import * as React from "react";
import {
  IStyleFunctionOrObject,
  IToggleStyleProps,
  IToggleStyles,
} from "@fluentui/react";
import { ITogglePropsCustom } from "./models/IToggle";
import ToggleView from "./toggle.view";

let controlStyles: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
  root: {
    margin: "20px 0 20px 0",
    maxWidth: "300px",
  },
};

export class ToggleController extends React.Component<
  ITogglePropsCustom,
  React.StatelessComponent
> {
  constructor(props: ITogglePropsCustom) {
    super(props);
    if (this.props.styles != null) {
      controlStyles = this.props.styles;
    }
  }

  render() {
    return (
      <ToggleView
        onText={this.props.onText}
        offText={this.props.offText}
        styles={controlStyles}
        checked={this.props.checked}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      />
    );
  }
}

const Toggle = (Props: ITogglePropsCustom) => {
  return (
    <ToggleController
      onText={Props.onText}
      offText={Props.offText}
      checked={Props.checked}
      onChange={Props.onChange}
      disabled={Props.disabled}
    />
  );
};
export default Toggle;
