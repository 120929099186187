import {
  IActivityValidations,
  IRule,
  IValidationMetadata,
} from "../../models/validation";

export const GET_VALIDATION_DETAILS = "GET_VALIDATION_DETAILS";
export const GET_VALIDATION_LIST = "GET_VALIDATION_LIST";
export const GET_ACTIVITY_VALIDATION_LIST = "GET_ACTIVITY_VALIDATION_LIST";
export const GET_VALIDATION_METADATA = "GET_VALIDATION_METADATA";
export const DELETE_VALIDATION = "DELETE_VALIDATION";

export interface GetValidataionDetails {
  type: typeof GET_VALIDATION_DETAILS;
  validationDetails: IRule;
}

export interface GetAllValidations {
  type: typeof GET_VALIDATION_LIST;
  validationList: IRule[];
}

export interface GetAllActivityValidations {
  type: typeof GET_ACTIVITY_VALIDATION_LIST;
  activityValidationList: IActivityValidations[];
}

export interface GetValidataionMetadata {
  type: typeof GET_VALIDATION_METADATA;
  validationMetadata: IValidationMetadata;
}

export interface DeleteValidataion {
  type: typeof DELETE_VALIDATION;
  ruleId: number;
}

export type ValidataionActionTypes =
  | GetValidataionDetails
  | GetAllValidations
  | GetAllActivityValidations
  | GetValidataionMetadata
  | DeleteValidataion;
