import * as types from "../types/template-run-details-action-types";
import { AppActions } from "../types/app-actions";
import { Dispatch } from "redux";
import { cancelRunTemplate, getTemplateRunStatus, pauseRunTemplate, reRunFromFailedRunTemplate, resumeRunTemplate, updateProcessStatus } from "../../api/run-dashboard-api";
import { ITemplateRunDetails } from "../../models/template-run";
import { dialogActions } from "../../components/run-dashboard-v2/template-run-details/template-run-details.controller";
import { MarkRunListsAsDirty } from "./run-dashboard-v2-actions";

export const showTemplateRunDetails = (templateRunId: number) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(RequestTemplateRunDetails({ templateRunId }));
    try {
      const result = await getTemplateRunStatus(templateRunId);
      if (!result) {
        throw new Error("Template Run Details not returned");
      }
      return dispatch(ReceivedTemplateRunDetailDataSuccess(result));
    } catch (ex) {
      return dispatch(ReceivedTemplateRunDetailDataFailure(ex));
    }
  }
}

export const markProcessAsComplete = (processId: number, runTemplateScheduleId: number) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(StartMarkTemplateRunProcessComplete({ processId, runTemplateScheduleId }));

    try {
      await updateProcessStatus(runTemplateScheduleId, { ProcessId: processId });
    }
    catch (ex) {
      return dispatch(TemplateRunUpdateFailure(`Mark process as complete failed with the following error: ${ex}`));
    }
    // Reload the TemplateRun data
    try {
      const result = await getTemplateRunStatus(runTemplateScheduleId);
      const successMessage = "Successfully marked as complete."
      return dispatch(ReceivedTemplateRunDetailDataSuccess(result, successMessage))
    }
    catch (ex) {
      return dispatch(ReceivedTemplateRunDetailDataFailure(ex));
    }
  }
}

export const performTemplateRunAction = (action: dialogActions, runTemplateScheduleID: number, reason: string, userAlias: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    let successMessage = ""
    try {
      switch (action) {
        case "cancel":
          await cancelRunTemplate(runTemplateScheduleID, reason, userAlias);
          successMessage = "Train Canceled!"
          break;
        case "pause":
          await pauseRunTemplate(runTemplateScheduleID, reason, userAlias);
          successMessage = "Train Paused!"
          break;
        case "resume":
          await resumeRunTemplate(runTemplateScheduleID, reason, userAlias);
          successMessage = "Train Resumed!"
          break;
        case "retrigger":
          await reRunFromFailedRunTemplate(runTemplateScheduleID, reason, userAlias);
          successMessage = "Train Retriggered!"
          break;
        default:
          successMessage = ""
          break;
      }
    } catch (ex) {
      return dispatch(TemplateRunUpdateFailure(`${action} failed with the following error: ${ex}`));
    }
    // Reload the TemplateRun data
    try {
      const result = await getTemplateRunStatus(runTemplateScheduleID);

      dispatch(ReceivedTemplateRunDetailDataSuccess(result, successMessage))
    }
    catch (ex) {
      return dispatch(ReceivedTemplateRunDetailDataFailure(ex));
    }
    return dispatch(MarkRunListsAsDirty());
  }
}

export const RequestTemplateRunDetails = (payload: any): AppActions => ({
  type: types.REQUEST_TEMPLATE_RUN_DETAILS_DATA,
});
export const updateIsTemplateRunDetailsShown = (isShown: boolean): AppActions => ({
  type: types.UPDATE_IS_TEMPLATE_RUN_DETAILS_SHOWN,
  isShown,
});
export const ReceivedTemplateRunDetailDataSuccess = (templateRunDetails: ITemplateRunDetails, successMessage: string = ""): AppActions => ({
  type: types.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS,
  data: templateRunDetails,
  successMessage,
});
export const ReceivedTemplateRunDetailDataFailure = (errorMessage: any): AppActions => ({
  type: types.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE,
  errorMessage,
});
export const TemplateRunUpdateFailure = (errorMessage: any): AppActions => ({
  type: types.TEMPLATE_RUN_UPDATE_FAILURE,
  errorMessage,
});
export const StartMarkTemplateRunProcessComplete = (payload: { processId: number; runTemplateScheduleId: number }): AppActions => ({
  type: types.START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE,
  data: payload,
});
export const UpdateHideNotScheduled = (hideNotScheduled: boolean): AppActions => ({
  type: types.UPDATE_HIDE_NOT_SCHEDULED,
  hideNotScheduled,
});
