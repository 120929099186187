import * as types from "../types/run-dashboard-action-types";
import { RunDashboardActionTypes } from "../types/run-dashboard-action-types";
import {
  ICurrentRunDashboard,
  ICurrentRunScheduleList,
} from "../../models/run-dashboard";
import { IRunScheduleStatus } from "../../models/run-details";

interface IRunDashboardReducer {
  currentRunData?: ICurrentRunScheduleList;
  upcomingRunData?: IRunScheduleStatus[];
  previousRunData?: IRunScheduleStatus[];
  runDetails?: IRunScheduleStatus;
  isRunDetailsLoading?: boolean;
  isUpcomingRunsLoading?: boolean;
  isPreviousRunsLoading?: boolean;
  isCurrentRunsLoading?: boolean;
}

const runDashboardReducerDefaultState: IRunDashboardReducer = {};

const runDashboardReducer = (
  state = runDashboardReducerDefaultState,
  action: RunDashboardActionTypes
): any => {
  switch (action.type) {
    case types.GET_CURRENT_RUN_DATA:
      return Object.assign({}, state, {
        currentRunData: action.currentRunData,
        isCurrentRunsLoading: false
      });
    case types.GET_UPCOMING_RUN_DATA:
      return Object.assign({}, state, {
        upcomingRunData: action.upcomingRunData,
      });
    case types.GET_PREVIOUS_RUN_DATA:
      return Object.assign({}, state, {
        previousRunData: action.previousRunData,
      });
    case types.GET_RUN_DETAILS:
      return Object.assign({}, state, {
        runDetails: action.runDetails,
      });
    case types.SET_RUN_DETAILS:
      return Object.assign({}, state, {
        runDetails: action.runDetails,
      });
    case types.PAUSE_RUNTEMPLATE_SCHEDULE:
      return Object.assign({}, state);
    case types.CANCEL_RUNTEMPLATE_SCHEDULE:
      return Object.assign({}, state);
    case types.START_RUNTEMPLATE_SCHEDULE:
      return Object.assign({}, state);
    case types.RERUNFROMFAILED_RUNTEMPLATE_SCHEDULE:
      return Object.assign({}, state);
    case types.UPDATE_RUN_DETAILS_SECTION_STATUS:
      return Object.assign({}, state, {
        isRunDetailsLoading: action.isRunDetailsLoading,
      });
    case types.UPDATE_UPCOMING_RUNS_SECTION_STATUS:
      return Object.assign({}, state, {
        isUpcomingRunsLoading: action.isUpcomingRunsLoading,
      });
    case types.UPDATE_PREVIOUS_RUNS_SECTION_STATUS:
      return Object.assign({}, state, {
        isPreviousRunsLoading: action.isPreviousRunsLoading,
      });
    case types.REQUEST_CURRENT_RUNS:
      return Object.assign({}, state, {
        isCurrentRunsLoading: true,
      });
    case types.UPDATE_PROCESS_STATUS:
      return Object.assign({}, state);
    case types.GET_RUN_UPDATE_RESULT:
      return Object.assign({}, state, {
        runUpdateResult: action.runUpdateResult,
      });
    default:
      return state;
  }
};

export default runDashboardReducer;
