import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const runSchedulePageView: IPageViewTelemetry = {
  name: "Run Schedule",
  uri: "orchestration/run-schedule",
};

export const applyFilterClickEvent: IEventTelemetry = {
  name: "RunSchedule_ApplyFilterClick",
};
export const monthlyWeeklyToggleClickEvent: IEventTelemetry = {
  name: "RunSchedule_MonthlyWeeklyToggleClick",
};
export const saveClickEvent: IEventTelemetry = {
  name: "RunSchedule_SaveClick",
};
export const previewClickEvent: IEventTelemetry = {
  name: "RunSchedule_PreviewClick",
};
export const cancelClickEvent: IEventTelemetry = {
  name: "RunSchedule_CancelClick",
};
export const checkboxClickEvent: IEventTelemetry = {
  name: "RunSchedule_CheckboxClick",
};
export const processNameClickEvent: IEventTelemetry = {
  name: "RunSchedule_ProcessNameClick",
};
export const recurrenceRuleButtonClickEvent: IEventTelemetry = {
  name: "RunSchedule_RecurrenceRuleButtonClick",
};
export const configurationButtonClickEvent: IEventTelemetry = {
  name: "RunSchedule_ConfigurationButtonClick",
};
export const timePickerClickEvent: IEventTelemetry = {
  name: "RunSchedule_TimePickerClick",
};
export const cloneScheduleClickEvent: IEventTelemetry = {
  name: "RunSchedule_CloneScheduleClick",
};
export const deleteScheduleClickEvent: IEventTelemetry = {
  name: "RunSchedule_DeleteScheduleClick",
};
export const runTemplateDrilldownClickEvent: IEventTelemetry = {
  name: "RunTemplateDrilldownClick",
};
