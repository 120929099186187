import React, { useState, useEffect,useCallback } from "react";
import ReactFlow, {
  isEdge,
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Elements,
  Connection,
  Edge,
  ReactFlowProvider,
  Position,
  isNode
} from "react-flow-renderer";
import dagre from 'dagre';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IRunTemplateDetails } from "../../../../models/run-template";
import { AppState } from "../../../../redux/configureStore";
import { AppActions } from "../../../../redux/types/app-actions";
import { getDeepCopy } from "../../../../util/javascript-functions";
import {
  getLink,
  getNode,
} from "../../../process/process-details/process-dependency-node/process-dependency-node.helper";
import CustomNode from "../../../process/process-details/process-dependency-node/process-dependency-node.controller";
import EditProcessDependencyLink, { IEditProcessDependencyState } from "../../../process/process-details/process-dependency-link/edit-process-dependency-link.controller";
import {
  IRunTemplateDependencyGridLinkDispatchProps,
  IRunTemplateDependencyGridLinkStateProps,
  IRunTemplateDependencyGridProps,
} from "./models/IRunTemplateDependencyGrid";
import { getRunTemplateDetails } from "../../../../redux/actions/run-template-actions";
import {
  ILinkProps,
  INodeProps,
} from "../../../process/process-details/process-dependency-node/models/IProcessDependencyNode";
import { IProcessDependency, IProcessDetails } from "../../../../models/process";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const onLoad = (reactFlowInstance: any) =>
  reactFlowInstance.fitView();
const onNodeDragStop = (event: any, node: any) =>
  console.log("drag stop", node);

const initBgColor = "#1A192B";

const connectionLineStyle = { stroke: "#008000" };
const snapGrid = [16, 16];
const nodeTypes = {
  selectorNode: CustomNode,
};

type Props = IRunTemplateDependencyGridProps &
  IRunTemplateDependencyGridLinkDispatchProps &
  IRunTemplateDependencyGridLinkStateProps &
  any;
export const RunTemplateDependencyGrid: React.FunctionComponent<Props> = (
  props
) => {
  const [elements, setElements] = useState<any[]>([]);
  const [bgColor, setBgColor] = useState(initBgColor);
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<any>();
  const [[sourceNode, targetNode], setSelectedSourceTargetNode] = useState<[string, string]>(["",""]);

  useEffect(() => {}, []);

  useEffect(() => {
    let depthlist: any;
    let dependencyGraphElements: any;
    if (props.runTemplateDetails) {
      depthlist = getDepthList();
      dependencyGraphElements = buildDependencyGraph(depthlist);
      setElements(dependencyGraphElements);
    }
  }, [props.runTemplateDetails]);

  const handleModalDismiss = () => {
    setShowModal(!showModal);
  }

  const onSaveDependencyButtonClick = (dep: IEditProcessDependencyState) => {

      if (props.isEditMode) {
        var runTemplateDetails: IRunTemplateDetails = getDeepCopy(props.runTemplateDetails);
        var process = runTemplateDetails.Processes?.find(p => p.ProcessId == Number(dep.target));
        if (process) {
          var dependency = process.Dependencies.find(d => d.DependentProcessID == Number(dep.source));
          if (dependency != undefined) {
            dependency.CanBeExcluded = dep.canBeExcluded;
            dependency.DependencyTypeId = dep.dependencyTypeID;
            dependency.DependencyTypeName = dep.dependencyTypeName;
          }
        }
        props.getRunTemplateDetails(runTemplateDetails);
        setShowModal(!showModal);
      }
  }

  const onDeleteDependencyButtonClick = (dep: IEditProcessDependencyState) => {

    if (props.isEditMode) {
      var val = window.confirm("Are you sure you want to delete the dependency between the two processes?");
      if (val) {
        var runTemplateDetails: IRunTemplateDetails = getDeepCopy(props.runTemplateDetails);
        var process = runTemplateDetails.Processes?.find(p => p.ProcessId == Number(dep.target));
        if (process) {
          var dependency = process.Dependencies.findIndex(d => d.DependentProcessID == Number(dep.source));
          if (dependency != -1)
            process.Dependencies.splice(dependency, 1);
        }
        props.getRunTemplateDetails(runTemplateDetails);
        setShowModal(!showModal);
      }
    }
  }

  const deleteNodeHandler = (nodeId: string) => {
    let _runTemplateDetailsCopy: IRunTemplateDetails = getDeepCopy(props.runTemplateDetails);
    var processIndex = _runTemplateDetailsCopy.Processes?.findIndex(p => p.ProcessId == parseInt(nodeId, 10));

    if (processIndex != undefined && processIndex != -1)
      _runTemplateDetailsCopy.Processes?.splice(processIndex, 1);

    props.getRunTemplateDetails(_runTemplateDetailsCopy);
  };

  const onElementClick = (event: any, element: any) => {
    console.log("click", element);
  
    if (props.isEditMode && element.id.includes("link")) {
      var sourceNode = props.runTemplateDetails.Processes?.find((p: IProcessDetails) => p.ProcessId == Number(element.source))?.ProcessName;
      var targetNode = props.runTemplateDetails.Processes?.find((p: IProcessDetails) => p.ProcessId == Number(element.target))?.ProcessName;
      setSelectedSourceTargetNode([sourceNode, targetNode]);
      setSelectedLink(element);
      setShowModal(true);
    }
  }

  //DeleteEdgeHandler
  const onElementsRemove = (elementsToRemove: any) => {
    console.log("remove", elementsToRemove);
    var element = elementsToRemove[0];

    if (props.isEditMode && element != undefined && element.id.includes("link")) {
      var val = window.confirm("Are you sure you want to delete the dependency between the two processes?");
      if (val) {
        var runTemplateDetails: IRunTemplateDetails = getDeepCopy(props.runTemplateDetails);
        var process = runTemplateDetails.Processes?.find(p => p.ProcessId == Number(element.target));
        if (process) {
          var dependency = process.Dependencies.findIndex(d => d.DependentProcessID == Number(element.source));
          if (dependency != -1)
            process.Dependencies.splice(dependency, 1);
        }
        props.getRunTemplateDetails(runTemplateDetails);
      }
    }
  }

  const onConnect = (params: any) => {
    //setElements((els) => addEdge({ ...params, animated: true, style: { stroke: "#ee5" } }, els));

    if (!props.isEditMode) {
      return;
    }
    var runTemplateDetails: IRunTemplateDetails = getDeepCopy(props.runTemplateDetails);
    var childProcess = runTemplateDetails.Processes?.find(p => p.ProcessId == Number(params.target)); 
    if (childProcess) {
      let _dependencyInfo: IProcessDependency = {
        ProcessDependencyID: 0,
        FiscalYear: childProcess.FiscalYear,
        ProcessId: Number(params.target),
        DependentProcessID: Number(params.source),
        CanBeExcluded: false,
        IsActive: true,
      };
      childProcess.Dependencies.push(_dependencyInfo);
    }
    props.getRunTemplateDetails(runTemplateDetails);
  }

  const getDepthList = () => {
    let _runTemplateDetailsCopy: IRunTemplateDetails = getDeepCopy(
      props.runTemplateDetails
    );
    let nodeHashKey = new Map<number, any>();

    // Process View
    let _runTemplateDetailsWithDuplicates: IRunTemplateDetails = getDeepCopy(
      props.runTemplateDetails
    );
    _runTemplateDetailsWithDuplicates.Processes!.forEach((node) => {
      if (nodeHashKey.has(node.ProcessId)) {
        let index = _runTemplateDetailsCopy.Processes!.findIndex(
          (d) => d.ProcessId === node.ProcessId
        );
        _runTemplateDetailsCopy.Processes!.splice(index, 1);
      } else {
        nodeHashKey.set(node.ProcessId, node);
      }
    });
    _runTemplateDetailsCopy.Processes!.forEach((node) => {
      let _nodeCopy: IProcessDetails = getDeepCopy(node);
      _nodeCopy.Dependencies.forEach((dep) => {
        if (!nodeHashKey.has(dep.DependentProcessID) || dep.DependencyTypeId == 5) { //'None' dependency type
          let index = node.Dependencies.findIndex(
            (d) => d.DependentProcessID === dep.DependentProcessID
          );
          node.Dependencies.splice(index, 1);
        }
      });
    });

    // Building depth list
    let depth = 0;
    let _depthList = [];
    let nodeToDepthMapping = new Map<number, number>();
    let _nodeListCopy: IProcessDetails[] = getDeepCopy(
      _runTemplateDetailsCopy.Processes
    );
    while (_runTemplateDetailsCopy.Processes!.length > 0) {
      let _currentDepthNodes: { nodeList: IProcessDetails[] } = {
        nodeList: [],
      };
      _nodeListCopy.forEach((node) => {
        let _nodeCopy: IProcessDetails = getDeepCopy(node);
        _nodeCopy.Dependencies.forEach((dep) => {
          if (nodeToDepthMapping.has(dep.DependentProcessID)) {
            // splice dependency
            let index = node.Dependencies.findIndex(
              (d) => d.DependentProcessID === dep.DependentProcessID
            );
            node.Dependencies.splice(index, 1);
          }
        });
      });
      // eslint-disable-next-line no-loop-func
      _nodeListCopy.forEach((node) => {
        if (
          node.Dependencies.length === 0 &&
          !nodeToDepthMapping.has(node.ProcessId)
        ) {
          let index = _runTemplateDetailsCopy.Processes!.findIndex(
            (d) => d.ProcessId === node.ProcessId
          );
          const _nodeWithNoRemainingDependencies = _runTemplateDetailsCopy.Processes!.splice(
            index,
            1
          )[0];
          nodeToDepthMapping.set(node.ProcessId, depth);
          _currentDepthNodes.nodeList.push(_nodeWithNoRemainingDependencies);
        }
      });
      _depthList.push(_currentDepthNodes);
      depth++;
    }
    return _depthList;
  };

  const buildDependencyGraph = (
    depthlist: any,
  ) => {
    let _dependencyGraphElements = [];
    const xGap = 300;
    const yGap = 150;
    let existingNodes = new Map<number, boolean>();
    let existingDependencies = new Map<string, boolean>();
    for (let d = 0; d < depthlist.length; d++) {
      let currentDepthList = depthlist[d];
      for (let n = 0; n < currentDepthList.nodeList.length; n++) {
        let node: IProcessDetails = currentDepthList.nodeList[n];
        if (existingNodes !== undefined && !existingNodes.has(node.ProcessId)) {
          let nodeProps: INodeProps = {
            nodeId: node.ProcessId,
            nodeType: "selectorNode",
            data: {
              color: initBgColor,
              deleteNode: deleteNodeHandler,
              nodeName: node.ProcessName,
              isEditMode: props.isEditMode,
              processId: node.ProcessId
            },
            type: "Process",
            xCoordinate: (d + 1) * xGap,
            yCoordinate: (n + 1) * yGap,
          };
          let z = getNode(nodeProps, elements);
          _dependencyGraphElements.push(z);
          existingNodes.set(node.ProcessId, true);
        }
        node.Dependencies.forEach(dep => {
          let linkId = "link" + dep.DependentProcessID + "-" + dep.ProcessId;
          if (
            existingDependencies !== undefined &&
            !existingDependencies.has(linkId)
          ) {
            let linkProps: ILinkProps = {
              sourceNodeId: dep.DependentProcessID,
              targetNodeId: dep.ProcessId,
              label: dep.DependencyGroupNumber == null ? "" : dep.DependencyGroupNumber.toString(), //dep.CanBeExcluded ? "EXC" : "Not EXC",
              dependencyLabel: "",
              type: "",
              data: {
                canBeExcluded: dep.CanBeExcluded,
                dependencyTypeId: dep.DependencyTypeId,
                dependencyTypeName: dep.DependencyTypeName,
                processId: dep.ProcessId
              }
            };
            let y = getLink(linkProps);
            _dependencyGraphElements.push(y);
            existingDependencies.set(linkId, true);
          }
        });
      }
    }

    return _dependencyGraphElements;
  };

  const nodeWidth = 250;
  const nodeHeight = 100;

  const getLayoutedElements = (elements: any, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });
  
    elements.forEach((el: any) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });
  
    dagre.layout(dagreGraph);
  
    return elements.map((el: any) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? Position.Left : Position.Top;
        el.sourcePosition = isHorizontal ?  Position.Right : Position.Bottom;
  
        // unfortunately we need this little hack to pass a slighltiy different position
        // to notify react flow about the change. More over we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }
  
      return el;
    });
  };

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );

  return (
    <ReactFlowProvider>
      <div className="controls">
          { <button onClick={() => onLayout('LR')}>Auto Layout</button> }
      </div>
      <ReactFlow
        elements={elements}
        onElementClick={onElementClick}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onNodeDragStop={onNodeDragStop}
        style={{ background: "#FFFFFF" }}
        onLoad={onLoad}
        nodeTypes={nodeTypes}
        //connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={[16, 16]}
        defaultZoom={0.4}
      >
        <MiniMap
          nodeColor={(n) => {
            return "#000000";
          }}
        />
        <Controls />
      </ReactFlow>

      {showModal ?  
        <EditProcessDependencyLink 
          showModal={showModal}
          handleModalDismiss={handleModalDismiss}
          title={"Edit Process Dependency"}
          link={selectedLink}
          nodes={[sourceNode, targetNode]}
          onSaveDependencyButtonClick={onSaveDependencyButtonClick}
          onDeleteDependencyButtonClick={onDeleteDependencyButtonClick}
        />
      : ""}
      
    </ReactFlowProvider>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IRunTemplateDependencyGridProps
): IRunTemplateDependencyGridLinkStateProps => {
  return {
    runTemplateDetails: state.runTemplate.runTemplateDetails,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunTemplateDependencyGridProps
): IRunTemplateDependencyGridLinkDispatchProps => ({
  getRunTemplateDetails: bindActionCreators(getRunTemplateDetails, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunTemplateDependencyGrid);
