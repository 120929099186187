import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import Loading from "../../loading/loading";
import {
  IRunTemplateDetailsLinkDispatchProps,
  IRunTemplateDetailsLinkStateProps,
  IRunTemplateDetailsProps,
} from "./models/IRunTemplateDetails";
import RunTemplateInfo from "./run-template-info/run-template-info.controller";
import RunTemplateDependencyGrid from "./run-template-dependency-grid/run-template-dependency-grid.controller";
import { startGetRunTemplateDetails } from "./run-template-details.service";
import EditRunTemplatePanel from "./edit-run-template-panel/edit-run-template-panel.controller";
import { DefaultEffects } from "@fluentui/react";
import { IRunTemplateDetails } from "../../../models/run-template";
import { getRunTemplateDetails } from "../../../redux/actions/run-template-actions";
import { startGetAllDependencyTypes } from "../../process/process-details/process-details.service";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { runTemplateDetailsPageView } from "../run-template.telemtetry-constants";

type Props = IRunTemplateDetailsProps &
  IRunTemplateDetailsLinkDispatchProps &
  IRunTemplateDetailsLinkStateProps &
  any;
const getNewRunTemplateDefinition = () => {
  const newRunTemplate: IRunTemplateDetails = {
    RunTemplateID: 0,
    FiscalYear: 0,
    RunTemplateName: "",
    IsMaster: false,
    IsActive: true,
    LastRunId: 0,
    LastRunExecutionStatus: "",
    CreatedBy: "",
    CreatedOn: new Date(),
    ModifiedBy: "",
    ModifiedOn: new Date(),
    Processes: [],
  };
  return newRunTemplate;
};

export const RunTemplateDetails: React.FunctionComponent<Props> = (props) => {
  const [isEditMode, updateIsEditMode] = useState<boolean>(false);
  const [isFetchingDetails, updateIsFetchingDetails] = useState<boolean>(true);
  // Constructor method
  useEffect(() => {
    TelemetryProvider._trackPageView(runTemplateDetailsPageView);
    if (props.location.state.runTemplateId === 0) {
      // Add new run template
      let runTemplateDetails: IRunTemplateDetails = getNewRunTemplateDefinition();
      runTemplateDetails.FiscalYear = props.fiscalYear;
      props.getRunTemplateDetails(runTemplateDetails);
      updateIsFetchingDetails(false);
    } else {
      props.startGetRunTemplateDetails(
        props.location.state.runTemplateId,
        updateIsFetchingDetails
      );
    }
    props.startGetAllDependencyTypes();
    updateIsEditMode(props.location.state.isEditMode);
  }, []);

  if (!isFetchingDetails) {
    return (
      <>
        <RunTemplateInfo isEditMode={isEditMode} />
        <EditRunTemplatePanel
          isEditMode={isEditMode}
          updateIsFetchingDetails={updateIsFetchingDetails}
        />
        <br />
        <div
          style={{
            boxShadow: DefaultEffects.elevation64,
            padding: "10px",
            height: "75vh",
            width: "100%",
          }}
        >
          <RunTemplateDependencyGrid
            isEditMode={isEditMode}
            updateIsFetchingDetails={updateIsFetchingDetails}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

const mapStateToProps = (
  state: AppState,
  ownProps: IRunTemplateDetailsProps
): IRunTemplateDetailsLinkStateProps => {
  return {
    runTemplateDetails: state.runTemplate.runTemplateDetails,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunTemplateDetailsProps
): IRunTemplateDetailsLinkDispatchProps => ({
  startGetRunTemplateDetails: bindActionCreators(
    startGetRunTemplateDetails,
    dispatch
  ),
  getRunTemplateDetails: bindActionCreators(getRunTemplateDetails, dispatch),
  startGetAllDependencyTypes: bindActionCreators(
    startGetAllDependencyTypes,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunTemplateDetails);
