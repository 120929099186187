import * as React from "react";
import { ICheckboxPropsCustom } from "./models/ICheckbox";
import CheckboxView from "./checkbox.view";

const CheckboxController = (Props: ICheckboxPropsCustom) => {
  return (
    <CheckboxView
      checked={Props.checked}
      onChange={Props.onChange}
      indeterminate={Props.indeterminate}
      key={Props.key}
      id={Props.id}
      className={Props.className}
      label={Props.label}
      disabled={Props.disabled}
      title={Props.title}
      ariaLabel={Props.ariaLabel}
      styles={Props.styles}
    />
  );
};

const Checkbox = (Props: ICheckboxPropsCustom) => {
  return CheckboxController(Props);
};
export default Checkbox;
