import { ISideNavBarItem } from "../../model/ISideNavBar";
import React from "react";
import SideNavNavigateButton from "../../side-nav-bar-buttons/side-nav-navigate-button";
import "./side-nav-bar-leaf-nodes.scss";

// Leaf node data list
const LeafNodes: React.FunctionComponent = (props: any) => {
  return props.navItem.children.map((data: ISideNavBarItem, index: any) => {
    return (
      <li>
        <div key={index + data.name} className="nav-item" tabIndex={-1}>
          <div className="nav-image-container leaf-image"></div>
          <div className="nav-data nav-leaf-data">
            {SideNavNavigateButton(data)}
          </div>
        </div>
      </li>
    );
  });
};

export const getLeafNodes = (nodes: any, isSideNavOpen: boolean) => {
  if (nodes.navItem.children.length > 0) {
    if (nodes.navItem.isExpanded && isSideNavOpen) {
      return <LeafNodes {...nodes} />;
    }
  }
};

export default LeafNodes;
