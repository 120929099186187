import {
    IValidateEntityObject
} from "../../models/validation";
import * as types from "../types/validate-entity-action-types"
import { ValidateEntityActionTypes } from "../types/validate-entity-action-types"

interface IValidateEntityReducer {
    entityValidation?: IValidateEntityObject;
}

const validateEntityReducerDefaultState: IValidateEntityReducer = {};

const validateEntityReducer = (
    state = validateEntityReducerDefaultState,
    action: ValidateEntityActionTypes
): IValidateEntityReducer => {
    switch (action.type) {
        case types.VALIDATE_ENTITY:
            return state;
        default:
            return state;
    }
};

export default validateEntityReducer;