export const config = {
    runsDropDown : [5,10,25,50,75,100],
    corpPowerBiReport : {					 
                    dataValidation: "https://msit.powerbi.com/reportEmbed?reportId=a1595e35-e84a-43d5-a30d-23500c5b7225&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D",
                    factValidation: "https://msit.powerbi.com/reportEmbed?filterPaneEnabled=False&reportId=7fc4fec2-bcc0-4889-b206-3cc52eff38c5&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
    },
    fedPowerBiReport : {
        factValidation:"",
        dataValidation:""
    }
}