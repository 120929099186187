import { IRecurrenceRule } from "../../models/recurrence-rule";

export const GET_RECURRENCE_RULES = "GET_RECURRENCE_RULES";
export const ADD_OR_UPDATE_RULE = "ADD_OR_UPDATE_RULE";

export interface GetRecurrenceRulesAction {
  type: typeof GET_RECURRENCE_RULES;
  rules: IRecurrenceRule[];
}

export interface AddOrUpdateRecurrenceRuleAction {
  type: typeof ADD_OR_UPDATE_RULE;
  rule: IRecurrenceRule;
}

export type RecurrenceRuleActionTypes =
  | GetRecurrenceRulesAction
  | AddOrUpdateRecurrenceRuleAction;
