import * as React from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import PrimaryButton from "../../../common/button/primary-button/primary-button.controller";
import ComboBox from "../../../common/combo-box/combo-box.controller";
import "./edit-run-template-panel.style.scss";
import {
  IComboBox,
  IComboBoxOption
} from "@fluentui/react/lib/ComboBox";
import {
  IEditRunTemplatePanelProps,
  IEditRunTemplatePanelLinkStateProps,
  IEditRunTemplatePanelLinkDispatchProps,
} from "./models/IEditRunTemplatePanel";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { startGetActivityList } from "../../../activity/activity.service";
import { useEffect } from "react";
import { useState } from "react";
import { IActivity, IActivityDependency } from "../../../../models/activity";
import {
  IProcessDependency,
  IProcessDetails,
} from "../../../../models/process";
import { IRunTemplateDetails } from "../../../../models/run-template";
import { getDeepCopy } from "../../../../util/javascript-functions";
import { getRunTemplateDetails } from "../../../../redux/actions/run-template-actions";
import { startGetRunTemplateList } from "../../run-template.service";
import Toggle from "../../../common/toggle/toggle.controller";
import {
  startGetRunTemplateDetails,
  startSaveRunTemplate,
} from "../run-template-details.service";
import { startGetProcessList } from "../../../process/process.service";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import {
  addProcessClickEvent,
  addUpdateRunTemplateClickEvent,
} from "../../run-template.telemtetry-constants";

type Props = IEditRunTemplatePanelProps &
  IEditRunTemplatePanelLinkStateProps &
  IEditRunTemplatePanelLinkDispatchProps;

const buttonStyles = { root: { marginRight: 8 } };

const EditRunTemplatePanel: React.FunctionComponent<Props> = (props) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(
    false
  );
  const [existingNodeList, updateExistingNodeList] = useState<
    IComboBoxOption[]
  >([]);
  const [allProcessList, updateAllProcessList] = useState<IComboBoxOption[]>(
    []
  );
  const [childDependencies, updateChildDependencies] = useState<number[]>([]);
  const [parentDependencies, updateParentDependencies] = useState<number[]>([]);
  const [selectedProcessId, updateSelectedProcessId] = useState<number>(0);

  useEffect(() => {
    props.startGetProcessList(props.fiscalYear);
  }, []);

  useEffect(() => {
    if (props.processList) {
      let allProcesses: IComboBoxOption[] = [];
      let existngAllProcessesList = new Map<number, boolean>();

      props.processList.forEach((process: IProcessDetails) => {
        if (
          !existngAllProcessesList.has(process.ProcessId) &&
          !process.IsSubProcess
        ) {
          let processDetails: IComboBoxOption = {
            key: process.ProcessId,
            text: process.ProcessName,
          };
          allProcesses.push(processDetails);
          existngAllProcessesList.set(process.ProcessId, true);
        }
      });
      updateAllProcessList(allProcesses);
    }
  }, [props.processList]);

  useEffect(() => {
    if (props.runTemplateDetails && props.runTemplateDetails.Processes) {
      let existingProcesses: IComboBoxOption[] = [];
      let exisitingProcessesList = new Map<number, boolean>();
      props.runTemplateDetails.Processes.forEach((process: IProcessDetails) => {
        if (
          !exisitingProcessesList.has(process.ProcessId) &&
          !process.IsSubProcess
        ) {
          let processDetails: IComboBoxOption = {
            key: process.ProcessId,
            text: process.ProcessName,
          };
          existingProcesses.push(processDetails);
          exisitingProcessesList.set(process.ProcessId, true);
        }
      });
      updateExistingNodeList(existingProcesses);
    }
  }, [props.runTemplateDetails]);

  const onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          text="Save"
          onClick={onSaveClick}
          styles={buttonStyles}
        />
        <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
      </div>
    );
  };

  const onProcessSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let processId = parseInt(String(option.key));
      updateSelectedProcessId(processId);
    }
  };

  const onParentDependenciesSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let _currentParentDependencies = getDeepCopy(parentDependencies);
      let processId = parseInt(String(option.key));
      if (option.selected === true) {
        _currentParentDependencies.push(processId);
      } else {
        _currentParentDependencies.splice(
          _currentParentDependencies.indexOf(processId),
          1
        );
      }
      updateParentDependencies(_currentParentDependencies);
    }
  };

  const onChildDependenciesSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let _currentChildDependencies = getDeepCopy(childDependencies);
      let processId = parseInt(String(option.key));
      if (option.selected === true) {
        _currentChildDependencies.push(processId);
      } else {
        _currentChildDependencies.splice(
          _currentChildDependencies.indexOf(processId),
          1
        );
      }
      updateChildDependencies(_currentChildDependencies);
    }
  };

  // ToDo: Add validations for duplicate process selection, overlapping list of proceses/activities in parent and child dependencies
  const onSaveClick = (event?: any) => {
    let _newRunTemplateDefinition: IRunTemplateDetails = getDeepCopy(
      props.runTemplateDetails
    );
    let _newlyAddedProcess: IProcessDetails = getDeepCopy(
      props.processList!.find(
        (process: IProcessDetails) => process.ProcessId === selectedProcessId
      )!
    );
    /*if (_newlyAddedProcess.Dependencies == undefined){
      _newlyAddedProcess.Dependencies = [];
    }

    // Add dependencies from parentDependencies
    parentDependencies.forEach((dependency) => {
      if (
        _newlyAddedProcess.Dependencies.find(
          (d) =>
            d.ProcessId == _newlyAddedProcess.ProcessId &&
            d.DependentProcessID == dependency
        ) == null
      ) {
        let _dependencyDetails = props.processList!.find(
          (process: IProcessDetails) => process.ProcessId === dependency
        );
        let _dependencyInfo: IProcessDependency = {
          ProcessDependencyID: 0,
          FiscalYear: _dependencyDetails?.FiscalYear!,
          ProcessId: _newlyAddedProcess.ProcessId,
          DependentProcessID: _dependencyDetails?.ProcessId!,
          CanBeExcluded: false,
          IsActive: true,
        };
        _newlyAddedProcess.Dependencies.push(_dependencyInfo);
      }
    });

    // Add newly added process to child processes as dependencies
    let _dependencyDetails = props.processList!.find(
      (process: IProcessDetails) => process.ProcessId === selectedProcessId
    )!;
    childDependencies.forEach((dependency) => {
      _newRunTemplateDefinition.Processes!.forEach(
        (process: IProcessDetails) => {
          if (process.ProcessId === dependency) {
            if (process.Dependencies === undefined) {
              process.Dependencies = [];
            }
            if (
              process.Dependencies.find(
                (d) =>
                  d.ProcessId == process.ProcessId &&
                  d.DependentProcessID == _dependencyDetails.ProcessId
              ) == null
            ) {
              let _dependencyInfo: IProcessDependency = {
                ProcessDependencyID: 0,
                FiscalYear: process.FiscalYear,
                ProcessId: process.ProcessId,
                DependentProcessID: _dependencyDetails.ProcessId,
                CanBeExcluded: false,
                IsActive: true,
              };
              process.Dependencies.push(_dependencyInfo);
            }
          }
        }
      );
    });*/
    if (_newRunTemplateDefinition.Processes === undefined) {
      _newRunTemplateDefinition.Processes = [];
    }
    if (
      _newRunTemplateDefinition.Processes.find(
        (p) => p.ProcessId == selectedProcessId
      ) == undefined
    ) {
      _newRunTemplateDefinition.Processes.push(_newlyAddedProcess);
    }

    // Call action to update state.runTemplate.runTemplateDetails
    props.getRunTemplateDetails(_newRunTemplateDefinition);
  };

  const saveRunTemplateCallback = () => {
    // Todo: Retreive the right Process DetailsRowGlobalClassNames. In case of adding a new process?
    props.startGetRunTemplateDetails(
      props.runTemplateDetails ? props.runTemplateDetails.RunTemplateID : 0,
      props.updateIsFetchingDetails
    );
    dismissPanel();
  };

  const errorCallback = () => {
    props.updateIsFetchingDetails(false);
  };

  const onSaveAddUpdateRunTemplateClick = () => {
    console.log(props.runTemplateDetails); //FOR DEBUGGING

    const onSaveConfirmation = window.confirm(
      "Are you sure you want to save the below changes?"
    );
    if (onSaveConfirmation) {
      // TODO: Adding validation checks.

      let _runTemplateDetails: IRunTemplateDetails = getDeepCopy(
        props.runTemplateDetails
      );
      props.updateIsFetchingDetails(true);
      props.startSaveRunTemplate(_runTemplateDetails, saveRunTemplateCallback, errorCallback);
    }
  };

  return (
    <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
      {props.isEditMode ? (
        <div>
          <span style={{ float: "left" }}>
            <ComboBox
              ariaLabel={"Add Process"}
              className="saved-processes-dropdown"
              label={""}
              placeholder="Type/select a Process you want to add."
              options={allProcessList}
              onChange={onProcessSelectionChange}
            />
          </span>

          <span style={{ float: "left", paddingLeft: "10px" }}>
            <PrimaryButton
              text="Add Process"
              onClick={() => {
                TelemetryProvider._trackEvent(addProcessClickEvent);
                onSaveClick();
              }}
            />
          </span>

          <span style={{ float: "right" }}>
            <PrimaryButton
              text="Save"
              onClick={() => {
                TelemetryProvider._trackEvent(addUpdateRunTemplateClickEvent);
                onSaveAddUpdateRunTemplateClick();
              }}
            />
          </span>
        </div>
      ) : (
        ""
      )}

      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        headerText={"Add Process"}
        type={PanelType.custom}
        customWidth={"450px"}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <br />
        <div>
          <ComboBox
            className="saved-processes-dropdown"
            label={"Process"}
            placeholder="Type/select a Process you want to add."
            options={allProcessList}
            onChange={onProcessSelectionChange}
          />
          <ComboBox
            className="saved-processes-dropdown"
            label={"Parent  Process"}
            placeholder="Select all steps that must succeed before the current step."
            multiSelect
            options={existingNodeList}
            onChange={onParentDependenciesSelectionChange}
          />
          <ComboBox
            className="saved-processes-dropdown"
            label={"Child Process"}
            placeholder="Select all steps that will commence after the current step."
            multiSelect
            options={existingNodeList}
            onChange={onChildDependenciesSelectionChange}
          />
        </div>
      </Panel>
      <br />
    </div>
    
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IEditRunTemplatePanelProps
): IEditRunTemplatePanelLinkStateProps => {
  return {
    runTemplateDetails: state.runTemplate.runTemplateDetails,
    processList: state.process.processList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IEditRunTemplatePanelProps
): IEditRunTemplatePanelLinkDispatchProps => ({
  startGetProcessList: bindActionCreators(startGetProcessList, dispatch),
  startGetRunTemplateDetails: bindActionCreators(
    startGetRunTemplateDetails,
    dispatch
  ),
  getRunTemplateDetails: bindActionCreators(getRunTemplateDetails, dispatch),
  startSaveRunTemplate: bindActionCreators(startSaveRunTemplate, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRunTemplatePanel);
