import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import ActivityAddEdit from "./activity-add-edit/activity-add-edit.controller";
import { getLocalTimeZone } from "../../util/time-zone-util";
import * as TelemetryProvider from "../../TelemetryProvider";
import { addNewActivityClickEvent } from "./activity.telemtetry-constants";
import ActivityDetails from "./activity-view/activity-view.controller";

type ActivityViewProps = {
  columnDefs: any[];
  rowData: any[];
  showAddEditActivityModal: boolean;
  updateAddEditActivityModalVisibility: any;
  showActivityDetailModal: boolean;
  updateShowActivityDetailModal: any;
  selectedActivityDetails: any;
  updateSelectedActivityDetails: any;
  isAddActivity: boolean;
  updateIsAddActivity: any;
  isUpdateActivity: boolean;
  updateIsUpdateActivity: any;
  gridCellKeyPress: (event: any) => void;
};
export class ActivityView extends React.Component<ActivityViewProps> {
  returnAddActivityButton = () => {
    return (
      <PrimaryButton
        text="Add New Activity"
        onClick={(event: any) => {
          TelemetryProvider._trackEvent(addNewActivityClickEvent);
          this.props.updateAddEditActivityModalVisibility(true);
          this.props.updateIsAddActivity(true);
          this.props.updateIsUpdateActivity(false);
          this.props.updateSelectedActivityDetails({
            activityId: 0,
            activityName: "",
          });
        }}
      />
    );
  };

  public render(): React.JSX.Element {
    return (
      <div>
        <div className="new-button-div">{this.returnAddActivityButton()}</div>
        <div>
          <div
            className="ag-theme-balham"
            style={{
              height: "80vh",
              width: "100%",
              marginTop:
                "11px" /*, margin: '5px 0px 0px 60px', width: '100%' */,
            }}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={50}
              enableRangeSelection={true}
              pagination={true}
              rowHeight={30}
              enableCellTextSelection={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>
        <div className="time-zone-message">
          *All date and time displayed in the above grid and it's subcomponents
          are in <b>{getLocalTimeZone()} Time Zone</b> (adjusted for daylight
          saving if applicable).
        </div>
        <div>
          <b>Note:</b> Please proceed to Process tab post Activity creation to
          finally add it to a Run Template.
        </div>
        {this.props.showActivityDetailModal === true &&
        this.props.selectedActivityDetails.activityId ? (
          <ActivityDetails
            {...{
              activityId: this.props.selectedActivityDetails.activityId,
              showModal: this.props.showActivityDetailModal,
              updateShowActivityDetailModal: this.props
                .updateShowActivityDetailModal,
              title: "Activity Details",
            }}
          />
        ) : (
          <></>
        )}
        {this.props.showAddEditActivityModal === true &&
        this.props.selectedActivityDetails.activityId !== undefined ? (
          <ActivityAddEdit
            {...{
              activityId: this.props.selectedActivityDetails.activityId,
              activityName: this.props.selectedActivityDetails.activityName,
              isAddActivity: this.props.isAddActivity,
              isUpdateActivity: this.props.isUpdateActivity,
              showModal: this.props.showAddEditActivityModal,
              updateAddEditActivityModalVisibility: this.props
                .updateAddEditActivityModalVisibility,
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ActivityView;
