import React from "react";
import { Checkbox, TextField } from "@fluentui/react";
import { narrowTextFieldStyles } from "../../recurrence-pattern.style";
import { getDeepCopy } from "../../../../../util/javascript-functions";
import { IWeeklyProps } from "../../model/IRecurrenceRulePatternProps";


const WeeklyView: React.FunctionComponent<IWeeklyProps> = (props) => {
  const { selectedWeekDays, weekDaySelection } = props;
  const days = [
    { key: 0, text: "Sunday" },
    { key: 1, text: "Monday" },
    { key: 2, text: "Tuesday" },
    { key: 3, text: "Wednesday" },
    { key: 4, text: "Thursday" },
    { key: 5, text: "Friday" },
    { key: 6, text: "Saturday" },
  ];
  const getCheckBox = (day: any) => {
    const onWeekDaySelection = (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      let list = selectedWeekDays.days.slice();
      if (checked) {
        list.push(day.key);
      } else {
        var index = selectedWeekDays.days.findIndex((a) => a == day.key);
        list.splice(index, 1);
      }
      weekDaySelection({ frequency: selectedWeekDays.frequency, days: list });
    };

    return (
      <Checkbox
        key={day.key}
        label={day.text}
        onChange={onWeekDaySelection}
        className="control-style"
        checked={selectedWeekDays.days.findIndex((a) => a == day.key) > -1}
      />
    );
  };

  const dayControlsSundayToWed = days.slice(0, 4).map((a) => {
    return getCheckBox(a);
  });
  const dayControlsThursdayToSat = days.slice(4, 7).map((a) => {
    return getCheckBox(a);
  });

  const onFrequencyChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    let data = getDeepCopy(selectedWeekDays);
    data.frequency = newValue;
    weekDaySelection(data);
  };
  return (
    <div>
      <div className="flex-box">
        <div>Recur every</div>
        <TextField
          title="weekly"
          value={selectedWeekDays?.frequency}
          onChange={onFrequencyChange}
          styles={narrowTextFieldStyles}
          className="control-style"
        />
        <div>week(s) on:</div>
      </div>
      <br></br>
      <div className="flex-box">{dayControlsSundayToWed}</div>
      <br></br>
      <div className="flex-box">{dayControlsThursdayToSat}</div>
    </div>
  );
};

export default WeeklyView;
