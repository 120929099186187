import * as types from "../types/process-action-types";
import { SourceRefreshActionTypes } from "../types/source-refresh-action-types";

const sourceRefreshDefaultState: any = {}; // Update any to a type if possible

const sourceRefreshReducer = (
  state = sourceRefreshDefaultState,
  action: SourceRefreshActionTypes
): any => {
  switch (action.type) {
	case types.GET_SOURCE_REFRESH_DETAILS: 
	  return Object.assign({}, state, {
		sourceRefreshData: action.sourceRefreshData,
	  })
    default:
      return state;
  }
};

export default sourceRefreshReducer;
