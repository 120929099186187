import * as React from "react";
import { IIconButtonPropsCustom } from "./models/IIconButon";
import { IconButton } from "@fluentui/react";

const IconButtonView = (Props: IIconButtonPropsCustom) => {
  return (
    <IconButton
      // tabIndex={-1}
      iconProps={{
        iconName: Props.iconName,
        style: { fontSize: Props.fontSize },
      }}
      title={Props.title}
      onClick={Props.onClick}
      disabled={Props.disabled}
      ariaLabel={Props.ariaLabel}
      styles={Props.styles}
      onMouseOver={Props.onMouseOver}
    />
  );
};
export default IconButtonView;
