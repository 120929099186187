import React, { useEffect, useReducer, useState } from "react";
import "./activity.style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IActivity } from "../../models/activity";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import {
  formatDateTime,
  formatDateTimeWithoutTimezone,
} from "../../util/time-zone-util";
import { getDate,getFormattedDate } from "../run-dashboard-v2/run-dashboard-v2.helper";
import Loading from "../loading/loading";
import { getColorByStatus } from "../run-dashboard-v2/run-dashboard-v2.helper";
import { getExecutionStatus } from "../run-dashboard-v2/run-dashboard-v2.helper";
import { getExecutionStatusValue } from "../run-dashboard-v2/run-dashboard-v2.helper";
import { startDeleteActivity, startGetActivityList } from "./activity.service";
import ActivityView from "./activity.view";
import {
  IActivityLinkDispatchProps,
  IActivityLinkStateProps,
  IActivityProps,
} from "./models/IActivity";
import IconButton from "../common/button/icon-button/icon-button.controller";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import {
  useAppInsightsContext,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import * as TelemetryProvider from "../../TelemetryProvider";
import {
  activityPageView,
  deleteActivityClickEvent,
  editActivityClickEvent,
  viewActivityClickEvent,
} from "./activity.telemtetry-constants";

type Props = IActivityProps &
  IActivityLinkDispatchProps &
  IActivityLinkStateProps;

export const Activity: React.FunctionComponent<Props> = (props) => {
  const [showAddEditActivityModal, updateShowAddEditActivityModal] = useState<
    boolean
  >(false);
  const [showActivityDetailModal, updateShowActivityDetailModal] = useState<
    boolean
  >(false);
  const [selectedActivityDetails, updateSelectedActivityDetails] = useState({});
  const [isAddActivity, updateIsAddActivity] = useState<boolean>(false);
  const [isUpdateActivity, updateIsUpdateActivity] = useState<boolean>(false);

  useEffect(() => {
    TelemetryProvider._trackPageView(activityPageView);
    props.startGetActivityList(props.fiscalYear);
  }, [props.fiscalYear]);

  const getColDefinitions = () => {
    var columnDefs: ColDef[] = [
      {
        headerName: "Activity Name",
        field: "ActivityName",
        width: 220,
        sortable: true,
        filter: true,
        resizable: true,
        lockPinned: true,
        pinned: "left",
      },
      {
        headerName: "Activity Type",
        field: "ActivityTypeName",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      // {
      //   headerName: "Is Custom Activity",
      //   field: "IsCustomActivity",
      //   width: 140,
      //   sortable: true,
      //   filter: true,
      // },
      {
        headerName: "Dataset Name",
        field: "DatasetName",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Connection Name",
        field: "ConnectionName",
        width: 220,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Last Run Date",
        field: "LastRunDate",
        width: 160,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },
      {
        headerName: "Last Run Id",
        field: "LastRunId",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Execution Status",
        width: 130,
        cellRendererFramework: getExecutionStatus,
        valueGetter: getExecutionStatusValue,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Created By",
        field: "CreatedBy",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Created On",
        field: "CreatedOn",
        width: 160,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate
      },
      {
        headerName: "Modified By",
        field: "ModifiedBy",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Modified On",
        field: "ModifiedOn",
        width: 160,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate
      },
      {
        field: "ContainedByProcesses",
        width: 180,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ContainedByRunTemplates",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Is Active",
        field: "IsActive",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "View",
        width: 60,
        cellRendererFramework: getViewControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: getEditControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: getDeleteControl,
        lockPinned: true,
        pinned: "right",
      },
    ];
    return columnDefs;
  };

  const getViewControl = (params: any) => {
    return (
      <IconButton
        iconName="RedEye"
        fontSize={12}
        title="View Activity"
        onClick={() => {
          TelemetryProvider._trackEvent(viewActivityClickEvent);
          updateShowActivityDetailModal(true);
          updateSelectedActivityDetails({
            activityId: params.data.ActivityId,
            activityName: params.data.ActivityName,
          });
        }}
        ariaLabel="View Activity"
      />
    );
  };

  const getEditControl = (params: any) => {
    return (
      <IconButton
        iconName="SingleColumnEdit"
        fontSize={12}
        title="Edit Activity"
        onClick={() => {
          TelemetryProvider._trackEvent(editActivityClickEvent);
          updateShowAddEditActivityModal(true);
          updateIsAddActivity(false);
          updateIsUpdateActivity(true);
          updateSelectedActivityDetails({
            activityId: params.data.ActivityId,
            activityName: params.data.ActivityName,
          });
        }}
        ariaLabel="Edit Activity"
      />
    );
  };

  const getDeleteControl = (params: any) => {
    return (
      <>
        <IconButton
          iconName="Delete"
          fontSize={12}
          title="Delete Activity"
          disabled={!(params.data.IsActive === "Yes")}
          onClick={(event) => onDeleteClick(params.data)}
          ariaLabel="Delete Activity"
        />
      </>
    );
  };

  const startDeleteActivityCallback = () => {
    props.startGetActivityList(props.fiscalYear);
  };

  const onDeleteClick = (data: any) => {
    TelemetryProvider._trackEvent(deleteActivityClickEvent);
    const onDeleteConfirmation = window.confirm(
      "This will soft delete the selected Activity. Click Ok to confirm."
    );
    if (onDeleteConfirmation) {
      startDeleteActivity(data.ActivityId, startDeleteActivityCallback);
    }
  };

  const getRowData = (activityList: IActivity[]) => {
    let rowData: any[] = [];
    for (let i = 0; i < activityList.length; i++) {
      var obj = activityList[i];

      let processList = "";
      obj.ContainedByProcesses?.forEach(
        (val) => (processList += val.ProcessName + ", ")
      );
      processList = processList.slice(0, -2);

      let runTemplateList = "";
      obj.ContainedByRunTemplates?.forEach(
        (val) => (runTemplateList += val.RunTemplateName + ", ")
      );
      runTemplateList = runTemplateList.slice(0, -2);

      rowData.push({
        FiscalYear: obj.FiscalYear,
        ActivityId: obj.ActivityId,
        ActivityName: obj.ActivityName,
        ActivityTypeName: obj.ActivityTypeName ? obj.ActivityTypeName : "-",
        IsCustomActivity: obj.IsCustomActivity,
        DatasetName: obj.DatasetName ? obj.DatasetName : "-",
        ConnectionName: obj.ConnectionName ? obj.ConnectionName : "-",
        IsActive: obj.IsActive === true ? "Yes" : "No",
        LastRunDate: obj.LastRunDateTimeStamp
          ? obj.LastRunDateTimeStamp
          : "-",
        LastRunId: obj.LastRunId ? parseInt(obj.LastRunId, 10) : 0,
        ExecutionStatus: obj.LastRunExecutionStatus
          ? obj.LastRunExecutionStatus
          : "-",
        CreatedBy: obj.CreatedBy,
        CreatedOn: obj.CreatedOn,
        ModifiedBy: obj.ModifiedBy,
        ModifiedOn: obj.ModifiedOn,
        ContainedByProcesses: processList,
        ContainedByRunTemplates: runTemplateList,
      });
    }
    return rowData;
  };

  const gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "View":
          TelemetryProvider._trackEvent(viewActivityClickEvent);
          updateSelectedActivityDetails({
            activityId: event.data.ActivityId,
            activityName: event.data.ActivityName,
          });
          updateShowActivityDetailModal(true);
          break;
        case "Edit":
          TelemetryProvider._trackEvent(editActivityClickEvent);
          updateShowAddEditActivityModal(true);
          updateSelectedActivityDetails({
            activityId: event.data.ActivityId,
            activityName: event.data.ActivityName,
          });
          break;
        case "Delete":
          if (event.data.IsActive === "Yes") {
            onDeleteClick(event.data.ActivityId);
          }
          break;
        default:
          return;
      }
    }
  };

  if (props.activityList !== undefined && props.activityList != null) {
    var columnDefs: any[] = [];
    var rowData: any[] = [];
    var activityList = props.activityList;

    columnDefs = getColDefinitions();
    rowData = getRowData(activityList);
    const params = {
      columnDefs: columnDefs,
      rowData: rowData,
      showAddEditActivityModal: showAddEditActivityModal,
      updateAddEditActivityModalVisibility: updateShowAddEditActivityModal,
      showActivityDetailModal: showActivityDetailModal,
      updateShowActivityDetailModal: updateShowActivityDetailModal,
      selectedActivityDetails: selectedActivityDetails,
      updateSelectedActivityDetails: updateSelectedActivityDetails,
      isAddActivity: isAddActivity,
      updateIsAddActivity: updateIsAddActivity,
      isUpdateActivity: isUpdateActivity,
      updateIsUpdateActivity: updateIsUpdateActivity,
      gridCellKeyPress: gridCellKeyPress,
    };
    return <ActivityView {...params} />;
  } else {
    return <Loading />;
  }
};

const mapStateToProps = (
  state: AppState,
  ownProps: IActivityProps
): IActivityLinkStateProps => {
  return {
    activityList: state.activity.activityList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IActivityProps
): IActivityLinkDispatchProps => ({
  startGetActivityList: bindActionCreators(startGetActivityList, dispatch),
  startDeleteActivity: bindActionCreators(startDeleteActivity, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
