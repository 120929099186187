import React, { Fragment } from "react";
import { FontIcon } from "@fluentui/react";
import "./add-delete-header.scss";
import { formatDate } from "../../../util/time-zone-util";

type AddDeleteHeaderParams = {
  data: any;
  onAdd: any;
  onDelete: any;
  onTagChange: any;
  excludeKeyName: any[];
};

const AddDeleteHeader: React.FunctionComponent<AddDeleteHeaderParams> = (
  props
) => {
  const onAddClick = () => {
    props.onAdd(props.data.column);
  };

  const onDeleteClick = () => {
    const onDeleteConfirmation = window.confirm(
      "This will delete the selected run for this session. Click Ok to confirm."
    );
    if (onDeleteConfirmation) {
      props.onDelete(props.data.column);
    }
  };

  const onTagClick = (data: any) => {
    const tagName = prompt("Please enter tag name", data.column?.tag);
    data.column.tag = (tagName == "" ? " " : tagName);
    props.onTagChange(props.data.column);
  };

  const deleteView = () => {
    // if (props.data.column?.isCloned) {
    if (
      props.data.column?.runScheduleDateTime &&
      props.data.column.runScheduleDateTime >= new Date()
    ) {
      return (
        <button
          tabIndex={-1}
          title="Clear"
          className="add-delete-button"
          onClick={() => onDeleteClick()}
        >
          <FontIcon iconName="Clear"></FontIcon>
        </button>
      );
    }
  };

  const addView = () => {
    // if (props.data.column?.isCloned) {
    // if (
    //   props.data.column?.runScheduleDateTime &&
    //   new Date(formatDate(props.data.column.runScheduleDateTime)) >=
    //     new Date(formatDate(new Date()))
    // ) {
      return (
        <button
          tabIndex={-1}
          title="Add"
          className="add-delete-button"
          onClick={() => onAddClick()}
        >
          <FontIcon iconName="CalculatorAddition"></FontIcon>
        </button>
      );
    // }
  };

  const tagView = () => {
    // This input is disabled if the scheduled run time has passed
    var isDisabled = props.data.column?.runScheduleDateTime && props.data.column.runScheduleDateTime >= new Date() ? false : true;

    return (
      <span className="ms-Button-flexContainer flexContainer-60" style={{ marginTop: '-20px' }}>
        <span className="ms-Button-textContainer textContainer-61">
          <input
            type="button"
            className="ms-Button-label ms-Button--primary"
            title={
              (props.data.column?.tag || "Tag name not set")
              + " for " + props.data.column?.runScheduleDateTime?.toLocaleString()
              + (isDisabled ? ' (disabled)' : '')
            }
            value={props.data.column?.tag?.length > 10 ? props.data.column?.tag.substring(0,10) + ".." : props.data.column?.tag}
            onClick={() => onTagClick(props.data)}
            style={{width: '95px', position: 'absolute'}}
            disabled={isDisabled}
          >
          </input>
        </span>
      </span>
    );
};

  const view = () => {
    if (!props.excludeKeyName.includes(props.data.column?.key)) {
      return (
        <Fragment>

          <div className="add-delete-container left-align-header">
            {deleteView()}
            <div
              className="add-delete-container-date left-align-header"
              tabIndex={-1}
            >
              <b>{props.data.column?.name}</b>
            </div>
            {addView()}
          </div>
          {tagView()}

          {props.children}
        </Fragment>
      );
    } else if (props.data.column?.name !== "") {
      return (
        <div className="left-align-header">
          {props.data.column?.name}
          <div>{props.children}</div>
        </div>
      );
    } else {
      return props.data.column?.name;
    }
  };
  return view();
};

export default AddDeleteHeader;
