import { dailyPatternControllerService } from "./patterns/daily/daily.controller";
import { weeklyPatternControllerService } from "./patterns/weekly/weekly.controller";
import { monthlyPatternControllerService } from "./patterns/monthly/monthly.controller";
import { yearlyPatternControllerService } from "./patterns/yearly/yearly.controller";
import { setStartTimeForDate } from "../../../util/javascript-functions";

export function getDatesOnPattern(
  selectedRecurrenceOptionKey: string,
  filterStartDate: Date,
  filterEndDate: Date,
  ruleStartDate: Date,
  ruleEndDate: Date,
  selectedDailyDates: Date[] = [],
  selectedWeekDays: { frequency: string; days: number[] } = {
    frequency: "",
    days: [],
  },
  selectedMonthDatesConfig: { frequency: string; dates: string } = {
    frequency: "",
    dates: "",
  },
  selectedYearDatesConfig: {
    frequency: string;
    month: string;
    dates: string;
  } = { frequency: "", month: "", dates: "" }
) {
  // Corner case
  let today = setStartTimeForDate(new Date());
  // past dates
  if (
    (filterStartDate <= today && filterEndDate <= today) ||
    (ruleStartDate <= today && ruleEndDate <= today)
  ) {
    return [];
  }

  if (filterEndDate < ruleStartDate || ruleEndDate < filterStartDate) {
    return [];
  }

  // readjust start date , if its in current month
  if (filterStartDate <= today && filterEndDate > today) {
    let nextDay = today.getDate() + 1;
    today.setDate(nextDay);
    filterStartDate = setStartTimeForDate(today);
  }
  console.log("filter start date",filterStartDate)

  // max date for start
  var startDate =
    filterStartDate >= ruleStartDate ? filterStartDate : ruleStartDate;
    startDate=  setStartTimeForDate(startDate);
  //min date for end
  var endDate = filterEndDate <= ruleEndDate ? filterEndDate : ruleEndDate;
  endDate=  setStartTimeForDate(endDate);

  switch (selectedRecurrenceOptionKey) {
    case "Custom":
      return dailyPatternControllerService(
        startDate,
        endDate,
        selectedDailyDates
      );
    case "Weekly":
      return weeklyPatternControllerService(
        startDate,
        endDate,
        selectedWeekDays
      );
    case "Monthly":
      return monthlyPatternControllerService(
        startDate,
        endDate,
        ruleStartDate,
        selectedMonthDatesConfig
      );
    case "Yearly":
      return yearlyPatternControllerService(
        startDate,
        endDate,
        ruleStartDate,
        selectedYearDatesConfig
      );
  }
  return [];
}
