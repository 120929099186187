import { handleResponse, handleError, handleResponseTyped } from "./api.util";
import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";
import { IRunScheduleStatus } from "../models/run-details";
import { ITemplateRun, ITemplateRunDetails } from "../models/template-run";

const baseUrl = Configurations.mintHubApiBaseUrl + "/TemplateRun/";
var authToken = "";

async function getResponse<T>(url: string, method: string): Promise<T> {
  try {
    const authToken: string = await GetToken();
    const response: Response = await fetch(url, {
      method,
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    return (await handleResponseTyped<{ Result: T }>(response)).Result;
  }
  catch (ex) {
    handleError(ex);
  }
}

/**
 * Gets a list of TemplateRuns based on the specified ExecutionStatus and filtered by fiscalYear and limited to the specified count.
 * 
 * ExecutionStatus map
 * * 1 = Not Started/New
 * * 2 = InProgress (2 will return Runs with 2 & 4)
 * * 3 = Completed (3 will return Runs with 3 & 5)
 * * 4 = Failed
 * * 5 = Cancelled
 * @param ExecutionStatus - The ExecutionStatus used to filter results. 
 * @param count - Number of results to return.
 * @param fiscalYear - If provided, fiscalYear to limit results to.
 * @returns List of TemplateRuns.
 */
export async function getTemplateRunDetails(ExecutionStatus: number, count: number = 5, fiscalYear?: number): Promise<ITemplateRun[]> {
  let templateRunUrl = `${baseUrl}ADF/TemplateRun?executionStatus=${ExecutionStatus}&count=${count}`;
  if (fiscalYear) {
    templateRunUrl += `&fiscalYear=${fiscalYear}`;
  }
  return getResponse(templateRunUrl, "GET");
}

/**
 * Gets the status of the TemplateRun that has the provided runTemplateScheduleID, including Processes.
 * @param runTemplateScheduleID - Used to determine which TemplateRun (or train) to return.
 * @returns The status of the found TemplateRun and it's processes.
 */
export async function getTemplateRunStatus(runTemplateScheduleID: number): Promise<ITemplateRunDetails> {
    return getResponse(`${baseUrl}ADF/TemplateRun/${runTemplateScheduleID}`, "GET")
}

export function getCurrentRunDetails() {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/RunnningStatus?count=5";
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getRunTemplateScheduleStatus(runTemplateScheduleId: number) {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/RunScheduleStatus?runTemplateScheduleId=" +
    runTemplateScheduleId;
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function setRunTemplateScheduleStatus(
  runTemplateScheduleStatus: IRunScheduleStatus
) {
  const url = baseUrl + "ADF/TemplateRun/RunScheduleStatus";
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(runTemplateScheduleStatus),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function pauseRunTemplate(runTemplateScheduleID: number, reason: string, userAlias: string) {
  var obj = { RunTemplateScheduleId: runTemplateScheduleID, Reason: reason, ScheduleModifiedBy: userAlias };
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/Pause";
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(obj),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function cancelRunTemplate(runTemplateScheduleID: number, reason: string, userAlias: string) {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/Cancel";
  var obj = { RunTemplateScheduleId: runTemplateScheduleID, Reason: reason, ScheduleModifiedBy: userAlias };
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(obj),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function resumeRunTemplate(runTemplateScheduleID: number, reason: string, userAlias: string) {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/Resume";
  var obj = { RunTemplateScheduleId: runTemplateScheduleID, Reason: reason, ScheduleModifiedBy: userAlias };
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(obj),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function reRunFromFailedRunTemplate(runTemplateScheduleID: number, reason: string, userAlias: string, processInfo?: any) {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/Retrigger";
  var obj: any;
  if (processInfo == undefined) {
    obj = { RunTemplateScheduleId: runTemplateScheduleID, Reason: reason, ScheduleModifiedBy: userAlias }
  }
  else {
    obj = { RunTemplateScheduleId: runTemplateScheduleID, Processes: processInfo.Processes, Reason: reason, ScheduleModifiedBy: userAlias };
  }
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(obj),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function updateProcessStatus(runTemplateScheduleID: number, processInfo?: any) {
  const scheduleUrl =
    baseUrl +
    "ADF/TemplateRun/UpdateProcessStatus";
  var obj: any;
  if (processInfo == undefined) {
    obj = { RunTemplateScheduleId: runTemplateScheduleID }
  }
  else {
    var Processes = [processInfo];
    obj = { RunTemplateScheduleId: runTemplateScheduleID, Processes };
  }
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(obj),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}