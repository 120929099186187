import { Dispatch } from "react";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import * as ActivityAPI from "../../api/activity-api";
import {
  IActivity,
  IActivityMetadata,
  IActivityParameter,
  IActivityType,
} from "../../models/activity";
import * as ActivityActions from "../../redux/actions/activity-actions";
import * as NewConnectionActions from "../../redux/actions/new-connection-action";
import { utcToBrowserTimeZone } from "../../util/time-zone-util";
import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { Connection } from "../../models/connection";

const updateRunScheduleDataTimeZineToLocal = (activityList: IActivity[]) => {
  activityList.forEach((activity) => {
    if (activity.LastRunDateTimeStamp) {
      activity.LastRunDateTimeStamp = utcToBrowserTimeZone(
        activity.LastRunDateTimeStamp
      );
    }
    activity.CreatedOn = utcToBrowserTimeZone(activity.CreatedOn);
    activity.ModifiedOn = utcToBrowserTimeZone(activity.ModifiedOn);
  });
  return activityList;
};

const sortActivityList = (activityList: IActivity[]) => {
  // Default sort by FiscalYear DESC, Activity Name ASC
  activityList.sort((a, b) =>
    a.ActivityName.toUpperCase() > b.ActivityName.toUpperCase()
      ? 1
      : b.ActivityName.toUpperCase() > a.ActivityName.toUpperCase()
      ? -1
      : 0
  );
  activityList.sort((a, b) =>
    a.FiscalYear < b.FiscalYear ? 1 : b.FiscalYear < a.FiscalYear ? -1 : 0
  );
};

export const startGetActivityList = (fiscalYear: number) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ActivityAPI.getActivities(fiscalYear)
      .then((value) => {
        const activityList: IActivity[] = updateRunScheduleDataTimeZineToLocal(
          value.Result
        );
        sortActivityList(activityList);
        dispatch(ActivityActions.getActivityList(activityList));
      })
      .catch((error) => {
        
      });
  };
};

export const startDeleteActivity = (
  activityId: number,
  startDeleteActivityCallback: any
) => {
  ActivityAPI.deleteActivity(activityId)
    .then((value) => {
      window.alert("Activity deleted successfully.");
      if (startDeleteActivityCallback) startDeleteActivityCallback();
    })
    .catch((error) => {
    });
};

export const startGetActivityDetails = (
  activityId: number,
  populateActivityDetailsCallback?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ActivityAPI.getActivityDetails(activityId)
      .then((value) => {
        const _activityDetails: IActivity = value.Result;
        dispatch(ActivityActions.getActivityDetails(_activityDetails));
        if (populateActivityDetailsCallback) populateActivityDetailsCallback();
      })
      .catch((error) => {
      });
  };
};

const sortConnectionList = (connectionList: IComboBoxOption[]) => {
  // Default sort by Connection Name ASC
  connectionList.sort((a: IComboBoxOption, b: IComboBoxOption) =>
    a.text.toUpperCase() > b.text.toUpperCase()
      ? 1
      : b.text.toUpperCase() > a.text.toUpperCase()
      ? -1
      : 0
  );
};

const sortActivityMetadata = (activityMetadata: IActivityMetadata) => {
  if (activityMetadata.ActivityTypes) {
    activityMetadata.ActivityTypes.sort((a: IActivityType, b: IActivityType) =>
      a.ActivityTypeName.toUpperCase() > b.ActivityTypeName.toUpperCase()
        ? 1
        : b.ActivityTypeName.toUpperCase() > a.ActivityTypeName.toUpperCase()
        ? -1
        : 0
    );
  }
  if (activityMetadata.Connections) {
    activityMetadata.Connections.sort((a: Connection, b: Connection) =>
      a.ConnectionName!.toUpperCase() > b.ConnectionName!.toUpperCase()
        ? 1
        : b.ConnectionName!.toUpperCase() > a.ConnectionName!.toUpperCase()
        ? -1
        : 0
    );
  }
  if (activityMetadata.Parameters) {
    activityMetadata.Parameters.sort(
      (a: IActivityParameter, b: IActivityParameter) =>
        a.ParameterName.toUpperCase() > b.ParameterName.toUpperCase()
          ? 1
          : b.ParameterName.toUpperCase() > a.ParameterName.toUpperCase()
          ? -1
          : 0
    );
  }
};

export const startGetActivityMetadata = (fiscalYear: number) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ActivityAPI.getActivityMetadata(fiscalYear)
      .then((value) => {
        const activityMetadata: IActivityMetadata = value.Result;
        sortActivityMetadata(activityMetadata);
        dispatch(ActivityActions.getActivityMetadata(activityMetadata));
        // Add Activity Type List to Redux Store
        let activityTypeList: IDropdownOption[] = [];
        activityMetadata.ActivityTypes.forEach((activityType) => {
          activityTypeList.push({
            key: activityType.ActivityTypeId,
            text: activityType.ActivityTypeName,
          });
        });
        let connectionList: IComboBoxOption[] = [];
        activityMetadata.Connections.forEach((connection: Connection) => {
          connectionList.push({
            // tslint:disable-next-line: radix
            key: connection.ConnectionId!,
            text: connection.ConnectionName!,
          });
        });
        dispatch(ActivityActions.getActivityTypeList(activityTypeList));
        sortConnectionList(connectionList);
        dispatch(NewConnectionActions.getConnectionList(connectionList));
        // Add Activity Parameter List to Redux Store
        let activityParameterList: IDropdownOption[] = [];
        activityMetadata.Parameters.forEach((activityParameter) => {
          activityParameterList.push({
            key: activityParameter.ParameterId,
            text: activityParameter.ParameterName,
          });
        });
        dispatch(
          ActivityActions.getActivityParameterList(activityParameterList)
        );
      })
      .catch((error) => {
      });
  };
};

export const startSaveActivity = (
  activity: IActivity,
  saveActivityCallback?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ActivityAPI.saveActivity(activity)
      .then((value) => {
        window.alert(
          "Activity '" + activity.ActivityName + "' saved successfully."
        );
        if (saveActivityCallback) saveActivityCallback();
      })
      .catch((error) => {
      });
  };
};
