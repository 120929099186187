import * as React from "react";
import {
  IRunScheduleListProps,
  RunScheduleListLinkDispatchProps,
  RunScheduleListLinkStateProps,
  IRunScheduleListState,
} from "./models/IRunScheduleList";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import {
  startGetRunTemplateScheduleData,
  startSaveRunTemplateScheduleData,
  updateRunScheduleData,
} from "./run-schedule-list.service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateRunTemplateScheduleColumnData } from "../../../redux/actions/run-schedule-actions";
import "./run-schedule-list.style.scss";
import {
  IRunSchedule,
  IScheduledDates,
  IRunScheduleData,
  IRunTemplateSchedule,
  IRunScheduleProcess,
  IRunScheduleFiltersData,
  IRunScheduleParameters,
} from "../../../models/run-schedule";
import {
  IRunScheduleColumn,
  IRunScheduleInfoList,
} from "../models/IRunSchedule";

import Checkbox from "../../common/checkbox/checkbox.controller";
import {
  getDeepCopy,
  extractTimeInZuluFormat,
  setStartTimeForDate,
  setEndTimeForDate,
} from "../../../util/javascript-functions";
import {
  createProcess,
  createRunScheduleInfoItem,
  isProcessNotPresentInMapStateToProcessList,
  getRecurrenceRuleColumn,
  getProcessNameColumn,
  getNoRunScheduleColumn,
  deleteRunScheduleAndGetUpdatedList,
  getClonedRunSchedule,
  createRunSchedule,
  getRandomIdForFirstRunScheduleOnThatDay,
  getRunScheduleIndex,
  getNonSubProcessListInRunSchedule,
  getRunScheduleRecord,
  getRunScheduleProcessList,
  getProcessInAllProcessList,
  getRunScheduleColumnForTheDay,
  otherSubProcessGroupSelected,
  allSubProcessGroupsSelected,
  createRunScheduleWithEmptyProcess,
  getDefaultStartTmeForFutureDates,
} from "./run-schedule-list.helper";
import { RunScheduleListView } from "./run-schedule-list.view";
import { Prompt } from "react-router-dom";
import CreditingPreview from "./../crediting-preview/creditingpreview";
import { CreditingEntry } from "../enum/creditingEntryPoint";
import {
  formatDateTime,
  formatTime,
  formatDate,
  formatDateTimeWithoutTimezone,
} from "../../../util/time-zone-util";
import { startGetRecurrenceRuleList } from "../recurrence-rule/recurrence-rule.service";
import Loading from "../../loading/loading";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  cancelClickEvent,
  checkboxClickEvent,
  cloneScheduleClickEvent,
  deleteScheduleClickEvent,
  saveClickEvent,
} from "../run-schedule.telemtetry-constants";

type Props = IRunScheduleListProps &
  RunScheduleListLinkDispatchProps &
  RunScheduleListLinkStateProps;

export class RunScheduleList extends React.Component<
  Props,
  IRunScheduleListState
> {
  // properties
  runScheduleInfoList: IRunScheduleInfoList[] = [];
  // default values
  runTemplateScheduleData: IRunScheduleData = {
    RunTemplateId: 0,
    RunTemplateName: "0",
    FiscalYear: 0,
    StartDateTime: new Date(),
    EndDateTime: new Date(),
    RunTemplateSchedule: [],
    Processes: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isGridAtSubProcessLevel: false,
      runTemplateName: props.runTemplateScheduleData.RunTemplateName,
      selectedParentProcessName: undefined,
      selectedParentProcessId: undefined,
    };
    this.updateDrillDownLevel = this.updateDrillDownLevel.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  onUnload = (e: any) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    if (this.props.runTemplateScheduleData.isDirty) {
      e.returnValue = "You have unsaved changes. Do you still want to proceed?";
    }
  };
  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);

    if (this.props.startGetRecurrenceRuleList) {
      this.props.startGetRecurrenceRuleList();
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.runTemplateScheduleData.isDirty === false) {
      this.setState({
        isGridAtSubProcessLevel: false,
        runTemplateName: this.props.runTemplateScheduleData.RunTemplateName,
        selectedParentProcessName: undefined,
        selectedParentProcessId: undefined,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.updateRunScheduleData !== undefined) {
      this.props.updateRunScheduleData({});
    }
  }

  //*********** Properties ****************************************

  getRunScheduleList(): IRunTemplateSchedule[] {
    return this.runTemplateScheduleData.RunTemplateSchedule;
  }

  getAllProcessList() {
    return this.runTemplateScheduleData.Processes;
  }

  getProcessInAllProcessList(key: any) {
    return getProcessInAllProcessList(this.runTemplateScheduleData, key);
  }

  getRunScheduleIndex(id: any): number {
    return getRunScheduleIndex(this.getRunScheduleList(), id);
  }

  getNonSubProcessListInRunSchedule(index: any) {
    return getNonSubProcessListInRunSchedule(this.getRunScheduleList(), index);
  }

  getRunScheduleRecord(id: any) {
    return getRunScheduleRecord(this.getRunScheduleList(), id);
  }

  getRunScheduleProcessList(id: any) {
    return getRunScheduleProcessList(this.getRunScheduleList(), id);
  }

  onAddClick = (column: IRunScheduleColumn) => {
    //Logic
    // 1. Find the runschedule record of the selected column
    // 2. Get the index
    // 3. Create a copy of the runschedule
    // 4. Assign new id temp
    // 5. Add the element in the runschedule list
    // 6. update the state
    // Edge case : if no runschedule was assigned: pop-up message
    TelemetryProvider._trackEvent(cloneScheduleClickEvent);

    var scheduleData: IRunTemplateSchedule[] = this.getRunScheduleList(); //templateData.RunTemplateSchedule;
    var scheduleIndex = this.getRunScheduleIndex(column.runScheduleId);

    if (scheduleIndex == -1) {
      alert("Run schedule not assigned for the day.");
      return;
    }

    var nonSubProcessCount = this.getNonSubProcessListInRunSchedule(
      scheduleIndex
    ).length;

    if (nonSubProcessCount == 0) {
      alert("No processes have been assigned to the run schedule.");
      return;
    }
    scheduleData = getClonedRunSchedule(
      scheduleData,
      scheduleIndex + 1,
      column.runScheduleId
    );
    this.updateState(scheduleData);
  };

  onDeleteClick = (column: IRunScheduleColumn) => {
    //Logic
    // 1. get the runschedule id of the column
    // 2. filter out that item
    // 3. update the state

    TelemetryProvider._trackEvent(deleteScheduleClickEvent);
    var filteredData: IRunTemplateSchedule[] = deleteRunScheduleAndGetUpdatedList(
      this.getRunScheduleList(),
      column.runScheduleId
    );
    this.updateState(filteredData);
  };

  mapStateToProcessListAtProcessLevel(
    mapStateToProcessList: Map<number, IRunSchedule>
  ) {
    this.runTemplateScheduleData?.Processes.forEach(
      (process: IRunScheduleProcess) => {
        if (
          isProcessNotPresentInMapStateToProcessList(
            mapStateToProcessList,
            process
          )
        ) {
          const processDetails: IRunSchedule = createProcess(process);
          mapStateToProcessList.set(process.ProcessId, processDetails);
        }
      }
    );
  }

  mapStateToProcessListAtSubProcessLevel(
    mapStateToProcessList: Map<number, IRunSchedule>
  ) {
    const selectedProcessIndex = this.runTemplateScheduleData?.Processes.findIndex(
      (process: IRunScheduleProcess) =>
        process.ProcessName === this.state.selectedParentProcessName
    );
    this.runTemplateScheduleData?.Processes[
      selectedProcessIndex
    ].ChildProcesses?.forEach((process: IRunScheduleProcess) => {
      if (
        isProcessNotPresentInMapStateToProcessList(
          mapStateToProcessList,
          process
        )
      ) {
        const processDetails: IRunSchedule = createProcess(process);
        mapStateToProcessList.set(process.ProcessId, processDetails);
      }
    });
  }

  addScheduleDetailsToMappedProcesses(
    schedule: IRunTemplateSchedule,
    scheduledProcesses: IRunScheduleProcess[],
    mapStateToProcessList: Map<number, IRunSchedule>
  ) {
    if (scheduledProcesses !== undefined && scheduledProcesses.length > 0) {
      scheduledProcesses.forEach((scheduledprocess: IRunScheduleProcess) => {
        if (mapStateToProcessList.get(scheduledprocess.ProcessId) != null) {
          var item = mapStateToProcessList.get(scheduledprocess.ProcessId);
          item?.scheduledDates?.push(new Date(schedule.ScheduleDateTime));
          item?.runScheduleIdList?.push(schedule.RunTemplateScheduleId);
        }
      });
    }
  }

  getItemList() {
    // To store sub/process level data based on drilldown
    var mapStateToProcessList = new Map<number, IRunSchedule>();
    this.runScheduleInfoList = [];

    // Process Level
    if (this.state.isGridAtSubProcessLevel === false) {
      // Obtain the full set of processes into a map
      this.mapStateToProcessListAtProcessLevel(mapStateToProcessList);
    }
    // Sub Process Level
    else {
      this.mapStateToProcessListAtSubProcessLevel(mapStateToProcessList);
    }
    // Add Schedule Details to Mapped Processes in mapStateToProcessList
    this.runTemplateScheduleData.RunTemplateSchedule.forEach(
      (schedule: IRunTemplateSchedule) => {
        this.runScheduleInfoList.push(createRunScheduleInfoItem(schedule));

        // Process Level
        if (this.state.isGridAtSubProcessLevel === false) {
          this.addScheduleDetailsToMappedProcesses(
            schedule,
            schedule.Processes,
            mapStateToProcessList
          );
        }
        // Sub Process Level
        else {
          schedule.Processes.forEach(
            (scheduledProcess: IRunScheduleProcess) => {
              if (
                scheduledProcess.ProcessName ===
                this.state.selectedParentProcessName
              ) {
                this.addScheduleDetailsToMappedProcesses(
                  schedule,
                  scheduledProcess.ChildProcesses!,
                  mapStateToProcessList
                );
              }
            }
          );
        }
      }
    );

    // Get all items list
    let scheduleditems: IRunSchedule[] = [];
    if (mapStateToProcessList.size > 0) {
      mapStateToProcessList.forEach((processvalue) => {
        scheduleditems.push(processvalue);
      });
    }
    return scheduleditems;
  }

  getColumnList() {
    let schedulingDates: any;
    if (this.props.filters && this.props.filters.gridDateRange) {
      schedulingDates = this.props.filters.gridDateRange;
    } else {
      const startdate: any = setStartTimeForDate(
        new Date(this.props.runTemplateScheduleData.StartDateTime)
      );
      const enddate: any = setEndTimeForDate(
        new Date(this.props.runTemplateScheduleData.EndDateTime)
      );
      const numMillisecondsInADay = 24 * 60 * 60 * 1000;
      const diffTime = Math.abs(startdate - enddate);
      const diffDays = Math.round(diffTime / numMillisecondsInADay);  // Account for DST
      schedulingDates = {
        startDate: startdate,
        endDate: enddate,
        noOfDays: diffDays,
      };
    }

    return this.fetchColumns(
      schedulingDates,
      this.runScheduleInfoList,
      this.props.runTemplateScheduleData
    );
  }

  // Building columns
  fetchColumns(
    schedulingDates: IScheduledDates,
    runScheduleList: IRunScheduleInfoList[],
    runTemplateScheduleData: IRunScheduleData
  ) {
    const columns: IRunScheduleColumn[] = [];
    // insert fixed columns
    columns.push(getRecurrenceRuleColumn());
    columns.push(
      getProcessNameColumn(
        schedulingDates,
        runTemplateScheduleData,
        this.updateDrillDownLevel
      )
    );

    const startdate: any = setStartTimeForDate(
      new Date(this.props.runTemplateScheduleData.StartDateTime)
    );
    const enddate: any = setEndTimeForDate(
      new Date(this.props.runTemplateScheduleData.EndDateTime)
    );
    // insert date related columns
    for (let dayIndex = 1; dayIndex <= schedulingDates?.noOfDays; dayIndex++) {
      var runScheduleListForTheDay: IRunScheduleInfoList[] = runScheduleList.filter(
        (a) => {
          return (
            startdate <= a.dateTime &&
            enddate >= a.dateTime &&
            a.date.toString() ==
              (schedulingDates.startDate.getDate() + dayIndex - 1).toString()
          );
        }
      );
      runScheduleListForTheDay.sort((a, b) =>
        a.dateTime > b.dateTime ? 1 : b.dateTime > a.dateTime ? -1 : 0
      );
      // Set Date
      var date = new Date(schedulingDates.startDate);
      date.setDate(date.getDate() + dayIndex - 1);
      // when there is no runschedule for the day
      if (runScheduleListForTheDay.length == 0) {
        columns.push(this.getNoRunScheduleColumnForTheDay(date));
      } else {
        runScheduleListForTheDay.forEach((element: IRunScheduleInfoList) => {
          columns.push(this.getRunScheduleColumnForTheDay(date, element));
        });
      }
    }

    return columns;
  }

  getNoRunScheduleColumnForTheDay(date: Date): IRunScheduleColumn {
    const column = getNoRunScheduleColumn(date.getDate().toString());
    column.runScheduleDateTime = date;
    column.isPastDate = column.runScheduleDateTime < new Date();
    column.isNoScheduleColumn = true;
    if (column.isPastDate === false) {
      const defaultStartTime = getDefaultStartTmeForFutureDates(date);
      column.runScheduleDateTime.setHours(
        parseInt(defaultStartTime.Hours),
        parseInt(defaultStartTime.Mins)
      );

      column.runScheduleTime =
        defaultStartTime.Hours + ":" + defaultStartTime.Mins;
    }
    column.onRender = (
      item: IRunSchedule,
      index?: number,
      column?: IRunScheduleColumn
    ) => {
      return this.getCheckbox(item, index, column, true);
    };
    return column;
  }

  getRunScheduleColumnForTheDay(
    date: Date,
    element: IRunScheduleInfoList
  ): IRunScheduleColumn {
    const column = getRunScheduleColumnForTheDay(date, element);
    column.runScheduleDateTime = element.dateTime;
    column.isPastDate = column.runScheduleDateTime < new Date();
    column.isNoScheduleColumn = false;
    column.onRender = (
      item: IRunSchedule,
      index?: number,
      column?: IRunScheduleColumn
    ) => {
      return this.getCheckbox(item, index, column);
    };
    return column;
  }

  getCheckbox = (
    item: IRunSchedule,
    index?: number,
    column?: IRunScheduleColumn,
    isNoRunScheduleColumn?: boolean
  ): any => {
    let isSchd = false;
    let isDisabled = false;
    let formattedDate = "";
    formattedDate = formatDateTime(column?.runScheduleDateTime!);

    if (
      column?.runScheduleDateTime &&
      column.runScheduleDateTime < new Date()
    ) {
      // Enabling today's checkboxes in case there is no schedule
      if (
        !!isNoRunScheduleColumn &&
        formatDate(column.runScheduleDateTime) === formatDate(new Date())
      ) {
        isDisabled = false;
      } else {
        isDisabled = true;
      }
    }
    if (item.runScheduleIdList && column?.runScheduleId) {
      isSchd = item.runScheduleIdList.includes(column?.runScheduleId);
    }

    const onCheckBoxStatusChange = () => {
      TelemetryProvider._trackEvent(checkboxClickEvent);
      this.checkBoxChangeHandler(item, column);
    };

    const checkBox = (
      <Checkbox
        checked={isSchd}
        onChange={onCheckBoxStatusChange}
        indeterminate={isSchd}
        id={item.name + "/" + column?.runScheduleId + "/" + column?.key}
        disabled={isDisabled}
        title={"Select for scheduling " + item.name + " on " + formattedDate}
      />
    );

    if (isSchd && item.name.includes("Credit") && column?.runScheduleDateTime) {
      var date: any = this.getRunScheduleRecord(column?.runScheduleId)
        ?.ScheduleDateTime;

      var timeInFormat: any = formatTime(date);
      return (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            {checkBox}
            <div style={{ marginTop: -4 }}>
              <CreditingPreview
                type={CreditingEntry.Local}
                selectedRunTemplateName={
                  this.props.runTemplateScheduleData.RunTemplateName
                }
                startDate={column.runScheduleDateTime}
                endDate={column.runScheduleDateTime}
                selectedyear={this.props.runTemplateScheduleData.FiscalYear}
                time={timeInFormat}
                runScheduleRecord={this.getRunScheduleRecord(
                  column.runScheduleId
                )}
              />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      {
        return checkBox;
      }
    }
  };

  addOrDeleteProcessToSchedule(
    runScheduleRecord: any,
    processList: any,
    item: any
  ) {
    // Process Details
    var processList = runScheduleRecord.Processes;
    var processIndex = processList?.findIndex((a: any) => {
      return a.ProcessId == item.key;
    });

    // Delete
    if (processIndex > -1) {
      processList.splice(processIndex, 1);
    }
    // Add
    else {
      // get process from process list and add it for runschedule process list
      var processObj = this.getProcessInAllProcessList(item.key);
      // Add only if process does not have sub groups
      if (allSubProcessGroupsSelected(processObj) !== true) {
        processList.splice(0, 0, processObj);
      } else {
        alert("Please select a sub-proccess within this process.");
        return;
      }
    }
  }

  addOrDeleteSubProcessToSchedule(
    runScheduleRecord: any,
    processList: any,
    item: any
  ) {
    const parentProcessIndex = processList?.findIndex((a: any) => {
      return a.ProcessId == this.state.selectedParentProcessId;
    });
    var processObj = this.getProcessInAllProcessList(
      this.state.selectedParentProcessId
    );
    const selectedProcess = processObj?.ChildProcesses?.find(
      (subProcess: IRunScheduleProcess) => {
        return subProcess.ProcessId === item.key;
      }
    );

    if (parentProcessIndex === -1) {
      // Add process with selected child process
      if (
        processObj?.ChildProcesses !== undefined &&
        selectedProcess !== undefined
      ) {
        processObj.ChildProcesses = [selectedProcess];
      }
      processList.splice(0, 0, processObj);
    }
    // Add or delete sub process in an existing process
    else {
      let scheduledChildProcesses =
        processList[parentProcessIndex].ChildProcesses;
      const childProcessIndex = scheduledChildProcesses?.findIndex((a: any) => {
        return a.ProcessId == item.key;
      });
      // Delete
      if (childProcessIndex > -1) {
        // scheduledChildProcesses.splice(childProcessIndex, 1);
        processList[parentProcessIndex].ChildProcesses.splice(
          childProcessIndex,
          1
        );

        // If only sub process in the entire process, remoce process as well from the schedule
        if (processList[parentProcessIndex].ChildProcesses.length === 0) {
          processList.splice(parentProcessIndex, 1);
        }
      }
      // Add
      else {
        // Add sub process at start only if no other subprocess group is selected
        if (
          otherSubProcessGroupSelected(
            scheduledChildProcesses,
            selectedProcess
          ) !== true
        ) {
          processList[parentProcessIndex].ChildProcesses.splice(
            0,
            0,
            selectedProcess
          );
        } else {
          alert("Please select only one sub-process from the below list.");
          return;
        }
      }
    }
  }

  checkBoxChangeHandler(item: IRunSchedule, column?: IRunScheduleColumn) {
    /// Logic
    /// 1. if process is present for run schedule : delete
    /// 2. else add
    /// Edge case : if runscheduleid is null : need to Add with empty processlist

    var data: IRunScheduleData = getDeepCopy(
      this.props.runTemplateScheduleData
    );
    var scheduleData: IRunTemplateSchedule[] = this.getRunScheduleList();
    if (!column?.runScheduleTime || column?.runScheduleTime === "") {
      window.alert(
        "Please set start time in order to schedule processes for this Run."
      );
      return;
    }
    if (column?.runScheduleId == 0) {
      // If column not assigned with runschedule
      this.addRunSchedule(data, scheduleData, item, column);
      return;
    }

    // Run schedule Record
    var scheduleIndex = this.getRunScheduleIndex(column?.runScheduleId);
    var runScheduleRecord: any = this.getRunScheduleRecord(
      column?.runScheduleId
    );

    // Process Details
    var processList = runScheduleRecord.Processes;

    // Add process -> Add all subprocesses in any
    // Add sub process -> Add parent process in case not in the list
    // Delete process
    // Delete sub process -> Delete process if only subprocess
    if (this.state.isGridAtSubProcessLevel !== true) {
      this.addOrDeleteProcessToSchedule(runScheduleRecord, processList, item);
    } else {
      this.addOrDeleteSubProcessToSchedule(
        runScheduleRecord,
        processList,
        item
      );
    }
    // Update Process list
    var newRunScheduleRecord = Object.assign(
      {},
      { ...runScheduleRecord, Processes: processList }
    );

    scheduleData[scheduleIndex] = newRunScheduleRecord;
    this.updateState(scheduleData);
  }

  addRunSchedule(
    data: IRunScheduleData,
    scheduleData: IRunTemplateSchedule[],
    item: any,
    column: IRunScheduleColumn,
    processStartTime?: Date
  ) {
    let parentProcessIndex;
    if (this.state.isGridAtSubProcessLevel) {
      parentProcessIndex = data.Processes.findIndex((proc) => {
        return proc.ProcessName === this.state.selectedParentProcessName;
      });
    }
    var runSchedule = createRunSchedule(
      data,
      item,
      getRandomIdForFirstRunScheduleOnThatDay(column.name), //  random id
      extractTimeInZuluFormat(
        column.runScheduleDateTime!,
        column.runScheduleTime!
      ),
      processStartTime,
      parentProcessIndex,
      this.state.isGridAtSubProcessLevel
    );
    if (runSchedule == undefined) {
      alert("Please select a sub-proccess within this process.");
      return;
    }
    scheduleData.splice(0, 0, runSchedule);
    this.updateState(scheduleData);
  }

  updateState(scheduleData: IRunTemplateSchedule[]) {
    this.props.updateRunTemplateScheduleData({
      RunTemplateSchedule: scheduleData,
    });
  }

  // For not saving schedules for past dates
  decreasePayload = (runTemplateScheduleData: IRunScheduleData) => {
    runTemplateScheduleData.RunTemplateSchedule = runTemplateScheduleData.RunTemplateSchedule.filter(
      (schedule) => {
        return new Date(schedule.ScheduleDateTime) > new Date();
      }
    );
    if (runTemplateScheduleData.StartDateTime < new Date()) {
      runTemplateScheduleData.StartDateTime = new Date();
    }
    return runTemplateScheduleData;
  };

  addRunScheduleWithTag = (scheduleData: any, column: any, time: any) => {
    var runSchedule = createRunScheduleWithEmptyProcess(
      getRandomIdForFirstRunScheduleOnThatDay(column.name), //  random id
      extractTimeInZuluFormat(column?.runScheduleDateTime, time)
    );
    runSchedule.Parameters = [];
    var tagParameter: IRunScheduleParameters = {
      ParameterId: 0,
      ParameterName: "TagName",
      ParameterValue: column.tag?.trim(),
    };
    runSchedule.Parameters.push(tagParameter);
    scheduleData.splice(0, 0, runSchedule);
    this.updateState(scheduleData);
  };

  onTagChange = (column: IRunScheduleColumn) => {
    // runschedule not present for the day
    if (column?.runScheduleId == 0) {
      this.addRunScheduleWithTag(
        this.runTemplateScheduleData.RunTemplateSchedule,
        column,
        column.runScheduleTime
      );
    } else {
      var matched = this.runTemplateScheduleData.RunTemplateSchedule.filter(
        (a) => {
          return a.RunTemplateScheduleId == column.runScheduleId;
        }
      );
      if (matched != null && matched.length > 0) {
        var matchedSchedule = matched[0];
        if (matchedSchedule.Parameters == null) {
          matchedSchedule.Parameters = [];
        }
        var parameter = matchedSchedule.Parameters.find(
          (a: any) => a.ParameterName == "TagName"
        );

        if (parameter != null) {
          parameter.ParameterValue = column.tag;
        } else {
          var tagParameter: IRunScheduleParameters = {
            ParameterId: 0,
            ParameterName: "TagName",
            ParameterValue: column.tag?.trim(),
          };
          matchedSchedule.Parameters.push(tagParameter);
        }
        this.updateState(this.runTemplateScheduleData.RunTemplateSchedule);
      }
    }
  };

  onSaveClick = () => {
    // INTEGRATION

    TelemetryProvider._trackEvent(saveClickEvent);
    var runScheduleData: IRunScheduleData = getDeepCopy(
      this.props.runTemplateScheduleData
    );
    var newlyCreatedRSWithoutProcessesIds: any[] = [];

    // remove new created run schedule ids
    // remove newly created runschedule if processes is empty

    runScheduleData.RunTemplateSchedule.forEach((data) => {
      if (
        data.RunTemplateScheduleId.toString().includes("new") ||
        data.RunTemplateScheduleId.toString().includes("clone")
      ) {
        if (data.Processes.length == 0) {
          newlyCreatedRSWithoutProcessesIds.push(data.RunTemplateScheduleId);
        } else {
          delete data.RunTemplateScheduleId;
        }
      }
    });

    if (newlyCreatedRSWithoutProcessesIds.length > 0) {
      runScheduleData.RunTemplateSchedule = runScheduleData.RunTemplateSchedule.filter(
        (a) => {
          return !newlyCreatedRSWithoutProcessesIds.includes(
            a?.RunTemplateScheduleId
          );
        }
      );
    }

    var dirty = getDeepCopy(runScheduleData.isDirty);
    delete runScheduleData.isDirty;

    if (this.props.startSaveRunTemplateScheduleData && dirty) {
      // remove past dates to decrease payload
      runScheduleData = this.decreasePayload(runScheduleData);
      if (runScheduleData.RunTemplateSchedule.length > 0) {
        this.props.startSaveRunTemplateScheduleData(
          runScheduleData,
          this.props.startGetRunTemplateScheduleData!
        );
      }
    }
  };

  onCancelClick = () => {
    TelemetryProvider._trackEvent(cancelClickEvent);
    const runScheduleFilterData: IRunScheduleFiltersData = {
      FiscalYear: this.props.runTemplateScheduleData.FiscalYear,
      RunTemplateId: this.props.runTemplateScheduleData.RunTemplateId,
      RunTemplateName: this.props.runTemplateScheduleData.RunTemplateName,
      StartDate: this.props.runTemplateScheduleData.StartDateTime,
      EndDate: this.props.runTemplateScheduleData.EndDateTime,
    };
    this.props.startGetRunTemplateScheduleData!(runScheduleFilterData);
  };

  updateDrillDownLevel(
    event: any,
    updateGridToSubProcessLevel: boolean,
    processName?: string,
    processId?: number
  ) {
    this.setState({
      isGridAtSubProcessLevel: updateGridToSubProcessLevel,
      selectedParentProcessName: processName,
      selectedParentProcessId: processId,
    });
  }

  public render(): JSX.Element {
    var temp: any = this.props.runTemplateScheduleData;
    if (temp.RunTemplateId !== undefined) {
      this.runTemplateScheduleData = getDeepCopy(
        this.props.runTemplateScheduleData
      );
      var runDrilldownNavBarParams = {
        runTemplateName: this.props.runTemplateScheduleData.RunTemplateName,
        isGridAtSubProcessLevel: this.state.isGridAtSubProcessLevel,
        selectedParentProcessName: this.state.selectedParentProcessName,
        selectedParentProcessId: this.state.selectedParentProcessId,
        updateDrillDownLevel: this.updateDrillDownLevel,
      };
      var params = {
        runTemplateScheduleData: this.props.runTemplateScheduleData,
        runScheduleListState: this.state,
        runDrilldownNavBarParams: runDrilldownNavBarParams,
        items: this.getItemList(),
        columns: this.getColumnList(),
        onAddClick: this.onAddClick,
        onDeleteClick: this.onDeleteClick,
        onSaveClick: this.onSaveClick,
        onTagChange: this.onTagChange,
        onCancelClick: this.onCancelClick,
        isLoading: this.props.runTemplateScheduleData?.isLoading,
        isDirty: this.props.runTemplateScheduleData.isDirty,
        isSaving: this.props.runTemplateScheduleData.isSaving,
        updateDrillDownLevel: this.updateDrillDownLevel,
        authControls: this.props.authControls,
      };
      return (
        <React.Fragment>
          <Prompt
            when={this.props.runTemplateScheduleData.isDirty}
            message={(location) =>
              `You have unsaved changes. Do you still want to proceed?`
            }
          />
          <RunScheduleListView {...params} />
        </React.Fragment>
      );
    } else if (this.props.runTemplateScheduleData?.isLoading) {
      return <Loading />;
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: IRunScheduleListProps
): RunScheduleListLinkStateProps => {
  return {
    runTemplateScheduleData: state.runSchedule,
    filters: state.runScheduleFilter.filters,
    authControls: state.userProfile.authControls,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunScheduleListProps
): RunScheduleListLinkDispatchProps => ({
  startGetRecurrenceRuleList: bindActionCreators(
    startGetRecurrenceRuleList,
    dispatch
  ),
  startGetRunTemplateScheduleData: bindActionCreators(
    startGetRunTemplateScheduleData,
    dispatch
  ),
  updateRunTemplateScheduleData: bindActionCreators(
    updateRunTemplateScheduleColumnData,
    dispatch
  ),
  startSaveRunTemplateScheduleData: bindActionCreators(
    startSaveRunTemplateScheduleData,
    dispatch
  ),
  updateRunScheduleData: bindActionCreators(updateRunScheduleData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunScheduleList);
