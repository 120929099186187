import * as types from "../types/fiscal-year-action-types";
import { FiscalYearActionTypes } from "../types/fiscal-year-action-types";

const fiscalYearDefaultState: any = {}; // Update any to a type if possible

const fiscalYearReducer = (
  state = fiscalYearDefaultState,
  action: FiscalYearActionTypes
): any => {
  switch (action.type) {
    case types.GET_FISCAL_YEAR_LIST:
      return Object.assign({}, state, {
        fiscalYearList: action.fiscalYearList,
      });
    case types.GET_FISCAL_YEAR:
      return Object.assign({}, state, {
        fiscalYear: action.fiscalYear,
      });
    default:
      return state;
  }
};

export default fiscalYearReducer;
