import {
  FETCH_CONNECTION_TYPES_SUCCESS,
  FETCH_AUTHENTICATION_OPTIONS_SUCCESS,
  FETCH_CONNECTION_SAVE_SUCCESS,
  FETCH_CONNECTION_VALIDATION_SUCCESS,
  FETCH_CONNECTION_UPDATE_SUCCESS,
  FETCH_VALIDATE_MSGS,
  FETCH_SAVE_MSGS,
  FETCH_UPDATE_MSGS,
  FETCH_FAILURE,
} from "../actions/actionTypes";
import { FETCH_ALL_CONNECTION_SUCCESS } from "../types/connection-action-types";

const initState = {
  loading: false,
  connectionTypesList: [],
  authenticationOptionsList: [],
  //saveMsg: {},
  validateMsg: {},
  //updateMsg: {},
  error: "",
};

const connectionReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    /*  case FETCH_CONNECTION_TYPES_REQUEST:
              return Object.assign({}, state, {
                  loading: true
              });*/
        case FETCH_ALL_CONNECTION_SUCCESS:
            return Object.assign({}, state, {
                connectionList: action.connectionList,
            });
        case FETCH_CONNECTION_TYPES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                connectionTypesList: action.payload,
            });
        case FETCH_AUTHENTICATION_OPTIONS_SUCCESS:
            return Object.assign({}, state, {
                authenticationOptionsList: action.payload,
            });
        case FETCH_CONNECTION_SAVE_SUCCESS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_CONNECTION_VALIDATION_SUCCESS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_CONNECTION_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_VALIDATE_MSGS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_SAVE_MSGS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_UPDATE_MSGS:
            return Object.assign({}, state, {
                validateMsg: action.payload,
            });
        case FETCH_FAILURE:
            return Object.assign({}, state , {
                loading: false,
                validateMsg: action.payload,
            });
        default:
            return state;
    }
}

export default connectionReducer;
