import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "@fluentui/react";
import { GetToken } from "../auth/authorization-token";

let authToken = "";
const baseUrl = Configurations.mintHubApiBaseUrl + "/Connection/";

const columns: IColumn[] = [];
// Get all connections
export function StartGetAllConnections1() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "Connection/Connections", {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteConnectionById(connectionId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "DeleteConnection?connectionId=" + connectionId, {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function EditOrCloneConnection(connectionId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(
        baseUrl + "Connection/EditCloneConnection?connectionID=" + connectionId,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            Authorization: "Bearer " + authToken,
          },
        }
      );
    })
    .then(handleResponse)
    .catch(handleError);
}
