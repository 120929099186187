import * as React from "react";
import { IButtonPropsCustom } from "../models/IButton";
import { PrimaryButton } from "@fluentui/react";

const PrimaryButtonView = (Props: IButtonPropsCustom) => {
  return (
    <PrimaryButton
      allowDisabledFocus
      text={Props.text}
      ariaLabel={Props.ariaLabel}
      onClick={Props.onClick}
      disabled={Props.disabled}
      className={Props.className}
    />
  );
};
export default PrimaryButtonView;
