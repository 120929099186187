import { Dispatch } from "redux";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as RunScheduleAPI from "../../../api/run-schedule-api";
import * as RunScheduleActions from "../../../redux/actions/run-schedule-actions";
import {
  IRunScheduleFiltersData,
  IRunScheduleFilters,
} from "../../../models/run-schedule";

export const getRunScheduleFilters = (filters: IRunScheduleFiltersData[]) => {
  const runScheduleFilters: IRunScheduleFilters = {
    runTemplate: [],
    months: [],
  };

  filters.forEach(function (value) {
    runScheduleFilters.runTemplate.push({
      key: value.RunTemplateId + "," + value.FiscalYear,
      text: String(value.RunTemplateName),
    });
  });

  return runScheduleFilters;
};

// Adding data from API to Redux store
export const startGetRunScheduleFilters = (autoPopulateSchedule?: any) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    // Add error handling if required

    RunScheduleAPI.getRunScheduleFilters()
      .then((value) => {
        const filters: IRunScheduleFiltersData[] = value.Result;

        let runScheduleFilters = getRunScheduleFilters(filters);
        dispatch(RunScheduleActions.getRunScheduleFilters(runScheduleFilters));

        if (autoPopulateSchedule) autoPopulateSchedule();
      })
      .catch((error) => {
        const err = error;
      });
  };
};

export const updateFilterData = (data: any) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(RunScheduleActions.updateFilterData(data));
  };
};
