export function weeklyPatternControllerService(
  startDate: Date,
  endDate: Date,
  selectedWeekDays: { frequency: string; days: number[] }
) {
  let dates = [];
  // Create new Date instance
  var date = new Date(startDate);

  if (
    selectedWeekDays.frequency == "0" ||
    selectedWeekDays.frequency.trim() == ""
  ) {
    return [];
  }

  var weekNumber = 1;

  // start date its self is sunday , then reassign to 0
  // base condition
  if (date.getDay() == 0) {
    weekNumber = 0;
  }

  while (
    date.getTime() >= startDate.getTime() &&
    date.getTime() <= endDate.getTime()
  ) {
    let day = date.getDay();
    if (day == 0) {
      weekNumber++;
    }

    if (
      weekNumber % parseInt(selectedWeekDays.frequency) === 1 ||
      parseInt(selectedWeekDays.frequency) === 1
    ) {
      if (selectedWeekDays.days.includes(day)) {
        dates.push(new Date(date));
      }
    }
    // Add a day
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
