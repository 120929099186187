import * as types from "../types/user-profile-action-types";
import { AppActions } from "../types/app-actions";
import { UserRoleDetails } from "../../models/user-profile";

export const getAuthControls = (
  userRoleDetails: UserRoleDetails
): AppActions => ({
  type: types.GET_AUTH_DATA,
  userRoleDetails,
});
