import React from "react";
import { IDropdownOption, TextField, Dropdown } from "@fluentui/react";
import { getDeepCopy } from "../../../../../util/javascript-functions";
import {
  narrowTextFieldStyles,
  dropdownStyles,
  broadTextFieldStyles,
} from "../../recurrence-pattern.style";
import { IYearlyProps } from "../../model/IRecurrenceRulePatternProps";


const YearlyView: React.FunctionComponent<IYearlyProps> = (props) => {
  const { selectedYearDatesConfig, yearlyDatesSelection } = props;
  const monthCollection: IDropdownOption[] = [
    { key: 0, text: "January" },
    { key: 1, text: "February" },
    { key: 2, text: "March" },
    { key: 3, text: "April" },
    { key: 4, text: "May" },
    { key: 5, text: "June" },
    { key: 6, text: "July" },
    { key: 7, text: "August" },
    { key: 8, text: "September" },
    { key: 9, text: "October" },
    { key: 10, text: "November" },
    { key: 11, text: "December" },
  ];

  const onYearlyDatesConfigSetup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    let configDates = getDeepCopy(selectedYearDatesConfig);
    configDates.dates = newValue;
    yearlyDatesSelection(configDates);
  };

  const onYearlyMonthConfigSetup = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => {
    let configDates = getDeepCopy(selectedYearDatesConfig);
    configDates.month = option?.key;
    yearlyDatesSelection(configDates);
  };

  const onYearlyFrequencyConfigSetup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    let configDates = getDeepCopy(selectedYearDatesConfig);
    configDates.frequency = newValue;
    yearlyDatesSelection(configDates);
  };
  return (
    <>
      <div>
        <div className="flex-box">
          <div>Recur every </div>
          <TextField
             title="recur"
            value={selectedYearDatesConfig.frequency}
            onChange={onYearlyFrequencyConfigSetup}
            styles={narrowTextFieldStyles}
            className="control-style"
          />
          <div> year(s)</div>
        </div>
        <br></br>
        <div className="flex-box">
          <div style={{ width: 40 }}>On:</div>
          <Dropdown
            placeholder="Select Month"
            title="month"
            options={monthCollection}
            styles={dropdownStyles}
            onChange={onYearlyMonthConfigSetup}
            defaultSelectedKey={parseInt(selectedYearDatesConfig.month)}
          />
        </div>
        <br></br>
        <div className="flex-box">
          <div style={{ width: 40 }}>Days:</div>
          <TextField
            title="yearly"
            value={selectedYearDatesConfig.dates}
            onChange={onYearlyDatesConfigSetup}
            styles={broadTextFieldStyles}
            className="control-style"
          />
        </div>
      </div>
    </>
  );
};

export default YearlyView;
