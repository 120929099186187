import * as types from "../types/run-schedule-action-types";
import {
  IRunScheduleFilters,
  IRunScheduleData,
} from "../../models/run-schedule";
import { AppActions } from "../types/app-actions";

export const getRunScheduleFilters = (
  filters: IRunScheduleFilters
): AppActions => ({
  type: types.GET_RUN_SCHEDULE_FILTERS,
  filters,
});

export const updateFilterData = (
  gridViewData: IRunScheduleFilters
): AppActions => ({
  type: types.UPDATE_FILTER_DATA,
  gridViewData,
});

export const getRunTemplateScheduleData = (
  runTemplateScheduleData: IRunScheduleData
): AppActions => ({
  type: types.GET_RUN_TEMPLATE_SCHEDULE_DATA,
  runTemplateScheduleData,
});

export const updateRunTemplateScheduleColumnData = (
  runTemplateScheduleColumnData: IRunScheduleData
): AppActions => {
  return {
    type: types.UPDATE_RUN_TEMPLATE_SCHEDULE_COLUMN_DATA,
    runTemplateScheduleColumnData,
  };
};

export const isRunScheduleGridLoading = (loading: boolean): AppActions => {
  return {
    type: types.IS_RUN_SCHEDULE_GRID_LOADING,
    loading,
  };
};

export const updateCompleteData = (data: any): AppActions => {
  return {
    type: types.UPDATE_COMPLETE_DATA,
    data,
  };
};

export const isRunTemplateScheduleSaving = (saving: boolean): AppActions => {
  return {
    type: types.IS_RUN_TEMPLATE_SCHEDULE_SAVING,
    saving,
  };
};
