import { IActivity, IActivityMetadata } from "../../models/activity";
import { AppActions } from "../types/app-actions";
import * as types from "../types/activity-action-types";
import { IDropdownOption } from "@fluentui/react";

export const getActivityList = (activityList: IActivity[]): AppActions => ({
  type: types.GET_ACTIVITY_LIST,
  activityList,
});

export const getActivityDetails = (activityDetails: IActivity): AppActions => ({
  type: types.GET_ACTIVITY_DETAILS,
  activityDetails,
});

export const getActivityMetadata = (
  activityMetadata: IActivityMetadata
): AppActions => ({
  type: types.GET_ACTIVITY_METADATA,
  activityMetadata,
});

export const getActivityTypeList = (
  activityTypeList: IDropdownOption[]
): AppActions => ({
  type: types.GET_ACTIVITY_TYPE_LIST,
  activityTypeList,
});

export const getActivityParameterList = (
  activityParameterList: IDropdownOption[]
): AppActions => ({
  type: types.GET_ACTIVITY_PARAMETER_LIST,
  activityParameterList,
});
