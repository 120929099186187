import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  IConfig,
  IPageViewTelemetry,
  IMetricTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
} from "@microsoft/applicationinsights-common";
import {
  IPlugin,
  IConfiguration,
  IAppInsightsCore,
  BaseTelemetryPlugin,
  ITelemetryItem,
  IProcessTelemetryContext,
  ITelemetryPluginChain,
  ICustomProperties,
} from "@microsoft/applicationinsights-core-js";
import { appInsights } from "./App.Interceptor";

export const _processTelemetry = (
  event: ITelemetryItem,
  itemCtx?: IProcessTelemetryContext
) => {
  appInsights.processTelemetry(event, itemCtx);
};

export const _trackMetric = (
  metric: IMetricTelemetry,
  customProperties: ICustomProperties
) => {
  appInsights.trackMetric(metric, customProperties);
};

export const _trackPageView = (pageView: IPageViewTelemetry) => {
  appInsights.trackPageView(pageView);
};

export const _trackEvent = (
  event: IEventTelemetry,
  customProperties?: ICustomProperties
) => {
  appInsights.trackEvent(event, customProperties);
};

export const _trackException = (
  exception: IExceptionTelemetry,
  customProperties?: {
    [key: string]: any;
  }
) => {
  appInsights.trackException(exception, customProperties);
};
