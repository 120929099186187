import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";
import { IRecurrenceRule } from "../models/recurrence-rule";

const baseUrl = Configurations.mintHubApiBaseUrl + "/RecurrenceRule/";
let authToken = "";

export function getRecurrenceRuleList() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function saveorUpdateRecurrenceRule(
  recurrenceRuleData: IRecurrenceRule
) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(recurrenceRuleData),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}
