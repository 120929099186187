import * as React from "react";
import {
  IRecurrenceRuleProps,
  IRecurrenceRuleLinkDispatchProps,
  IRecurrenceRuleLinkStateProps,
} from "./models/IRecurrenceRule";
import "./recurrence-rule.style.scss";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import { connect } from "react-redux";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import {
  IComboBoxOption,
  IComboBox,
  IDropdownOption,
} from "@fluentui/react";
import { IDatePickerProps } from "../../common/date-picker/Date";
import { RecurrenceRuleView } from "./recurrence-rule.view";
import {
  setStartTimeForDate,
  setEndTimeForDate,
  getFormattedDateInString,
  getDeepCopy,
  extractTimeInZuluFormat,
  useCustomUseState,
} from "../../../util/javascript-functions";
import { useState } from "react";
import { IRecurrenceRulePatternProps } from "../../common/recurrence-pattern/model/IRecurrenceRulePatternProps";
import { getDatesOnPattern } from "../../common/recurrence-pattern/getDatesOnPattern";
import { IRuleHooks } from "./models/IRecurrenceRuleModel";
import {
  weekDayFromStringToNumberArray,
  getInitialRecurrenceRuleObject,
  getMonthInNumber,
  extractDatesFromString,
  convertToJSON,
} from "./recurrence-rule.helper";
import { getTimeIn24HourFormat } from "../../../util/time-zone-util";
import { RuleConfigurationManager } from "./ruleConfiguration.manager";
import { IWeekly } from "../../common/recurrence-pattern/patterns/weekly/IWeekly";
import { IMonthly } from "../../common/recurrence-pattern/patterns/monthly/IMonthly";
import { IYearly } from "../../common/recurrence-pattern/patterns/yearly/IYearly";
import {
  IRunScheduleData,
  IRunScheduleProcess,
  IRunTemplateSchedule,
} from "../../../models/run-schedule";
import { bindActionCreators } from "redux";
import { updateRunTemplateScheduleColumnData } from "../../../redux/actions/run-schedule-actions";
import {
  getRandomIdForFirstRunScheduleOnThatDay,
  createRunSchedule,
  getDefaultStartTmeForFutureDates,
} from "../run-schedule-list/run-schedule-list.helper";
import {
  IRecurrenceRule,
  IProcessDetails,
} from "../../../models/recurrence-rule";
import {
  startSaveOrUpdateRecurrenceRuleData,
  startGetRecurrenceRuleList,
} from "./recurrence-rule.service";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { recurrenceRuleButtonClickEvent } from "../run-schedule.telemtetry-constants";

type Props = IRecurrenceRuleProps &
  IRecurrenceRuleLinkDispatchProps &
  IRecurrenceRuleLinkStateProps;

// Checking if grid is at Process/Sub Process Level
let isGridAtSubProcessLevel: boolean = false;
let parentProcessIndex: number;
let processIndex: number;
let selectedRunTemplateProcessDetails: IRunScheduleProcess;
let selectedRunTemplateParentProcessDetails: IRunScheduleProcess;

const onDateSelection = (date: Date) => {
  var day = date?.getDate().toString();
  var month = String(date?.getMonth());
  var year = date?.getFullYear().toString();
};

// Arranging order A -> Z, 0 -> 9
const compare = (a: IComboBoxOption, b: IComboBoxOption) => {
  if (a.text.toUpperCase() < b.text.toUpperCase()) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

export const RecurrenceRule: React.FunctionComponent<Props> = (props) => {
  /******React hooks ************************ ****************************************************/
  /**item : process data */
  /**runtemplate : grid data */
  const { item, runTemplateScheduleData } = props;

  /**********dirty handling *************************************** */

  const [isDirty, isDirtyChange] = useState<boolean>(false);

  const [showModal, updateShowModal] = useState<boolean>(true);

  // dates to applied in grid
  const [dates, updateDates] = useState<Date[]>([]);

  //********Config selection**********************************888/

  var config: any;
  if (props.recurrenceRule) {
    config = props.recurrenceRule?.find((a) => {
      return (
        a.ProcessDetails?.findIndex((b) => {
          return b.RunTemplateProcessId == item?.runTemplateProcessId;
        }) != -1
      );
    });
  }

  // setProcessContext(props.runTemplateScheduleData, item);

  config = config ?? getInitialRecurrenceRuleObject();
  var stateDefaultValues = new RuleConfigurationManager(config);
  stateDefaultValues.ruleStartDate = runTemplateScheduleData.StartDateTime;
  stateDefaultValues.ruleEndDate = runTemplateScheduleData.EndDateTime;

  /********Drop down option */
  const INITIAL_OPTIONS: IComboBoxOption[] = props.recurrenceRule
    .filter((a) => {
      return !a.IsInternalRule;
    })
    .map((element) => {
      return {
        key: element.RecurrenceRuleId,
        text: element.RecurrenceRuleName ?? "",
      };
    });

  // Rule name if any
  const [selectedRuleName, onRuleNameSelection] = useCustomUseState<
    IComboBoxOption
  >(isDirtyChange, stateDefaultValues.selectedRuleName);

  //****************Process paramters  */
  // Start time of process

  const [startTimeOfProcess, onStartTimeChange] = useCustomUseState<string>(
    isDirtyChange,
    stateDefaultValues.startTimeOfProcess
  );
  // as per run property
  const [
    ruleScheduledAsPerRun,
    ruleScheduledAsPerRunSelection,
  ] = useCustomUseState<boolean>(
    isDirtyChange,
    stateDefaultValues.ruleScheduledAsPerRun
  );

  /**** Rule Dates *****************************************************************************/

  const [ruleStartDate, ruleStartDateSelection] = useCustomUseState<Date>(
    isDirtyChange,
    stateDefaultValues.ruleStartDate
  );

  const [ruleEndDate, ruleEndDateSelection] = useCustomUseState<Date>(
    isDirtyChange,
    stateDefaultValues.ruleEndDate
  );

  /**** Rucurrence Range Limit Dates *****************************************************************************/

  const [rangeStartDate, rangeStartDateSelection] = useState<Date>(new Date());

  const [rangeEndDate, rangeEndDateSelection] = useState<Date>(new Date());

  /************Recurrence pattern ********************************************************* */

  // selected recurrence pattern
  const [
    selectedRecurrenceOptionKey,
    setRecurrenceOptionKey,
  ] = useCustomUseState<string>(
    isDirtyChange,
    stateDefaultValues.selectedRecurrenceOptionKey
  );

  /*** daily dates ************************************************************************/

  const [selectedDailyDates, dailyDateSelection] = useCustomUseState<Date[]>(
    isDirtyChange,
    stateDefaultValues.selectedDailyDates
  );

  /**Weekday **********************************************************************************/

  const [selectedWeekDays, weekDaySelection] = useCustomUseState<IWeekly>(
    isDirtyChange,
    stateDefaultValues.selectedWeekDays
  );

  /*********Month *****************************************************************************/

  const [selectedMonthDatesConfig, monthlyDatesSelection] = useCustomUseState<
    IMonthly
  >(isDirtyChange, stateDefaultValues.selectedMonthDatesConfig);

  /***Year ***********************************************************************************/

  const [selectedYearDatesConfig, yearlyDatesSelection] = useCustomUseState<
    IYearly
  >(isDirtyChange, stateDefaultValues.selectedYearDatesConfig);

  /********Rule hooks ******************************* */
  const hooks: IRuleHooks = {
    onRuleNameSelection,
    onStartTimeChange,
    ruleScheduledAsPerRunSelection,
    ruleStartDateSelection,
    ruleEndDateSelection,
    setRecurrenceOptionKey,
    dailyDateSelection,
    weekDaySelection,
    monthlyDatesSelection,
    yearlyDatesSelection,
  };
  /***********Preview related functions *************************************8 */
  const getRecurrenceRuleButton = () => {
    return (
      <IconButton
        iconName="RecurringEvent"
        fontSize={16}
        title={"Set recurrence rule for " + item?.name}
        ariaLabel={"Set recurrence rule for " + item?.name}
        onClick={() => {
          TelemetryProvider._trackEvent(recurrenceRuleButtonClickEvent);
          setProcessContext(props.runTemplateScheduleData, item);
        }}
      />
    );
  };

  const recurrenceRuleModal = {
    title: "Recurrence Rule for " + item?.name,
    control: getRecurrenceRuleButton(),
    showModal: showModal,
  };

  /*******************************************************************************************8 */

  /*********controller function *****************************************************************8 */

  const orderedRules = INITIAL_OPTIONS.sort(compare);

  const setProcessContext = (
    runTemplateScheduleData: IRunScheduleData,
    item: any
  ) => {
    runTemplateScheduleData.Processes.forEach((a) => {
      let subProcessIndex = null;
      if (a.ChildProcesses !== undefined) {
        subProcessIndex = a.ChildProcesses.findIndex((b) => {
          return b.ProcessId == item?.key;
        });
      }
      if (subProcessIndex !== null && subProcessIndex !== -1) {
        isGridAtSubProcessLevel = true;
        parentProcessIndex = runTemplateScheduleData.Processes.indexOf(a);
        processIndex = subProcessIndex;
        return;
      }
      if (a.ProcessId == item?.key) {
        isGridAtSubProcessLevel = false;
        processIndex = runTemplateScheduleData.Processes.indexOf(a);
        return;
      }
    });

    // Recurrence range limit
    rangeStartDateSelection(props.runTemplateScheduleData.StartDateTime);
    rangeEndDateSelection(props.runTemplateScheduleData.EndDateTime);

    // Process Level
    if (!isGridAtSubProcessLevel) {
      selectedRunTemplateProcessDetails =
        runTemplateScheduleData.Processes[processIndex!];
    }
    // Sub Process Level
    else {
      selectedRunTemplateProcessDetails = runTemplateScheduleData.Processes[
        parentProcessIndex!
      ].ChildProcesses![processIndex!];
      selectedRunTemplateParentProcessDetails =
        runTemplateScheduleData.Processes[parentProcessIndex!];
    }
    updateModalVisibility(true);
  };

  const onSavedRuleSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    event.defaultPrevented = true;
    const selectedRule = orderedRules.find((o) => o.text === option?.text);
    if (selectedRule) {
      onRuleNameSelection(selectedRule);
    } else {
      onRuleNameSelection({ key: 0, text: "" });
    }
  };

  const populateRule = (): void => {
    if (selectedRuleName.key == 0) {
      window.alert(
        "No rule selected. Please select a rule from the given list."
      );
      onRuleNameSelection({ key: 0, text: "" });
    } else {
      let configObj = props.recurrenceRule.find((a) => {
        return a.RecurrenceRuleId == selectedRuleName.key;
      });
      if (configObj) {
        var changeConfig = new RuleConfigurationManager(configObj);
        changeConfig.ruleStartDate = ruleStartDate;
        changeConfig.ruleEndDate = ruleEndDate;
        changeConfig.updateStateOnConfigChange(hooks);
      }
      isDirtyChange(false);
    }
  };

  const onTimeChange = (event?: React.FormEvent<HTMLInputElement>) => {
    if (event != null) onStartTimeChange(event?.currentTarget.value);
  };

  const scheduleAsPerRun = (
    ev?: React.FormEvent<HTMLElement>,
    isChecked?: boolean
  ) => {
    if (isChecked != null) {
      ruleScheduledAsPerRunSelection(isChecked);
    }
  };

  const onCancelClick = () => {
    updateModalVisibility(false);
  };

  const createJSON = (isInternalRule: boolean) => {
    var req = convertToJSON(
      isInternalRule,
      item,
      runTemplateScheduleData,
      selectedRuleName,
      startTimeOfProcess,
      ruleScheduledAsPerRun,
      ruleStartDate,
      ruleEndDate,
      selectedRecurrenceOptionKey,
      selectedDailyDates,
      selectedWeekDays,
      selectedMonthDatesConfig,
      selectedYearDatesConfig,
      props.account.name
    );

    if (isInternalRule) {
      req.RecurrenceRuleId = 0;
      req.RecurrenceRuleName = "";
    }

    return req;
  };

  const addProcessStartTimeToExistingSchedule = (
    datesVisited: any,
    index: number,
    selectedProcess: IRunScheduleProcess,
    selectedSubProcessIndex?: number
  ) => {
    // Process Level
    if (!isGridAtSubProcessLevel) {
      selectedProcess.ProcessScheduleDateTime = new Date(
        extractTimeInZuluFormat(datesVisited[index].date, startTimeOfProcess)
      );
    }
    // Sub Process Level
    else {
      const processStartTime = new Date(
        extractTimeInZuluFormat(datesVisited[index].date, startTimeOfProcess)
      );
      selectedProcess.ChildProcesses![
        selectedSubProcessIndex!
      ].ProcessScheduleDateTime = processStartTime;
      // To override Parent Process Start Time
      if (selectedProcess.ProcessScheduleDateTime === undefined) {
        selectedProcess.ProcessScheduleDateTime = processStartTime;
      } else {
        if (selectedProcess.ProcessScheduleDateTime! > processStartTime) {
          selectedProcess.ProcessScheduleDateTime = processStartTime;
        }
      }
    }
  };

  const addProcessToExistingSchedule = (
    rsState: IRunScheduleData,
    runSchedule: IRunTemplateSchedule,
    isProcessPresentInSchedule: boolean,
    processToBeAdded: IRunScheduleProcess,
    isParentProcessPresentInSchedule?: boolean,
    parentIndex?: number
  ) => {
    if (!isProcessPresentInSchedule) {
      if (!isGridAtSubProcessLevel) {
        runSchedule.Processes.splice(0, 0, processToBeAdded);
      } else {
        if (isParentProcessPresentInSchedule) {
          runSchedule.Processes[parentIndex!].ChildProcesses!.splice(
            0,
            0,
            processToBeAdded
          );
        } else {
          // const processObj = selectedRunTemplateParentProcessDetails; // TODO: Add Process Start Time if applicable
          var processObj = rsState.Processes[parentProcessIndex!];
          processObj.ChildProcesses = [processToBeAdded];
          runSchedule.Processes.splice(0, 0, processObj);
        }
      }
    }
  };

  const checkIfProcessPresentInSchedule = (
    rsState: IRunScheduleData,
    rs: IRunTemplateSchedule,
    isProcessPresentInSchedule: boolean,
    datesVisited: any,
    index: number,
    processToBeAdded: IRunScheduleProcess
  ) => {
    let isParentProcessPresentInSchedule = false;
    let parentIndex: number;
    // Process Level
    if (!isGridAtSubProcessLevel) {
      rs.Processes.forEach((p) => {
        if (p.ProcessId == item?.key) {
          isProcessPresentInSchedule = true;
          if (!ruleScheduledAsPerRun) {
            addProcessStartTimeToExistingSchedule(datesVisited, index, p);
          }
        }
      });
    }
    // Sub Process Level
    else {
      // var processObj = selectedRunTemplateParentProcessDetails;
      var processObj = rsState.Processes[parentProcessIndex!];
      const processList = rs.Processes;
      for (let i = 0; i < processList.length; i++) {
        if (processList[i].ProcessId == processObj.ProcessId) {
          isParentProcessPresentInSchedule = true;
          parentIndex = i;
          const selectedSubProcessIndex = processList[
            i
          ].ChildProcesses!.findIndex((c) => {
            return c.ProcessId == item?.key;
          });
          if (selectedSubProcessIndex > -1) {
            isProcessPresentInSchedule = true;
            if (!ruleScheduledAsPerRun) {
              addProcessStartTimeToExistingSchedule(
                datesVisited,
                index,
                processList[i],
                selectedSubProcessIndex
              );
            }
          }
        }
      }
    }
    if (isParentProcessPresentInSchedule) {
      addProcessToExistingSchedule(
        rsState,
        rs,
        isProcessPresentInSchedule,
        processToBeAdded,
        isParentProcessPresentInSchedule,
        parentIndex!
      );
    } else {
      addProcessToExistingSchedule(
        rsState,
        rs,
        isProcessPresentInSchedule,
        processToBeAdded,
        isParentProcessPresentInSchedule
      );
    }
  };

  const manageRunScheduleDateOnApply = (dates: Date[]) => {
    // Logic:
    // tranverse through all rs and check the dates
    // if present, add process
    // If not, create rs and then add
    type DateVisited = {
      date: Date;
      visited: boolean;
    };
    var datesVisited: DateVisited[] = [];
    dates.forEach((a) => {
      datesVisited.push({ date: setStartTimeForDate(a), visited: false });
    });

    var rsState: IRunScheduleData = getDeepCopy(props.runTemplateScheduleData);
    var runTemplateSchedule = rsState.RunTemplateSchedule;

    // Adding Process Time and adding Process/Sub Process to the schedule(if available)
    runTemplateSchedule.forEach((rs) => {
      let rsDate = setStartTimeForDate(new Date(rs.ScheduleDateTime));
      let index = 0;
      let datesVisitedLength = datesVisited.length;

      while (
        datesVisitedLength > 0 &&
        index < datesVisitedLength &&
        datesVisited[index].date <= rsDate
      ) {
        if (rsDate.toString() == datesVisited[index].date.toString()) {
          let isProcessPresentInSchedule = false;
          let processToBeAdded = getDeepCopy(selectedRunTemplateProcessDetails);

          // add process start time to sub/process in context
          if (!ruleScheduledAsPerRun && process !== undefined && process !== null) {
            processToBeAdded.ProcessScheduleDateTime = new Date(
              extractTimeInZuluFormat(
                datesVisited[index].date,
                startTimeOfProcess
              )
            );
          }

          checkIfProcessPresentInSchedule(
            rsState,
            rs,
            isProcessPresentInSchedule,
            datesVisited,
            index,
            processToBeAdded
          );

          datesVisited[index].visited = true;
          break;
        }
        index++;
      }
    });

    // add runschedule with process or subprocess
    datesVisited.forEach((element) => {
      if (!element.visited) {
        let rs: any;
        let processStartTime: Date | undefined;
        if (!ruleScheduledAsPerRun) {
          processStartTime = new Date(
            extractTimeInZuluFormat(element.date, startTimeOfProcess)
          );
        }

        const defaultStartTime = getDefaultStartTmeForFutureDates(element.date);
        let defaultTime = defaultStartTime.Hours + ":" + defaultStartTime.Mins;
        if (!isGridAtSubProcessLevel) {
          rs = createRunSchedule(
            rsState,
            item,
            getRandomIdForFirstRunScheduleOnThatDay(element.date.getDate()), //  random id
            extractTimeInZuluFormat(element.date, defaultTime),
            processStartTime
          );
        }
        // Sub Process Level
        else {
          rs = createRunSchedule(
            rsState,
            item,
            getRandomIdForFirstRunScheduleOnThatDay(element.date.getDate()), //  random id
            extractTimeInZuluFormat(element.date, defaultTime),
            processStartTime,
            parentProcessIndex,
            true
          );
        }
        runTemplateSchedule.splice(0, 0, rs);
      }
    });

    props.updateRunTemplateScheduleData({
      RunTemplateSchedule: runTemplateSchedule,
    });
  };
  /// On Applying rule
  const onApply = () => {
    var req: IRecurrenceRule = createJSON(isDirty);
    const isValidationSuccessful = validateRule(req, true);
    if (isValidationSuccessful === -1) return;

    var filterStartDate = setStartTimeForDate(
      new Date(runTemplateScheduleData.StartDateTime)
    );

    var filterEndDate = setStartTimeForDate(
      new Date(runTemplateScheduleData.EndDateTime)
    );

    var datesToBeApplied = getDatesOnPattern(
      selectedRecurrenceOptionKey,
      filterStartDate,
      filterEndDate,
      ruleStartDate,
      ruleEndDate,
      selectedDailyDates,
      selectedWeekDays,
      selectedMonthDatesConfig,
      selectedYearDatesConfig
    );

    manageRunScheduleDateOnApply(datesToBeApplied);
    updateDates(datesToBeApplied);

    props.startSaveOrUpdateRecurrenceRuleData(
      req,
      props.startGetRecurrenceRuleList,
      onAddOrUpdateRuleSuccess
    );

    updateModalVisibility(false);
    // recurrenceRuleModal.showModal = false;
  };

  const validateRule = (req: IRecurrenceRule, isOnApplyClick: boolean) => {
    let validationErrorMessage: string = "";
    // Rule Name
    if (
      !req.IsInternalRule &&
      req.RecurrenceRuleName === (null || undefined || "") &&
      !isOnApplyClick) 
    {
      validationErrorMessage =
        "Please select a rule from dropdown to update an exising rule.";
    }

    // As per Run | Start time
    if (req.IsAsPerRun === false && req.ProcessStartTime === "") {
      validationErrorMessage =
        "Please select one of the following: Start Time | As per run.";
    }

    // Rule date range
    if (req.RecurrenceRuleStartDate > req.RecurrenceRuleEndDate) {
      validationErrorMessage =
        "Please select a valid date range for this rule.";
    }

    // Rule Type and Parameter
    if (selectedRecurrenceOptionKey == "") {
      validationErrorMessage = "Please select recurrence pattern.";
    }

    req.RecurrenceRuleParameters.forEach((param) => {
      if (
        param.RecurrenceRuleTypeParameterValue === (null || undefined || "")
      ) {
        validationErrorMessage = "Please input all parameters.";
      }
    });

    if (validationErrorMessage.length > 0) {
      alert(validationErrorMessage);
      return -1;
    }
    return;
  };

  const onAddOrUpdateRuleSuccess = (selectedRule?: string) => {
    const rule = orderedRules.find((o) => o.text === selectedRule);
    if (rule) {
      onRuleNameSelection(rule);
    } else {
      onRuleNameSelection({ key: 0, text: "" });
    }
    if (selectedRule) {
      window.alert("Recurrence rule saved successfully.");
    }
  };

  const onSaveRule = () => {
    var req: IRecurrenceRule = createJSON(false);
    const isValidationSuccessful = validateRule(req, false);
    if (isValidationSuccessful === -1) return;

    const rule = props.recurrenceRule.find(
      (r) => r.RecurrenceRuleId === req.RecurrenceRuleId
    );
    const affectedProcesses = rule?.ProcessDetails;
    const confirmation = window.confirm(
      "This update will affect " +
        String(affectedProcesses?.length) +
        " process(s) associated to this rule. Do you wish to continue?"
    );
    if (confirmation === true) {
      props.startSaveOrUpdateRecurrenceRuleData(
        req,
        props.startGetRecurrenceRuleList,
        onAddOrUpdateRuleSuccess
      );
    }
  };

  const onSaveAsNewRule = () => {
    var req: IRecurrenceRule = createJSON(false);
    let newRuleName: string | null = "";
    while (newRuleName != null && newRuleName.trim().length === 0) {
      newRuleName = window.prompt("Please enter a name for this rule:");
    }

    // Will be null only on the click of cancel on the prompt screen
    if (newRuleName !== null) {
      req.RecurrenceRuleId = 0;
      req.RecurrenceRuleName = newRuleName.trim();
      const isValidationSuccessful = validateRule(req, false);
      if (isValidationSuccessful === -1) return;
      props.startSaveOrUpdateRecurrenceRuleData(
        req,
        props.startGetRecurrenceRuleList,
        onAddOrUpdateRuleSuccess
      );
    }
  };

  const updateModalVisibility = (showModal: boolean): void => {
    updateShowModal(showModal);
  };

  const recurrencePatternParams: IRecurrenceRulePatternProps = {
    selectedDailyDates: selectedDailyDates,
    dailyDateSelection: dailyDateSelection,

    selectedWeekDays: selectedWeekDays,
    weekDaySelection: weekDaySelection,

    selectedMonthDatesConfig: selectedMonthDatesConfig,
    monthlyDatesSelection: monthlyDatesSelection,

    selectedYearDatesConfig: selectedYearDatesConfig,
    yearlyDatesSelection: yearlyDatesSelection,

    ruleStartDate: ruleStartDate,
    ruleStartDateSelection: ruleStartDateSelection,

    ruleEndDate: ruleEndDate,
    ruleEndDateSelection: ruleEndDateSelection,

    selectedRecurrenceOptionKey: selectedRecurrenceOptionKey,
    setRecurrenceOptionKey: setRecurrenceOptionKey,

    dates: dates,
    updateDates: updateDates,

    rangeStartDate: rangeStartDate,
    rangeEndDate: rangeEndDate,
  };

  const params = {
    subTitle: item != null ? item?.name : "",
    recurrenceRuleModal: recurrenceRuleModal,
    orderedRules: orderedRules,
    selectedRule: selectedRuleName,
    ruleScheduledAsPerRun: ruleScheduledAsPerRun,
    onSavedRuleSelectionChange: onSavedRuleSelectionChange,
    populateRule: populateRule,
    startTimeOfProcess: startTimeOfProcess,
    onTimeChange: onTimeChange,
    scheduleAsPerRun: scheduleAsPerRun,
    onCancelClick: onCancelClick,
    recurrencePatternParams: recurrencePatternParams,
    onApply: onApply,
    onSaveRule: onSaveRule,
    onSaveAsNewRule: onSaveAsNewRule,
    dates: dates,
    showModal: showModal,
    authControls: props.authControls,
  };
  return (
    <>
      <RecurrenceRuleView {...params} />
    </>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IRecurrenceRuleProps
): IRecurrenceRuleLinkStateProps => {
  return {
    runTemplateScheduleData: state.runSchedule,
    recurrenceRule: state.recurrenceRule,
    authControls: state.userProfile.authControls,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRecurrenceRuleProps
): IRecurrenceRuleLinkDispatchProps => ({
  updateRunTemplateScheduleData: bindActionCreators(
    updateRunTemplateScheduleColumnData,
    dispatch
  ),
  startSaveOrUpdateRecurrenceRuleData: bindActionCreators(
    startSaveOrUpdateRecurrenceRuleData,
    dispatch
  ),
  startGetRecurrenceRuleList: bindActionCreators(
    startGetRecurrenceRuleList,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurrenceRule);
