import * as types from "../actions/actionTypes";

const sideNavReducerDefaultState = {
  isSideNavOpen: false,
}; // Update any to a type if possible

const sideNavReducer = (state = sideNavReducerDefaultState, action: any) => {
  switch (action.type) {
    case types.TOGGLE_CLICK:
      return action.isSideNavOpen;

    default:
      return state;
  }
};

export default sideNavReducer;
