import { UserRoleDetails } from "../../models/user-profile";

export const GET_AUTH_DATA = "GET_AUTH_DATA";

export interface GetAuthControls {
  type: typeof GET_AUTH_DATA;
  userRoleDetails: UserRoleDetails;
}

export type UserProfileActionTypes = GetAuthControls;
