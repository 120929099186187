import * as React from "react";
import {
  Stack,
  mergeStyles,
  IDropdownOption,
  Link,
} from "@fluentui/react";
import {
  IRunScheduleFiltersState,
  IRunScheduleFiltersProps,
  RunScheduleFiltersLinkDispatchProps,
  RunScheduleFiltersLinkStateProps,
  IWeekRange,
} from "./models/IRunScheduleFilters";
import {
  startGetRunScheduleFilters,
  updateFilterData,
} from "./run-schedule-filters.service";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import "./run-schedule-filters.style.scss";
import {
  startGetRunTemplateScheduleData,
  updateRunScheduleData,
} from "../run-schedule-list/run-schedule-list.service";
import {
  IRunScheduleFiltersData,
  IRunScheduleData,
} from "../../../models/run-schedule";
import { RunScheduleListLinkStateProps } from "../run-schedule-list/models/IRunScheduleList";
import { RunScheduleFilterView } from "./run-schedule-filters.view";
import Loading from "../../loading/loading";
import {
  setStartTimeForDate,
  setEndTimeForDate,
  getDeepCopy,
} from "../../../util/javascript-functions";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  applyFilterClickEvent,
  monthlyWeeklyToggleClickEvent,
} from "../run-schedule.telemtetry-constants";

type Props = IRunScheduleFiltersProps &
  RunScheduleFiltersLinkDispatchProps &
  RunScheduleFiltersLinkStateProps;

export class RunScheduleFilter extends React.Component<
  Props,
  IRunScheduleFiltersState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRunTemplateId: { key: -1, text: "-1" },
      selectedMonthId: { key: -1, text: "" },
      isWeeklyView: false,
      runTemplateList: [],
      monthList: [],
      weekRange: [],
      weekNumber: 0,
      gridDateRange: {
        startDate: new Date(),
        endDate: new Date(),
        noOfDays: 1,
      },
    };
  }

  // update to will mount
  componentDidMount() {
    this.props.startGetRunScheduleFilters(this.autoPopulateSchedule);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.fiscalYear !== prevProps.fiscalYear) {
      this.autoPopulateSchedule();
    }
  }

  autoPopulateSchedule = () => {
    if (this.props.fiscalYear) {
      this.populateRunTemplates(this.props.fiscalYear);
      this.populateMonths(this.props.fiscalYear, true);
    }
  };

  updateFilter = () => {
    if (this.props.filters && this.props.updateFilterData) {
      let data = getDeepCopy(this.props.filters);
      data.gridDateRange = this.state.gridDateRange;
      this.props.updateFilterData(data);
    }
  };

  updateGridDateRange = (isWeekly: boolean = true) => {
    let gridStartDate: any;
    let gridEndDate: any;
    let days: number = 0;

    if (isWeekly && this.state.weekRange.length > 0) {
      gridStartDate = setStartTimeForDate(
        this.state.weekRange[this.state.weekNumber - 1].startRange
      );
      gridEndDate = setEndTimeForDate(
        this.state.weekRange[this.state.weekNumber - 1].endRange
      );
      days = this.getNumberOfDays(gridStartDate, gridEndDate);
    } else if (this.props.runTemplateScheduleData?.RunTemplateId != undefined) {
      gridStartDate = setStartTimeForDate(
        new Date(this.props.runTemplateScheduleData.StartDateTime)
      );
      gridEndDate = setEndTimeForDate(
        new Date(this.props.runTemplateScheduleData.EndDateTime)
      );
      days = this.getNumberOfDays(gridStartDate, gridEndDate);
    }
    this.setState(
      {
        gridDateRange: {
          startDate: gridStartDate,
          endDate: gridEndDate,
          noOfDays: days,
        },
      },
      this.updateFilter
    );
  };

  onForwardClick = () => {
    if (this.state.weekNumber < this.state.weekRange.length) {
      this.setState(
        { weekNumber: this.state.weekNumber + 1 },
        this.updateGridDateRange
      );
    }
  };

  onBackClick = () => {
    if (this.state.weekNumber > 1) {
      this.setState(
        { weekNumber: this.state.weekNumber - 1 },
        this.updateGridDateRange
      );
    }
  };

  public render(): JSX.Element {
    if (this.props.filters) {
      const params = {
        filters: this.props.filters,
        selectedRunTemplateId: this.state.selectedRunTemplateId,
        selectedMonthId: this.state.selectedMonthId,
        runTemplateList: this.state.runTemplateList,
        monthList: this.state.monthList,
        isWeeklyView: this.state.isWeeklyView,
        onRunTemplateDropdownValueChange: this.onRunTemplateDropdownValueChange,
        onMonthDropdownValueChange: this.onMonthDropdownValueChange,
        onApplyClicked: this.onApplyClicked,
        onChange: this.onChange,
        alertClicked: alertClicked,
        onForwardClick: this.onForwardClick,
        onBackClick: this.onBackClick,
        weekNumber: this.state.weekNumber,
        weekRange: this.state.weekRange,
        gridDateRange: this.state.gridDateRange,
      };
      return (
        <React.Fragment>
          <RunScheduleFilterView {...params} />
        </React.Fragment>
      );
    } else {
      return <Loading />;
    }
  }

  private onChange = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ): void => {
    TelemetryProvider._trackEvent(monthlyWeeklyToggleClickEvent);
    this.setState({ isWeeklyView: checked == null ? false : checked }, () => {
      if (this.props.runTemplateScheduleData?.RunTemplateId != undefined) {
        if (
          this.state.isWeeklyView &&
          this.state.weekRange.length >= this.state.weekNumber &&
          this.state.weekNumber > 0
        ) {
          this.updateGridDateRange(true);
        } else {
          this.updateGridDateRange(false);
        }
      }
    });
  };

  private populateRunTemplates = (fiscalYear: number): void => {
    let applicableRunTemplates: IDropdownOption[] = [];
    if (this.props.filters) {
      this.props.filters.runTemplate.forEach((template) => {
        const templateFiscalYear = template.key.toString().split(",")[1];
        if (parseInt(templateFiscalYear, 10) === fiscalYear) {
          applicableRunTemplates.push(template);
        }
      });
    }
    this.setState({ runTemplateList: applicableRunTemplates });

    // Populating default value
    let defaultRunTemplate = applicableRunTemplates.find(
      (runTemplate: IDropdownOption) => runTemplate.text.startsWith("Mint FY")
    );
    this.setState({
      selectedRunTemplateId: {
        key: defaultRunTemplate?.key ?? -1,
        text:
          defaultRunTemplate?.key !== undefined
            ? String(defaultRunTemplate?.key).split(",")[0]
            : "-1",
      },
    });
  };

  private populateMonths = (
    fiscalYear: number,
    isAutoPopulate: boolean = false
  ): void => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let applicableMonths: IDropdownOption[] = [];

    // For Adding prior fiscal year months
    applicableMonths.push({
      key: "3," + (fiscalYear - 1),
      text: monthNames[3] + " " + (fiscalYear - 1),
    });
    applicableMonths.push({
      key: "4," + (fiscalYear - 1),
      text: monthNames[4] + " " + (fiscalYear - 1),
    });
    applicableMonths.push({
      key: "5," + (fiscalYear - 1),
      text: monthNames[5] + " " + (fiscalYear - 1),
    });

    // For Adding selected fiscal year months and subsequent fiscal year months
    for (let i = 6; i < 18; i++) {
      const monthId = i % 12;
      if (monthId > 5) {
        applicableMonths.push({
          key: monthId + "," + (fiscalYear - 1),
          text: monthNames[monthId] + " " + (fiscalYear - 1),
        });
      } else {
        applicableMonths.push({
          key: monthId + "," + fiscalYear,
          text: monthNames[monthId] + " " + fiscalYear,
        });
      }
    }

    // For Adding subsequent fiscal year months
    applicableMonths.push({
      key: "6," + fiscalYear,
      text: monthNames[6] + " " + fiscalYear,
    });
    applicableMonths.push({
      key: "7," + fiscalYear,
      text: monthNames[7] + " " + fiscalYear,
    });

    this.setState({ monthList: applicableMonths });

    // Populating default value
    const d = new Date();
    const currentMonth = monthNames[d.getMonth()] + " " + d.getFullYear();
    const defaultMonth =
      applicableMonths.find(
        (runTemplate: IDropdownOption) => runTemplate.text === currentMonth
      ) ?? applicableMonths[applicableMonths.length - 1];

    if (isAutoPopulate) {
      this.setState(
        {
          selectedMonthId: {
            key: defaultMonth?.key ?? -1,
            text: String(defaultMonth?.key),
          },
        },
        this.onApplyClicked
      );
    } else {
      this.setState({
        selectedMonthId: {
          key: defaultMonth?.key ?? -1,
          text: String(defaultMonth?.key),
        },
      });
    }
  };

  private onRunTemplateDropdownValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    event.defaultPrevented = true;
    const runTemplateId = parseInt(
      option !== undefined ? String(option.key).split(",")[0] : "-1",
      10
    );
    this.setState({
      selectedRunTemplateId: {
        key: option?.key ?? -1,
        text: String(runTemplateId),
      },
    });
  };

  private onMonthDropdownValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    event.defaultPrevented = true;
    this.setState({
      selectedMonthId: { key: option?.key ?? -1, text: String(option?.key) },
    });
  };

  getNumberOfDays = (gridStartDate: any, gridEndDate: any) => {
    const numMillisecondsInADay = 24 * 60 * 60 * 1000;
    let diffTime = Math.abs(gridStartDate - gridEndDate);
    let diffDays = Math.round(diffTime / numMillisecondsInADay);  //Account for DST
    return diffDays;
  };

  getRangeDisplayName = (startDate: Date, endDate: Date) => {
    return startDate.getDate().toString() + "-" + endDate.getDate().toString();
  };

  updateGridAfterApply = () => {
    this.updateGridDateRange(this.state.isWeeklyView);
  };

  manageWeekly = () => {
    let weekDates: IWeekRange[] = [];

    if (this.props.runTemplateScheduleData) {
      this.setState({ weekNumber: 1 });

      let startDate = setStartTimeForDate(
        this.props.runTemplateScheduleData.StartDateTime
      );
      let endDate = setEndTimeForDate(
        this.props.runTemplateScheduleData.EndDateTime
      );

      while (startDate <= endDate) {
        let newEndDate = setStartTimeForDate(startDate);
        let addDays: any;

        //get range for first week as per calender
        // other weeks , add 6
        if (weekDates.length == 0) {
          addDays = newEndDate.getDate() + 6 - newEndDate.getDay();
        } else {
          addDays = newEndDate.getDate() + 6;
        }
        newEndDate.setDate(addDays);

        if (newEndDate <= endDate) {
          // push data in weekdates
          weekDates.push({
            weekNumber: weekDates.length + 1,
            display: this.getRangeDisplayName(startDate, newEndDate),
            startRange: setStartTimeForDate(startDate),
            endRange: setEndTimeForDate(newEndDate),
          });

          // on end break
          if (setStartTimeForDate(newEndDate) == endDate) {
            break;
          }

          // update next week start date
          startDate = setStartTimeForDate(newEndDate);
          let addNextDay = startDate.getDate() + 1;
          startDate.setDate(addNextDay);
        } else {
          // update for last week
          weekDates.push({
            weekNumber: weekDates.length + 1,
            display: this.getRangeDisplayName(startDate, endDate),
            startRange: setStartTimeForDate(startDate),
            endRange: setEndTimeForDate(endDate),
          });
          break;
        }
      }

      this.setState({ weekRange: weekDates }, this.updateGridAfterApply);
    }
  };

  private onApplyClicked = (): void => {
    TelemetryProvider._trackEvent(applyFilterClickEvent);
    const filters = this.state.selectedMonthId.text.toString().split(",");
    const monthId = parseInt(filters[0], 10);
    const fiscalYear = parseInt(filters[1], 10);

    var startDateTime = new Date(fiscalYear, monthId, 1);
    // Day 0 of a month reverts to the last day of the previous month. Then set the hour:min to 23:59
    var endDateTime = new Date(fiscalYear, monthId + 1, 0, 23, 59);

    const runScheduleFilterData: IRunScheduleFiltersData = {
      FiscalYear: fiscalYear,
      RunTemplateId: parseInt(this.state.selectedRunTemplateId.text),
      RunTemplateName: "",
      StartDate: startDateTime,
      EndDate: endDateTime,
    };

    if (this.props.runTemplateScheduleData?.isDirty) {
      if (
        !window.confirm(
          "You have unsaved changes. Do you still want to proceed?"
        )
      ) {
        return;
      }
    }
    this.props.startGetRunTemplateScheduleData(
      runScheduleFilterData,
      this.manageWeekly
    );
  };
}

function alertClicked(): void {
  alert("Clicked");
}

const mapStateToProps = (
  state: AppState,
  ownProps: IRunScheduleFiltersProps
): RunScheduleFiltersLinkStateProps | RunScheduleListLinkStateProps => {
  return {
    filters: state.runScheduleFilter.filters,
    runTemplateScheduleData: state.runSchedule,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunScheduleFiltersProps
): RunScheduleFiltersLinkDispatchProps => ({
  startGetRunScheduleFilters: bindActionCreators(
    startGetRunScheduleFilters,
    dispatch
  ),
  startGetRunTemplateScheduleData: bindActionCreators(
    startGetRunTemplateScheduleData,
    dispatch
  ),
  updateFilterData: bindActionCreators(updateFilterData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunScheduleFilter);
