import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as SourceOnboardingAPI from "../../../api/source-onboarding-api";
import * as SourceOnboardingActions from "../../../redux/actions/source-onboarding-actions";
import { IColumn, IconButton } from "@fluentui/react";
import { ISourceOnboardingList2, ISourceOnboardingConnectionsList } from "./model/ISourceOnboarding";
import "../../onboarding/common/onboarding-summary.scss";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewConnection from '../source-onboarding/new-connection';
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { Connection, ConnectionTypeAttribute, ConnectionAttributes } from "../../../models/connection";
import { getRunScheduleFilters } from "../../../api/run-schedule-api";
import { IAzureSqlConnection, ISqlServerConnection, IAzureDataLakeStorageGen2, ISparkConnection } from './model/IConnection';
import { setStartTimeForDate } from "../../../util/javascript-functions";

export const startGetAllConnections1 = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
      const connections_temp = SourceOnboardingAPI.StartGetAllConnections1()
        .then((value) => {
            const connections: Connection[] = value.Result;
            var totalConnections: ISourceOnboardingConnectionsList = {
                totConnections: connections,
            };
            dispatch(
                SourceOnboardingActions.getAllConnections1(totalConnections)
            );
        })
        .catch((error) => {
        });
    };
};

export const deleteConnectionById = (params: any, clonedState: ISourceOnboardingConnectionsList) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
      const temp1 = SourceOnboardingAPI.deleteConnectionById(params);
      const connections_temp = SourceOnboardingAPI.StartGetAllConnections1()
        .then((value) => {
            const result: any = value.Result;
            if (result) {
                const connObj = clonedState.totConnections;
                const connObj1 = connObj.find(({ ConnectionId }) => ConnectionId === params);
                if (connObj1 != null || connObj1 != undefined) connObj1.IsActive = false;
                var totalConnectionsAfterDelete: ISourceOnboardingConnectionsList = {
                    totConnections: connObj,
                };
                dispatch(SourceOnboardingActions.deleteConnection(totalConnectionsAfterDelete));
            }
        })
        .catch((error) => {
        });
    };
}


export const getAllConns = (clonedState: ISourceOnboardingConnectionsList) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
      const connections_temp = SourceOnboardingAPI.StartGetAllConnections1()
        .then((value) => {
            const connections: Connection[] = value.Result;
            // clonedState.totConnections = connections,
            dispatch(
                SourceOnboardingActions.getAllConnections1(clonedState)
            );
        })
        .catch((error) => {
        });
    };
}