import { IRunTemplateDetails } from "../../models/run-template";
import { AppActions } from "../types/app-actions";
import * as types from "../types/run-template-action-types";

export const getRunTemplateList = (
  runTemplateList: IRunTemplateDetails[]
): AppActions => ({
  type: types.GET_RUN_TEMPLATE_LIST,
  runTemplateList,
});

export const getRunTemplateDetails = (
  runTemplateDetails: IRunTemplateDetails
): AppActions => ({
  type: types.GET_RUN_TEMPLATE_DETAILS,
  runTemplateDetails,
});
