import React from "react";
import DetailsListController from "../../common/details-list/details-list.controller";
import { IDetailsListPropsCustom } from "../../common/details-list/models/IDetailsList";
import {
  IRenderFunction,
  IDetailsHeaderProps,
  DetailsHeader,
  IDetailsColumnRenderTooltipProps,
  Sticky,
  StickyPositionType,
  Selection
} from "@fluentui/react";

export class DetailListWithStickyHeader extends React.Component<
  IDetailsListPropsCustom
  > {
  constructor(props: IDetailsListPropsCustom) {
    super(props);
  }

  renderStickyHeader: IRenderFunction<IDetailsHeaderProps> = (
    properties?: any
  ) => {
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        <DetailsHeader {...properties} />
      </Sticky>
    );
  };

  render() {
    return (
      <DetailsListController
        items={this.props.items}
        columns={this.props.columns}
        selectionMode={this.props.selectionMode}
        selectionPreservedOnEmptyClick={
          this.props.selectionPreservedOnEmptyClick
        }
        onItemInvoked={this.props.onItemInvoked}
        onRenderDetailsHeader={this.renderStickyHeader}
        enableShimmer={this.props.enableShimmer}
        shimmerLines={this.props.shimmerLines}
        selection={this.props.selection}
      />
    );
  }
}

export default DetailListWithStickyHeader;
