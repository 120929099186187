import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";
import { handleResponse, handleError } from "./api.util";

const baseUrl = Configurations.mintHubApiBaseUrl + "/Connection/";
let authToken = "";

export interface IConnectionAttribute {
    ConnectionTypeAttributeName?: string;
    ConnectionAttributeValue?: string;
    IsSecretValue: boolean;
    IsActive: boolean;
}

export interface IConnectionAPI {
    ConnectionName?: string;
    ConnectionTypeName?: string;
    FiscalYear?: number;
    ConnectionAttributesInput?: IConnectionAttribute[]
}

export class ConnectionAuthenticationOptions {
    ConnectionTypeName?: string;
    AuthType?: string;
    Credentials: string[] = [];
}

export function GetAllConnectionTypes() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllConnectionTypes', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function GetAllAuthenticationOptions() {
    var authTypeVal = "Sql Auth";
    var connectionTypeVal = "Azure SQL Database";

    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllUserAccounts?authType=' + authTypeVal + '&connectionType=' + connectionTypeVal, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);

}

export function saveConnectionToDBAPI(conn: IConnectionAPI) {
    console.log(conn);
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'CreateNewConnectionData', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(conn),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}



export function validateConnectionDetails(conn: IConnectionAPI) {
    console.log(conn);
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'ValidateConnection', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(conn),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updateConnectionDetails(conn: IConnectionAPI, ConnectionId: number) {
    console.log(conn);

    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'UpdateConnection?connectionID=' + ConnectionId, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(conn),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}
