import { combineReducers } from "redux";
import isSideNavOpen from "./side-nav-reducer";
import expandCollapseClick from "./expand-collapse-reducer";
import runSchedule from "./run-schedule-reducer";
import runScheduleFilter from "./run-schedule-filter-reducer";
import connectionState from "./connection-reducer";
import authentication from "./authentication-reducer";
import sourceOnboardingReducer from "./source-onboarding.reducer";
import runDashboard from "./run-dashboard-reducer";
import runDashboardV2 from "./run-dashboard-v2-reducer";
import recurrenceRule from "./recurrence-rule-reducer";
import userProfile from "./user-profile-reducer";
import activity from "./activity-reducer";
import process from "./process-reducer";
import runTemplate from "./run-template-reducer";
import fiscalYear from "./fiscal-year-reducer";
import validation from "./validation-reducer";
import sourceRefreshReducer from "./source-refresh-reducer";
import templateRunDetails from "./template-run-details-reducer";
import validateEntity from "./validate-entity-reducer";

const rootReducer = combineReducers({
  authentication,
  userProfile,
  isSideNavOpen,
  expandCollapseClick,
  runSchedule,
  runScheduleFilter,
  connectionState,
  sourceOnboardingReducer,
  recurrenceRule,
  runDashboard,
  runDashboardV2,
  activity,
  process,
  runTemplate,
  fiscalYear,
  validation,
  validateEntity,
  sourceRefreshReducer,
  templateRunDetails
});

export default rootReducer;
