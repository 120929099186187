import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";
import { IRunTemplateDetails } from "../models/run-template";

const baseUrl = Configurations.mintHubApiBaseUrl + "/RunTemplate/";
const listBaseUrl = Configurations.mintHubApiBaseUrl + "/RunTemplate?fiscalYear=";
var authToken = "";

export function getRunTemplates(fiscalYear: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(listBaseUrl + fiscalYear, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getRunTemplateDetails(runTemplateId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + runTemplateId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getRunTemplateMetadata() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "GetRunTemplateMetadata", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

// Create or Update an Run Template
export function saveRunTemplate(runTemplateDetails: IRunTemplateDetails) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: runTemplateDetails.RunTemplateID === 0 ? "PUT" : "POST", // PUT for create, POST to update when id already exists.
        headers: {
          Authorization: "Bearer " + authToken,
          "content-type": "application/json",
        },
        body: JSON.stringify(runTemplateDetails),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function saveRunTemplateToADF(runTemplateId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(Configurations.mintHubApiBaseUrl + "/Template/Template/MasterTemplate?runTemplateId=" + runTemplateId, 
        {
        method: "POST",
        headers: {
          Authorization: "Bearer " + authToken,
          "content-type": "application/json",
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRunTemplate(runTemplateId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + runTemplateId, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}
