import { AppActions } from "../types/app-actions";
import * as types from "../types/source-onboarding-acion-types";
import { ISourceOnboardingConnectionsList } from "../../components/onboarding/source-onboarding/model/ISourceOnboarding";

export const getAllConnections1 = (
  totalConnections: ISourceOnboardingConnectionsList
): AppActions => {
  return ({
    type: types.GET_ALL_CONNETIONS1,
    totalConnections,
  });
};

export const deleteConnection = (
  data: ISourceOnboardingConnectionsList
): AppActions => {
  return ({
    type: types.DELETE_CONNECTION,
    data,
  });
};

/*export function getAllConnections() {
    return function(dispatch: (arg0: { type: string; payload: any; }) => void) {
      return fetch("https://jsonplaceholder.typicode.com/posts")
        .then(response => response.json())
        .then(json => {
          dispatch({ type: "DATA_LOADED", payload: json });
        });
    };
  }*/

