import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const processDetailsPageView: IPageViewTelemetry = {
  name: "Process Details",
  uri: "orchestration/process-details",
};

export const addActivityClickEvent: IEventTelemetry = {
  name: "ProcessDetails_AddActivityClick",
};

export const addSubProcessClickEvent: IEventTelemetry = {
  name: "ProcessDetails_AddSubProcessClick",
};

export const addUpdateProcessClickEvent: IEventTelemetry = {
  name: "ProcessDetails_AddUpdateProcessClick",
};
