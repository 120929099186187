import { IDropdownOption } from "@fluentui/react";
import { IActivity, IActivityMetadata } from "../../models/activity";

export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const GET_ACTIVITY_DETAILS = "GET_ACTIVITY_DETAILS";
export const GET_ACTIVITY_METADATA = "GET_ACTIVITY_METADATA";
export const GET_ACTIVITY_TYPE_LIST = "GET_ACTIVITY_TYPE_LIST";
export const GET_ACTIVITY_PARAMETER_LIST = "GET_ACTIVITY_PARAMETER_LIST";

export interface GetAllActivities {
  type: typeof GET_ACTIVITY_LIST;
  activityList: IActivity[];
}

export interface DeleteActivity {
  type: typeof DELETE_ACTIVITY;
  activityId: number;
}

export interface GetActivityDetails {
  type: typeof GET_ACTIVITY_DETAILS;
  activityDetails: IActivity;
}

export interface GetActivityMetadata {
  type: typeof GET_ACTIVITY_METADATA;
  activityMetadata: IActivityMetadata;
}

export interface GetActivityTypeList {
  type: typeof GET_ACTIVITY_TYPE_LIST;
  activityTypeList: IDropdownOption[];
}

export interface GetActivityParameterList {
  type: typeof GET_ACTIVITY_PARAMETER_LIST;
  activityParameterList: IDropdownOption[];
}

export type ActivityActionTypes =
  | GetAllActivities
  | DeleteActivity
  | GetActivityDetails
  | GetActivityMetadata
  | GetActivityTypeList
  | GetActivityParameterList;
