import { Dialog, DialogFooter, DialogType, Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import React from "react";
import RunList, { RunsToList } from "./run-list/run-list";
import TemplateRunDetails from "./template-run-details/template-run-details.controller";
import { clearErrorAndSuccessMessage } from "../../redux/actions/run-dashboard-v2-actions";
import { AppState } from "../../redux/configureStore";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../redux/types/app-actions";
import { bindActionCreators } from "redux";

interface IRunDashboradV2Props {
  successMessageProps: string,
  errorMessageProps: string
}

interface IRunDashboradV2DispatchProps {
  clearErrorAndSuccessMessage: () => void,
}

type Props = IRunDashboradV2DispatchProps 
  & IRunDashboradV2Props;

export class RunDashboardV2 extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogVisible: false
    }
  }

componentDidUpdate(prevProps: Readonly<Props>): void {
  if (prevProps.errorMessageProps !== this.props.errorMessageProps || prevProps.successMessageProps !== this.props.successMessageProps)
    this.setState({isDialogVisible: true})
}

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Pivot aria-label="Menu Pivot for Run Dashboard">
          <PivotItem headerText="In Progress Runs">
            <RunList
              runsToList={RunsToList.inProgressRuns}
            />
          </PivotItem>
          <PivotItem headerText="Future Runs">
            <RunList
              runsToList={RunsToList.upcomingRuns}
            />
          </PivotItem>
          <PivotItem headerText="Past Runs">
            <RunList
              runsToList={RunsToList.previousRuns}
            />
          </PivotItem>
        </Pivot>
    {/* Conditional check to show the dialog box necessary since the hidden property gets updated before it shows. */}
    {this.props.successMessageProps.length > 0 && <Dialog
          onDismiss={() => {this.props.clearErrorAndSuccessMessage()}}
          hidden={this.props.successMessageProps.length === 0}
          dialogContentProps={{
            title: "Confirmation" || "Title",
            subText: this.props.successMessageProps || "SubText",
            type: DialogType.normal
          }}
        >
            <DialogFooter>
            <PrimaryButton onClick={() => {this.props.clearErrorAndSuccessMessage()}} text="Ok" />
          </DialogFooter>
        </Dialog>
  }
      {this.props.errorMessageProps.length > 0 && <Dialog
          onDismiss={() => {this.props.clearErrorAndSuccessMessage()}}
          hidden={this.props.errorMessageProps.length === 0}
          dialogContentProps={{
            title: "Error" || "Title",
            subText: this.props.errorMessageProps || "SubText",
            type: DialogType.normal
          }}
        >
            <DialogFooter>
            <PrimaryButton onClick={() => {this.props.clearErrorAndSuccessMessage()}} text="Ok" />
          </DialogFooter>
        </Dialog>
  }
        <TemplateRunDetails />
      </React.Fragment>
    );
  }
} 

  const mapStateToProps = (
    state: AppState
  ): IRunDashboradV2Props => {
  
    return {
      successMessageProps: state.runDashboardV2.SuccessMessageText,   
      errorMessageProps: state.runDashboardV2.ErrorMessageText
    }
  }

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, AppActions>
  ): IRunDashboradV2DispatchProps => ({
    clearErrorAndSuccessMessage: bindActionCreators(
      clearErrorAndSuccessMessage , dispatch
      ),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(RunDashboardV2);
  