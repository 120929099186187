import { Dispatch } from "redux";
import { AppActions } from "./redux/types/app-actions";
import { AppState } from "./redux/configureStore";
import * as UserDetailsAPI from "./api/user-details-api";
import { UserRoleDetails } from "./models/user-profile";
import * as UserProfileActions from "./redux/actions/user-profile-actions";
import * as FiscalYearActions from "./redux/actions/fiscal-year-actions";
import * as TelemetryProvider from "./TelemetryProvider";

export const startGetUserRoleData = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    UserDetailsAPI.getUserRoleDetails()
      .then((value) => {
        const userRoleDetails: UserRoleDetails = value.Result;
        dispatch(UserProfileActions.getAuthControls(userRoleDetails));
      })
      .catch((error) => {
        TelemetryProvider._trackException(error);
      });
  };
};

export const startGetFiscalYearInfo = () => {
  var FiscalyearList = [
    { key: 2024, text: "2024" },
    { key: 2025, text: "2025" },
  ];
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(FiscalYearActions.getFiscalYearList(FiscalyearList));

    const fiscalYearState = getState().fiscalYear;

    // If there isn't a fiscalYear state object in state or the fiscalYear on the fiscalYear state object
    // is not in the list of fiscal years, then set the selected fiscal year to the default.
    if (!fiscalYearState?.fiscalYear || FiscalyearList.findIndex((f) => f.key === fiscalYearState.fiscalYear) === -1) {
      dispatch(FiscalYearActions.getFiscalYear(2025));
    }
  };
}
