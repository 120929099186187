export const RecurrenceRuleParameter = [
  { type: "Custom", dates: { id: 1, name: "SelectedDates" } },
  {
    type: "Weekly",
    days: { id: 2, name: "SelectedDays" },
    frequency: { id: 3, name: "Frequency" },
  },
  {
    type: "Monthly",
    dates: { id: 4, name: "SelectedDates" },
    frequency: { id: 5, name: "Frequency" },
  },
  {
    type: "Yearly",
    dates: { id: 6, name: "SelectedDates" },
    month: { id: 7, name: "SelectedMonth" },
    frequency: { id: 8, name: "Frequency" },
  },
];
