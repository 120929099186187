import { ITemplateRunDetails, ITemplateRunProcesses } from "../../models/template-run";
import * as ActionTypes from "../types/template-run-details-action-types";
import { TemplateRunDetailsActionTypes } from "../types/template-run-details-action-types";
type ITemplateRunDetailsReducer = {
	showTemplateRunDetails: boolean;
	selectedTemplateRunDetails?: ITemplateRunDetails;
	isLoading: boolean;
	hideNotScheduled: boolean;
}
const templateRunDetailsReducerDefaultState: ITemplateRunDetailsReducer = {
	showTemplateRunDetails: false,
	selectedTemplateRunDetails: undefined,
	isLoading: false,
	hideNotScheduled: false,
}
const templateRunDetails = (
	state = templateRunDetailsReducerDefaultState,
	action: TemplateRunDetailsActionTypes
): ITemplateRunDetailsReducer => {
	switch (action.type) {
		case ActionTypes.UPDATE_HIDE_NOT_SCHEDULED:
			return {
				...state,
				hideNotScheduled: action.hideNotScheduled,
			};
		case ActionTypes.UPDATE_IS_TEMPLATE_RUN_DETAILS_SHOWN:
			return {
				...state,
				showTemplateRunDetails: action.isShown,
			};
		case ActionTypes.REQUEST_TEMPLATE_RUN_DETAILS_DATA:
			return {
				...state,
				showTemplateRunDetails: true,
				selectedTemplateRunDetails: undefined,
				isLoading: true,
			};
		case ActionTypes.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS:
			return {
				...state,
				selectedTemplateRunDetails: action.data,
				isLoading: false,
			};
		case ActionTypes.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE:
			return {
				...state,
				isLoading: false,
				showTemplateRunDetails: false,
			};
		case ActionTypes.START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE:
			if (!state.selectedTemplateRunDetails?.Processes) {
				return {...state};
			}

			const newDetails: ITemplateRunDetails = {
				...state.selectedTemplateRunDetails,
				Processes: state.selectedTemplateRunDetails.Processes.map(item => item.ProcessId !== action.data.processId ? item : {
					...item,
					IsProcessingAction: true
				})
			}
			return {
				...state,
				selectedTemplateRunDetails: newDetails
			};
		default:
			return state;
	}
};
export default templateRunDetails;
