import { ITemplateRun } from "../../models/template-run";
export const UPDATE_RUN_LIST_COUNT = "UPDATE_RUN_LIST_COUNT";
export const REQUEST_TEMPLATE_RUN_DATA = "REQUEST_TEMPLATE_RUN_DATA";
export const RECEIVED_TEMPLATE_RUN_DATA_SUCCESS = "RECEIVED_TEMPLATE_RUN_DATA_SUCCESS";
export const RECEIVED_TEMPLATE_RUN_DATA_FAILURE = "RECEIVED_TEMPLATE_RUN_DATA_FAILURE";
export const MARK_RUN_LISTS_DIRTY = "MARK_RUN_LISTS_DIRTY";
export const CLEAR_ERROR_AND_SUCCESS_MESSAGE = "CLEAR_ERROR_AND_SUCCESS_MESSAGE";
export interface UpdateRunListCount {
  type: typeof UPDATE_RUN_LIST_COUNT;
  count: number;
  executionStatus: number,
}
export interface RequestTemplateRunData {
  type: typeof REQUEST_TEMPLATE_RUN_DATA;
  payload?: any;
  executionStatus: number;
}
export interface ReceivedTemplateRunDataSuccess {
  type: typeof RECEIVED_TEMPLATE_RUN_DATA_SUCCESS;
  runData: ITemplateRun[];
  executionStatus: number;
}
export interface ReceivedTemplateRunDataFailure {
  type: typeof RECEIVED_TEMPLATE_RUN_DATA_FAILURE;
  errorMessage: any;
  executionStatus: number;
}
export interface MarkRunListDirty {
  type: typeof MARK_RUN_LISTS_DIRTY;
}
export interface ClearErrorAndSuccessMessage {
  type: typeof CLEAR_ERROR_AND_SUCCESS_MESSAGE;
}
export type RunDashboardV2ActionTypes =
  | UpdateRunListCount
  | ReceivedTemplateRunDataSuccess
  | ReceivedTemplateRunDataFailure
  | RequestTemplateRunData
  | MarkRunListDirty
  | ClearErrorAndSuccessMessage;
  