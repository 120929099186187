import * as React from "react";
import { IDetailsListPropsCustom } from "./models/IDetailsList";
import {
  DetailsListLayoutMode,
  ConstrainMode,
  ShimmeredDetailsList,
} from "@fluentui/react";

const DetailsListView = (Props: IDetailsListPropsCustom) => {
  return (
    <ShimmeredDetailsList
      items={Props.items}
      columns={Props.columns}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={Props.selectionMode}
      selectionPreservedOnEmptyClick={Props.selectionPreservedOnEmptyClick}
      onItemInvoked={Props.onItemInvoked}
      onRenderDetailsHeader={Props.onRenderDetailsHeader}
      constrainMode={ConstrainMode.unconstrained}
      enableShimmer={Props.enableShimmer}
      shimmerLines={Props.shimmerLines}
      ariaLabelForShimmer="Content is being fetched"
      ariaLabelForGrid="Item details"
      setKey={Props.setKey}
      checkButtonAriaLabel={Props.checkButtonAriaLabel}
      onRenderRow={Props.onRenderRow}
    />
  );
};
export default DetailsListView;
