import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getLocalTimeZone } from "../../util/time-zone-util";
import { Link } from "react-router-dom";
import * as TelemetryProvider from "../../TelemetryProvider";
import { addNewProcessClickEvent } from "./process.telemtetry-constants";

type ProcessViewProps = {
  columnDefs: any[];
  rowData: any[];
  gridCellKeyPress: any;
};

export class ProcessView extends React.Component<ProcessViewProps> {
  public render(): JSX.Element {
    return (
      <div>
        <Link
          to={{
            pathname: `/orchestration/process-details`,
            state: { processId: 0, isEditMode: true },
          }}
        >
          <PrimaryButton
            text="Add New Process"
            onClick={() => {
              TelemetryProvider._trackEvent(addNewProcessClickEvent);
            }}
          />
        </Link>
        <div>
          <div
            className="ag-theme-balham"
            style={{
              width: "100%",
              height: "82vh",
              marginTop:
                "11px" /*, margin: '5px 0px 0px 60px', width: '100%' */,
            }}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={50}
              enableRangeSelection={true}
              pagination={true}
              rowHeight={30}
              enableCellTextSelection={true}
              onCellKeyDown={this.props.gridCellKeyPress}
            ></AgGridReact>
          </div>
        </div>
        <div className="time-zone-message">
          *All date and time displayed in the above grid and it's subcomponents
          are in <b>{getLocalTimeZone()} Time Zone</b> (adjusted for daylight
          saving if applicable).
        </div>
      </div>
    );
  }
}

export default ProcessView;
