import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import Loading from "../../loading/loading";
import {
  IProcessDetailsLinkDispatchProps,
  IProcessDetailsLinkStateProps,
  IProcessDetailsProps,
  IProcessDetailsState,
} from "./models/IProcessDetails";
import { useParams, useLocation } from "react-router-dom";
import ProcessInfo from "./process-info/process-info.controller";
import ProcessDependencyGrid from "./process-dependency-grid/process-dependency-grid.controller";
import {
  startGetAllDependencyTypes,
  startGetProcessDetails,
  startGetProcessMetadata,
} from "./process-details.service";
import EditProcessPanel from "./edit-process-panel/edit-process-panel.controller";
import { DefaultEffects } from "@fluentui/react";
import { IProcessDetails } from "../../../models/process";
import { getProcessDetails } from "../../../redux/actions/process-actions";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { processDetailsPageView } from "./process-details.telemtetry-constants";
import queryString from "query-string";

type Props = IProcessDetailsProps &
  IProcessDetailsLinkDispatchProps &
  IProcessDetailsLinkStateProps &
  any;
const getNewProcessDefinition = () => {
  const newProcess: IProcessDetails = {
    ProcessId: 0,
    Depth: 0,
    ParentProcessName: "",
    FiscalYear: 0,
    ProcessName: "",
    IsSubProcess: false,
    IsInternalProcess: false,
    IsActive: true,
    ChildProcesses: [],
    Activities: [],
    CreatedBy: "",
    CreatedOn: new Date(),
    ModifiedBy: "",
    ModifiedOn: new Date(),
    Dependencies: [],
    LastRunId: "",
    LastRunExecutionStatus: "",
    InfoGroup: "",
    FailureGroup: "",
  };
  return newProcess;
};

// class ProcessDetails extends React.Component<Props, IProcessDetailsState> {
export const ProcessDetails: React.FunctionComponent<Props> = (props) => {
  // Constructor method
  const [isEditMode, updateIsEditMode] = useState<boolean>(false);
  const [isFetchingDetails, updateIsFetchingDetails] = useState<boolean>(true);
  var l = useLocation();
  let isInEditMode: boolean = false;
  let processId: number = 0;
  if (l.search) {
    let params = queryString.parse(l.search);
    isInEditMode = params.mode == "edit" ? true : false;
    processId = Number(params.processId);
  }

  useEffect(() => {
    TelemetryProvider._trackPageView(processDetailsPageView);

    props.startGetProcessMetadata(props.fiscalYear);

    if (props.location.state != null && props.location.state.processId === 0) {
      // Add new process
      let processDetails: IProcessDetails = getNewProcessDefinition();
      processDetails.FiscalYear = props.fiscalYear;
      props.getProcessDetails(processDetails);
      updateIsFetchingDetails(false);
    } else {
      if (props.location.state != null) {
        props.startGetProcessDetails(
          props.location.state.processId,
          updateIsFetchingDetails
        );
      } else if (processId > 0) {
        props.startGetProcessDetails(processId, updateIsFetchingDetails);
      }
    }
    props.startGetAllDependencyTypes();

    updateIsEditMode(
      processId > 0 ? isInEditMode : props.location.state.isEditMode
    );
  }, []);

  if (!isFetchingDetails) {
    return (
      <>
        <ProcessInfo isEditMode={isEditMode} />
        <EditProcessPanel
          isEditMode={isEditMode}
          updateIsFetchingDetails={updateIsFetchingDetails}
        />

        <div
          style={{
            boxShadow: DefaultEffects.elevation64,
            padding: "10px",
            height: "70vh",
            width: "100%",
          }}
        >
          <ProcessDependencyGrid
            isEditMode={isEditMode}
            updateIsFetchingDetails={updateIsFetchingDetails}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

const mapStateToProps = (
  state: AppState,
  ownProps: IProcessDetailsProps
): IProcessDetailsLinkStateProps => {
  return {
    processDetails: state.process.processDetails,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IProcessDetailsProps
): IProcessDetailsLinkDispatchProps => ({
  startGetProcessDetails: bindActionCreators(startGetProcessDetails, dispatch),
  startGetProcessMetadata: bindActionCreators(
    startGetProcessMetadata,
    dispatch
  ),
  getProcessDetails: bindActionCreators(getProcessDetails, dispatch),
  startGetAllDependencyTypes: bindActionCreators(
    startGetAllDependencyTypes,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDetails);
