import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const sourceOnboardingPageView: IPageViewTelemetry = {
  name: "Source Onboarding",
  uri: "system/source-onboarding",
};

export const sourceOnboardingExportButtonClickEvent: IEventTelemetry = {
  name: "SourceOnboarding_ExportButtonClick",
};
export const sourceOnboardingDeleteButtonClickEvent: IEventTelemetry = {
  name: "SourceOnboarding_DeleteButtonClick",
};
export const newConnectionSaveButtonClickEvent: IEventTelemetry = {
  name: "SourceOnboarding_NewConnection_SaveButtonClick",
};
export const newConnectionValidateButtonClickEvent: IEventTelemetry = {
  name: "SourceOnboarding_NewConnection_ValidateButtonClick",
};
export const newConnectionClearButtonClickEvent: IEventTelemetry = {
  name: "SourceOnboarding_NewConnection_ClearButtonClick",
};
