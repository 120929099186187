import HorizontalNavBarView from "./horizontal-nav-bar.view";
import React, { Fragment } from "react";
import horizontalNavigationList from "./horizontal-nav-bar.service";
import { Route } from "react-router-dom";

const HorizontalNavBar = (props) => {
  return (
    <Fragment>
      <HorizontalNavBarView
        {...props}
        horizontal_nav_data={horizontalNavigationList}
      />
    </Fragment>
  );
};

export default HorizontalNavBar;
