import * as React from "react";
import {
  DatePicker,
  IDatePickerStrings
} from "@fluentui/react";
import { DayOfWeek } from '@fluentui/date-time-utilities/lib/dateValues/dateValues';


import { mergeStyleSets } from "@fluentui/react/lib/Styling";

const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",

  isRequiredErrorMessage: "Field is required.",

  invalidInputErrorMessage: "Invalid date format.",
};

const getMonthInNumber = (month: string): number => {
  let monthInNumber = 0;
  switch (month.toUpperCase()) {
    case "JANUARY":
      monthInNumber = 1;
      break;
    case "FEBRUARY":
      monthInNumber = 2;
      break;
    case "MARCH":
      monthInNumber = 3;
      break;
    case "APRIL":
      monthInNumber = 4;
      break;
    case "MAY":
      monthInNumber = 5;
      break;
    case "JUNE":
      monthInNumber = 6;
      break;
    case "JULY":
      monthInNumber = 7;
      break;
    case "AUGUST":
      monthInNumber = 8;
      break;
    case "SEPTEMBER":
      monthInNumber = 9;
      break;
    case "OCTOBER":
      monthInNumber = 10;
      break;
    case "NOVEMBER":
      monthInNumber = 11;
      break;
    case "DECEMBER":
      monthInNumber = 12;
      break;
  }
  return monthInNumber;
};

const controlClass = mergeStyleSets({
  control: {
    margin: "0 0 15px 0",
    maxWidth: "300px",
  },
});

export interface IDatePickerState {
  firstDayOfWeek?: DayOfWeek;
  minDate?: Date;
  maxDate?: Date;
  value: any;
  showInitialDate?: boolean;
}

export interface IDatePickerProps {
  id?: string;
  label?: string;
  placeholder?: string;
  ariaLabel?: string;
  StartDate?: Date;
  EndDate?: Date;
  year: number;
  value: Date;
  showInitialDate?: boolean;
  disabled?: boolean;
  role?:string;
  onSelectDate: (date: Date) => void;
}

export class DateInput extends React.Component<
  IDatePickerProps,
  IDatePickerState
> {
  constructor(props: IDatePickerProps) {
    super(props);
    this.state = {
      firstDayOfWeek: DayOfWeek.Sunday,
      minDate: props.StartDate,
      maxDate: props.EndDate,
      value: props.showInitialDate ? props.value : null,
    };
    this.onSelection = this.onSelection.bind(this);
  }

  onSelection(date: any) {
    this.setState({
      value: date,
    });
    this.props.onSelectDate(date);
  }

  public render(): React.JSX.Element {
    const { firstDayOfWeek } = this.state;
    return (
      <div className="docs-DatePickerExample" aria-label={this.props.ariaLabel} role="listbox" aria-multiselectable={false} >
        <DatePicker
          id={this.props.id}
          label={this.props.label}
          className={controlClass.control}
          isRequired={false}
          firstDayOfWeek={firstDayOfWeek}
          strings={DayPickerStrings}
          placeholder={this.props.placeholder}
          ariaLabel={this.props.ariaLabel}
          minDate={this.props.StartDate}
          maxDate={this.props.EndDate}
          value={this.props.value}
          allowTextInput={true}
          initialPickerDate={this.state.minDate}
          onSelectDate={this.onSelection}
          disabled={this.props.disabled}
          role = {"option"}
        />
      </div>
    );
  }
}
