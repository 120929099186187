import * as types from "../actions/actionTypes";
import { AppState } from "../configureStore";

const runScheduleReducerDefaultState = {
  expandCollapseClick: false,
}; // Update any to a type if possible

const expandCollapseReducer = (
  state = runScheduleReducerDefaultState,
  action: any
) => {
  switch (action.type) {
    case types.EXPAND_CLICK:
      return action.expandCollapseClick;

    default:
      return state;
  }
};

export default expandCollapseReducer;
