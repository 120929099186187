import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const runTemplatePageView: IPageViewTelemetry = {
  name: "Run Template",
  uri: "orchestration/run-template",
};

export const addNewRunTemplateClickEvent: IEventTelemetry = {
  name: "RunTemplate_AddNewRunTemplateClick",
};

export const viewRunTemplateClickEvent: IEventTelemetry = {
  name: "RunTemplate_ViewRunTemplateClick",
};

export const editRunTemplateClickEvent: IEventTelemetry = {
  name: "RunTemplate_EditRunTemplateClick",
};

export const deleteRunTemplateClickEvent: IEventTelemetry = {
  name: "RunTemplate_DeleteRunTemplateClick",
};

export const runTemplateDetailsPageView: IPageViewTelemetry = {
  name: "Run Template Details",
  uri: "orchestration/run-template-details",
};

export const addProcessClickEvent: IEventTelemetry = {
  name: "RunTemplateDetails_AddProcessClick",
};

export const addUpdateRunTemplateClickEvent: IEventTelemetry = {
  name: "RunTemplateDetails_AddUpdateRunTemplateClick",
};
