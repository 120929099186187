import React from "react";
import {
  Stack,
  PrimaryButton,
  DefaultButton,
  Dropdown,
  Label,
} from "@fluentui/react";
import { stackTokens } from "../run-schedule.helper";
import { DateInput } from "../../common/date-picker/Date";
import { ModalView } from "../../common/modal/modal.view";
import ComboBox from "../../common/combo-box/combo-box.controller";
import Checkbox from "../../common/checkbox/checkbox.controller";
import TimePicker from "../../common/time-picker/time-picker.controller";
import { RecurrenceRuleViewProps } from "./models/IRecurrenceRule";
import RecurrencePatternController from "../../common/recurrence-pattern/recurrence-pattern.controller";
import "./recurrence-rule.style.scss";
import { getFormattedDateInString } from "../../../util/javascript-functions";

export class RecurrenceRuleView extends React.Component<
  RecurrenceRuleViewProps
> {
  render() {
    return (
      <>
        <ModalView {...this.props.recurrenceRuleModal}>
          <div className="rule-modal">
            <div className="saved-rule">
              <ComboBox
                className="saved-rules-dropdown"
                label="Saved Rules"
                placeholder="Type or select a Rule you want to apply."
                options={this.props.orderedRules}
                selectedKey={this.props.selectedRule?.key}
                onChange={this.props.onSavedRuleSelectionChange}
              />
              <PrimaryButton
                className="saved-rules-apply-button"
                text="Populate"
                onClick={this.props.populateRule}
                disabled={false}
              />
            </div>
            <div className="preview-control-date">
              <span>
                <b>Recurrence range:</b>
              </span>
            </div>
            <RecurrencePatternController
              {...this.props.recurrencePatternParams}
            />
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <PrimaryButton
                text="Apply"
                onClick={() => {
                  this.props.onApply();
                }}
                disabled={!this.props.authControls.canUpdateRunSchedule}
              />

              <PrimaryButton
                text="Save Rule"
                onClick={this.props.onSaveRule}
                disabled={!this.props.authControls.canUpdateRunSchedule}
              />
              <PrimaryButton
                text="Save as New Rule"
                onClick={this.props.onSaveAsNewRule}
                disabled={!this.props.authControls.canUpdateRunSchedule}
              />
              <DefaultButton
                text="Cancel"
                onClick={this.props.onCancelClick}
                disabled={false}
              />
            </Stack>
            {/* {this.props.dates.map((a) => {
              return <div>{getFormattedDateInString(a)}</div>;
            })} */}
          </div>
        </ModalView>
      </>
    );
  }
}
