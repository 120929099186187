import * as types from "../types/run-schedule-action-types";
import { RunScheduleActionTypes } from "../types/run-schedule-action-types";

const runScheduleReducerDefaultState: any = {}; // Update any to a type if possible

const runScheduleFilterReducer = (
  state = runScheduleReducerDefaultState,
  action: RunScheduleActionTypes
): any => {

  switch (action.type) {
    case types.GET_RUN_SCHEDULE_FILTERS:
      return Object.assign({}, state, {
        filters: action.filters,
      });
    case types.UPDATE_FILTER_DATA:
      return Object.assign({}, state, {
        filters: action.gridViewData,
      });
    default:
      return state;
  }
};

export default runScheduleFilterReducer;
