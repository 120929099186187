import React from "react";
import { IRunScheduleData } from "../../../models/run-schedule";
import { runTemplateDrilldownClickEvent } from "../run-schedule.telemtetry-constants";
import { IRunScheduleListState } from "./models/IRunScheduleList";
import "./run-schedule-list.style.scss";
import * as TelemetryProvider from "../../../TelemetryProvider";

export type RunDrilldownNavBarProps = {
  runTemplateName: string;
  isGridAtSubProcessLevel: boolean;
  selectedParentProcessName?: string;
  selectedParentProcessId?: number;
  updateDrillDownLevel: any;
};
export class RunDrilldownNavBar extends React.Component<
  RunDrilldownNavBarProps,
  {}
> {
  getRenderParams() {
    return {
      runTemplateName: this.props.runTemplateName,
      isGridAtSubProcessLevel: this.props.isGridAtSubProcessLevel,
      selectedParentProcessName: this.props.selectedParentProcessName,
      selectedParentProcessId: this.props.selectedParentProcessId,
    };
  }

  render() {
    const params = this.getRenderParams();
    return (
      <>
        <div>
          <span className="run-drilldown-template-name">
            <a
              className="run-drilldown-nav-bar"
              onClick={(event) => {
                TelemetryProvider._trackEvent(runTemplateDrilldownClickEvent);
                this.props.updateDrillDownLevel(event, false);
              }}
              href={"#" + params.runTemplateName}
            >
              {params.runTemplateName}
            </a>
          </span>
          {this.props.isGridAtSubProcessLevel === true ? (
            <>
              {" / "}
              <a
                className="run-drilldown-nav-bar"
                onClick={(event) => {
                  TelemetryProvider._trackEvent(runTemplateDrilldownClickEvent);
                  this.props.updateDrillDownLevel(
                    event,
                    true,
                    params.selectedParentProcessName,
                    params.selectedParentProcessId
                  );
                }}
                href={"#" + params.selectedParentProcessName}
              >
                {params.selectedParentProcessName}
              </a>
            </>
          ) : null}
        </div>
      </>
    );
  }
}
