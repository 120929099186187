import Activity from "./components/activity/activity.controller";
import Process from "./components/process/process.controller";
import RunTemplate from "./components/run-template/run-template.controller";
import UnderConstruction from "./components/under-construction/under-construction";
import { Configurations } from "./App.global";

export const configureFeatureFlighting = () => {
  if (parseInt(Configurations.enableFlighting) === 1) {
    let flightedFeatures = {
      activity: Activity,
      process: Process,
      runTemplate: RunTemplate,
    };
    return flightedFeatures;
  } else {
    let flightedFeatures = {
      runTemplate: UnderConstruction,
    };
    return flightedFeatures;
  }
};
