import * as React from "react";
import { Stack, mergeStyles, IDropdownOption } from "@fluentui/react";
import {
  stackTokens,
  dropdownStyles,
  controlStyles,
} from "../run-schedule.helper";
import Dropdown from "../../common/dropdown/dropdown.controller";
import PrimaryButton from "../../common/button/primary-button/primary-button.controller";
import Toggle from "../../common/toggle/toggle.controller";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import {
  IRunScheduleFilters,
  IScheduledDates,
} from "../../../models/run-schedule";
import "./run-schedule-filters.style.scss";
import { IWeekRange } from "./models/IRunScheduleFilters";

type RunScheduleFilterViewProps = {
  filters: IRunScheduleFilters;
  selectedRunTemplateId: IDropdownOption;
  selectedMonthId: IDropdownOption;
  runTemplateList: IDropdownOption[];
  monthList: IDropdownOption[];
  isWeeklyView: boolean;
  onRunTemplateDropdownValueChange: any;
  onMonthDropdownValueChange: any;
  onApplyClicked: any;
  onChange: any;
  alertClicked: any;
  weekRange: IWeekRange[];
  weekNumber: number;
  gridDateRange: IScheduledDates;
  onBackClick: any;
  onForwardClick: any;
};
export class RunScheduleFilterView extends React.Component<
  RunScheduleFilterViewProps
> {
  render() {
    return (
      <>
        <div className="filter">
          <fieldset className="filter-size">
            <legend>Filters:</legend>
            <Stack
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <Dropdown
                placeholder="Select Run Template"
                label="Run Template"
                options={this.props.runTemplateList}
                selectedKey={this.props.selectedRunTemplateId.key}
                styles={dropdownStyles}
                onChange={this.props.onRunTemplateDropdownValueChange}
                ariaLabel={"Run Template"}
              />
              <Dropdown
                placeholder="Select Month"
                label="Month"
                options={this.props.monthList}
                selectedKey={this.props.selectedMonthId.key}
                styles={dropdownStyles}
                onChange={this.props.onMonthDropdownValueChange}
                ariaLabel={"Month"}
              />
              <PrimaryButton
                text="Apply"
                onClick={this.props.onApplyClicked}
                disabled={false}
                className={mergeStyles({
                  alignSelf: "safe flex-end",
                  marginRight: 10,
                })}
              />
            </Stack>
          </fieldset>
          {/* <br></br> */}
          {/* <fieldset className="rule">
            <legend>Recurrence Rule:</legend>
            <PrimaryButton
              text="Apply All"
              onClick={() => {}}
              disabled={false}
              className={mergeStyles({ padding: 8, margin: 10, marginTop: 28 })}
            />
          </fieldset>*/}
          <fieldset className="view-options">
            <legend>Display Options:</legend>
            <Stack
              horizontal
              tokens={stackTokens}
              horizontalAlign="space-between"
            >
              <div className="view-controls">
                <div className="run-schedule-toggle">
                  <Toggle
                    onText="Weekly"
                    offText="Monthly"
                    onChange={this.props.onChange}
                    checked={this.props.isWeeklyView}
                    styles={controlStyles}
                  />
                </div>
                {this.props.isWeeklyView && this.props.weekNumber > 0 && (
                  <div className="weekly">
                    <IconButton
                      iconName="CaretLeftSolid8"
                      fontSize={16}
                      title="week-back"
                      onClick={this.props.onBackClick}
                      disabled={this.props.weekNumber == 1}
                    />

                    {this.props.weekNumber > 0 &&
                      this.props.weekRange.length > 0 && (
                        <div className="week-range">
                          {this.props.weekRange[
                            this.props.weekNumber - 1
                          ].display.toString()}
                        </div>
                      )}

                    <IconButton
                      iconName="CaretRightSolid8"
                      fontSize={16}
                      title="week-forward"
                      onClick={this.props.onForwardClick}
                      disabled={
                        this.props.weekNumber == this.props.weekRange.length
                      }
                    />
                  </div>
                )}
                {/* <div className="expand-icon">
                  <IconButton
                    iconName="LargeGrid"
                    fontSize={32}
                    title="Expand/Collapse view"
                    onClick={this.props.alertClicked}
                  />
                </div> */}
              </div>
            </Stack>
          </fieldset>
        </div>
      </>
    );
  }
}
