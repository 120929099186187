import React from "react";
import "./App.scss";
import { connect } from "react-redux";
import AccountContext from "./auth/account-context";
import { bindActionCreators } from "redux";
import { AppActions } from "./redux/types/app-actions";
import { ThunkDispatch } from "redux-thunk";
import { IAppLinkDispatchProps, IAppProps, IAppLinkStateProps } from "./IApp";
import {
  startGetUserRoleData,
  startGetFiscalYearInfo,
} from "./App.service";
import AppInterceptor from "./App.Interceptor";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import * as TelemetryProvider from "./TelemetryProvider";

type Props = IAppProps & IAppLinkStateProps & IAppLinkDispatchProps;

export class App extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.startGetUserRoleData) {
      this.props.startGetUserRoleData();
      this.props.startGetFiscalYearInfo();
    }
  }

  componentDidCatch(error: any, info: any) {
    TelemetryProvider._trackException(error);
    console.log(error, info);
  }

  public render(): React.JSX.Element {
    return (
      <div className="App">
        <React.Fragment>
          <AccountContext.Provider value={this.props.accountInfo.account}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <AppInterceptor />
            </AppInsightsContext.Provider>
          </AccountContext.Provider>
        </React.Fragment>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IAppProps
): IAppLinkDispatchProps => ({
  startGetUserRoleData: bindActionCreators(startGetUserRoleData, dispatch),
  startGetFiscalYearInfo: bindActionCreators(startGetFiscalYearInfo, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
