export const GET_FISCAL_YEAR_LIST = "GET_FISCAL_YEAR_LIST";
export const GET_FISCAL_YEAR = "GET_FISCAL_YEAR";


export interface GetFiscalYearList {
  type: typeof GET_FISCAL_YEAR_LIST;
  fiscalYearList: any[];
}

export interface GetFiscalYear {
  type: typeof GET_FISCAL_YEAR;
  fiscalYear: number;
}

export type FiscalYearActionTypes =
    GetFiscalYearList
  | GetFiscalYear;