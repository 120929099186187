import { AppActions } from "../types/app-actions";
import * as types from "../types/fiscal-year-action-types";

export const getFiscalYearList = (fiscalYearList: any[]): AppActions => ({
  type: types.GET_FISCAL_YEAR_LIST,
  fiscalYearList,
});

export const getFiscalYear = (fiscalYear: number): AppActions => ({
  type: types.GET_FISCAL_YEAR,
  fiscalYear,
});