import * as React from 'react';
import { IButtonPropsCustom } from '../models/IButton';
import { DefaultButton } from '@fluentui/react';

const DefaultButtonView = (Props: IButtonPropsCustom) => {
    return <DefaultButton 
                allowDisabledFocus 
                text={Props.text}
                onClick={Props.onClick} 
                disabled={Props.disabled} 
                />;
}
export default DefaultButtonView;

