import { RunsToList } from "../../components/run-dashboard-v2/run-list/run-list";
import { ITemplateRun } from "../../models/template-run";
import * as RunDashboardV2ActionType from "../types/run-dashboard-v2-action-types";
import * as TemplateRunDetailsActionType from "../types/template-run-details-action-types"

type CombinedActionTypes =
  | RunDashboardV2ActionType.UpdateRunListCount
  | RunDashboardV2ActionType.ReceivedTemplateRunDataSuccess
  | RunDashboardV2ActionType.ReceivedTemplateRunDataFailure
  | RunDashboardV2ActionType.RequestTemplateRunData
  | RunDashboardV2ActionType.MarkRunListDirty
  | RunDashboardV2ActionType.ClearErrorAndSuccessMessage
  | TemplateRunDetailsActionType.RecievedTemplateRunDetailsSuccess
  | TemplateRunDetailsActionType.RecievedTemplateRunDetailsFailure
  | TemplateRunDetailsActionType.TemplateRunUpdateFailure;
  
interface RunData {
	runs: ITemplateRun[],
	isLoaded: boolean,
	isLoading: boolean,
	isDirty: boolean,
	count: number
};

type IRunDashboardV2Reducer = {
	RunData: {
		[key: string]: RunData
	},
	ErrorMessageText: string, 
	SuccessMessageText: string
}

const runDashboardV2ReducerDefaultState: IRunDashboardV2Reducer = {
	RunData: {},
	ErrorMessageText: "",
	SuccessMessageText: ""
};

// Updates the appropriate RunData property on the state parameter based on
// the provided executionStatus using the values provided in NewRunData.
function updateRunData(
	state: IRunDashboardV2Reducer,
	executionStatus: number,
	NewRunData: Partial<RunData>,
	): IRunDashboardV2Reducer {
	return {
		...state,
		RunData: {
			...state.RunData,
			[RunsToList[executionStatus]]: {
				...state.RunData[RunsToList[executionStatus]],
				...NewRunData
			}
		},
	};
}

const runDashboardV2 = (
	state = runDashboardV2ReducerDefaultState,
	action: CombinedActionTypes
): IRunDashboardV2Reducer => {
	switch (action.type) {
		case RunDashboardV2ActionType.UPDATE_RUN_LIST_COUNT:
			return updateRunData(state, action.executionStatus, {
				count: action.count,
			});
		case RunDashboardV2ActionType.REQUEST_TEMPLATE_RUN_DATA:
			return updateRunData(state, action.executionStatus, {
				isLoading: true,
				isDirty: false,
			});
		case RunDashboardV2ActionType.RECEIVED_TEMPLATE_RUN_DATA_SUCCESS:
			
			return updateRunData(state, action.executionStatus, {
				runs: action.runData,
				isLoading: false,
				isLoaded: true,
				isDirty: false
				
			});
		case RunDashboardV2ActionType.RECEIVED_TEMPLATE_RUN_DATA_FAILURE:
			let tmpState: IRunDashboardV2Reducer = {
				...state,
				ErrorMessageText: action.errorMessage
				
			};
			return updateRunData(tmpState, action.executionStatus, {
				isLoading: false
			});
		case RunDashboardV2ActionType.MARK_RUN_LISTS_DIRTY:
			const newState = { ...state, RunData: { ...state.RunData } };
			// This is to iterate through the RunData's properties and if a property has
			// the property 'isDirty' then it is a RunData, and we can mark it as dirty.
			for (const key in newState.RunData) {
				if (Object.prototype.hasOwnProperty.call(newState.RunData, key)
					&& Object.prototype.hasOwnProperty.call(newState.RunData[key], "isDirty")) {
					newState.RunData[key] = {
						...newState.RunData[key],
						isDirty: true,
					};
				}
			}
			return newState;
		case RunDashboardV2ActionType.CLEAR_ERROR_AND_SUCCESS_MESSAGE:
			return {
				...state,
				ErrorMessageText: "",
				SuccessMessageText: ""
			};
		// Following switch cases are to handle the alert box messages through runDasboardV2 state
		case TemplateRunDetailsActionType.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE:
			return {
				...state,
				ErrorMessageText: action.errorMessage
			};

		case TemplateRunDetailsActionType.TEMPLATE_RUN_UPDATE_FAILURE:
			return {
				...state,
				ErrorMessageText: action.errorMessage
			};

		case TemplateRunDetailsActionType.RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS:
			return {
				...state,
				SuccessMessageText: action.successMessage
			};
		default:
			return state;
	}
};

export default runDashboardV2;
