import { useState, Dispatch, SetStateAction } from "react";

export function getShallowCopyObject(data: any) {
  return Object.assign({}, data);
}

export function getShallowCopyArray(data: any) {
  return Object.assign([], data);
}

export function getDeepCopy(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function addItemInArray(arrayList: any[], index: any, item: any) {
  return arrayList.splice(index + 1, 0, item);
}

export function updateProperty(obj: any, property: any) {
  return Object.assign(obj, property);
}

export function extractTimeInZuluFormat(date: Date, time: string) {
  var yearString = date.getFullYear().toString();
  var month = date.getMonth() + 1;
  var monthString = month > 9 ? month.toString() : "0" + month.toString();
  var day = date.getDate();
  var dayString = day > 9 ? day.toString() : "0" + day.toString();
  return (
    yearString + "-" + monthString + "-" + dayString + "T" + time + ":00.000"
  );
}

export function getFormattedDateInString(date: any) {
  var day = date?.getDate().toString();
  var month = date?.getMonth();
  var year = date?.getFullYear().toString();
  if (month != null) month = month + 1;
  if (month <= 9) month = "0" + month?.toString();
  else month = month?.toString();
  var selecteddate =
    day <= 9
      ? year + "-" + month?.toString() + "-0" + day
      : year + "-" + month?.toString() + "-" + day;
  return selecteddate;
}

export function setStartTimeForDate(date: Date): Date {
  var d = new Date(new Date(date.setHours(0)).setMinutes(0));
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function setEndTimeForDate(date: Date) {
  var d = new Date(new Date(date.setHours(23)).setMinutes(59));
  d.setSeconds(59);
  d.setMilliseconds(59);
  return d;
}

export function useCustomUseState<S>(
  isDirty: any,
  initialState: S
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState(initialState);

  function dispatch(value: React.SetStateAction<S>): void {
    //const nextState = reducer(state, action);
    isDirty(true);

    setState(value);
  }

  return [state, dispatch];
}
