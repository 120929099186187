import React, { useState } from "react";
import "./recurrence-pattern.style.scss";
import RecurrencePatternView from "./recurrence-pattern.view";
import { IRecurrenceRulePatternProps } from "./model/IRecurrenceRulePatternProps";

export const RecurrencePatternController: React.FunctionComponent<IRecurrenceRulePatternProps> = (
  props
) => {

  return (
    <>
      <RecurrencePatternView {...props}>
        {props.children}
      </RecurrencePatternView>
    </>
  );
};

export default RecurrencePatternController;
