import * as types from "../types/process-action-types";
import { ProcessActionTypes } from "../types/process-action-types";

const processDefaultState: any = {}; // Update any to a type if possible

const processReducer = (
  state = processDefaultState,
  action: ProcessActionTypes
): any => {
  switch (action.type) {
    case types.GET_PROCESS_LIST:
      return Object.assign({}, state, {
        processList: action.processList,
      });
    case types.GET_PROCESS_METADATA:
      return Object.assign({}, state, {
        processMetadata: action.processMetadata,
      });
    case types.GET_PROCESS_GROUP_LIST:
      return Object.assign({}, state, {
        processGroupList: action.processGroupList,
      });
    case types.GET_PROCESS_DETAILS:
      return Object.assign({}, state, {
        processDetails: action.processDetails,
      });
    case types.GET_ALL_DEPENDENCY_TYPES:
      return Object.assign({}, state, {
        dependencyTypesList: action.dependencyTypesList,
      });
	case types.GET_PROCESS_SLA_DETAILS:
		return Object.assign({}, state, {
			processSlaDetails: action.processSlaDetails,
		});
    default:
      return state;
  }
};

export default processReducer;
