import * as React from "react";
import { Checkbox } from "@fluentui/react";
import { ICheckboxPropsCustom } from "./models/ICheckbox";

const CheckboxView = (Props: ICheckboxPropsCustom) => {
  return (
    <Checkbox
      checked={Props.checked}
      onChange={Props.onChange}
      indeterminate={Props.indeterminate}
      key={Props.key}
      id={Props.id}
      className={Props.className}
      label={Props.label}
      disabled={Props.disabled}
      title={Props.title}
      ariaLabel={Props.ariaLabel}
      styles={Props.styles}
    />
  );
};
export default CheckboxView;
