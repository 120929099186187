import { IRunTemplateDetails } from "../../models/run-template";

export const GET_RUN_TEMPLATE_LIST = "GET_RUN_TEMPLATE_LIST";
export const GET_RUN_TEMPLATE_DETAILS = "GET_RUN_TEMPLATE_DETAILS";

export interface GetAllRunTemplates {
  type: typeof GET_RUN_TEMPLATE_LIST;
  runTemplateList: IRunTemplateDetails[];
}

export interface GetRunTemplateDetails {
  type: typeof GET_RUN_TEMPLATE_DETAILS;
  runTemplateDetails: IRunTemplateDetails;
}

export type RunTemplateActionTypes = GetAllRunTemplates | GetRunTemplateDetails;
