import * as React from "react";
import { ITogglePropsCustom } from "./models/IToggle";
import { Toggle } from "@fluentui/react";

const ToggleView = (Props: ITogglePropsCustom) => {
  return (
    <Toggle
      onText={Props.onText}
      offText={Props.offText}
      onChange={Props.onChange}
      checked={Props.checked}
      disabled={Props.disabled}
      styles={Props.styles}
    />
  );
};
export default ToggleView;
