import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getLocalTimeZone } from "../../util/time-zone-util";
import { Link } from "react-router-dom";
import * as TelemetryProvider from "../../TelemetryProvider";
import { addNewRunTemplateClickEvent } from "./run-template.telemtetry-constants";

type RunTemplateViewProps = {
  columnDefs: any[];
  rowData: any[];
  gridCellKeyPress: any;
};

var newRunTemplateControl = () => {
  return <PrimaryButton text="Add New Run Template" onClick={() => {}} />;
};

var newRunTemplateModal = {
  title: "Add New Run Template",
  control: newRunTemplateControl(),
};

export class RunTemplateView extends React.Component<RunTemplateViewProps> {
  public render(): JSX.Element {
    const defaultColDef = {
      resizable: true,
        };
    return (
      <div>
        <Link
          to={{
            pathname: `/orchestration/run-template-details`,
            state: { runTemplateId: 0, isEditMode: true },
          }}
        >
          <PrimaryButton
            text="Add New Run Template"
            onClick={() => {
              TelemetryProvider._trackEvent(addNewRunTemplateClickEvent);
            }}
          />
        </Link>
        <div>
          <div
            className="ag-theme-balham"
            style={{
              height: "80vh",
              width: "100%",
              marginTop:
                "11px" /*, margin: '5px 0px 0px 60px', width: '100%' */,
            }}
          > 
          
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={50}
              enableRangeSelection={true}
              pagination={true}
              rowHeight={30}
              enableCellTextSelection={true}
              onCellKeyDown={this.props.gridCellKeyPress}
            ></AgGridReact>
          </div>
        </div>
        <div className="time-zone-message">
          *All date and time displayed in the above grid and it's subcomponents
          are in <b>{getLocalTimeZone()} Time Zone</b> (adjusted for daylight
          saving if applicable).
        </div>
      </div>
    );
  }
}

export default RunTemplateView;
