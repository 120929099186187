import * as React from "react";
import ComboBoxView from "./combo-box.view";
import { IComboBoxPropsCustom } from "./models/IComboBox";

const ComboBoxController = (Props: IComboBoxPropsCustom) => {
  return (
    <ComboBoxView
      placeholder={Props.placeholder}
      label={Props.label}
      ariaLabel={Props.ariaLabel}
      options={Props.options}
      onChange={Props.onChange}
      className={Props.className}
      selectedKey={Props.selectedKey}
      styles={Props.styles}
      multiSelect={Props.multiSelect}
      defaultSelectedKey={Props.defaultSelectedKey}
      required={Props.required}
      defaultValue={Props.defaultValue}
      dropdownWidth={Props.dropdownWidth}
      disabled={Props.disabled}
      title={Props.title}
    />
  );
};

const ComboBox = (Props: IComboBoxPropsCustom) => {
  return ComboBoxController(Props);
};
export default ComboBox;
