import {
  IRunSchedule,
  IRunScheduleData,
  IRunTemplateSchedule,
  IScheduledDates,
  IRunScheduleProcess,
} from "../../../models/run-schedule";
import {
  IRunScheduleInfoList,
  IRunScheduleColumn,
} from "../models/IRunSchedule";
import { classNames, getNameStyles } from "../run-schedule.helper";
import {
  ColumnActionsMode,
  Checkbox,
  addElementAtIndex,
} from "@fluentui/react";
import React from "react";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import {
  getDeepCopy,
  updateProperty,
} from "../../../util/javascript-functions";

import CreditingPreview from "../crediting-preview/creditingpreview";
import { CreditingEntry } from "../enum/creditingEntryPoint";
import RecurrenceRule from "../recurrence-rule/recurrence-rule.controller";
import AccountContext from "../../../auth/account-context";
import {
  getTimeIn24HourFormat,
  utcToBrowserTimeZone,
  formatDate,
  formatDateTimeWithoutTimezone,
  isTodayDaylightSavingDay,
} from "../../../util/time-zone-util";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { processNameClickEvent } from "../run-schedule.telemtetry-constants";

export function getDefaultStartTmeForFutureDates(date: Date) {
  //Setting default start time to 12 AM PDT
  date.setHours(1, 0, 0, 0);
  if (isTodayDaylightSavingDay(new Date())){
    date.setHours(0, 0, 0, 0); 
  }

  let utcDateTime = new Date(date);
  const utcOffset =
    new Date().getTimezoneOffset() - utcDateTime.getTimezoneOffset();

  const defaultStartTimeWithoutTimeZone =
    formatDateTimeWithoutTimezone(date) + " PDT";

  let newDate = new Date(defaultStartTimeWithoutTimeZone);
  newDate.setMinutes(newDate.getMinutes() - utcOffset);
  let hours = new Date(newDate).getHours();
  let minutes = new Date(newDate).getMinutes();

  const defaultStartTime = {
    Hours: hours.toString(),
    Mins: minutes.toString(),
  };
  if (parseInt(defaultStartTime.Hours) < 10)
    defaultStartTime.Hours = "0" + defaultStartTime.Hours;
  if (parseInt(defaultStartTime.Mins) < 10)
    defaultStartTime.Mins = "0" + defaultStartTime.Mins;
  return defaultStartTime;
}

export function createProcess(process: IRunScheduleProcess): IRunSchedule {
  const processDetails = {
    key: process.ProcessId,
    name: process.ProcessName,
    hasSubProcesses: process.ChildProcesses !== undefined,
    status: "#000000",
    time: "10:00 AM - 11:10 AM",
    duration: "70",
    info: "",
    isConfigurable: true,
    scheduledDates: [],
    runScheduleIdList: [],
    runTemplateProcessId: process.RunTemplateProcessId,
  };
  return processDetails;
}

// Create Run scheule list info having
// run schedule id
//  day
// time in 24 hour format
export function createRunScheduleInfoItem(
  schedule: IRunTemplateSchedule
): IRunScheduleInfoList {
  var runScheduleInfo = {
    id: schedule.RunTemplateScheduleId,
    dateTime: new Date(schedule.ScheduleDateTime),
    date: new Date(schedule.ScheduleDateTime).getDate(),
    timeIn24HourFormat: getTimeIn24HourFormat(schedule.ScheduleDateTime),
    tag: "",
  };
  if (schedule.Parameters != null && schedule.Parameters.length > 0) {
    var parameter = schedule.Parameters.find(
      (a: any) => a.ParameterName == "TagName"
    );
    if (parameter != null) {
      runScheduleInfo.tag =
        parameter.ParameterValue == null ? "" : parameter.ParameterValue;
    }
  }

  return runScheduleInfo;
}

export function isProcessNotPresentInMapStateToProcessList(
  mapStateToProcessList: any,
  allprocess: any
): boolean {
  return (
    mapStateToProcessList.get(allprocess.ProcessId) == null
    // &&
    // allprocess.IsSubProcess === false
  );
}

//****** State related helper**** */
export function getClonedRunSchedule(
  dataList: any,
  indexToAdd: any,
  runScheduleId: any
) {
  var record = getDeepCopy(
    dataList.find((a: any) => a.RunTemplateScheduleId == runScheduleId)
  );

  var originalScheduleDateTime = new Date(record.ScheduleDateTime);
  // Scheduling cloned run for 11:59 PM by default.
  const scheduleDateTime = new Date(formatDate(record.ScheduleDateTime));
  record.ScheduleDateTime = new Date(scheduleDateTime);

  if (record.ScheduleDateTime < new Date()) {
    record.ScheduleDateTime = new Date().setDate(new Date().getDate());
    record.ScheduleDateTime = new Date(record.ScheduleDateTime).setHours(
      originalScheduleDateTime.getHours()
    );
    record.ScheduleDateTime = new Date(record.ScheduleDateTime).setMinutes(
      originalScheduleDateTime.getMinutes()
    );
    record.ScheduleDateTime = new Date(record.ScheduleDateTime).setSeconds(
      originalScheduleDateTime.getSeconds()
    );
  }

  if (record.ScheduleDateTime <= new Date()) {
    record.ScheduleDateTime = new Date(record.ScheduleDateTime).setHours(
      23,
      59
    );
  }

  var newId = {
    RunTemplateScheduleId: getRandomCloneId(record.RunTemplateScheduleId),
  };

  dataList = addElementAtIndex(
    dataList,
    indexToAdd,
    updateProperty(record, newId)
  );

  return dataList;
}

export function deleteRunScheduleAndGetUpdatedList(
  scheduleData: IRunTemplateSchedule[],
  runScheduleId: any
): IRunTemplateSchedule[] {
  var filteredData = scheduleData.filter((a: any) => {
    return a.RunTemplateScheduleId !== runScheduleId;
  });

  return filteredData;
}

export function getProcessInAllProcessList(data: IRunScheduleData, key: any) {
  return data.Processes.find((allprocess: any) => {
    return allprocess.ProcessId == key;
  });
}

export function getRunScheduleIndex(
  runScheduleList: IRunTemplateSchedule[],
  id: any
): number {
  return runScheduleList?.findIndex((a: IRunTemplateSchedule) => {
    return a.RunTemplateScheduleId == id;
  });
}

export function getNonSubProcessListInRunSchedule(
  runScheduleList: IRunTemplateSchedule[],
  index: any
) {
  return runScheduleList[index].Processes.filter(
    (a) => a.IsSubProcess == false
  );
}

export function getRunScheduleRecord(
  runScheduleList: IRunTemplateSchedule[],
  id: any
) {
  return runScheduleList.find((a: any) => a.RunTemplateScheduleId == id);
}

export function getRunScheduleProcessList(
  runScheduleList: IRunTemplateSchedule[],
  id: any
) {
  return getRunScheduleRecord(runScheduleList, id)?.Processes;
}

export function otherSubProcessGroupSelected(
  childProcesses: IRunScheduleProcess[],
  selectedProcess: IRunScheduleProcess | undefined
): boolean {
  var res = false;
  childProcesses.forEach((a) => {
    //alert("child DGN:" + a.DependencyGroupNumber + ". selected DGN:" + selectedProcess?.DependencyGroupNumber);
    if (
      a.DependencyGroupNumber !== undefined &&
      selectedProcess !== undefined &&
      selectedProcess?.DependencyGroupNumber != undefined &&
      a.DependencyGroupNumber != selectedProcess?.DependencyGroupNumber
    )
      res = true;
  });
  return res;
}

export function allSubProcessGroupsSelected(
  process: IRunScheduleProcess | undefined
): boolean {
  if (process?.ChildProcesses == undefined) return false;

  var check = -1;
  for (var i = 0; i < process?.ChildProcesses?.length; i++) {
    var groupNumber = process.ChildProcesses[i].DependencyGroupNumber;

    if (groupNumber != undefined) {
      if (check == -1) check = groupNumber;
      else if (check != groupNumber) return true;
    }
  }
  return false;
}

function createRunScheduleAtProcessLevel(
  data: any,
  item: any,
  id: any,
  time: any,
  processStartTime?: Date
) {
  var processObj = data.Processes.find((allprocess: any) => {
    return allprocess.ProcessId == item.key;
  });
  if (processStartTime !== undefined) {
    processObj.ProcessScheduleDateTime = processStartTime;
  }

  if (allSubProcessGroupsSelected(processObj) == true) {
    return undefined;
  }

  const runschedule: IRunTemplateSchedule = {
    RunTemplateScheduleId: id,
    ScheduleDateTime: time,
    Processes: [processObj],
  };

  return runschedule;
}

function createRunScheduleAtSubProcessLevel(
  data: any,
  item: any,
  id: any,
  time: any,
  processStartTime?: Date,
  parentProcessIndex?: number,
  isSubProcess?: boolean
) {
  // Refactor: adding a process at sub proces level selection
  let processObj = data.Processes[parentProcessIndex!];
  let subProcessObj = data.Processes[parentProcessIndex!].ChildProcesses!.find(
    (allprocess: any) => {
      return allprocess.ProcessId == item.key;
    }
  );
  if (processStartTime !== undefined) {
    subProcessObj.ProcessScheduleDateTime = processStartTime;
    // To override Parent Process Start Time
    if (processObj.ProcessScheduleDateTime === undefined) {
      processObj.ProcessScheduleDateTime = processStartTime;
    } else {
      if (processObj.ProcessScheduleDateTime > processStartTime) {
        processObj.ProcessScheduleDateTime = processStartTime;
      }
    }
  }
  processObj.ChildProcesses = [subProcessObj];

  const runschedule: IRunTemplateSchedule = {
    RunTemplateScheduleId: id,
    ScheduleDateTime: time,
    Processes: [processObj],
  };

  return runschedule;
}

export function createRunSchedule(
  data: any,
  item: any,
  id: any,
  time: any,
  processStartTime?: Date,
  parentProcessIndex?: number,
  isSubProcess?: boolean
) {
  // Process Level
  if (!isSubProcess) {
    return createRunScheduleAtProcessLevel(
      data,
      item,
      id,
      time,
      processStartTime
    );
  }
  // Sub Process Level
  else {
    return createRunScheduleAtSubProcessLevel(
      data,
      item,
      id,
      time,
      processStartTime,
      parentProcessIndex,
      isSubProcess
    );
  }
}

export function createRunScheduleWithEmptyProcess(id: any, time: any) {
  var runschedule: IRunTemplateSchedule = {
    RunTemplateScheduleId: id,
    ScheduleDateTime: time,
    Processes: [],
  };

  return runschedule;
}

//***** Column related helper methods */
export function getRecurrenceRuleColumn(): IRunScheduleColumn {
  const column: IRunScheduleColumn = {
    key: "recurrenceRule",
    name: "",
    className: classNames.fileIconCell,
    iconClassName: classNames.fileIconHeaderIcon,
    ariaLabel: "Configure recurrence rule for a process.",
    isIconOnly: true,
    minWidth: 24,
    maxWidth: 24,
    onRender: (item: IRunSchedule) => {
      return (
        <AccountContext.Consumer>
          {(data) => <RecurrenceRule item={item} account={data} />}
        </AccountContext.Consumer>
      );
    },
  };

  return column;
}

export function getProcessNameColumn(
  schedulingDates: IScheduledDates,
  runTemplateScheduleData: IRunScheduleData,
  updateDrillDownLevel: any
): IRunScheduleColumn {
  const column: IRunScheduleColumn = {
    key: "processName",
    name: "Name",
    fieldName: "name", // to be updated based on data contract
    minWidth: 200,
    maxWidth: 260,
    isResizable: true,
    runScheduleTime: "",
    columnActionsMode: ColumnActionsMode.hasDropdown,
    onRender: (item: IRunSchedule) => {
      return getProcessName(
        item,
        schedulingDates,
        runTemplateScheduleData,
        updateDrillDownLevel
      );
    },
  };

  return column;
}

export function getNoRunScheduleColumn(day: any): IRunScheduleColumn {
  const column = {
    key: "new" + day, // update once schedulingDates data contract updates.
    name: day,
    fieldName: "date",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    runScheduleId: 0,
    runScheduleTime: "",
  };
  return column;
}

export function getRunScheduleColumnForTheDay(
  date: Date,
  element: IRunScheduleInfoList
): IRunScheduleColumn {
  const column = {
    key: element.id.toString(),
    name: date.getDate().toString(),
    fieldName: "date",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    runScheduleId: element.id,
    runScheduleTime: element.timeIn24HourFormat.toString(),
    isCloned: element.id.toString().length > 10,
    tag: element.tag,
  };
  return column;
}

function getProcessDetails() {}

function getProcessName(
  item: IRunSchedule,
  schedulingDates: IScheduledDates,
  runTemplateScheduleData: IRunScheduleData,
  updateDrillDownLevel: any
) {
  const styles = getNameStyles(item.status);
  if (item.name.includes("Credit")) {
    if (item.hasSubProcesses) {
      return (
        <a
          className="run-drilldown-nav-bar"
          onClick={(event) => {
            TelemetryProvider._trackEvent(processNameClickEvent);
            updateDrillDownLevel(event, true, item.name, item.key);
          }}
          href={"#" + item.name}
          style={{ display: "flex" }}
        >
          <div> {item.name}</div>
          <div style={{ marginTop: -8 }}>
            <CreditingPreview
              type={CreditingEntry.Global}
              selectedRunTemplateName={runTemplateScheduleData.RunTemplateName}
              startDate={schedulingDates.startDate}
              endDate={schedulingDates.endDate}
              selectedyear={runTemplateScheduleData.FiscalYear}
            />
          </div>
        </a>
      );
    }
    // Process Level
    else {
      return (
        <span className={styles} style={{ display: "flex" }}>
          <div> {item.name}</div>
          <div style={{ marginTop: -8 }}>
            <CreditingPreview
              type={CreditingEntry.Global}
              selectedRunTemplateName={runTemplateScheduleData.RunTemplateName}
              startDate={schedulingDates.startDate}
              endDate={schedulingDates.endDate}
              selectedyear={runTemplateScheduleData.FiscalYear}
            />
          </div>
        </span>
      );
    }
  } else {
    // Sub Process Level
    if (item.hasSubProcesses) {
      return (
        <a
          className="run-drilldown-nav-bar"
          onClick={(event) => {
            TelemetryProvider._trackEvent(processNameClickEvent);
            updateDrillDownLevel(event, true, item.name, item.key);
          }}
          href={"#" + item.name}
        >
          {item.name}
        </a>
      );
    }
    // Process Level
    else {
      return <span className={styles}>{item.name}</span>;
    }
  }
}

// **************Create random ids for runschedule*******************************************

/// 10000's series
export function getRandomIdForFirstRunScheduleOnThatDay(day: any) {
  return 10000 + parseInt(day) + "_new";
}

export function getRandomCloneId(data: number) {
  return data + Math.floor(Date.now()) + "_clone";
}
