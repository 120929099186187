import React from "react";
import "./App.scss";
import { Route } from "react-router-dom";
import MintHubContainer from "./components/mint-hub-container/mint-hub-container";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import HorizontalNavBar from "./components/common/horizontal-nav-bar/horizontal-nav-bar.controller";

export let appInsights: any;

export const AppInterceptor: React.FunctionComponent<any> = (props) => {
  appInsights = useAppInsightsContext();

  return (
    <>
      <Route path="/" component={MintHubContainer}></Route>
    </>
  );
};

export default AppInterceptor;
