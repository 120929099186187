import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const processPageView: IPageViewTelemetry = {
  name: "Process",
  uri: "orchestration/process",
};

export const addNewProcessClickEvent: IEventTelemetry = {
  name: "Process_AddNewProcessClick",
};

export const viewProcessClickEvent: IEventTelemetry = {
  name: "Process_ViewProcessClick",
};

export const editProcessClickEvent: IEventTelemetry = {
  name: "Process_EditProcessClick",
};

export const deleteProcessClickEvent: IEventTelemetry = {
  name: "Process_DeleteProcessClick",
};
