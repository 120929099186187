import * as types from "../types/source-onboarding-acion-types";
import { SourceOnboardingActionTypes } from "../types/source-onboarding-acion-types";

const sourceOnboarding1ReducerDefaultState1: any = {};

const sourceOnboardingReducerCopy = (
    state = sourceOnboarding1ReducerDefaultState1,
    action: SourceOnboardingActionTypes
): any => {
    switch (action.type) {
        case types.GET_ALL_CONNETIONS1:
            return Object.assign({}, state, {
                totalConnections: action.totalConnections,
            });
        case types.DELETE_CONNECTION:
            console.log("=======" + state.totalConnections);
            return Object.assign({}, state, {
                totalConnections: action.data,
            });
        default:
            return state;
    }
};

export default sourceOnboardingReducerCopy;