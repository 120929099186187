import { IComboBoxOption } from "@fluentui/react";

export const FETCH_ALL_CONNECTION_SUCCESS = "FETCH_ALL_CONNECTION_SUCCESS";

export interface GetAllConnections {
  type: typeof FETCH_ALL_CONNECTION_SUCCESS;
  connectionList: IComboBoxOption[];
}

export type ConnectionActionTypes = GetAllConnections;
