import * as types from "../types/run-schedule-action-types";
import { RunScheduleActionTypes } from "../types/run-schedule-action-types";

const runScheduleReducerDefaultState: any = {}; // Update any to a type if possible

const runScheduleReducer = (
  state = runScheduleReducerDefaultState,
  action: RunScheduleActionTypes
): any => {
  switch (action.type) {
    case types.GET_RUN_SCHEDULE_FILTERS:
      return Object.assign({}, state, {
        filters: action.filters,
      });

    case types.GET_RUN_TEMPLATE_SCHEDULE_DATA:
      return { ...action.runTemplateScheduleData };

    case types.UPDATE_RUN_TEMPLATE_SCHEDULE_COLUMN_DATA:
      var data = Object.assign({}, state, action.runTemplateScheduleColumnData);
      // Set data as dirty.
      data.isDirty = true;
      return data;

    case types.IS_RUN_SCHEDULE_GRID_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading,
      });

    case types.UPDATE_COMPLETE_DATA:
      return { ...action.data };

    case types.IS_RUN_TEMPLATE_SCHEDULE_SAVING:
      return Object.assign({}, state, {
        isSaving: action.saving,
      });

    default:
      return state;
  }
};

export default runScheduleReducer;
