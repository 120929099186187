import { getDeepCopy } from "../../../../util/javascript-functions";
import { ILinkProps, INodeProps } from "./models/IProcessDependencyNode";

export function getNode(nodeProps: INodeProps, elements: any) {
  let node = {
    id: nodeProps.nodeId.toString(),
    type: nodeProps.nodeType,
    data: nodeProps.data,
    position: {},
    sourcePosition: null,
    targetPosition: null
    //position: { x: nodeProps.xCoordinate, y: nodeProps.yCoordinate},
  };

  if(elements != null)
  {
      var element = elements.find((n: { id: string; }) => n.id == node.id);
      if(element != null && element.position != null)
      {
          node.position = {x: element.position.x, y: element.position.y};
          node.sourcePosition = element.sourcePosition;
          node.targetPosition = element.targetPosition;
      }
      else
      {
        node.position = { x: nodeProps.xCoordinate, y: nodeProps.yCoordinate, sourcePosition: "right", targetPosition: "left"};
      }
  }
  else
  {
    node.position = { x: nodeProps.xCoordinate, y: nodeProps.yCoordinate};
  }
  

  return node;
}

export function getLink(linkProps: ILinkProps) {
  let link = {
    id:
      "link" +
      linkProps.sourceNodeId +
      "-" +
      linkProps.targetNodeId +
      "-" +
      new Date().getMilliseconds().toString(),
    source: linkProps.sourceNodeId.toString(),
    target: linkProps.targetNodeId.toString(),
    data: linkProps.data,
    animated: linkProps.data?.canBeExcluded,
    style: { strokeWidth: 1.5, innerHeight: 80, outerHeight: 80, innerWidth: 120, outerWidth: 120 },
    arrowHeadType: "arrowclosed",
    type: 'smoothstep',
    label: linkProps.label,
    labelStyle: { fill: '#A9A9A9', fontWeight: 500 }
  };
  return link;
}
