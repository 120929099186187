import React, {
  Fragment,
  useState,
  HtmlHTMLAttributes,
  ReactHTMLElement,
  useEffect,
} from "react";
import "../preview-modal/preview/preview.scss";
import {
  TextField,
  IDropdownOption,
  Label,
  mergeStyles,
  Stack,
} from "@fluentui/react";
import Dropdown from "../../common/dropdown/dropdown.controller";
import { DateInput } from "../../common/date-picker/Date";
import { ModalView } from "../../common/modal/modal.view";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import {
  ICreditingPreviewProps,
  CreditingPreviewLinkStateProps,
  CreditingPreviewLinkDispatchProps,
} from "../crediting-preview/models/ICreditingPreview";
import { bindActionCreators } from "redux";
import { updateRunTemplateScheduleColumnData } from "../../../redux/actions/run-schedule-actions";
import { AppState } from "../../../redux/configureStore";
import {
  IRunSchedule,
  IScheduledDates,
  IRunScheduleData,
  IRunTemplateSchedule,
} from "../../../models/run-schedule";
import { getDeepCopy } from "../../../util/javascript-functions";
//import { PreviewGrid } from "../preview-grid/preview-grid";
import CreditingPreviewGrid from "../crediting-preview/previewgrid";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import { CreditingEntry } from "../enum/creditingEntryPoint";
import "./previewgrid.scss";
import { formatDate, formatTime } from "../../../util/time-zone-util";
import { stackTokens } from "../run-schedule.helper";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { configurationButtonClickEvent } from "../run-schedule.telemtetry-constants";

type PreviewProps = {
  startDate: Date;
  endDate: Date;
  selectedyear: number;
  selectedRunTemplateName: string;
  type?: CreditingEntry;
  time?: any;
  runScheduleRecord?: any;
};

type Props = ICreditingPreviewProps &
  CreditingPreviewLinkDispatchProps &
  CreditingPreviewLinkStateProps &
  PreviewProps;

const CreditingPreview = (props: Props) => {
  // var runScheduleRecord : any;
  const [selecteddata, OnRunScheduleRecordChange] = useState({
    RunTemplateScheduleId: 0,
    ScheduleDateTime: new Date(),
    Processes: [
      {
        IsSubProcess: true,
        ProcessId: 0,
        ProcessName: "",
      },
    ],
  });
  const [clicked, ViewButton] = useState(false);
  const [ShowView, ShowViewButton] = useState(true);
  const [selectdate, DateSelected] = useState(new Date());
  var previewControl = () => {
    return (
      <IconButton
        iconName={"Settings"}
        fontSize={12}
        title={"Preview"}
        onClick={() => {
          TelemetryProvider._trackEvent(configurationButtonClickEvent);
          {
            props.type == CreditingEntry.Global && ViewButton(false);
          }
          onDateSelectionChange([]);
          ShowViewButton(true);
        }}
      />
    );
  };

  var previewmodal = {
    title: "Crediting Config Preview",
    control: previewControl(),
  };
  var mapDateToRunScheduleList = new Map<string, IRunTemplateSchedule[]>();
  props.runTemplateScheduleData.RunTemplateSchedule.forEach(
    (schedule: IRunTemplateSchedule) => {
      var RunTemplateDate = formatDate(schedule.ScheduleDateTime);
      if (mapDateToRunScheduleList.get(RunTemplateDate) == null) {
        var item: IRunTemplateSchedule[] = [];
        item.push(schedule);
        mapDateToRunScheduleList.set(RunTemplateDate, item);
      } else {
        var existingRunTemplateScheduleList = mapDateToRunScheduleList.get(
          RunTemplateDate
        );
        existingRunTemplateScheduleList?.push(schedule);
      }
    }
  );

  const onDateSelection = (date: any | Date | null | undefined) => {
    DateSelected(date);

    var runSchedule = mapDateToRunScheduleList.get(formatDate(date));
    var runobjects: any[] = [];
    runSchedule?.forEach((rSchedule) => {
      var time = formatTime(rSchedule.ScheduleDateTime);
      runobjects.push({
        key: rSchedule.RunTemplateScheduleId,
        text: time,
      });
    });
    onDateSelectionChange(runobjects);
    ViewButton(false);
    ShowViewButton(true);
  };

  const [Timeoptions, onDateSelectionChange] = useState([
    { key: "Default", text: "" },
  ]);

  var params = {
    label: "Select date",
    placeholder: "Select a date...",
    ariaLabel: "Select a date",
    StartDate: props.startDate,
    EndDate: props.endDate,
    year: props.selectedyear,
    value: selectdate,
    onSelectDate: onDateSelection,
  };

  /**** For Local : near checkbox */
  var paramsForLocal = {
    label: "Select date",
    placeholder: "Select a date...",
    ariaLabel: "Select a date",
    StartDate: props.startDate,
    EndDate: props.endDate,
    year: props.selectedyear,
    value: props.startDate,
    showInitialDate: true,
    onSelectDate: onDateSelection,
  };

  return (
    <div className="preview-modal">
      <ModalView {...previewmodal}>
        
        <div className="crediting-preview">
        <div className="validation-warning-banner">
              <span>WARNING: Only upload a document with 300 or less rows of data. Larger datasets may cause train failures.</span>
            </div>
          <Stack
            className="preview-filters"
            horizontal
            tokens={stackTokens}
            horizontalAlign="space-between"
          >
            <div className="preview-control-date">
              {props.type == CreditingEntry.Global && <DateInput {...params} />}
              {props.type == CreditingEntry.Local && (
                <DateInput {...paramsForLocal} />
              )}
            </div>
            <div className="preview-control-time">
              {props.type == CreditingEntry.Global && (
                <Dropdown
                  placeholder="Select a time"
                  label="StartTime"
                  onChange={(
                    ev: React.FormEvent<HTMLDivElement>,
                    option?: IDropdownOption
                  ): void => {
                    var Schedule: any[] =
                      props.runTemplateScheduleData.RunTemplateSchedule;
                    var runScheduleRecord = Schedule.find(
                      (a: any) => a.RunTemplateScheduleId == option?.key
                    );
                    OnRunScheduleRecordChange(runScheduleRecord);
                    ViewButton(false);
                    ShowViewButton(false);
                  }}
                  defaultSelectedKey=""
                  disabled={false}
                  options={Timeoptions}
                />
              )}
              {props.type == CreditingEntry.Local && (
                <Dropdown
                  placeholder={props.time}
                  label="StartTime"
                  onChange={() => {}}
                  defaultSelectedKey=""
                  disabled={true}
                  options={Timeoptions}
                />
              )}
            </div>
            {props.type == CreditingEntry.Global && (
              <PrimaryButtonView
                text="View"
                onClick={() => {
                  ViewButton(true);
                }}
                disabled={Timeoptions.length == 0 || ShowView}
                className={mergeStyles({
                  alignSelf: "safe flex-end",
                  marginRight: 10,
                  marginBottom: 15,
                })}
              />
            )}
          </Stack>

          <div className="preview-grid">
            {props.type == CreditingEntry.Global && clicked ? (
              <CreditingPreviewGrid runScheduledata={selecteddata} />
            ) : null}
            {props.type == CreditingEntry.Local && (
              <CreditingPreviewGrid runScheduledata={props.runScheduleRecord} />
            )}
          </div>
        </div>
      </ModalView>
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: ICreditingPreviewProps
): CreditingPreviewLinkStateProps => {
  return {
    runTemplateScheduleData: state.runSchedule,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: ICreditingPreviewProps
): CreditingPreviewLinkDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreditingPreview);
