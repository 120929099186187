import { IRunScheduleStatus, ProcessDetails } from "../../models/run-details";
import { utcToBrowserTimeZone } from "../../util/time-zone-util";
import { formatDateTimeWithoutTimezone } from "../../util/time-zone-util";


export const updateRunDetailsDataTimeZineToLocal = (
  stateData: IRunScheduleStatus
) => {
  stateData.ScheduleDateTime = utcToBrowserTimeZone(stateData.ScheduleDateTime);
  stateData.StartTime =
    stateData.StartTime !== undefined
      ? utcToBrowserTimeZone(stateData.StartTime)
      : undefined;
  stateData.EndTime =
    stateData.EndTime !== undefined
      ? utcToBrowserTimeZone(stateData.EndTime)
      : undefined;
  stateData.EstimatedEndTime =
    stateData.EstimatedEndTime !== undefined
      ? utcToBrowserTimeZone(stateData.EstimatedEndTime)
      : undefined;

  stateData.ProcessGroups.forEach((group) => {
    group.StartTime =
      group.StartTime !== undefined
        ? utcToBrowserTimeZone(group.StartTime)
        : undefined;
    group.EndTime =
      group.EndTime !== undefined
        ? utcToBrowserTimeZone(group.EndTime)
        : undefined;
  });

  updateProcessTimeZone(stateData.Processes);

  stateData.Processes.forEach((proc) => {
    if (proc.ChildProcesses !== undefined) {
      updateProcessTimeZone(proc.ChildProcesses);
    }
  });
  return stateData;
};

export const getExecutionStatus = (params: any) => {
  if (params.data.ExecutionStatus === "-") {
    return <div>{params.data.ExecutionStatus}</div>;
  } else {
    return (
      <div
        className="status"
        style={{
          backgroundColor: getColorByStatus(params.data.ExecutionStatus),
          color: "white",
          textAlign: "center",
          borderRadius: 10,
          paddingLeft: 10,
          paddingBottom: 2,
          paddingRight: 10,
        }}
      >
        {params.data.ExecutionStatus}
      </div>
    );
  }
};

export const getExecutionStatusValue = (params: any) => {
    return params.data.ExecutionStatus
};

export const getFormattedDate = (input: any) => {
  if(input.value == null)
  {
    return "-";
  }
  return formatDateTimeWithoutTimezone(input.value)
};

export const getDate = (input: any) => {
  var data = Object(input.data)[input.colDef.field];
  return data == "-" ? null : data;
};

const updateProcessTimeZone = (Processes: ProcessDetails[]) => {
  Processes.forEach((proc) => {
    proc.StartTime =
      proc.StartTime !== undefined
        ? utcToBrowserTimeZone(proc.StartTime)
        : undefined;
    proc.EndTime =
      proc.EndTime !== undefined
        ? utcToBrowserTimeZone(proc.EndTime)
        : undefined;
    proc.EstimatedEndTime =
      proc.EstimatedEndTime !== undefined
        ? utcToBrowserTimeZone(proc.EstimatedEndTime)
        : undefined;
    proc.ProcessScheduleDateTime =
      proc.ProcessScheduleDateTime !== undefined
        ? utcToBrowserTimeZone(proc.ProcessScheduleDateTime)
        : undefined;
  });
};

export const getColorByStatus = (status?: string) => {
  switch (status) {
    case "Completed":
      return "#42860B";
    case "Running":
      return "#006CFF";
    case "Failed":
      return "#E42C06";
    case "In Progress":
      return "#006CFF";
    case "Start":
      return "#006CFF";
    case "Scheduled":
      return "#7A7575";
    case "New":
      return "#7A7575";
    default:
      return "#7A7575";
  }
};
