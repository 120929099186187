import { Middleware, AnyAction } from "redux";
import { AppState } from "./configureStore";

/**
 * A StorageConverter is responsible for getting the value to be stored in local storage
 * from the provided state when an action of the specified type is received by redux. It also
 * is used to rehydrate the state with the value that was retrieved from local storage on app load.
 */
export class StorageConverter {
  constructor(
    public actionType: string,
    public valueSelector: (state: AppState) => any,
    public rehydrator: (state: AppState, value: any) => AppState
  ) { }
}

// If a value can be stored and retrieved from localStorage, then it's enabled.
const isLocalStorageEnabled = (): boolean => {
  const test = "_test_storage_works_";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch {
    return false;
  }
}

/**
 * Creates a Redux Middleware will update local storage when actions are received, based on the provided Converters.
 * @param converters The converters that specify what actions that will trigger a local storage update and provide the values to be stored.
 * @returns A redux middleware that will update local storage when actions are received, based on the provided Converters.
 */
export const createLocalStorageMiddleware = (converters: StorageConverter[]): Middleware => {
  let map: { [key: string]: StorageConverter } = {};

  if (!isLocalStorageEnabled()) {
    console.warn("LocalStorage is not enabled. Unable to persist values.");
  } else {
    // Create a map/dictionary of actionType to converter.
    map = converters.reduce((m, converter) => {
      if (m[converter.actionType]) {
        console.warn(`StorageConverter with duplicate actionType (${converter.actionType}) detected. LocalStorage value overwriting will occur.`);
      }
      m[converter.actionType] = converter;
      return m;
    }, map)
  }

  return store => next => {
    return (action: AnyAction) => {
      const result = next(action);

      // If we have a converter for this action type, call the converter's valueSelector and store the value as JSON.
      if (map[action.type]) {
        const state = store.getState();
        window.localStorage.setItem(action.type, JSON.stringify(map[action.type].valueSelector(state)));
      }

      return result;
    };
  };
}

/**
 * Iterates through the converters, checking for local storage entries that match the converters' actionType,
 * passing the found values and the state to the converters to update (rehydrate) the state.
 * @param converters The converters that will be used to rehydrate the state with values from local storage.
 * @param state The initial state to be rehydrated.
 * @returns The rehydrated state.
 */
export const rehydrateFromLocalStorage = (converters: StorageConverter[], state: AppState): AppState => {
  if (!isLocalStorageEnabled()) {
    console.warn("LocalStorage is not enabled. Unable to rehydrate state.");
    return state;
  }
  for (const converter of converters) {
    // If localStorage doesn't have a value at all for the actionType, don't call the rehydrator.
    if (localStorage.getItem(converter.actionType) !== null) {
      // Otherwise, get the JSON value from localStorage, parse it, and call the converter's rehydrator.
      state = converter.rehydrator(state, JSON.parse(localStorage.getItem(converter.actionType) as string));
    }
  }

  return state;
}
