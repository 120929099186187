import * as types from "../types/activity-action-types";
import { ActivityActionTypes } from "../types/activity-action-types";

const activityDefaultState: any = {}; // Update any to a type if possible

const activityReducer = (
  state = activityDefaultState,
  action: ActivityActionTypes
): any => {
  switch (action.type) {
    case types.GET_ACTIVITY_LIST:
      return Object.assign({}, state, {
        activityList: action.activityList,
      });
    case types.GET_ACTIVITY_DETAILS:
      return Object.assign({}, state, {
        activityDetails: action.activityDetails,
      });
    case types.GET_ACTIVITY_METADATA:
      return Object.assign({}, state, {
        activityMetadata: action.activityMetadata,
      });
    case types.GET_ACTIVITY_TYPE_LIST:
      return Object.assign({}, state, {
        activityTypeList: action.activityTypeList,
      });
    case types.GET_ACTIVITY_PARAMETER_LIST:
      return Object.assign({}, state, {
        activityParameterList: action.activityParameterList,
      });
    // case types.DELETE_ACTIVITY:
    //   return { ...action.activityId };
    default:
      return state;
  }
};

export default activityReducer;
