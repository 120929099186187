import * as types from "../actions/actionTypes";
import { AuthenticationActions, AuthenticationState } from "react-aad-msal";
import { AppState } from "../configureStore";

const runScheduleReducerDefaultState = {
  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  state: AuthenticationState.Unauthenticated,
};

const authenticationReducer = (
  state = runScheduleReducerDefaultState,
  action: { type: any; payload: { account: any } }
  //   action: any
) => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      const InitializingObject = { initializing: true, initialized: false };
      return Object.assign({}, state, {
        authentication: InitializingObject,
      });
    case AuthenticationActions.Initialized:
      const InitializedObject = { initializing: false, initialized: true };
      return Object.assign({}, state, {
        authentication: InitializedObject,
      });
    case AuthenticationActions.AcquiredIdTokenSuccess:
      const AcquiredIdTokenSuccessObject = { idToken: action.payload };
      return Object.assign({}, state, {
        authentication: AcquiredIdTokenSuccessObject,
      });
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      const AcquiredAccessTokenSuccessObject = { accessToken: action.payload };
      return Object.assign({}, state, {
        authentication: AcquiredAccessTokenSuccessObject,
      });
    case AuthenticationActions.AcquiredAccessTokenError:
      const AcquiredAccessTokenErrorObject = { accessToken: null };
      return Object.assign({}, state, {
        authentication: AcquiredAccessTokenErrorObject,
      });
    case AuthenticationActions.LoginSuccess:
      const LoginSuccessObject = { account: action.payload.account };
      return Object.assign({}, state, {
        authentication: LoginSuccessObject,
      });
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      const LogoutSuccessObject = {
        idToken: null,
        accessToken: null,
        account: null,
      };
      return Object.assign({}, state, {
        authentication: LogoutSuccessObject,
      });
    case AuthenticationActions.AuthenticatedStateChanged:
      const AuthenticatedStateChangedObject = { state: action.payload };
      return Object.assign({}, state, {
        authentication: AuthenticatedStateChangedObject,
      });
    default:
      return state;
  }
};

export default authenticationReducer;
