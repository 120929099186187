import * as React from "react";
import { IButtonPropsCustom } from "../models/IButton";
import PrimaryButtonView from "./primary-button.view";

const PrimaryButtonController = (Props: IButtonPropsCustom) => {
  return (
    <PrimaryButtonView
      text={Props.text}
      ariaLabel={Props.ariaLabel}
      onClick={Props.onClick}
      disabled={Props.disabled}
      className={Props.className}
    />
  );
};

const PrimaryButton = (Props: IButtonPropsCustom) => {
  return PrimaryButtonController(Props);
};

export default PrimaryButton;
