import * as React from "react";
import { IAgGridPropsCustom } from "./models/IAgGrid";
import AgGridView from "./ag-grid.view";

const AgGridController = (Props: IAgGridPropsCustom) => {
  return (
    <AgGridView
      columnDefs={Props.columnDefs}
      rowData={Props.rowData}
      paginationPageSize={Props.paginationPageSize}
      enableRangeSelection={Props.enableRangeSelection}
      pagination={Props.pagination}
      rowHeight={Props.rowHeight}
      onCellKeyDown={Props.onCellKeyDown}
    />
  );
};

const AgGrid = (Props: IAgGridPropsCustom) => {
  return AgGridController(Props);
};
export default AgGrid;
