import React from "react";
import {
  setStartTimeForDate,
  extractTimeInZuluFormat,
} from "../../../util/javascript-functions";
import { IWeekly } from "../../common/recurrence-pattern/patterns/weekly/IWeekly";
import { IMonthly } from "../../common/recurrence-pattern/patterns/monthly/IMonthly";
import { IYearly } from "../../common/recurrence-pattern/patterns/yearly/IYearly";
import { IRunSchedule, IRunScheduleData } from "../../../models/run-schedule";
import { IComboBoxOption } from "@fluentui/react";
import { IRecurrenceRule } from "../../../models/recurrence-rule";
import { RecurrenceRuleParameter } from "./recurrence-rule-parameters";

export function getInitialRecurrenceRuleObject(): IRecurrenceRule {
  return {
    RecurrenceRuleId: 0,
    RecurrenceRuleName: "",
    RecurrenceRuleDescription: "Description...",
    RecurrenceRuleStartDate: extractTimeInZuluFormat(new Date(), "00:00"),
    RecurrenceRuleEndDate: extractTimeInZuluFormat(new Date(), "00:00"),
    IsAsPerRun: true,
    IsInternalRule: true,
    ProcessStartTime: "",
    RecurrenceRuleType: {
      RecurrenceRuleTypeId: 0,
      RecurrenceRuleTypeName: "",
    },
    RecurrenceRuleParameters: [
      {
        RecurrenceRuleTypeParameterId: 2,
        RecurrenceRuleTypeParameterValue: "1",
        RecurrenceRuleTypeParameterName: "Frequency",
      },
    ],
  };
}

export function weekDayFromStringToNumberArray(data: string) {
  var days = data.split(",");
  var daysInNumber: number[] = [];
  days.forEach((a) => {
    switch (a) {
      case "S":
        checkIfDayExist(daysInNumber, 0);
        break;
      case "M":
        checkIfDayExist(daysInNumber, 1);
        break;
      case "T":
        checkIfDayExist(daysInNumber, 2);
        break;
      case "W":
        checkIfDayExist(daysInNumber, 3);
        break;
      case "TH":
        checkIfDayExist(daysInNumber, 4);
        break;
      case "F":
        checkIfDayExist(daysInNumber, 5);
        break;
      case "ST":
        checkIfDayExist(daysInNumber, 6);
        break;
    }
  });
  return daysInNumber;
}

function checkIfDayExist(arr: number[], day: number) {
  var t = arr.findIndex((a) => a == day) == -1 ? arr.push(day) : arr;
  return arr;
}

export function getMonthInNumber(month: string): number {
  var num = -1;
  switch (month) {
    case "January":
      num = 0;
      break;
    case "February":
      num = 1;
      break;
    case "March":
      num = 2;
      break;
    case "April":
      num = 3;
      break;
    case "May":
      num = 4;
      break;
    case "June":
      num = 5;
      break;
    case "July":
      num = 6;
      break;
    case "August":
      num = 7;
      break;
    case "September":
      num = 8;
      break;
    case "October":
      num = 9;
      break;
    case "November":
      num = 10;
      break;
    case "December":
      num = 11;
      break;
  }
  return num;
}

export function extractDatesFromString(datesString: string): Date[] {
  // base case
  if (datesString == "") {
    return [];
  }
  var datesArr = datesString.split(",");
  var dates: Date[] = [];

  datesArr.forEach((a) => {
    dates.push(setStartTimeForDate(new Date(a.split("T")[0])));
  });
  return dates;
}

function getPatternId(selectedRecurrenceOptionKey: string) {
  switch (selectedRecurrenceOptionKey) {
    case "Custom":
      return 1;
    case "Weekly":
      return 2;
    case "Monthly":
      return 3;
    case "Yearly":
      return 4;
  }
  return 0;
}

function getDaysInInitials(days: number[]) {
  var daysInString: string[] = [];
  days.forEach((element) => {
    switch (element) {
      case 0:
        daysInString.push("S");
        break;
      case 1:
        daysInString.push("M");
        break;
      case 2:
        daysInString.push("T");
        break;
      case 3:
        daysInString.push("W");
        break;
      case 4:
        daysInString.push("TH");
        break;
      case 5:
        daysInString.push("F");
        break;
      case 6:
        daysInString.push("ST");
        break;
    }
  });
  return daysInString.toString();
}

function convertDatesArrayIntoString(dates: Date[]) {
  var datesInString: string[] = [];
  dates.forEach((a) => {
    datesInString.push(extractTimeInZuluFormat(a, "00:00"));
  });
  return datesInString.toString();
}

function getRecurrenceRuleParameter(
  jsonObj: any,
  selectedRecurrenceOptionKey: string,
  selectedDailyDates: Date[],
  selectedWeekDays: IWeekly,
  selectedMonthDatesConfig: IMonthly,
  selectedYearDatesConfig: IYearly
) {
  var parameter = RecurrenceRuleParameter.find((a) => {
    return a.type == selectedRecurrenceOptionKey;
  });
  switch (selectedRecurrenceOptionKey) {
    case "Custom":
      jsonObj.RecurrenceRuleParameters = [
        {
          RecurrenceRuleTypeParameterId: parameter?.dates?.id,
          RecurrenceRuleTypeParameterValue: convertDatesArrayIntoString(
            selectedDailyDates
          ),
          RecurrenceRuleTypeParameterName: parameter?.dates?.name,
        },
      ];
      break;
    case "Weekly":
      jsonObj.RecurrenceRuleParameters = [
        {
          RecurrenceRuleTypeParameterId: parameter?.days?.id,
          RecurrenceRuleTypeParameterValue: getDaysInInitials(
            selectedWeekDays.days
          ),
          RecurrenceRuleTypeParameterName: parameter?.days?.name,
        },
        {
          RecurrenceRuleTypeParameterId: parameter?.frequency?.id,
          RecurrenceRuleTypeParameterValue: selectedWeekDays.frequency.toString(),
          RecurrenceRuleTypeParameterName: parameter?.frequency?.name,
        },
      ];
      break;
    case "Monthly":
      jsonObj.RecurrenceRuleParameters = [
        {
          RecurrenceRuleTypeParameterId: parameter?.dates?.id,
          RecurrenceRuleTypeParameterValue: selectedMonthDatesConfig.dates,
          RecurrenceRuleTypeParameterName: parameter?.dates?.name,
        },
        {
          RecurrenceRuleTypeParameterId: parameter?.frequency?.id,
          RecurrenceRuleTypeParameterValue: selectedMonthDatesConfig.frequency,
          RecurrenceRuleTypeParameterName: parameter?.frequency?.name,
        },
      ];
      break;
    case "Yearly":
      jsonObj.RecurrenceRuleParameters = [
        {
          RecurrenceRuleTypeParameterId: parameter?.dates?.id,
          RecurrenceRuleTypeParameterValue: selectedYearDatesConfig.dates,
          RecurrenceRuleTypeParameterName: parameter?.dates?.name,
        },
        {
          RecurrenceRuleTypeParameterId: parameter?.frequency?.id,
          RecurrenceRuleTypeParameterValue: selectedYearDatesConfig.frequency,
          RecurrenceRuleTypeParameterName: parameter?.frequency?.name,
        },
        {
          RecurrenceRuleTypeParameterId: parameter?.month?.id,
          RecurrenceRuleTypeParameterValue: selectedYearDatesConfig.month.toString(),
          RecurrenceRuleTypeParameterName: parameter?.month?.name,
        },
      ];
      break;
  }
  return jsonObj;
}

export function convertToJSON(
  isInternalRule: boolean,
  item: IRunSchedule | undefined,
  runTemplateScheduleData: IRunScheduleData,
  selectedRuleName: IComboBoxOption,
  startTimeOfProcess: string,
  ruleScheduledAsPerRun: boolean,
  ruleStartDate: Date,
  ruleEndDate: Date,
  selectedRecurrenceOptionKey: string,
  selectedDailyDates: Date[],
  selectedWeekDays: IWeekly,
  selectedMonthDatesConfig: IMonthly,
  selectedYearDatesConfig: IYearly,
  user: any
): IRecurrenceRule {
  var jsonObj: any = {};
  jsonObj.RecurrenceRuleId = parseInt(selectedRuleName.key.toString());
  jsonObj.RecurrenceRuleName = selectedRuleName.text;
  jsonObj.RecurrenceRuleDescription = "";
  jsonObj.IsInternalRule = isInternalRule;
  jsonObj.IsAsPerRun = true;     // This is a safeguard since the field was removed from UI
  jsonObj.ProcessStartTime = ""; // This is a safeguard since the field was removed from UI
  jsonObj.CreatedBy = user;
  jsonObj.RecurrenceRuleStartDate = extractTimeInZuluFormat(
    ruleStartDate,
    "00:00"
  );
  jsonObj.RecurrenceRuleEndDate = extractTimeInZuluFormat(ruleEndDate, "00:00");
  jsonObj.ProcessDetails = [
    {
      ProcessId: item?.key,
      RunTemplateProcessId: item?.runTemplateProcessId,
    },
  ];
  jsonObj.RecurrenceRuleType = {
    RecurrenceRuleTypeId: getPatternId(selectedRecurrenceOptionKey),
    RecurrenceRuleTypeName: selectedRecurrenceOptionKey,
  };

  return getRecurrenceRuleParameter(
    jsonObj,
    selectedRecurrenceOptionKey,
    selectedDailyDates,
    selectedWeekDays,
    selectedMonthDatesConfig,
    selectedYearDatesConfig
  );
}
