import { Configurations } from "../../../../App.global";
import { ISideNavBar } from "../model/ISideNavBar";

const nodeList: ISideNavBar[] = [
  {
    iconName: "Processing",
    iconDescription: "Mint System Config Hub",
    navItem: {
      name: "Mint System Config Hub",
      url: Configurations.ingestionUrl,
      children: [],
      target: "_blank"
        // {
        //   name: "Entity Onboarding",
        //   url: "/system/entity-onboarding",
        //   children: [],
        // },
        // {
        //   name: "Domain Definition",
        //   url: "/system/domain-definition",
        //   children: [],
        // },
        // {
        //   name: "Entity Relationship",
        //   url: "/system/entity-relationship",
        //   children: [],
        // },
        // {
        //   name: "Perspective Definition",
        //   url: "/system/perspective-definition",
        //   children: [],
        // },
        // {
        //   name: "Perspective Validation",
        //   url: "/system/perspective-validation",
        //   children: [],
        // },
    },
  },
  // {
  //   iconName: "RedEye",
  //   iconDescription: "Validation",
  //   navItem: {
  //     name: "Validation",
  //     url: "/validation/control-setup",
  //     children: [
  //       {
  //         name: "Control Setup",
  //         url: "/validation/control-setup",
  //         children: [],
  //       },
  //       {
  //         name: "Validate Entity",
  //         url: "/validation/validate-entity",
  //         children: [],
  //       },
  //     ],
  //   },
  // },
  {
    iconName: "Train",
    iconDescription: "Orchestration",
    navItem: {
      name: "Orchestration",
      url: "/orchestration/run-dashboard",
      target: "",
      children: [
        {
          name: "Run Dashboard",
          url: "/orchestration/run-dashboard",
          children: [],
          target: "",
        },
        {
          name: "Run Schedule",
          url: "/orchestration/run-schedule",
          children: [],
          target: "",
        },
        {
          name: "Activity",
          url: "/orchestration/activity",
          children: [],
          target: "",
        },
        {
          name: "Process",
          url: "/orchestration/process",
          children: [],
          target: "",
        },
        {
          name: "Run Template",
          url: "/orchestration/run-template",
          children: [],
          target: "",
        },
        {
          name: "Connections",
          url: "/system/source-onboarding",
          children: [],
          target: "",
        }
      ],
    },
  },
];

export default nodeList;
