import React from "react";
import { FontIcon, Label, List, DefaultButton } from "@fluentui/react";
import { DateInput } from "../../../date-picker/Date";
import PrimaryButtonView from "../../../button/primary-button/primary-button.view";
import {
  getFormattedDateInString,
  setStartTimeForDate,
} from "../../../../../util/javascript-functions";
import { formatDate } from "../../../../../util/time-zone-util";

type DailyProps = {
  ruleStartDate: Date;
  ruleEndDate: Date;
  selectedDailyDates: Date[];
  dailyDateSelection: any;
};

const DailyView: React.FunctionComponent<DailyProps> = (props) => {
  const {
    ruleStartDate,
    ruleEndDate,
    selectedDailyDates,
    dailyDateSelection,
  } = props;

  const checkIfDateNotPresentAndInRange = (date: Date) => {
    return (
      selectedDailyDates.findIndex((a) => a.toString() == date.toString()) ==
        -1 &&
      date >= ruleStartDate &&
      date <= ruleEndDate
    );
  };
  const onDateSelection = (date: any | Date | null | undefined) => {
    date = setStartTimeForDate(date);
    if (checkIfDateNotPresentAndInRange(date)) {
      var list: any[] = selectedDailyDates.slice();
      let newDate = setStartTimeForDate(date);
      console.log("new date", newDate);
      list.push(newDate);
      //list.sort();
      dailyDateSelection(sortDatesInAsc(list));
    }
  };

  const onRenderCell = (item: Date) => {
    return (
      <div className="flex-box" key={item.toString()}>
        <span style={{ width: 120 }}>
          {formatDate(new Date(item.toString()))}
        </span>

        <div>
          <button
            tabIndex={-1}
            title="clear"
            className="add-delete-button"
            onClick={() => {
              dailyDateSelection(
                selectedDailyDates.filter(
                  (a) => a.toString() != item.toString()
                )
              );
            }}
          >
            <FontIcon iconName="Clear"></FontIcon>
          </button>
        </div>
      </div>
    );
  };

  const onSelectAllClick = () => {
    let date = setStartTimeForDate(new Date(ruleStartDate));
    let list = selectedDailyDates.slice();

    while (date >= ruleStartDate && ruleEndDate >= date) {
      if (
        selectedDailyDates.findIndex((a) => a.toString() == date.toString()) ==
        -1
      ) {
        let newDate = setStartTimeForDate(date);
        list.push(newDate);
      }

      let newDate = date.getDate() + 1;
      date.setDate(newDate);
      date = setStartTimeForDate(date);
    }

    dailyDateSelection(sortDatesInAsc(list));
  };

  const sortDatesInAsc = (list: Date[]): Date[] => {
    return sortYear(sortMonth(sortDates(list)));
  };

  const sortDates = (arr: Date[]): Date[] => {
    return arr.sort(function (a, b) {
      return a.getDate() - b.getDate() + (a.getMonth() - b.getMonth());
    });
  };

  const sortMonth = (arr: Date[]): Date[] => {
    return arr.sort(function (a, b) {
      return a.getMonth() - b.getMonth();
    });
  };

  const sortYear = (arr: Date[]): Date[] => {
    return arr.sort(function (a, b) {
      return a.getFullYear() - b.getFullYear();
    });
  };

  const onSelectNoneClick = () => {
    dailyDateSelection([]);
  };

  var dailyParams = {
    StartDate: ruleStartDate,
    EndDate: ruleEndDate,
    year: 2020,
    value: new Date(),
    onSelectDate: onDateSelection,
    showInitialDate: true,
    label: "Select Dates",
  };
  return (
    <div className="daily-box">
      <div>
        <DateInput {...dailyParams} />
        <br></br>
        <DefaultButton
          text="Select All"
          onClick={onSelectAllClick}
          className="control-style"
        />{" "}
        &nbsp;
        <DefaultButton
          text="Select None"
          onClick={onSelectNoneClick}
          className="control-style"
        />
      </div>
      <div className="selected-Dates-Box" data-is-scrollable={true}>
        <div className="flex-box ">
          <Label style={{ width: 125 }}>Selected Dates </Label>
        </div>

        <div className="selected-Dates">
          <ul className="selected-Dates-list">
            {selectedDailyDates.map((item) => {
              return <li key={item.toString()}>{onRenderCell(item)}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default DailyView;
