import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import React from "react";
import { ITemplateRunDetails } from "../../../models/template-run";
import { IAuthControls } from "../../../redux/reducers/user-profile-reducer";

interface ITemplateRunCommandBarProps {
  TemplateRun: ITemplateRunDetails | undefined,
  AuthControls?: IAuthControls,
  onRetrigger: () => void,
  onCancel: () => void,
  onPause: () => void,
  onResume: () => void
}

type commandTypes = "Retrigger" | "Cancel" | "Pause" | "Resume";

export class TemplateRunCommandBar extends React.Component<ITemplateRunCommandBarProps> {
  constructor(props: ITemplateRunCommandBarProps) {
    super(props);

    this.getCommandBarItems = this.getCommandBarItems.bind(this);
  }

  private acceptedStatesMap: { [key in commandTypes]: string[] } = {
    "Retrigger": ["Failed", "Cancelled"],
    "Cancel": ["Failed", "InProgress"],
    "Pause": ["InProgress"],
    "Resume": ["InProgress"] // A train can only be resumed if it's InProgress and Paused is true, the pause check is below.
  };

  private actionDescriptions: { [key in commandTypes]: string } = {
    "Retrigger": "Reschedules the template run; skipping any completed processes, reschedules failed processes,\nand schedules processes that were originally scheduled but haven't run yet.",
    "Cancel": "Cancels a template run by sending a cancel command to Azure Data Factory for this run.\nCancellation may not propagate to external systems, such as notebooks running in Databricks.",
    "Pause": "Marks the template run as paused, which will cause the upcoming activity(ies) to pause when they start.\nThis will not pause activities that are already running.",
    "Resume": "Marks the template run as no longer paused, allowing any paused activities to resume."
  }

  private getCommandEnabledInfo(command: commandTypes): { enabled: boolean, reasons: string[] } {
    const createReturn = (...reasons: string[]) => ({ enabled: !reasons.length, reasons });
    // If templateRun isn't set, exit early since all other checks depend on it.
    if (!this.props.TemplateRun) {
      return createReturn("Template Run not loaded");
    }
    
    const messages: string[] = [];

    // Permissions check
    if (!this.hasPermissionsForCommand(command)) {
      messages.push("You do not have permissions to perform this action.");
    }

    // If the command is Resume, the run needs to have Paused === true and be InProgress (InProgress check is below).
    if (command === "Resume" && !this.props.TemplateRun.Paused) {
      messages.push("Resume requires the template run to be Paused.");
    }

    // If the command is Pause, the run needs to not be Paused and be InProgress (InProgress check is below).
    if (command === "Pause" && this.props.TemplateRun.Paused) {
      messages.push("Unable to pause an already paused run.");
    }

    const acceptedStates = this.acceptedStatesMap[command];

    // Check if the current ExecutionStatus is accepted, if not, build the tooltip message.
    if (!acceptedStates.includes(this.props.TemplateRun.ExecutionStatus)) {
      let message = `${command} requires the template run to be in `;
      // Pluralization check, bleh.
      if (acceptedStates.length > 1) {
        message += `one of the following states: ${acceptedStates.join(", ")}.`;
      } else {
        message += `the following state: ${acceptedStates[0]}.`;
      }
      messages.push(message);
    }

    return createReturn(...messages);
  }

  private createTooltip(command: commandTypes): string {
    var tooltip = this.actionDescriptions[command];
    var enabledInfo = this.getCommandEnabledInfo(command);
    if (!enabledInfo.enabled) {
      tooltip += "\n\n";
      tooltip += enabledInfo.reasons.join("\n");
    }

    return tooltip;
  }

  private isCommandEnabled(command: commandTypes): boolean {
    return this.getCommandEnabledInfo(command).enabled;
  }

  private hasPermissionsForCommand(command: commandTypes): boolean {
    // If they don't have authControls, they can't do anything.
    if (!this.props.AuthControls) {
      return false;
    }
    if (!this.props.AuthControls.canPauseResumeRun && (command === "Pause" || command === "Resume")) {
      return false;
    }
    if (!this.props.AuthControls.canRestartRetriggerCancel && (command === "Retrigger" || command === "Cancel")) {
      return false;
    }

    return true;
  }

  private getCommandBarItems(): ICommandBarItemProps[] {
    const status = this.props.TemplateRun?.ExecutionStatus || "";
    const result: ICommandBarItemProps[] = [
      {
        key: "Retrigger",
        text: "Retrigger Run",
        iconProps: { iconName: "PlaybackRate1x" },
        onClick: this.props.onRetrigger,
        title: this.createTooltip("Retrigger"),
        disabled: !this.isCommandEnabled("Retrigger")
      },
      {
        key: "Cancel",
        text: "Cancel Run",
        iconProps: { iconName: "Blocked" },
        onClick: this.props.onCancel,
        title: this.createTooltip("Cancel"),
        disabled: !this.isCommandEnabled("Cancel")
      },
      {
        key: "Pause",
        text: "Pause Run",
        iconProps: { iconName: "Pause" },
        onClick: this.props.onPause,
        title: this.createTooltip("Pause"),
        disabled: !this.isCommandEnabled("Pause")
      },
      {
        key: "Resume",
        text: "Resume Run",
        iconProps: { iconName: "Play" },
        onClick: this.props.onResume,
        title: this.createTooltip("Resume"),
        disabled: !this.isCommandEnabled("Resume")
      }
    ];

    return result;
  }

  render(): JSX.Element {
    return <CommandBar
      items={this.getCommandBarItems()}
      ariaLabel="Template Run Actions"
      styles={{ root: { padding: "0px" } }}
    />
  }
}
export default TemplateRunCommandBar;