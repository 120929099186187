import React from "react";
import {
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  PrimaryButton,
  DefaultButton,
  SelectionMode,
} from "@fluentui/react";
import DetailListWithAddDelete from "../detail-list-with-add-delete/detail-list-with-add-delete";
import { stackTokens } from "../run-schedule.helper";
import { IRunScheduleData } from "../../../models/run-schedule";
import Preview from "../preview-modal/preview/preview.view";
import { getLocalTimeZone } from "../../../util/time-zone-util";
import { IRunScheduleListState } from "./models/IRunScheduleList";
import {
  RunDrilldownNavBar,
  RunDrilldownNavBarProps,
} from "./run-drilldown-nav-bar";
import { IAuthControls } from "../../../redux/reducers/user-profile-reducer";

type RunScheduleListViewProps = {
  runTemplateScheduleData: IRunScheduleData;
  runScheduleListState: IRunScheduleListState;
  runDrilldownNavBarParams: RunDrilldownNavBarProps;
  items: any;
  columns: any;
  onAddClick: any;
  onDeleteClick: any;
  onSaveClick: any;
  onCancelClick: any;
  onTagChange: any;
  isLoading: any;
  isDirty?: boolean;
  isSaving?: boolean;
  updateDrillDownLevel: any;
  authControls: any;
};
export class RunScheduleListView extends React.Component<
  RunScheduleListViewProps
> {
  render() {
    return (
      <>
        <div
          className="run-schedule-grid run-schedule-grid-header"
          aria-label="Run Schedule NavBar"
          tabIndex={-1}
        >
          <RunDrilldownNavBar {...this.props.runDrilldownNavBarParams} />
          <ScrollablePane
            className="run-schedule-grid run-schedule-grid-header"
            scrollbarVisibility={ScrollbarVisibility.auto}
          >
            <DetailListWithAddDelete
              // runTemplateScheduleData={this.props.runTemplateScheduleData}
              items={this.props.items}
              columns={this.props.columns}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick={true}
              onAddClick={this.props.onAddClick}
              onTagChange={this.props.onTagChange}
              onDeleteClick={this.props.onDeleteClick}
              enableShimmer={this.props.isLoading}
            />
          </ScrollablePane>
        </div>
        <br />
        <div>
          <div className="time-zone-message">
            *All date and time displayed in the above grid and it's
            subcomponents are in <b>{getLocalTimeZone()} Time Zone</b> (adjusted
            for daylight saving if applicable).
          </div>
          <div className="run-schedule-update-buttons">
            <Stack horizontal wrap horizontalAlign="end" tokens={stackTokens}>
              <PrimaryButton
                text="Save"
                primaryDisabled={!this.props.isDirty || this.props.isSaving}
                onClick={this.props.onSaveClick}
                disabled={!this.props.authControls.canUpdateRunSchedule}
              />
              <Preview
                selectedRunTemplateName={
                  this.props.runTemplateScheduleData.RunTemplateName
                }
                startDate={
                  new Date(this.props.runTemplateScheduleData.StartDateTime)
                }
                endDate={
                  new Date(this.props.runTemplateScheduleData.EndDateTime)
                }
                selectedyear={this.props.runTemplateScheduleData.FiscalYear}
              />
              <DefaultButton
                text="Cancel"
                onClick={this.props.onCancelClick}
                disabled={false}
              />
            </Stack>
          </div>
        </div>
      </>
    );
  }
}
