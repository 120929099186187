import { AppState } from "./redux/configureStore";
import { AuthenticationState } from "react-aad-msal";

var _datasetColumnDetails: any[] = [];

export const InitialState: AppState = {
  expandCollapseClick: false,
  isSideNavOpen: false,
  runSchedule: {},
  runScheduleFilter: {},
  connectionState: {},
  sourceOnboardingReducer: {},
  authentication: {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
    state: AuthenticationState.Unauthenticated,
  },
  recurrenceRule: [],
  runDashboard: {
    isCurrentRunsLoading: true,
  },
  runDashboardV2: {
    ErrorMessageText: "",
    SuccessMessageText: "",
    RunData: {
      inProgressRuns: {
        runs: [],
        isLoaded: false,
        isLoading: false,
        isDirty: false,
        count: 5,
      },
      previousRuns: {
        runs: [],
        isLoaded: false,
        isLoading: false,
        isDirty: false,
        count: 5,
      },
      upcomingRuns: {
        runs: [],
        isLoaded: false,
        isLoading: false,
        isDirty: false,
        count: 5,
      },
    },
  },
  templateRunDetails: {
    showTemplateRunDetails: false,
    selectedTemplateRunDetails: undefined,
    isLoading: false,
    hideNotScheduled: false,
  },
  userProfile: {},
  activity: {},
  process: {},
  runTemplate: {},
  fiscalYear: {},
  validation: {},
  validateEntity: {},
  sourceRefreshReducer: {},
};
