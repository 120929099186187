import { ISideNavBarItem } from "../../model/ISideNavBar";
import SideNavNodeParentButton from "../../side-nav-bar-buttons/side-nav-node-parent-button";
import React from "react";
import SideNavNavigateButton from "../../side-nav-bar-buttons/side-nav-navigate-button";

type ParentNodeParams = {
  node: ISideNavBarItem;
};
const ParentNode: React.FunctionComponent<ParentNodeParams> = ({
  node
}) => {
  var parentToggleIcon = "ChevronRight";

  if (node.children.length > 0) {
    if (node.isExpanded) {
      parentToggleIcon = "ChevronDown";
    } else {
      parentToggleIcon = "ChevronRight";
    }

    var params = {
      node: node,
      parentToggleIcon: parentToggleIcon,
    };
    return <SideNavNodeParentButton {...params} />;
  } else {
    return <SideNavNavigateButton {...node} />;
  }
};

export default ParentNode;
