export function dailyPatternControllerService(
  startDate: Date,
  endDate: Date,
  selectedDailyDates: Date[]
) {
  // selected dates in daily are sorted and distinct
  // select the dates in fall in start ands end range
  let dates = [];

  for (let index = 0; index < selectedDailyDates.length; index++) {
    if (
      selectedDailyDates[index] >= startDate &&
      selectedDailyDates[index] <= endDate
    ) {
      dates.push(new Date(selectedDailyDates[index]));
    }
  }
  return dates;
}
