import { FontIcon, Stack, TextField } from "@fluentui/react";
import React, { memo, useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import IconButton from "../../../common/button/icon-button/icon-button.controller";
import { DefaultEffects } from "@fluentui/theme";
import { Link } from "react-router-dom";
import ActivityDetails from "./../../../activity/activity-view/activity-view.controller";
import ActivityAddEdit from "./../../../activity/activity-add-edit/activity-add-edit.controller";
import { ModalView } from "./../../../common/modal/modal.view";

export default memo((data: any) => {
  let activityDetailsParams = {
    activityId: data.data.activityId,
  };

  let activityDetailsControl = () => {
    return (
      <IconButton
        iconName="RedEye"
        fontSize={12}
        title="View"
        onClick={() => {}}
        ariaLabel="View"
      />
    );
  };
  let activityDetailsModal = {
    title: "Activity Details",
    control: activityDetailsControl(),
  };

  const [showEditActivityModal, updateEditActivityModalVisibility] = useState<
    boolean
  >(false);
  const [showActivityDetailsModal, updateShowActivityDetailsModal] = useState<
    boolean
  >(false);
  let editActivityControl = () => {
    return (
      <IconButton
        iconName="SingleColumnEdit"
        fontSize={12}
        title="Edit"
        onClick={() => {
          updateEditActivityModalVisibility(true);
        }}
        ariaLabel="Edit"
      />
    );
  };
  let editActivityParams = {
    activityId: data.data.activityId,
    activityName: data.data.nodeName,
    isAddActivity: false,
    isUpdateActivity: true,
    showModal: showEditActivityModal,
    updateAddEditActivityModalVisibility: updateEditActivityModalVisibility,
  };
  let editActivityModal = {
    title: "Edit " + data.data.nodeName + " Activity",
    control: editActivityControl(),
    showModal: showEditActivityModal,
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <div
        style={{
          width: "250px",
          height: "80px",
          boxShadow: DefaultEffects.elevation64,
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        {!data.data.isEditMode && (
          // <div style={{float:"left", width:"100%", height:15}}>{data.data.dependencyLabel == null ? "_" : "(" + data.data.dependencyLabel + ")"}</div>
          <div style={{ height: 15 }}>
            <span style={{ float: "left" }}>
              {data.data.dependencyLabel && (
                <div style={{ float: "left", width: "100%", height: 15 }}>
                  {data.data.dependencyLabel == null
                    ? "_"
                    : "(" + data.data.dependencyLabel + ")"}
                </div>
              )}
            </span>

            <span
              style={{
                textAlign: "center",
                float: "right",
                justifyContent: "end",
                textAlignLast: "right",
              }}
            >
              <IconButton
                iconName="Clear"
                fontSize={10}
                title=""
                disabled={true}
                onClick={(event: any) => {}}
              />
            </span>
          </div>
        )}
        {data.data.isEditMode && (
          <div style={{ height: 15 }}>
            <span style={{ float: "left" }}>
              {data.data.dependencyLabel && (
                <TextField
                  value={data.data.dependencyLabel}
                  style={{ float: "left", width: 40, justifyContent: "start" }}
                  type="number"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  aria-label="Dependency Group"
                  ariaLabel="Dependency Group"
                  title="Dependency Group"
                  onChange={(
                    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    textValue?: string
                  ) => {
                    // if(data.data.dependencyLabel != textValue)
                    data.data.dependencyLabel = textValue;
                    data.data.updateNode(data.id, data.data.dependencyLabel);
                    //this.props.onSaveDependencyButtonClick(this.state);
                    //data.DependencyGroupNumber = Number(textValue);
                  }}
                />
              )}
            </span>

            <span
              style={{
                textAlign: "center",
                float: "right",
                justifyContent: "end",
                textAlignLast: "right",
              }}
            >
              <IconButton
                iconName="Clear"
                fontSize={10}
                title="Remove"
                disabled={false}
                onClick={(event: any) => {
                  data.data.deleteNode(data.id);
                }}
                ariaLabel="Remove"
              />
            </span>
          </div>
        )}

        <div style={{ textAlign: "center", height: 40, marginTop: 10 }}>
          <strong>{data.data.nodeName}</strong>
        </div>

        <span
          style={{
            float: "left",
            width: "100%",
            fontSize: 12,
            marginLeft: 10,
            marginTop: 0,
          }}
        >
          {data.data.type}
        </span>
        {data.data.activityId && (
          <div style={{ marginTop: -10 }}>
            <span className="options" style={{ flexDirection: "row-reverse" }}>
              <div style={{ display: "flex" }}>
                <IconButton
                  iconName="SingleColumnEdit"
                  fontSize={12}
                  title="Edit"
                  onClick={() => {
                    updateEditActivityModalVisibility(true);
                  }}
                  ariaLabel="Edit"
                />
                <div></div>
                <IconButton
                  iconName="RedEye"
                  fontSize={12}
                  title="View"
                  onClick={() => {
                    updateShowActivityDetailsModal(true);
                  }}
                  ariaLabel="View"
                />
              </div>
            </span>
            {showEditActivityModal === true &&
            data.data.activityId !== undefined ? (
              <ActivityAddEdit
                {...{
                  activityId: data.data.activityId,
                  activityName: data.data.nodeName,
                  isAddActivity: false,
                  isUpdateActivity: true,
                  showModal: showEditActivityModal,
                  updateAddEditActivityModalVisibility: updateEditActivityModalVisibility,
                }}
              />
            ) : (
              <></>
            )}
            {showActivityDetailsModal === true && data.data.activityId ? (
              <ActivityDetails
                {...{
                  activityId: data.data.activityId,
                  showModal: showActivityDetailsModal,
                  updateShowActivityDetailModal: updateShowActivityDetailsModal,
                  title: "Activity Details",
                }}
              />
            ) : (
              <></>
            )}
          </div>
        )}

        {!data.data.activityId && (
          <div style={{ marginTop: -10 }}>
            <span className="options" style={{ flexDirection: "row-reverse" }}>
              <div>
                <Link
                  to={{
                    pathname:
                      `/orchestration/process-details?mode=edit&processId=` +
                      data.data.processId,
                    state: { processId: data.data.processId, isEditMode: true },
                  }}
                  target="_blank"
                >
                  <IconButton
                    iconName="SingleColumnEdit"
                    fontSize={12}
                    title="Edit Process"
                    ariaLabel="Edit Process"
                    onClick={() => {}}
                  />
                </Link>
              </div>

              <div>
                <Link
                  to={{
                    pathname:
                      `/orchestration/process-details?mode=view&processId=` +
                      data.data.processId,
                    state: {
                      processId: data.data.processId,
                      isEditMode: false,
                    },
                  }}
                  target="_blank"
                >
                  <IconButton
                    iconName="RedEye"
                    fontSize={12}
                    title="View Process"
                    onClick={() => {}}
                    ariaLabel="View Process"
                  />
                </Link>
              </div>
            </span>
          </div>
        )}
      </div>
      <Handle type="source" position={Position.Right} />
      {/* <Handle type="source" position="right" id="a" style={{ top: 10, background: '#555' }} />
      <Handle type="source" position="right" id="b" style={{ bottom: 10, top: 'auto', background: '#555' }} /> */}
    </>
  );
});
