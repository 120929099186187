// AppInsights.js
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { Configurations } from "./App.global";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: Configurations.instrumentationKey,
    endpointUrl: Configurations.appInsightsEndpoint == "0" ? undefined : Configurations.appInsightsEndpoint,
    // connectionString:"",
    autoTrackPageVisitTime: true, // logged as PageVisitTime:time a user spends on each page is tracked.
    // enableCorsCorrelation: true,
    // enableAutoRouteTracking: true,// enable automatic route change tracking for SPA // use only if you are not using the React plugin
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
// appInsights.trackPageView();
export { reactPlugin, appInsights };
