import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IProcessDetails } from "../../models/process";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import { formatDateTimeWithoutTimezone } from "../../util/time-zone-util";
import Loading from "../loading/loading";
import { startDeleteProcess, startGetProcessList } from "./process.service";
import ProcessView from "./process.view";
import { getDate,getFormattedDate } from "../run-dashboard-v2/run-dashboard-v2.helper";
import { getExecutionStatus } from "../run-dashboard-v2/run-dashboard-v2.helper";
import { getExecutionStatusValue } from "../run-dashboard-v2/run-dashboard-v2.helper";
import {
  IProcessLinkDispatchProps,
  IProcessLinkStateProps,
  IProcessProps,
  IProcessState,
} from "./models/IProcess";
import IconButton from "../common/button/icon-button/icon-button.controller";
import { Link } from "react-router-dom";
import { ColDef } from "ag-grid-community";
import * as TelemetryProvider from "../../TelemetryProvider";
import {
  deleteProcessClickEvent,
  editProcessClickEvent,
  processPageView,
  viewProcessClickEvent,
} from "./process.telemtetry-constants";

type Props = IProcessProps &
  IProcessLinkDispatchProps &
  IProcessLinkStateProps &
  any;

class Process extends React.Component<Props, IProcessState> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(processPageView);
    this.gridCellKeyPress = this.gridCellKeyPress.bind(this);
    this.getViewControl = this.getViewControl.bind(this);
    this.getEditControl = this.getEditControl.bind(this);
    this.getDeleteControl = this.getDeleteControl.bind(this);
  }

  componentDidMount() {
    this.props.startGetProcessList(this.props.fiscalYear);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.fiscalYear != this.props.fiscalYear) {
      this.props.startGetProcessList(this.props.fiscalYear);
    }
  }

  public render(): JSX.Element {
    if (
      this.props.processList !== undefined &&
      this.props.processList != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      var processList = this.props.processList;

      columnDefs = this.getColDefinations();
      rowData = this.getRowData(processList);
      var params = {
        columnDefs: columnDefs,
        rowData: rowData,
        gridCellKeyPress: this.gridCellKeyPress,
      };
      return <ProcessView {...params} />;
    } else {
      return <Loading />;
    }
  }

  private getColDefinations() {
    var columnDefs: ColDef[] = [
      {
        headerName: "Process Name",
        field: "ProcessName",
        width: 220,
        sortable: true,
        filter: true,
        resizable: true,
        lockPinned: true,
        pinned: "left",
      },
      {
        headerName: "Process Group",
        field: "ProcessGroup",
        width: 140,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Sub-Process",
        field: "IsSubProcess",
        width: 140,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Internal Process",
        field: "IsInternalProcess",
        width: 140,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Parent Process",
        field: "ParentProcess",
        width: 220,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Last Run Date",
        field: "LastRunDate",
        width: 160,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },
      {
        headerName: "Execution Status",
        width: 130,
        cellRendererFramework: getExecutionStatus,
        valueGetter: getExecutionStatusValue,
        sortable: true,
        filter: true,
      },
      {
        field: "ContainedByRunTemplates",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Created By",
        field: "CreatedBy",
        width: 140,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Created On",
        field: "CreatedOn",
        width: 160,
        sortable: true,
        filter: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },
      {
        headerName: "Modified By",
        field: "ModifiedBy",
        width: 140,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Modified On",
        field: "ModifiedOn",
        width: 160,
        sortable: true,
        filter: true,
        cellRendererFramework: getFormattedDate,
        valueGetter: getDate,
      },

      {
        headerName: "Is Active",
        field: "IsActive",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "View",
        width: 60,
        cellRendererFramework: this.getViewControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
        lockPinned: true,
        pinned: "right",
      },
    ];
    return columnDefs;
  }

  private getRowData(processList: IProcessDetails[]) {
    let rowData: any[] = [];
    for (let i = 0; i < processList.length; i++) {
      var obj = processList[i];

      let runTemplateList = "";
      obj.ContainedByRunTemplates?.forEach(
        (val) => (runTemplateList += val.RunTemplateName + ", ")
      );
      runTemplateList = runTemplateList.slice(0, -2);

      rowData.push({
        FiscalYear: obj.FiscalYear,
        ProcessId: obj.ProcessId,
        ProcessName: obj.ProcessName,
        ProcessGroup: obj.ProcessGroupName,
        IsSubProcess: obj.IsSubProcess === true ? "Yes" : "No",
        IsInternalProcess: obj.IsInternalProcess === true ? "Yes" : "No",
        ParentProcess: obj.ParentProcessName,
        IsActive: obj.IsActive === true ? "Yes" : "No",
        LastRunDate: obj.LastRunDateTimeStamp
         ? obj.LastRunDateTimeStamp
          : "-",
        LastRunId: obj.LastRunId ? parseInt(obj.LastRunId, 10) : 0,
        ExecutionStatus: obj.LastRunExecutionStatus
          ? obj.LastRunExecutionStatus
          : "-",
        CreatedBy: obj.CreatedBy,
        CreatedOn: obj.CreatedOn,
        ModifiedBy: obj.ModifiedBy,
        ModifiedOn: obj.ModifiedOn,
        ContainedByRunTemplates: runTemplateList,
        ThresholdTime: obj.ThresholdLimitInMinutes,
      });
    }
    return rowData;
  }

  private startDeleteProcessCallback() {
    this.props.startGetProcessList(this.props.fiscalYear);
  }

  private onDeleteClick(data: any) {
    TelemetryProvider._trackEvent(deleteProcessClickEvent);
    const onDeleteConfirmation = window.confirm(
      "This will soft delete the selected Process. Click Ok to confirm."
    );
    if (onDeleteConfirmation) {
      startDeleteProcess(data.ProcessId, this.startDeleteProcessCallback);
    }
  }

  private getViewControl = (params: any) => {
    return (
      <Link
        to={{
          pathname: `/orchestration/process-details`,
          state: { processId: params.data.ProcessId, isEditMode: false },
        }}
      >
        <IconButton
          iconName="RedEye"
          fontSize={12}
          title="View Process"
          disabled={!(params.data.IsActive === "Yes")}
          onClick={(event) => {
            TelemetryProvider._trackEvent(viewProcessClickEvent);
          }}
          ariaLabel="View Process"
        />
      </Link>
    );
  };

  private getEditControl = (params: any) => {
    return (
      <Link
        to={{
          pathname: `/orchestration/process-details`,
          state: { processId: params.data.ProcessId, isEditMode: true },
        }}
      >
        <IconButton
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit Process"
          disabled={!(params.data.IsActive === "Yes")}
          onClick={(event) => {
            TelemetryProvider._trackEvent(editProcessClickEvent);
          }}
          ariaLabel="Edit Process"
        />
      </Link>
    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <IconButton
        iconName="Delete"
        fontSize={12}
        title="Delete Process"
        disabled={!(params.data.IsActive === "Yes")}
        onClick={(event) => this.onDeleteClick(params.data)}
        ariaLabel="Delete Process"
      />
    );
  };

  private gridCellKeyPress = (event: any) => {
    const keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "View":
          TelemetryProvider._trackEvent(viewProcessClickEvent);
          this.props.history.push({
            pathname: `/orchestration/process-details`,
            state: { processId: event.data.ProcessId, isEditMode: false },
          });
          break;
        case "Edit":
          TelemetryProvider._trackEvent(editProcessClickEvent);
          this.props.history.push({
            pathname: `/orchestration/process-details`,
            state: { processId: event.data.ProcessId, isEditMode: true },
          });
          break;
        case "Delete":
          if (event.data.IsActive === "Yes") {
            this.onDeleteClick(event.data);
          }
          break;
        default:
          return;
      }
    }
  };
}

const mapStateToProps = (
  state: AppState,
  ownProps: IProcessProps
): IProcessLinkStateProps => {
  return {
    processList: state.process.processList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IProcessProps
): IProcessLinkDispatchProps => ({
  startGetProcessList: bindActionCreators(startGetProcessList, dispatch),
  startDeleteProcess: bindActionCreators(startDeleteProcess, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Process);
