import * as React from "react";
import { Dispatch } from "react";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import * as ProcessAPI from "../../api/process-api";
import { IProcessDetails } from "../../models/process";
import * as ProcessActions from "../../redux/actions/process-actions";
import { utcToBrowserTimeZone } from "../../util/time-zone-util";

const updateRunScheduleDataTimeZineToLocal = (
  processList: IProcessDetails[]
) => {
  processList.forEach((process) => {
    if (process.LastRunDateTimeStamp) {
      process.LastRunDateTimeStamp = utcToBrowserTimeZone(
        process.LastRunDateTimeStamp
      );
    }
    process.CreatedOn = utcToBrowserTimeZone(process.CreatedOn);
    process.ModifiedOn = utcToBrowserTimeZone(process.ModifiedOn);
  });
  return processList;
};

const sortProcessList = (processList: IProcessDetails[]) => {
  // Default sort by FiscalYear DESC, Process Name ASC
  processList.sort((a, b) =>
    a.ProcessName > b.ProcessName ? 1 : b.ProcessName > a.ProcessName ? -1 : 0
  );
  processList.sort((a, b) =>
    a.FiscalYear < b.FiscalYear ? 1 : b.FiscalYear < a.FiscalYear ? -1 : 0
  );
};

export const startGetProcessList = (fiscalYear: number) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ProcessAPI.getProcesses(fiscalYear)
      .then((value) => {
        const processList: IProcessDetails[] = updateRunScheduleDataTimeZineToLocal(
          value.Result
        );

        sortProcessList(processList);

        dispatch(ProcessActions.getProcessList(processList));
      })
      .catch((error) => {
        const err = error;
      });
  };
};

export const startDeleteProcess = (
  processId: number,
  startDeleteProcess: any
) => {
  ProcessAPI.deleteProcess(processId)
    .then((value) => {
      window.alert("Process deleted successfully.");
      if (startDeleteProcess) startDeleteProcess();
    })
    .catch((error) => {
      window.alert("An error occured. Please try again.");
      const err = error;
    });
};
