import { getDeepCopy } from "../../../../../util/javascript-functions";
import { getSelectedDates } from "../../shared/util";

export function yearlyPatternControllerService(
  startDate: Date,
  endDate: Date,
  ruleStartDate: Date,
  selectedYearDatesConfig: { frequency: string; month: string; dates: string }
) {
  let config = getDeepCopy(selectedYearDatesConfig);
  let days: any[] = config.dates.split(",");
  let month = startDate.getMonth();
  let year = startDate.getFullYear();
  let ruleStartDateYear = ruleStartDate.getFullYear();

  // Base conditions
  if (config.month != month) {
    return [];
  }
  if (config.frequency > 1) {
    ruleStartDateYear = ruleStartDateYear + parseInt(config.frequency) - 1;
  }

  while (ruleStartDateYear <= year) {
    if (ruleStartDateYear == year) {
      return getSelectedDates(startDate, endDate, days);
    } else {
      ruleStartDateYear = ruleStartDateYear + parseInt(config.frequency);
    }
  }
  return [];
}
