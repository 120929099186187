import * as React from "react";
import { ITimePickerPropsCustom } from "./models/ITimePicker";
import TimePickerView from "./time-picker.view";

export class TimePickerController extends React.Component<
  ITimePickerPropsCustom
> {
  constructor(props: ITimePickerPropsCustom) {
    super(props);
    this.state = {
      updatedvalue: this.props.value,
    };
    this.isValidTimeInputValue();
  }

  render(): JSX.Element {
    var state: any = this.state;
    return (
      <TimePickerView
        id={this.props.id}
        value={this.props.value}
        disabled={this.props.disabled}
        onChange={this._onSelectDefaultTime}
      />
    );
  }

  _onSelectDefaultTime = (event?: React.FormEvent<HTMLInputElement>): void => {
    this.setState({
      updatedvalue: event?.currentTarget.value,
    });
    this.props.onChange(event);
  };

  isValidTimeInputValue() {
    if (this.props.value != null) {
      let isValueValid = true;
      isValueValid = /^([0-1][0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        this.props.value
      );
      if (!isValueValid) {
        console.log("Wrong input value");
      }
    }
  }
}

const TimePicker = (Props: ITimePickerPropsCustom) => {
  return (
    <TimePickerController
      id={Props.id}
      value={Props.value}
      disabled={Props.disabled}
      onChange={Props.onChange}
    />
  );
};
export default TimePicker;
