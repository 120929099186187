import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import * as TelemetryProvider from "../TelemetryProvider";

export async function handleResponse(response: any) {
    if (response.ok) {
        return response.json();
    }
    const responseText = "Network response was not ok.";
    let errorMessage = responseText;
    if (response.status === 401 || response.status === 403) {
        errorMessage = "User doesn't have access to perform this operation.";
    }
    else {
        const responseJson = await response.json();
        if (response.status === 400 || response.status === 404 || response.status === 409) {
            // 4XX responses return a string error message.
            errorMessage = responseJson.ErrorMessage ? responseJson.ErrorMessage : responseText;
        }
        else if (response.status === 500) {
            errorMessage = responseJson.detail ? responseJson.detail : responseText;
        }
    }

    let errorDetails: IExceptionTelemetry = {
        error: new Error(errorMessage),
        severityLevel: 3,
    };

    TelemetryProvider._trackException(errorDetails);

    throw new Error(errorMessage);
}

export async function handleResponseTyped<T>(response: Response): Promise<T> {
    if (response.ok) {
        return response.json();
    }
    if (response.status === 400) {
        // So, a server-side validation error occurred.
        // Server side validation returns a string error message, so parse as text instead of json.
        const error = await response.text();
        throw new Error(error);
    }

    if (response.status === 403) {
        throw new Error("User doesn't have access to perform this operation.");
    }
    else {
        throw new Error("Network response was not ok.");
    }
}

export function handleError(error: any): never {
    console.error("API call failed. " + error);
    let errorMessage = getErrorMessage(error);
    window.alert(errorMessage);
    let errorDetails: IExceptionTelemetry = {
        error: error,
        severityLevel: 3,
    };
    TelemetryProvider._trackException(errorDetails);
    throw error;
}

export function getErrorMessage(error: any): string {
    // Check to see if the error passed in was originally in JSON format.
    let errorResponse = error;
    const errorMessageKey = "ErrorMessage";
    const statusCodeKey = "StatusCode";
    const fullErrorResponse = error.toString();
    const errorMessageIndex = fullErrorResponse.indexOf(errorMessageKey);
    const statusCodeIndex = fullErrorResponse.indexOf(statusCodeKey);

    // If ErrorMessage and StatusCode exist in the caught error, then we know that the error passed in was originally in JSON format,
    // and we can convert it back to a JSON safely.
    if (errorMessageIndex !== -1 && statusCodeIndex !== -1) {
        // Remove the key "Error: " leading the error string so we can convert the value back to a JSON safely.
        const errorKey = "Error: ";
        const errorMessageWithoutErrorKey = fullErrorResponse.substring(errorKey.length, fullErrorResponse.length);
        const errorAsJson = JSON.parse(errorMessageWithoutErrorKey);
        errorResponse = errorAsJson.ErrorMessage;
    }

    return errorResponse;
}
