import * as React from "react";
import { ITimePickerPropsCustom } from "./models/ITimePicker";
import "./time-picker.style.scss";

const TimePickerView = (Props: ITimePickerPropsCustom) => {
  return (
    <input
      className="form-control time-picker-style"
      type="time"
      title={Props.id}
      id={Props.id}
      value={Props.value}
      onChange={Props.onChange}
      disabled={Props.disabled}
      required
    ></input>
  );
};
export default TimePickerView;
