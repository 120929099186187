import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  ReactFlowProvider,
  Position,
  isNode,
} from "react-flow-renderer";
import dagre from "dagre";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IActivity, IActivityDependency } from "../../../../models/activity";
import {
  IProcessDependency,
  IProcessDetails,
} from "../../../../models/process";
import { AppState } from "../../../../redux/configureStore";
import { AppActions } from "../../../../redux/types/app-actions";
import { getDeepCopy } from "../../../../util/javascript-functions";
import {
  ILinkProps,
  INodeProps,
} from "../process-dependency-node/models/IProcessDependencyNode";
import {
  getLink,
  getNode,
} from "../process-dependency-node/process-dependency-node.helper";

import CustomNode from "../process-dependency-node/process-dependency-node.controller";
import {
  IProcessDependencyGridLinkDispatchProps,
  IProcessDependencyGridLinkStateProps,
  IProcessDependencyGridProps,
  IProcessDetailsExtended,
} from "./models/IProcessDependencyGrid";
import { getProcessDetails } from "../../../../redux/actions/process-actions";
import EditProcessDependencyLink, {
  IEditProcessDependencyState,
} from "../process-dependency-link/edit-process-dependency-link.controller";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const onLoad = (reactFlowInstance: any) => reactFlowInstance.fitView();
const onNodeDragStop = (event: any, node: any) =>
  console.log("drag stop", node);

const initBgColor = "#1A192B";

const connectionLineStyle = { stroke: "#008000" };
const snapGrid = [16, 16];
const nodeTypes = {
  selectorNode: CustomNode,
};

type Props = IProcessDependencyGridProps &
  IProcessDependencyGridLinkDispatchProps &
  IProcessDependencyGridLinkStateProps &
  any;
export const ProcessDependencyGrid: React.FunctionComponent<Props> = (
  props
) => {
  const [elements, setElements] = useState<any[]>([]);
  const [bgColor, setBgColor] = useState(initBgColor);
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<any>();
  const [[sourceNode, targetNode], setSelectedSourceTargetNode] = useState<
    [string, string]
  >(["", ""]);

  // useEffect(() => {
  //   setTimeout(function(){onLayout('LR')}, 5000);
  // }, []);

  useEffect(() => {
    let depthlist: any;
    let dependencyGraphElements: any;
    if (props.processDetails) {
      depthlist = getDepthList();
      if (
        props.processDetails.ChildProcesses &&
        props.processDetails.ChildProcesses.length > 0
      ) {
        dependencyGraphElements = buildDependencyGraph(depthlist, true);
      } else {
        dependencyGraphElements = buildDependencyGraph(depthlist, false);
      }

      setElements(dependencyGraphElements);

      //setTimeout(function(){onLayout('LR')}, 1000);
    }
  }, [props.processDetails]);

  const handleModalDismiss = () => {
    setShowModal(!showModal);
  };

  const onSaveDependencyButtonClick = (dep: IEditProcessDependencyState) => {
    if (props.isEditMode) {
      let _processDetailsCopy: IProcessDetails = getDeepCopy(
        props.processDetails
      );
      if (
        _processDetailsCopy.ChildProcesses &&
        _processDetailsCopy.ChildProcesses.length > 0
      ) {
        //Sub process view
        var childProcess = _processDetailsCopy.ChildProcesses?.find(
          (p) => p.ProcessId == Number(dep.target)
        );
        if (childProcess) {
          var dependency = childProcess.Dependencies.find(
            (d) => d.DependentProcessID == Number(dep.source)
          );
          if (dependency != undefined) {
            dependency.CanBeExcluded = dep.canBeExcluded;
            dependency.DependencyTypeId = dep.dependencyTypeID;
            dependency.DependencyTypeName = dep.dependencyTypeName;
            //dependency.DependencyGroupNumber = Number(
            //  dep.dependencyLabel == null ? "" : dep.dependencyLabel
            //);
          }
        }
      } else {
        //Activity view
        var activity = _processDetailsCopy.Activities?.find(
          (a) => a.ActivityId == Number(dep.target)
        );
        if (activity) {
          var actDependency = activity.Dependencies.find(
            (d) => d.DependentActivityId == Number(dep.source)
          );
          if (actDependency != undefined) {
            actDependency.CanBeExcluded = dep.canBeExcluded;
          }
        }
      }
      props.getProcessDetails(_processDetailsCopy);
      setShowModal(!showModal);
    }
  };

  const onDeleteDependencyButtonClick = (dep: IEditProcessDependencyState) => {
    if (props.isEditMode) {
      var val = window.confirm(
        "Are you sure you want to delete the dependency between the two processes?"
      );
      if (val) {
        let _processDetailsCopy: IProcessDetails = getDeepCopy(
          props.processDetails
        );
        if (
          _processDetailsCopy.ChildProcesses &&
          _processDetailsCopy.ChildProcesses.length > 0
        ) {
          //Sub process view
          var childProcess = _processDetailsCopy.ChildProcesses?.find(
            (p) => p.ProcessId == Number(dep.target)
          );
          if (childProcess) {
            var dependency = childProcess.Dependencies.findIndex(
              (d) => d.DependentProcessID == Number(dep.source)
            );
            if (dependency != -1)
              childProcess.Dependencies.splice(dependency, 1);
          }
        } else {
          //Activity view
          var activity = _processDetailsCopy.Activities?.find(
            (a) => a.ActivityId == Number(dep.target)
          );
          if (activity) {
            var dependency = activity.Dependencies.findIndex(
              (d) => d.DependentActivityId == Number(dep.source)
            );
            if (dependency != -1) activity.Dependencies.splice(dependency, 1);
          }
        }
        props.getProcessDetails(_processDetailsCopy);
        setShowModal(!showModal);
      }
    }
  };

  const deleteNodeHandler = (nodeId: string) => {
    let _processDetailsCopy: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    if (
      _processDetailsCopy.ChildProcesses &&
      _processDetailsCopy.ChildProcesses.length > 0
    ) {
      // Sub Process View
      var processIndex = _processDetailsCopy.ChildProcesses?.findIndex(
        (p) => p.ProcessId == parseInt(nodeId, 10)
      );

      if (processIndex != undefined && processIndex != -1)
        _processDetailsCopy.ChildProcesses?.splice(processIndex, 1);
    } else {
      // Activity view
      var activityIndex = _processDetailsCopy.Activities?.findIndex(
        (a) => a.ActivityId == parseInt(nodeId, 10)
      );

      if (activityIndex != undefined && activityIndex != -1)
        _processDetailsCopy.Activities?.splice(activityIndex, 1);
    }
    props.getProcessDetails(_processDetailsCopy);
  };

  const updateNodeHandler = (nodeId: string, dependencyGroupNumber: string) => {
    let _processDetailsCopy: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    if (
      _processDetailsCopy.ChildProcesses &&
      _processDetailsCopy.ChildProcesses.length > 0
    ) {
      // Sub Process View
      var processIndex = _processDetailsCopy.ChildProcesses?.findIndex(
        (p) => p.ProcessId == parseInt(nodeId, 10)
      );

      if (processIndex != undefined && processIndex != -1) {
        var selectedProcess = _processDetailsCopy.ChildProcesses[processIndex];
        selectedProcess.DependencyGroupNumber = Number(dependencyGroupNumber);
        var parentConnection = selectedProcess.Dependencies?.find(
          (d) => d.DependentProcessID == _processDetailsCopy.ProcessId
        );
        if (parentConnection != null) {
          parentConnection.DependencyGroupNumber = Number(
            dependencyGroupNumber
          );
        }
      }
    }

    props.getProcessDetails(_processDetailsCopy);
  };

  const onElementClick = (event: any, element: any) => {
    console.log("click", element);

    if (props.isEditMode && element.id.includes("link")) {
      if (
        props.processDetails.ChildProcesses &&
        props.processDetails.ChildProcesses.length > 0
      ) {
        var sourceNode = props.processDetails.ChildProcesses?.find(
          (p: IProcessDetails) => p.ProcessId == Number(element.source)
        )?.ProcessName;
        var targetNode = props.processDetails.ChildProcesses?.find(
          (p: IProcessDetails) => p.ProcessId == Number(element.target)
        )?.ProcessName;
        setSelectedSourceTargetNode([sourceNode, targetNode]);
      } else {
        var sourceNode = props.processDetails.Activities?.find(
          (p: IActivity) => p.ActivityId == Number(element.source)
        )?.ActivityName;
        var targetNode = props.processDetails.Activities?.find(
          (p: IActivity) => p.ActivityId == Number(element.target)
        )?.ActivityName;
        setSelectedSourceTargetNode([sourceNode, targetNode]);
      }

      setSelectedLink(element);
      setShowModal(true);
    }
  };

  //DeleteEdgeHandler
  const onElementsRemove = (elementsToRemove: any) => {
    console.log("remove", elementsToRemove);
    var element = elementsToRemove[0];

    if (props.isEditMode && element.id.includes("link")) {
      var val = window.confirm(
        "Are you sure you want to delete the dependency between the two sub-processes/activities?"
      );

      if (val) {
        let _processDetailsCopy: IProcessDetails = getDeepCopy(
          props.processDetails
        );
        if (
          _processDetailsCopy.ChildProcesses &&
          _processDetailsCopy.ChildProcesses.length > 0
        ) {
          //Sub process view
          var childProcess = _processDetailsCopy.ChildProcesses?.find(
            (p) => p.ProcessId == Number(element.target)
          );
          if (childProcess) {
            var dependency = childProcess.Dependencies.findIndex(
              (d) => d.DependentProcessID == Number(element.source)
            );
            if (dependency != -1)
              childProcess.Dependencies.splice(dependency, 1);
            props.getProcessDetails(_processDetailsCopy);
          }
        } else {
          //Activity view
          var activity = _processDetailsCopy.Activities?.find(
            (a) => a.ActivityId == Number(element.target)
          );
          if (activity) {
            var dependency = activity.Dependencies.findIndex(
              (d) => d.DependentActivityId == Number(element.source)
            );
            if (dependency != -1) activity.Dependencies.splice(dependency, 1);
            props.getProcessDetails(_processDetailsCopy);
          }
        }
      }
    }
  };

  const onConnect = (params: any) => {
    //setElements((els) => addEdge({ ...params, animated: true, style: { stroke: "#ee5" } }, els));

    if (!props.isEditMode) {
      return;
    }
    let _processDetailsCopy: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    if (
      _processDetailsCopy.ChildProcesses &&
      _processDetailsCopy.ChildProcesses.length > 0
    ) {
      //Sub process view
      var childProcess = _processDetailsCopy.ChildProcesses?.find(
        (p) => p.ProcessId == Number(params.target)
      );
      if (childProcess) {
        let _dependencyInfo: IProcessDependency = {
          ProcessDependencyID: 0,
          FiscalYear: childProcess.FiscalYear,
          ProcessId: Number(params.target),
          DependentProcessID: Number(params.source),
          CanBeExcluded: false,
          IsActive: true,
        };
        childProcess.Dependencies.push(_dependencyInfo);
        props.getProcessDetails(_processDetailsCopy);
      }
    } else {
      //Activity view
      var activity = _processDetailsCopy.Activities?.find(
        (a) => a.ActivityId == Number(params.target)
      );
      if (activity) {
        let _dependencyInfo: IActivityDependency = {
          ActivityDependencyId: 0,
          FiscalYear: activity.FiscalYear,
          ActivityId: Number(params.target),
          DependentActivityId: Number(params.source),
          CanBeExcluded: false,
          IsActive: true,
        };
        activity.Dependencies.push(_dependencyInfo);
        props.getProcessDetails(_processDetailsCopy);
      }
    }
  };

  const getDepthList = () => {
    let _processDetailsCopy: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    let nodeHashKey = new Map<number, any>();
    if (
      _processDetailsCopy.ChildProcesses &&
      _processDetailsCopy.ChildProcesses.length > 0
    ) {
      // Sub Process View
      let _processDetailsWithDuplicates: IProcessDetails = getDeepCopy(
        props.processDetails
      );
      _processDetailsWithDuplicates.ChildProcesses!.forEach((node) => {
        if (nodeHashKey.has(node.ProcessId)) {
          // Removing duplicate entries
          let index = _processDetailsCopy.ChildProcesses!.findIndex(
            (d) => d.ProcessId === node.ProcessId
          );
          _processDetailsCopy.ChildProcesses!.splice(index, 1);
        } else {
          nodeHashKey.set(node.ProcessId, node);
        }
      });
      _processDetailsCopy.ChildProcesses.forEach((node) => {
        let _nodeCopy: IProcessDetails = getDeepCopy(node);
        _nodeCopy.Dependencies.forEach((dep) => {
          const _isDependentProcessAChildProcess =
            _processDetailsCopy.ChildProcesses!.findIndex(
              (c) => c.ProcessId === dep.DependentProcessID
            ) > -1;
          if (
            !nodeHashKey.has(dep.DependentProcessID) ||
            dep.DependencyTypeId === 5
          ) {
            //'None' dependency type
            if (
              !(dep.DependencyTypeId === 5 && !_isDependentProcessAChildProcess)
            ) {
              let index = node.Dependencies.findIndex(
                (d) => d.DependentProcessID === dep.DependentProcessID
              );
              if (
                node.Dependencies[index].DependentProcessID ==
                _processDetailsCopy.ProcessId
              ) {
                node.DependencyGroupNumber =
                  node.Dependencies[index].DependencyGroupNumber;
              }
              node.Dependencies.splice(index, 1);
            }
          }
        });
      });

      // Building depth list
      let depth = 0;
      let _depthList = [];
      let nodeToDepthMapping = new Map<number, number>();
      let _nodeListCopy: IProcessDetails[] = getDeepCopy(
        _processDetailsCopy.ChildProcesses
      );
      while (_processDetailsCopy.ChildProcesses.length > 0) {
        let _currentDepthNodes: { nodeList: IProcessDetails[] } = {
          nodeList: [],
        };
        // eslint-disable-next-line no-loop-func
        _nodeListCopy.forEach((node) => {
          let _nodeCopy: IProcessDetails = getDeepCopy(node);
          _nodeCopy.Dependencies.forEach((dep) => {
            const _isDependentProcessAChildProcess =
              _processDetailsCopy.ChildProcesses!.findIndex(
                (c) => c.ProcessId === dep.DependentProcessID
              ) > -1;
            if (nodeToDepthMapping.has(dep.DependentProcessID)) {
              // splice dependency
              let index = node.Dependencies.findIndex(
                (d) => d.DependentProcessID === dep.DependentProcessID
              );
              node.Dependencies.splice(index, 1);
            }
            // logic to handle external dependencies,i.e.DependencyTypeId === 5
            if (
              dep.DependencyTypeId === 5 &&
              !_isDependentProcessAChildProcess
            ) {
              const _externalNodeDetails: IProcessDetailsExtended = getDeepCopy(
                props.processList.find(
                  (p: IProcessDetails) => p.ProcessId === dep.DependentProcessID
                )
              );
              _externalNodeDetails.IsExternal = true;
              nodeToDepthMapping.set(_externalNodeDetails.ProcessId, depth);
              _currentDepthNodes.nodeList.push(_externalNodeDetails);
            }
          });
        });
        // eslint-disable-next-line no-loop-func
        _nodeListCopy.forEach((node) => {
          if (
            node.Dependencies.length === 0 &&
            !nodeToDepthMapping.has(node.ProcessId)
          ) {
            let index = _processDetailsCopy.ChildProcesses!.findIndex(
              (d) => d.ProcessId === node.ProcessId
            );
            const _nodeWithNoRemainingDependencies = _processDetailsCopy.ChildProcesses!.splice(
              index,
              1
            )[0];
            nodeToDepthMapping.set(node.ProcessId, depth);
            _currentDepthNodes.nodeList.push(_nodeWithNoRemainingDependencies);
            // Add node to nodeToDepthMapping
            // Add node from _processDetailsCopy.ChildProcesses to _currentDepthNodes
          }
        });
        _depthList.push(_currentDepthNodes);
        depth++;
      }
      return _depthList;
    } else {
      // Activity View
      let _processDetailsWithDuplicates: IProcessDetails = getDeepCopy(
        props.processDetails
      );
      _processDetailsWithDuplicates.Activities!.forEach((node) => {
        if (nodeHashKey.has(node.ActivityId)) {
          let index = _processDetailsCopy.Activities!.findIndex(
            (d) => d.ActivityId === node.ActivityId
          );
          _processDetailsCopy.Activities!.splice(index, 1);
        } else {
          nodeHashKey.set(node.ActivityId, node);
        }
      });
      _processDetailsCopy.Activities!.forEach((node) => {
        let _nodeCopy: IActivity = getDeepCopy(node);
        _nodeCopy.Dependencies.forEach((dep) => {
          if (!nodeHashKey.has(dep.DependentActivityId)) {
            let index = node.Dependencies.findIndex(
              (d) => d.DependentActivityId === dep.DependentActivityId
            );
            node.Dependencies.splice(index, 1);
          }
        });
      });

      // Building depth list
      let depth = 0;
      let _depthList = [];
      let nodeToDepthMapping = new Map<number, number>();
      let _nodeListCopy: IActivity[] = getDeepCopy(
        _processDetailsCopy.Activities
      );
      while (_processDetailsCopy.Activities!.length > 0) {
        let _currentDepthNodes: { nodeList: IActivity[] } = {
          nodeList: [],
        };
        _nodeListCopy.forEach((node) => {
          let _nodeCopy: IActivity = getDeepCopy(node);
          _nodeCopy.Dependencies.forEach((dep) => {
            if (nodeToDepthMapping.has(dep.DependentActivityId)) {
              // splice dependency
              let index = node.Dependencies.findIndex(
                (d) => d.DependentActivityId === dep.DependentActivityId
              );
              node.Dependencies.splice(index, 1);
            }
          });
        });
        // eslint-disable-next-line no-loop-func
        _nodeListCopy.forEach((node) => {
          if (
            node.Dependencies.length === 0 &&
            !nodeToDepthMapping.has(node.ActivityId)
          ) {
            let index = _processDetailsCopy.Activities!.findIndex(
              (d) => d.ActivityId === node.ActivityId
            );
            const _nodeWithNoRemainingDependencies = _processDetailsCopy.Activities!.splice(
              index,
              1
            )[0];
            nodeToDepthMapping.set(node.ActivityId, depth);
            _currentDepthNodes.nodeList.push(_nodeWithNoRemainingDependencies);
          }
        });
        _depthList.push(_currentDepthNodes);
        depth++;
      }
      return _depthList;
    }
  };

  const buildDependencyGraph = (depthlist: any, atSubProcessLevel: boolean) => {
    let _dependencyGraphElements = [];
    const xGap = 300;
    const yGap = 150;
    let existingNodes = new Map<number, boolean>();
    let existingDependencies = new Map<string, boolean>();
    if (atSubProcessLevel) {
      for (let d = 0; d < depthlist.length; d++) {
        let currentDepthList = depthlist[d];
        for (let n = 0; n < currentDepthList.nodeList.length; n++) {
          let node = currentDepthList.nodeList[n];
          if (
            existingNodes !== undefined &&
            !existingNodes.has(node.ProcessId)
          ) {
            let nodeProps: INodeProps = {
              nodeId: node.ProcessId,
              nodeType: "selectorNode",
              data: {
                color: initBgColor,
                deleteNode: deleteNodeHandler,
                updateNode: updateNodeHandler,
                nodeName: node.ProcessName,
                isEditMode: props.isEditMode,
                dependencyLabel:
                  node.DependencyGroupNumber == null
                    ? null
                    : node.DependencyGroupNumber.toString(),
                type: (node.IsExternal ? "[External] " : "") + "Sub-Process",
                processId: node.ProcessId,
              },
              type: node.type,
              xCoordinate: d == 0 ? xGap - 220 : (d + 1) * xGap,
              yCoordinate: (n + 1) * yGap,
            };
            let z = getNode(nodeProps, elements);
            _dependencyGraphElements.push(z);
            existingNodes.set(node.ProcessId, true);
          }
          node.Dependencies.forEach((dep: any) => {
            let linkId = "link" + dep.DependentProcessID + "-" + dep.ProcessId;
            if (
              existingDependencies !== undefined &&
              !existingDependencies.has(linkId)
            ) {
              let linkProps: ILinkProps = {
                sourceNodeId: dep.DependentProcessID,
                targetNodeId: dep.ProcessId,
                //label: dep.DependencyLabel,
                //label: dep.DependencyGroupNumber == null ? "" : "(" + dep.DependencyGroupNumber.toString() + ")",
                label: dep.DependencyTypeName,
                dependencyLabel:
                  node.DependencyGroupNumber == null
                    ? null
                    : node.DependencyGroupNumber.toString(),
                type: "",
                data: {
                  canBeExcluded: dep.CanBeExcluded,
                  dependencyTypeId: dep.DependencyTypeId,
                  dependencyTypeName: dep.DependencyTypeName,
                  dependencyLabel: dep.DependencyGroupNumber,
                },
              };
              let y = getLink(linkProps);
              _dependencyGraphElements.push(y);
              existingDependencies.set(linkId, true);
            }
          });
        }
      }
    } else {
      for (let d = 0; d < depthlist.length; d++) {
        let currentDepthList = depthlist[d];
        for (let n = 0; n < currentDepthList.nodeList.length; n++) {
          let node = currentDepthList.nodeList[n];
          if (
            existingNodes !== undefined &&
            !existingNodes.has(node.ActivityId)
          ) {
            let nodeProps: INodeProps = {
              nodeId: node.ActivityId,
              nodeType: "selectorNode",
              data: {
                color: initBgColor,
                deleteNode: deleteNodeHandler,
                nodeName: node.ActivityName,
                isEditMode: props.isEditMode,
                type: node.ActivityTypeName,
                activityId: node.ActivityId,
              },
              type: node.type,
              xCoordinate: (d + 1) * xGap,
              yCoordinate: (n + 1) * yGap,
            };
            let z = getNode(nodeProps, elements);
            _dependencyGraphElements.push(z);
            existingNodes.set(node.ActivityId, true);
          }
          node.Dependencies.forEach((dep: any) => {
            let linkId =
              "link" + dep.DependentActivityId + "-" + dep.ActivityId;
            if (
              existingDependencies !== undefined &&
              !existingDependencies.has(linkId)
            ) {
              let linkProps: ILinkProps = {
                sourceNodeId: dep.DependentActivityId,
                targetNodeId: dep.ActivityId,
                label: dep.dependencyLabel,
                dependencyLabel: dep.dependencyLabel,
                type: "",
                data: {
                  canBeExcluded: dep.CanBeExcluded,
                },
              };
              let y = getLink(linkProps);
              _dependencyGraphElements.push(y);
              existingDependencies.set(linkId, true);
            }
          });
        }
      }
    }
    return _dependencyGraphElements;
  };

  const nodeWidth = 400;
  const nodeHeight = 120;

  const getLayoutedElements = (elements: any, direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el: any) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);

    return elements.map((el: any) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? Position.Left : Position.Top;
        el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

        // unfortunately we need this little hack to pass a slighltiy different position
        // to notify react flow about the change. More over we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }

      return el;
    });
  };

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );

  return (
    <ReactFlowProvider>
      <div className="controls">
        {<button onClick={() => onLayout("LR")}>Auto Layout</button>}
      </div>
      <ReactFlow
        elements={elements}
        onElementClick={onElementClick}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onNodeDragStop={onNodeDragStop}
        style={{ background: "#FFFFFF" }}
        onLoad={onLoad}
        nodeTypes={nodeTypes}
        //connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={[16, 16]}
        defaultZoom={1.5}
      >
        <MiniMap
          nodeColor={(n) => {
            return "#000000";
          }}
        />
        <Controls />
      </ReactFlow>

      {showModal ? (
        <EditProcessDependencyLink
          showModal={showModal}
          handleModalDismiss={handleModalDismiss}
          title={"Edit Process/Activity Dependency"}
          link={selectedLink}
          nodes={[sourceNode, targetNode]}
          onSaveDependencyButtonClick={onSaveDependencyButtonClick}
          onDeleteDependencyButtonClick={onDeleteDependencyButtonClick}
          isActivityDependency={
            props.processDetails.ChildProcesses &&
            props.processDetails.ChildProcesses.length > 0
              ? false
              : true
          }
        />
      ) : (
        ""
      )}
    </ReactFlowProvider>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IProcessDependencyGridProps
): IProcessDependencyGridLinkStateProps => {
  return {
    processDetails: state.process.processDetails,
    processList: state.process.processList,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IProcessDependencyGridProps
): IProcessDependencyGridLinkDispatchProps => ({
  getProcessDetails: bindActionCreators(getProcessDetails, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessDependencyGrid);
