import React from "react";
import SideNavBarNodesView from "./side-nav-bar-nodes-view/side-nav-bar-nodes-view";

const SideNavBarNodes: React.FunctionComponent = (props: any) => {
  return props.node_data.map((data: any, index: any) => {
    return (
      <SideNavBarNodesView key={index}
        {...props}
        {...{
          index: index,
          node: data,
        }}
      />
    );
  });
};

export default SideNavBarNodes;
