import * as React from "react";
import "./loading.style.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";

export class Loading extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="message">
          <div>
            <Spinner size={SpinnerSize.large} label="Loading..." />
          </div>
        </div>
      </>
    );
  }
}

export default Loading;
