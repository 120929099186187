import * as React from "react";
import { IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import {
  IRunTemplateSchedule,
  IRunSchedule,
} from "../../../../models/run-schedule";
import DetailListWithStickyHeader from "../../detail-list-with-add-delete/detail-list-with-sticky-header";
import { ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import "./preview-grid.scss";
import {
  formatDateTime,
  formatDate,
  getDateAndTimeInLocalTime,
  getLocalTimeZone,
  formatDateTimeWithoutTimezone,
} from "../../../../util/time-zone-util";

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: string;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
}

export interface IRunSchedulePreviewData {
  runScheduledata: IRunTemplateSchedule;
}

export class PreviewGrid extends React.Component<IRunSchedulePreviewData> {
  private _columns: IColumn[];

  constructor(props: IRunSchedulePreviewData) {
    super(props);
    this.state = {
      runScheduledata: props.runScheduledata,
    };
    this._columns = [
      {
        key: "column1",
        name: "Process Name",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "column2",
        name: "Scheduled Time(" + getLocalTimeZone() + " Time Zone)",
        fieldName: "value",
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
      },
    ];
  }

  public render(): JSX.Element {
    var allItems: any = [];
    this.props.runScheduledata.Processes.forEach((process) => {
      if (process.IsSubProcess != true) {
        allItems.push({
          key: process.ProcessId,
          name: process.ProcessName,
          value: process.ProcessScheduleDateTime
            ? formatDateTimeWithoutTimezone(process?.ProcessScheduleDateTime)
            : "",
        });
      }
    });
    return (
      <ScrollablePane
        className="run-schedule-grid preview-grid"
        scrollbarVisibility={ScrollbarVisibility.auto}
      >
        <DetailListWithStickyHeader
          items={allItems}
          columns={this._columns}
          selectionMode={SelectionMode.none}
          selectionPreservedOnEmptyClick={true}
        />
      </ScrollablePane>
    );
  }
}
