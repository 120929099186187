import { getLeafNodes } from "./base-nodes/leaf-nodes";
import { ISideNavBar } from "../model/ISideNavBar";
import React, { useState } from "react";
import "./side-nav-bar-nodes.scss";
import ParentNode from "./base-nodes/parent-node";
import { connect } from "react-redux";
import { AppState } from "../../../../redux/configureStore";
import { initializeIcons, mergeStyleSets } from "@fluentui/react";
import IconButton from "../../button/icon-button/icon-button.controller";
import { Link } from "react-router-dom";

initializeIcons();
type SideNavBarNodesViewParams = {
  index: any;
  node: ISideNavBar;
};
const SideNavBarNodesView: React.FunctionComponent<SideNavBarNodesViewParams> = (
  props: any
) => {
  return (
    <ul key={props.index}>
      <li>
        <div className="nav-item" tabIndex={-1}>
          <div className="nav-image-container">
            <div className="nav-image">
              <a href={props.node.navItem.url} target={props.node.navItem.target}>
                <IconButton
                  ariaLabel={props.node.iconDescription}
                  iconName={props.node.iconName}
                  fontSize={24}
                  title={props.node.iconDescription}
                  onClick={() => {
                    //props.history.push(props.node.navItem.url);
                  }}
                />
              </a>
            </div>
          </div>
          {props.isSideNavOpen ? (
            <div className="nav-data">
              <ParentNode
                {...{
                  node: props.node.navItem,
                }}
              />
            </div>
          ) : null}
        </div>
      </li>

      {getLeafNodes(props.node, props.isSideNavOpen)}
    </ul>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    expandCollapseClick: state.expandCollapseClick,
    isSideNavOpen: state.isSideNavOpen,
  };
};

export default connect(mapStateToProps)(SideNavBarNodesView);
