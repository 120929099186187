import {
  IDropdownStyles,
  mergeStyleSets,
  mergeStyles,
  IStackTokens,
} from "@fluentui/react";

export const controlStyles = {
  root: {
    margin: "20px 0 20px 0",
    maxWidth: "300px",
  },
};
export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};
export const divStyle = {
  margin: "20px",
};

export const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
});

export function getNameStyles(status?: string) {
  return mergeStyles({
    marginTop: 5,
    color: status,
    height: "100%",
    display: "block",
  });
}

export const stackTokens: IStackTokens = { childrenGap: 20 };
