import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";
import { IProcessDetails } from "../models/process";

const baseUrl = Configurations.mintHubApiBaseUrl + "/Process/";
const listBaseUrl = Configurations.mintHubApiBaseUrl + "/Process?fiscalYear=";
var authToken = "";

export function getProcesses(fiscalYear: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(listBaseUrl + fiscalYear, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProcessDetails(processId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + processId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProcessMetadata(fiscalYear: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "GetProcessMetadata?fiscalYear=" + fiscalYear, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

// Create or Update an process
export function saveProcess(processDetails: IProcessDetails) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: processDetails.ProcessId === 0 ? "PUT" : "POST", // PUT for create, POST to update when id already exists.
        headers: {
          Authorization: "Bearer " + authToken,
          "content-type": "application/json",
        },
        body: JSON.stringify(processDetails),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function saveProcessToADF(processId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(
        Configurations.mintHubApiBaseUrl +
          "/Template/Template/Processes/All?processId=" +
          processId,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + authToken,
            "content-type": "application/json",
          },
        }
      );
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteProcess(processId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + processId, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllDependencyTypes() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "DependencyTypes", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProcessSlaDetails(fiscalYear: number, processIdList: number[]) {
	return GetToken()
		.then((res) => {
			authToken = res;
			return fetch(baseUrl + "ProcessSLA?fiscalYear=" + fiscalYear, {
				method: "POST",
				headers: {
					"content-type": "application/json",
					Authorization: "Bearer " + authToken,
				},
				body: JSON.stringify(processIdList),
			});
		})
		.then(handleResponse)
		.catch(handleError);
}
