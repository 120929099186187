import { ITextFieldStyles, IDropdownStyles } from "@fluentui/react";

export const narrowTextFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 60 },
};
export const broadTextFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 150 },
};
export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 150, marginLeft: 8 },
};
