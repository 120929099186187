import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import editImage from "./../../../../src/assests/image/MintHub_Edit-icon.png";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewConnection from "./new-connection";
import { Connection } from "../../../models/connection";
import { ISourceOnboardingConnectionsList } from "./model/ISourceOnboarding";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { sourceOnboardingExportButtonClickEvent } from "./source-onboarding.telemtetry-constants";

type SourceOnboardingViewProps1 = {
  totalConnections: {};
  columnDefs: any[];
  rowData: any[];
  tempConns: any;
  editModalParams: any;
  showModal: boolean;
  updateIsEditModalVisible: any;
  gridCellKeyPress: any;
  isNewModal: boolean;
};

export class SourceOnboarding extends React.Component<
  SourceOnboardingViewProps1
> {
  public render(): JSX.Element {
    return (
      <div>
        <div className="new-button-div">
          <PrimaryButtonView
            text="Create New Source"
            onClick={() => {
              this.props.updateIsEditModalVisible(true);
            }}
          />
        </div>

        <div>
          <div
            className="ag-theme-balham"
            style={{
              height: "465px",
              width: "100%",
              marginTop:
                "11px" /*, margin: '5px 0px 0px 60px', width: '100%' */,
            }}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={15}
              enableRangeSelection={true}
              pagination={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>

        <div className="new-button-div" style={{ marginTop: "11px" }}>
          <PrimaryButtonView
            text="Export to Excel"
            onClick={this._onExportButtonClicked}
          />
        </div>
        {this.props.showModal === true ? (
          this.props.isNewModal ? (
            <NewConnection
              {...{
                tempConns: this.props.tempConns,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          ) : (
            <NewConnection
              {...{
                ...this.props.editModalParams,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }

  private _onExportButtonClicked = () => {
    TelemetryProvider._trackEvent(sourceOnboardingExportButtonClickEvent);
    OnboardingSummaryUtility._onExportButtonClicked(
      this.props.rowData,
      "SourceOnboarding"
    );
  };
}

export default SourceOnboarding;
