import * as React from "react";
import { Dropdown } from "@fluentui/react";
import { IDropdownPropsCustom } from "./models/IDropdown";

const DropdownView = (Props: IDropdownPropsCustom) => {
  return (
    <Dropdown
      placeholder={Props.placeholder}
      label={Props.label}
      ariaLabel={Props.ariaLabel}
      options={Props.options}
      onChange={Props.onChange}
      disabled={Props.disabled}
      styles={Props.styles}
      required={Props.required}
      defaultSelectedKey={Props.defaultSelectedKey}
      selectedKey={Props.selectedKey}
      multiSelect={Props.multiSelect}
      defaultSelectedKeys={Props.defaultSelectedKeys}
      selectedKeys={Props.selectedKeys}
      onDismiss={Props.onDismiss}
      title={
        Props.multiSelect
          ? Props.selectedKeys?.join(" , ")
          : Props.selectedKey?.toString()
      }
      dropdownWidth={Props.dropdownWidth}
    />
  );
};
export default DropdownView;
