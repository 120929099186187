import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const activityPageView: IPageViewTelemetry = {
  name: "Activity",
  uri: "orchestration/activity",
};

export const addNewActivityClickEvent: IEventTelemetry = {
  name: "Activity_AddNewActivityClick",
};

export const viewActivityClickEvent: IEventTelemetry = {
  name: "Activity_ViewActivityClick",
};

export const editActivityClickEvent: IEventTelemetry = {
  name: "Activity_EditActivityClick",
};

export const deleteActivityClickEvent: IEventTelemetry = {
  name: "Activity_DeleteActivityClick",
};
