import * as types from "../types/recurrence-rule-action-types";
import { RecurrenceRuleActionTypes } from "../types/recurrence-rule-action-types";

const recurrenceRuleReducerDefaultState: any = {}; // Update any to a type if possible

const recurrenceRuleReducer = (
  state = recurrenceRuleReducerDefaultState,
  action: RecurrenceRuleActionTypes
): any => {
  switch (action.type) {
    case types.GET_RECURRENCE_RULES:
      return Object.assign([], state, [...action.rules]);
    case types.ADD_OR_UPDATE_RULE:
      return { ...action.rule };
    default:
      return state;
  }
};

export default recurrenceRuleReducer;
