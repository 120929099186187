import { ISideNavBarItem } from "../model/ISideNavBar";
import React, { useState } from "react";
import { FontIcon, Icon, initializeIcons } from "@fluentui/react";
import "./side-nav-bar-buttons.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import expandCollapseAction from "../../../../redux/actions/expand-collapse-action";
import { AppState } from "../../../../redux/configureStore";
import IconButton from "../../button/icon-button/icon-button.controller";


initializeIcons();

type SideNavNodeParentButtonParams = {
  node: ISideNavBarItem;
  parentToggleIcon: string;
};

const SideNavNodeParentButton: React.FunctionComponent<SideNavNodeParentButtonParams> = (
  props: any
) => {
  return (
    <button 
      className="side-nav-button"
      onClick={() => {
        props.actions.expandCollapseAction(!props.expandCollapseClick);
        props.node.isExpanded = !props.node.isExpanded;
      }}
    >
      <div className="nav-node-name parent-node" tabIndex={-1}>
        {props.node.name}
      </div>

      <div className="parent-expand-collapse-icon" tabIndex={-1}>
        <Icon  iconName={props.parentToggleIcon} title= {props.node.name}  onClick={()=>{}}/>
      </div>
    </button>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    expandCollapseClick: state.expandCollapseClick,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      expandCollapseAction: bindActionCreators(expandCollapseAction, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavNodeParentButton);
