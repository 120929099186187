import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import PrimaryButton from "../../../common/button/primary-button/primary-button.controller";
import ComboBox from "../../../common/combo-box/combo-box.controller";
import "./edit-process-panel.style.scss";
import {
  IComboBox,
  IComboBoxOption
} from "@fluentui/react";
import {
  IEditProcessPanelLinkDispatchProps,
  IEditProcessPanelLinkStateProps,
  IEditProcessPanelProps,
} from "./models/IEditProcessPanel";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { startGetActivityList } from "../../../activity/activity.service";
import { useEffect } from "react";
import { useState } from "react";
import { IActivity, IActivityDependency } from "../../../../models/activity";
import {
  IProcessDependency,
  IProcessDetails,
} from "../../../../models/process";
import { getDeepCopy } from "../../../../util/javascript-functions";
import { getProcessDetails } from "../../../../redux/actions/process-actions";
import { startGetProcessList } from "../../process.service";
import Toggle from "../../../common/toggle/toggle.controller";
import {
  startGetProcessDetails,
  startSaveProcess,
} from "../process-details.service";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import {
  addActivityClickEvent,
  addSubProcessClickEvent,
  addUpdateProcessClickEvent,
} from "../process-details.telemtetry-constants";

type Props = IEditProcessPanelProps &
  IEditProcessPanelLinkDispatchProps &
  IEditProcessPanelLinkStateProps;

const buttonStyles = { root: { marginRight: 8 } };

const EditProcessPanel: React.FunctionComponent<Props> = (props) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(
    false
  );
  const [isSubProcessLevel, updateSubProcessLevel] = useState<boolean>(false);
  const [isToggleEnabled, updateIsToggleEnabled] = useState<boolean>(false);
  const [allActivityList, updateAllActivityList] = useState<IComboBoxOption[]>(
    []
  );
  const [existingNodeList, updateExistingNodeList] = useState<
    IComboBoxOption[]
  >([]);
  const [allProcessList, updateAllProcessList] = useState<IComboBoxOption[]>(
    []
  );
  const [childDependencies, updateChildDependencies] = useState<number[]>([]);
  const [parentDependencies, updateParentDependencies] = useState<number[]>([]);
  const [selectedProcessId, updateSelectedProcessId] = useState<number>(0);

  useEffect(() => {
    props.startGetActivityList(props.fiscalYear);
    props.startGetProcessList(props.fiscalYear);
  }, []);

  useEffect(() => {
    if (props.activityList) {
      let _allActivities: IComboBoxOption[] = [];
      let exisitngAllActivitiesList = new Map<number, boolean>();
      props.activityList.forEach((activity: IActivity) => {
        if (
          !exisitngAllActivitiesList.has(activity.ActivityId) &&
          activity.IsActive
        ) {
          let _activityDetails: IComboBoxOption = {
            key: activity.ActivityId,
            text: activity.ActivityName,
          };
          _allActivities.push(_activityDetails);
          exisitngAllActivitiesList.set(activity.ActivityId, true);
        }
      });
      updateAllActivityList(_allActivities);
    }
  }, [props.activityList]);

  useEffect(() => {
    if (props.processList) {
      let _allProcesses: IComboBoxOption[] = [];
      let exisitngAllProcessesList = new Map<number, boolean>();
      props.processList.forEach((process: IProcessDetails) => {
        if (
          !exisitngAllProcessesList.has(process.ProcessId) &&
          process.IsSubProcess &&
          process.IsActive
        ) {
          let _activityDetails: IComboBoxOption = {
            key: process.ProcessId,
            text: process.ProcessName,
          };
          _allProcesses.push(_activityDetails);
          exisitngAllProcessesList.set(process.ProcessId, true);
        }
      });
      updateAllProcessList(_allProcesses);
    }
  }, [props.processList]);

  useEffect(() => {
    let _isSubProcessLevel = false;
    if (props.processDetails) {
      if (
        (props.processDetails.ChildProcesses &&
          props.processDetails.ChildProcesses.length > 0) ||
        (props.processDetails.Activities &&
          props.processDetails.Activities.length > 0)
      ) {
        updateIsToggleEnabled(false);
      } else {
        updateIsToggleEnabled(true);
      }
      if (
        props.processDetails.ChildProcesses &&
        props.processDetails.ChildProcesses.length > 0
      ) {
        _isSubProcessLevel = true;
      }

      if (_isSubProcessLevel && props.processDetails.ChildProcesses) {
        // Sub Process
        let _exisitngSubProcesses: IComboBoxOption[] = [];
        let exisitngSubProcessesList = new Map<number, boolean>();
        props.processDetails.ChildProcesses.forEach(
          (process: IProcessDetails) => {
            if (!exisitngSubProcessesList.has(process.ProcessId)) {
              let _processDetails: IComboBoxOption = {
                key: process.ProcessId,
                text: process.ProcessName,
              };
              _exisitngSubProcesses.push(_processDetails);
              exisitngSubProcessesList.set(process.ProcessId, true);
            }
          }
        );
        updateExistingNodeList(_exisitngSubProcesses);
      } else {
        // Activities
        let _exisitngActivities: IComboBoxOption[] = [];
        let exisitngActivitiesList = new Map<number, boolean>();
        props.processDetails.Activities!.forEach((activity: IActivity) => {
          if (!exisitngActivitiesList.has(activity.ActivityId)) {
            let _activityDetails: IComboBoxOption = {
              key: activity.ActivityId,
              text: activity.ActivityName,
            };
            _exisitngActivities.push(_activityDetails);
            exisitngActivitiesList.set(activity.ActivityId, true);
          }
        });
        updateExistingNodeList(_exisitngActivities);
      }
      updateSubProcessLevel(_isSubProcessLevel);
    } else {
      updateIsToggleEnabled(true);
    }
  }, [props.processDetails]);

  const onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          text="Save"
          onClick={onSaveClick}
          styles={buttonStyles}
        />
        <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
      </div>
    );
  };

  const onActivitySelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let processId = parseInt(String(option.key));
      updateSelectedProcessId(processId);
    }
  };

  const onParentDependenciesSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let _currentParentDependencies = getDeepCopy(parentDependencies);
      let processId = parseInt(String(option.key));
      if (option.selected === true) {
        _currentParentDependencies.push(processId);
      } else {
        _currentParentDependencies.splice(
          _currentParentDependencies.indexOf(processId),
          1
        );
      }
      updateParentDependencies(_currentParentDependencies);
    }
  };

  const onChildDependenciesSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      let _currentChildDependencies = getDeepCopy(childDependencies);
      let processId = parseInt(String(option.key));
      if (option.selected === true) {
        _currentChildDependencies.push(processId);
      } else {
        _currentChildDependencies.splice(
          _currentChildDependencies.indexOf(processId),
          1
        );
      }
      updateChildDependencies(_currentChildDependencies);
    }
  };

  // ToDo: Add validations for duplicate process selection, overlapping list of proceses/activities in parent and child dependencies
  const onSaveClick = (event?: any) => {
    let _newProcessDefinition: IProcessDetails = getDeepCopy(
      props.processDetails
    );
    if (isSubProcessLevel) {
      // Sub Process
      let _newlyAddedSubProcess: IProcessDetails = getDeepCopy(
        props.processList!.find(
          (process: IProcessDetails) => process.ProcessId === selectedProcessId
        )!
      );

      if (_newProcessDefinition.ChildProcesses === undefined) {
        _newProcessDefinition.ChildProcesses = [];
      }

      if (
        _newProcessDefinition.ChildProcesses.find(
          (p) => p.ProcessId == selectedProcessId
        ) == undefined
      ) {
        _newProcessDefinition.ChildProcesses.push(_newlyAddedSubProcess);
      }
    } else {
      // Activity
      let _newlyAddedActivity: IActivity = getDeepCopy(
        props.activityList!.find(
          (activity: IActivity) => activity.ActivityId === selectedProcessId
        )!
      );

      if (_newProcessDefinition.Activities === undefined) {
        _newProcessDefinition.Activities = [];
      }

      if (
        _newProcessDefinition.Activities.find(
          (p) => p.ActivityId == selectedProcessId
        ) == undefined
      ) {
        _newProcessDefinition.Activities.push(_newlyAddedActivity);
      }
    }
    // Call action to update state.process.processDetails
    props.getProcessDetails(_newProcessDefinition);
  };

  const saveProcessCallback = () => {
    // Todo: Retreive the right Process DetailsRowGlobalClassNames. In case of adding a new process?
    props.startGetProcessDetails(
      props.processDetails ? props.processDetails.ProcessId : 0,
      props.updateIsFetchingDetails
    );
    dismissPanel();
  };

  const errorCallback = () => {
    props.updateIsFetchingDetails(false);
  };

  const runValidation = (): boolean => {
    let isValidationSucessful: boolean = true;
    //Checking the length of Process name
    if (
      props.processDetails != undefined &&
      props.processDetails.ProcessId == 0 &&
      props.processDetails.ProcessName.length > 40
    ) {
      window.alert("Process name must not exceed the limit of 40 characters.");
      isValidationSucessful = false;
      return isValidationSucessful;
    }

    return isValidationSucessful;
  };

  const onSaveAddUpdateProcessClick = () => {
    console.log(props.processDetails); //FOR DEBUGGING

    const onSaveConfirmation = window.confirm(
      "Are you sure you want to save the below changes?"
    );
    if (onSaveConfirmation) {
      // TODO: Adding validation checks.
      let _isValidationSucessful = runValidation();

      let _processDetails: IProcessDetails = getDeepCopy(props.processDetails);
      if (_isValidationSucessful) {
        props.updateIsFetchingDetails(true);
        props.startSaveProcess(_processDetails, saveProcessCallback, errorCallback);
      }
    }
  };

  const onToggle = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    updateSubProcessLevel(!isSubProcessLevel);
  };

  return (
    <div>
      <br />
      {props.isEditMode == true ? (
        <div>
          <span style={{ float: "left" }}>
            <ComboBox
              ariaLabel={"Add Activity/SubProcess"}
              className="saved-activities-dropdown"
              label={""}
              placeholder={
                isSubProcessLevel
                  ? "Type/select a Sub Process you want to add."
                  : "Type/select an Activity you want to add."
              }
              options={isSubProcessLevel ? allProcessList : allActivityList}
              onChange={onActivitySelectionChange}
            />
          </span>

          <span style={{ float: "left", paddingLeft: "10px" }}>
            <PrimaryButton
              className="add-subprocess-button"
              text={isSubProcessLevel ? "Add Sub Process" : " Add Activity"}
              onClick={() => {
                if (isSubProcessLevel)
                  TelemetryProvider._trackEvent(addSubProcessClickEvent);
                else TelemetryProvider._trackEvent(addActivityClickEvent);
                onSaveClick();
              }}
            />
          </span>

          <span
            style={{ float: "left", paddingLeft: "10px", marginTop: "-15px" }}
          >
            <Toggle
              onText="Activity"
              offText="Sub Process"
              onChange={onToggle}
              checked={!isSubProcessLevel}
              disabled={!isToggleEnabled}
            />
          </span>

          <span style={{ float: "right" }}>
            <PrimaryButton
              text="Save"
              onClick={() => {
                TelemetryProvider._trackEvent(addUpdateProcessClickEvent);

                onSaveAddUpdateProcessClick();
              }}
            />
          </span>
          <br />
          <br />
        </div>
      ) : (
        ""
      )}

      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        headerText={isSubProcessLevel ? "Add Sub Process" : "Add Activity"}
        type={PanelType.custom}
        customWidth={"450px"}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <br />
        <Toggle
          onText="Activity"
          offText="Sub Process"
          onChange={onToggle}
          checked={!isSubProcessLevel}
          disabled={!isToggleEnabled}
        />
        <div>
          <ComboBox
            className="saved-activities-dropdown"
            label={isSubProcessLevel ? "Sub Processes" : "Activities"}
            placeholder="Type/select an Activity you want to add."
            options={isSubProcessLevel ? allProcessList : allActivityList}
            onChange={onActivitySelectionChange}
          />
          <ComboBox
            className="saved-activities-dropdown"
            label={
              isSubProcessLevel ? "Parent Sub Process" : "Parent Activities"
            }
            placeholder="Select all steps that must succeed before the current step."
            multiSelect
            options={existingNodeList}
            onChange={onParentDependenciesSelectionChange}
          />
          <ComboBox
            className="saved-activities-dropdown"
            label={isSubProcessLevel ? "Child Sub Process" : "Child Activities"}
            placeholder="Select all steps that will commence after the current step."
            multiSelect
            options={existingNodeList}
            onChange={onChildDependenciesSelectionChange}
          />
        </div>
      </Panel>
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IEditProcessPanelProps
): IEditProcessPanelLinkStateProps => {
  return {
    processDetails: state.process.processDetails,
    activityList: state.activity.activityList,
    processList: state.process.processList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IEditProcessPanelProps
): IEditProcessPanelLinkDispatchProps => ({
  startGetActivityList: bindActionCreators(startGetActivityList, dispatch),
  startGetProcessList: bindActionCreators(startGetProcessList, dispatch),
  startGetProcessDetails: bindActionCreators(startGetProcessDetails, dispatch),
  getProcessDetails: bindActionCreators(getProcessDetails, dispatch),
  startSaveProcess: bindActionCreators(startSaveProcess, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProcessPanel);
