import * as React from "react";
import { IDetailsListPropsCustom } from "./models/IDetailsList";
import DetailsListView from "./details-list.view";

export class DetailsListController extends React.Component<
  IDetailsListPropsCustom,
  React.StatelessComponent
> {
  constructor(props: IDetailsListPropsCustom) {
    super(props);
  }

  render() {
    return (
      <DetailsListView
        items={this.props.items}
        columns={this.props.columns}
        selectionMode={this.props.selectionMode}
        selectionPreservedOnEmptyClick={
          this.props.selectionPreservedOnEmptyClick
        }
        onItemInvoked={this.props.onItemInvoked}
        onRenderDetailsHeader={this.props.onRenderDetailsHeader}
        enableShimmer={this.props.enableShimmer}
        shimmerLines={this.props.shimmerLines}
        selection={this.props.selection}
        setKey={this.props.setKey}
        checkButtonAriaLabel={this.props.checkButtonAriaLabel}
        onRenderRow={this.props.onRenderRow}
      />
    );
  }
}
export default DetailsListController;
