import * as React from "react";
import { IIconButtonPropsCustom } from "./models/IIconButon";
import IconButtonView from "./icon-button.view";

const IconButonController = (Props: IIconButtonPropsCustom) => {
  return (
    <IconButtonView
      iconName={Props.iconName}
      fontSize={Props.fontSize}
      title={Props.title}
      onClick={Props.onClick}
      disabled={Props.disabled}
      ariaLabel={Props.ariaLabel}
      styles={Props.styles}
      onMouseOver={Props.onMouseOver}
    />
  );
};

const IconButton = (Props: IIconButtonPropsCustom) => {
  return IconButonController(Props);
};
export default IconButton;
