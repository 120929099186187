import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
//import { getRunScheduleFiltersDetails } from "./run-schedule-api-mock";
import {
  IRunScheduleData,
  IRunTemplateSchedule,
  IRunScheduleFiltersData,
} from "../models/run-schedule";
import { GetToken } from "../auth/authorization-token";

const baseUrl = Configurations.mintHubApiBaseUrl + "/Template/";
var authToken = "";

export function getRunScheduleFilters() {
  // authToken = (async () => GetToken());
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getRunTemplateSchedule(
  runScheduleFiltersData: IRunScheduleFiltersData
) {
  const startDate = JSON.parse(
    JSON.stringify(runScheduleFiltersData.StartDate)
  );
  const endDate = JSON.parse(JSON.stringify(runScheduleFiltersData.EndDate));
  const scheduleUrl =
    Configurations.mintHubApiBaseUrl +
    "/TemplateSchedule?runTemplateId=" +
    String(runScheduleFiltersData.RunTemplateId) +
    "&startDateTime=" +
    startDate +
    "&endDateTime=" +
    endDate;
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(scheduleUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function saveRunTemplateSchedule(
  runTemplateScheduleData: IRunScheduleData
) {
  const url = Configurations.mintHubApiBaseUrl + "/TemplateSchedule";
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify(runTemplateScheduleData),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRunTemplateSchedule(runTemplateScheduleId: number) {
  return fetch(baseUrl + runTemplateScheduleId, { method: "DELETE" })
    .then(handleResponse)
    .catch(handleError);
}
