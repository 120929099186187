import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IActivity } from "../models/activity";
import { GetToken } from "../auth/authorization-token";
import { getCookie } from "./cookie-token";

const baseUrl = Configurations.mintHubApiBaseUrl + "/Activity/";
const listBaseUrl = Configurations.mintHubApiBaseUrl + "/Activity?fiscalYear=";
var authToken = "";
var csrfToken = getCookie("XSRF-TOKEN");

export function getActivities(fiscalYear: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(listBaseUrl + fiscalYear, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
          "X-CSRF-TOKEN": csrfToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getActivityDetails(activityId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + activityId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
          "X-CSRF-TOKEN": csrfToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getActivityMetadata(fiscalYear: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "GetActivityMetadata?fiscalYear=" + fiscalYear, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
          "X-CSRF-TOKEN": csrfToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getActivityColumns(activityId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "GetActivityColumns?activityId=" + activityId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

// Create or Update an activity
export function saveActivity(activity: IActivity) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: activity.ActivityId === 0 ? "PUT" : "POST", // PUT for create, POST to update when id already exists.
        headers: {
          Authorization: "Bearer " + authToken,
          "content-type": "application/json",
          "X-CSRF-TOKEN": csrfToken,
        },
        body: JSON.stringify(activity),
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteActivity(activityId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + activityId, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authToken,
          "X-CSRF-TOKEN": csrfToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
  // return fetch(baseUrl + activityId, { method: "DELETE" })
  //   .then(handleResponse)
  //   .catch(handleError);
}
