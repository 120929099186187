import * as React from "react";
import { IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import {
  IRunTemplateSchedule
} from "../../../models/run-schedule";
import DetailListWithStickyHeader from "../detail-list-with-add-delete/detail-list-with-sticky-header";
import {
  ScrollablePane,
  ScrollbarVisibility,
  FontIcon,
} from "@fluentui/react";
import "./previewgrid.scss";
import PrimaryButton from "../../common/button/primary-button/primary-button.controller";
import * as XLSX from "xlsx";
import { AppState } from "../../../redux/configureStore";
import {
  PreviewGridLinkDispatchProps,
  IPreviewGridProps,
  PreviewGridLinkStateProps,
} from "../crediting-preview/models/IPreviewGrid";
import { AppActions } from "../../../redux/types/app-actions";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { updateRunTemplateScheduleColumnData } from "../../../redux/actions/run-schedule-actions";
import { connect } from "react-redux";
import { getDeepCopy } from "../../../util/javascript-functions";

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: string;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
}

export interface IRunSchedulePreviewData {
  runScheduledata: IRunTemplateSchedule;
  //runTemplateScheduleData: IRunScheduleData;
  //updateRunTemplateScheduleData: (data: any) => void;
}

type Props = IPreviewGridProps &
  PreviewGridLinkDispatchProps &
  PreviewGridLinkStateProps &
  IRunSchedulePreviewData;

class CreditingPreviewGrid extends React.Component<Props> {
  private _columns: IColumn[];
  private RunTemplateScheduleId = this.props.runScheduledata
    .RunTemplateScheduleId;
  //private file: any = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      runScheduledata: props.runScheduledata,
    };
    this._columns = [
      {
        key: "column1",
        name: "Config Name",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: "column2",
        name: "Measure List",
        fieldName: "measurelist",
        minWidth: 550,
        maxWidth: 600,
        isResizable: true,
      },
      {
        key: "column3",
        name: "Incentive Plan List",
        fieldName: "incentiveplan",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: "column4",
        name: "Excel Upload",
        fieldName: "excelupload",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
    ];
  }

  updateState(scheduleData: IRunTemplateSchedule[]) {
    this.props.updateRunTemplateScheduleData({
      RunTemplateSchedule: scheduleData,
    });
  }

  exceluploadfunc = (
    processid: number,
    processname: string,
    filename: string
  ) => {
    //this.excelUploadProcessId = processid;

    return processname.toLowerCase().includes("adhoc") ? (
      <div>
        <span>
          <button
            title="Download"
            onClick={() =>
              this._onDownloadClicked(processid, processname, filename)
            }
          >
            {" "}
            <FontIcon iconName="Download"></FontIcon>
          </button>{" "}
          &nbsp;
        </span>
        <input
          type="file"
          className="fileclass"
          id={"file" + processid}
          accept=".xlsx,xls"
          style={{ display: "none" }}
          onChange={(e) => this._onUploadClicked(e, processid, processname)}
        />
        <input
          type="button"
          value="Upload File"
          onClick={(e) => document.getElementById("file" + processid)?.click()}
          disabled={
            new Date(this.props.runScheduledata.ScheduleDateTime) < new Date()
          }
        />
        <br />
        <span title={filename}>{filename}</span>
      </div>
    ) : (
      <></>
    );
  };

  s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  download = (url: any, name: any) => {
    let a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  _onDownloadClicked = (
    processid: number,
    processname: string,
    filename: string
  ): void => {
    var data: any = [];
    /*
    [{ ParameterId: 96, ParameterName: "PerspectiveNameIncludeList", ParameterValue: "ABC,DEF,GHI", },
    { ParameterId: 97, ParameterName: "PerspectiveNameExcludeList", ParameterValue: "EXC1,EXC2", },
    { ParameterName: "PerspectiveNameExcludeList", ParameterValue: "EXC1,EXC2", },
    ];
    */
    var schedule: IRunTemplateSchedule[] = getDeepCopy(
      this.props.runTemplateScheduleData.RunTemplateSchedule
    );

    var index = this.props.runTemplateScheduleData.RunTemplateSchedule.findIndex(
      (a: IRunTemplateSchedule) => {
        return a.RunTemplateScheduleId == this.RunTemplateScheduleId;
      }
    );

    schedule[index].Processes.forEach((process) => {
      if (process.ProcessName.includes("Credit")) {
        process.ChildProcesses?.forEach((subprocess) => {
          if (subprocess.ProcessId == processid) {
            data = subprocess.Parameters;
          }
        });
      }
    });

    var excelData: { [name: string]: string }[] = [];
    var paramsToInclude = [
      "-PPerspectiveNameIncludeList",
      "-PPerspectiveNameExcludeList",
      "-PPlanComponentIDIncludeList",
      "-PPlanComponentIDExcludeList",
      "-PParticipationIDIncludeList",
      "-PParticipationIDExcludeList",
      "-PPersonnelNumberIncludeList",
      "-PPersonnelNumberExcludeList",
      "-PFiscalMonthIDIncludeList",
      "-PFiscalMonthExIncludeList",
      "-PTerritoryIDIncludeList",
      "-PTerritoryIDExcludeList",
      "-PCountryCodeIncludeList",
      "-PCountryCodeExcludeList",
      "-PRevMapNameIncludeList",
      "-PRevMapNameExcludeList",
      "-PEmailAliasIncludeList",
      "-PEmailAliasExcludeList",
    ];
    if (!filename) {
      filename = "CreditingParameters.xlsx";
      excelData[0] = {};
      data.forEach((item: any) => {
        if (paramsToInclude.includes(item.ParameterName)) {
          excelData[0][item.ParameterName.substring(2)] = "";
        }
      });
    } else {
      data.forEach((item: any) => {
        if (paramsToInclude.includes(item.ParameterName)) {
          item.ParameterValue.split(",").forEach((i: any, index: number) => {
            if (!excelData[index]) excelData[index] = {};
            excelData[index][item.ParameterName.substring(2)] = i;
          });
        }
      });
    }

    var SheetNames: any[] = [];
    var Sheets: { [paramname: string]: any } = {};
    var wb = { SheetNames, Sheets };
    const ws = XLSX.utils.json_to_sheet(excelData);
    wb.SheetNames.push("CreditingParams");
    wb.Sheets["CreditingParams"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    let url = window.URL.createObjectURL(
      new Blob([this.s2ab(wbout)], { type: "application/octet-stream" })
    );
    this.download(url, filename);
  };

  _onUploadClicked = (e: any, processid: number, processname: string): void => {
    const files = e.target.files;
    var file: any = {};
    if (files && files[0]) file = files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);

      var filtersList: { [paramname: string]: any[] } = {
        "-PPerspectiveNameIncludeList": [],
        "-PPerspectiveNameExcludeList": [],
        "-PPlanComponentIDIncludeList": [],
        "-PPlanComponentIDExcludeList": [],
        "-PParticipationIDIncludeList": [],
        "-PParticipationIDExcludeList": [],
        "-PPersonnelNumberIncludeList": [],
        "-PPersonnelNumberExcludeList": [],
        "-PFiscalMonthIDIncludeList": [],
        "-PFiscalMonthExIncludeList": [],
        "-PTerritoryIDIncludeList": [],
        "-PTerritoryIDExcludeList": [],
        "-PCountryCodeIncludeList": [],
        "-PCountryCodeExcludeList": [],
        "-PRevMapNameIncludeList": [],
        "-PRevMapNameExcludeList": [],
        "-PEmailAliasIncludeList": [],
        "-PEmailAliasExcludeList": [],
      };

      data.forEach((item: any) => {
        for (let key in filtersList) {
          item[key.substring(2)] &&
            filtersList[key].push(item[key.substring(2)]);
        }
      });

      var schedule: IRunTemplateSchedule[] = getDeepCopy(
        this.props.runTemplateScheduleData.RunTemplateSchedule
      );

      var index = schedule.findIndex((a: IRunTemplateSchedule) => {
        return a.RunTemplateScheduleId == this.RunTemplateScheduleId;
      });

      schedule[index].Processes.forEach((process) => {
        if (process.ProcessName.includes("Credit")) {
          process.ChildProcesses?.forEach((subprocess) => {
            if (subprocess.ProcessId == processid) {
              subprocess.Parameters?.forEach((param) => {
                if (param.ParameterName == "-PFileName") {
                  param.ParameterValue = file.name;
                } else if (filtersList[param.ParameterName]) {
                  param.ParameterValue = filtersList[
                    param.ParameterName
                  ].toString();
                }
              });
            }
          });
        }
      });

      this.updateState(schedule);
    };
  };

  public render(): JSX.Element {
    var allItems: any = [];
    var scheduleTime = this.props.runScheduledata.ScheduleDateTime;
    var measurelistname = "";
    var incentiveplanlist = "";
    var filename = "";

    this.props.runScheduledata.Processes.forEach((process) => {
      if (process.ProcessName.includes("Credit")) {
        process.ChildProcesses?.forEach((subprocess) => {
          measurelistname = "";
          incentiveplanlist = "";
          filename = "";
          subprocess.Parameters?.forEach((param) => {
            if (param.ParameterName == "-PMeasureIncludeList") {
              measurelistname = param.ParameterValue;
            } else if (param.ParameterName == "-PContextType") {
              incentiveplanlist = param.ParameterValue;
            } else if (param.ParameterName == "-PFileName") {
              filename = param.ParameterValue;
            }
          });
          allItems.push({
            key: subprocess.ProcessId,
            name: subprocess.ProcessName,
            measurelist: measurelistname,
            incentiveplan: incentiveplanlist,
            excelupload: this.exceluploadfunc(
              subprocess.ProcessId,
              subprocess.ProcessName,
              filename
            ),
          });
        });
      }
    });
    return (
      <ScrollablePane
        className="run-schedule-grid previewgrid"
        scrollbarVisibility={ScrollbarVisibility.auto}
      >
        <DetailListWithStickyHeader
          items={allItems}
          columns={this._columns}
          selectionMode={SelectionMode.none}
          selectionPreservedOnEmptyClick={true}
        />
      </ScrollablePane>
    );
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: IPreviewGridProps
): PreviewGridLinkStateProps => {
  return {
    runTemplateScheduleData: state.runSchedule,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IPreviewGridProps
): PreviewGridLinkDispatchProps => ({
  updateRunTemplateScheduleData: bindActionCreators(
    updateRunTemplateScheduleColumnData,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditingPreviewGrid);
