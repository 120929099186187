import * as React from "react";
import { IRunProcessDetails } from "./models/IRunDetailsGrid";
import { ITooltipProps, ITooltipHostStyles } from "@fluentui/react";

export function getTooltipProps(item: IRunProcessDetails): ITooltipProps {
  let tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <ul style={{ margin: 10, padding: 0 }}>
        <li>{item.ProcessInfo}</li>
      </ul>
    ),
  };
  return tooltipProps;
}

export const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
