export function getSelectedDates(startDate: Date, endDate: Date, days: any[]) {
  let datesSelected: any[] = [];
  let month = startDate.getMonth();
  let year = startDate.getFullYear();
  let selectedDates = days.filter((a) => {
    return a >= startDate.getDate() && a <= endDate.getDate();
  });
  selectedDates.forEach((d) => datesSelected.push(new Date(year, month, d)));
  return datesSelected;
}
