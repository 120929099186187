import rootReducer from "./reducers";
import { InitialState } from "./../App.initial-state";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { AppActions } from "./types/app-actions";
import { createLocalStorageMiddleware, rehydrateFromLocalStorage, StorageConverter } from "./localstorage-middleware";
import { UPDATE_HIDE_NOT_SCHEDULED } from "./types/template-run-details-action-types";
import { GET_FISCAL_YEAR } from "./types/fiscal-year-action-types";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type AppState = ReturnType<typeof rootReducer>;

const localStorageConverters: StorageConverter[] = [
  new StorageConverter(
    UPDATE_HIDE_NOT_SCHEDULED, // Action type to trigger selector on, also used as local storage key.
    (state: AppState) => state.templateRunDetails.hideNotScheduled, // Value selector to get value to store.
    (state: AppState, value) => ({
      ...state,
      templateRunDetails: {
        ...state.templateRunDetails,
        hideNotScheduled: value,
      }
    }) // State rehydrator to populate state with value from storage.
  ),
  new StorageConverter(
    GET_FISCAL_YEAR,
    (state: AppState) => state.fiscalYear?.fiscalYear,
    (state: AppState, value) => ({
      ...state,
      fiscalYear: {
        ...state.fiscalYear,
        fiscalYear: value,
      }
    }) 
  )
];

// Update InitialState with values from local storage.
var state = rehydrateFromLocalStorage(localStorageConverters, InitialState)

var localStorageMiddleware = createLocalStorageMiddleware(localStorageConverters);

const configureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    state,
    composeEnhancers(
      applyMiddleware(
        reduxThunk as ThunkMiddleware<AppState, AppActions>, // To be analysied further
        reduxImmutableStateInvariant(),
        localStorageMiddleware
      )
    )
  );
};

export default configureStore;
