import * as React from 'react';
import DefaultButtonView from './default-button.view';
import { IButtonPropsCustom } from '../models/IButton';

const DefaultButtonController = (Props: IButtonPropsCustom) => {
    return <DefaultButtonView 
                text={Props.text}
                onClick={Props.onClick} 
                disabled={Props.disabled} 
                />;
}

const DefaultButton = (Props: IButtonPropsCustom) => {
    return DefaultButtonController(Props);
}
export default DefaultButton;

