import React from "react";
import { configureFeatureFlighting } from "../../featureFlightingConfig";
import SideNavBar from "../common/side-nav-bar-panel/side-nav-bar";
import nodeList from "../common/side-nav-bar-panel/nav-bar-data/node-list";
import MintHeader from "../common/header/mint-header";
import { Switch, Route } from "react-router-dom";
import "./mint-hub-container.scss";
import SourceOnboardingList from "../onboarding/source-onboarding/source-onboarding.controller";
import AccountContext from "../../auth/account-context";
import HorizontalNavBar from "../common/horizontal-nav-bar/horizontal-nav-bar.controller";
import RunSchedule from "../run-schedule/run-schedule.controller";
import UnderConstruction from "../under-construction/under-construction";
import Loading from "../loading/loading";
import RecurrencePatternController from "../common/recurrence-pattern/recurrence-pattern.controller";
import Activity from "../activity/activity.controller";
import Process from "../process/process.controller";
import RunTemplate from "../run-template/run-template.controller";
import ProcessDependency from "../process/process-details/process-details.controller";
import RunTemplateDependency from "../run-template/run-template-details/run-template-details.controller";
import { ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import RunDashboardV2 from "../run-dashboard-v2/run-dashboard-v2.controller";

const MintHubContainer = (props: any) => {
  const flightedFeatures = configureFeatureFlighting();
  return (
    <div className="mint-hub-container">
      <div className="mint-hub-header-wrapper">
        <SideNavBar node_data={nodeList} {...props} />
        <AccountContext.Consumer>
          {(data) => <MintHeader account={data} />}
        </AccountContext.Consumer>
      </div>
      <div className="horizontal-nav-bar-placeholder">
        <HorizontalNavBar {...props} />
      </div>

      <ScrollablePane
        className="component-scroll-config"
        scrollbarVisibility={ScrollbarVisibility.auto}
      >
        <div className="component-placeholder">
          <Switch>
            <Route exact path="/" component={RunDashboardV2}></Route>
            <Route
              path="/system/source-onboarding"
              component={SourceOnboardingList}
            ></Route>
            <Route
              path="/orchestration/run-schedule"
              component={RunSchedule}
            ></Route>
            <Route
              path="/orchestration/run-dashboard"
              component={RunDashboardV2}
            ></Route>
            <Route path="/orchestration/activity" component={Activity}></Route>
            <Route path="/orchestration/process" component={Process}></Route>
            <Route
              path="/orchestration/run-template"
              component={RunTemplate}
            ></Route>
            <Route
              path="/orchestration/process-details"
              component={ProcessDependency}
            ></Route>
            <Route
              path="/orchestration/run-template-details"
              component={RunTemplateDependency}
            ></Route>
          </Switch>
        </div>
      </ScrollablePane>
    </div>
  );
};
export default MintHubContainer;
