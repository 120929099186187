import React from "react";
import { getDeepCopy } from "../../../../../util/javascript-functions";
import { TextField } from "@fluentui/react";
import {
  broadTextFieldStyles,
  narrowTextFieldStyles,
} from "../../recurrence-pattern.style";
import { IMonthlyProps } from "../../model/IRecurrenceRulePatternProps";

const MonthlyView: React.FunctionComponent<IMonthlyProps> = (props) => {
  const { monthlyDatesSelection, selectedMonthDatesConfig } = props;
  const onMonthDatesConfigSetup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    let configDates = getDeepCopy(selectedMonthDatesConfig);
    configDates.dates = newValue;
    monthlyDatesSelection(configDates);
  };

  const onMonthFrequencyConfigSetup = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    let configDates = getDeepCopy(selectedMonthDatesConfig);
    configDates.frequency = newValue;
    monthlyDatesSelection(configDates);
  };
  return (
    <div className="flex-box center-box">
      <div>Days</div>
      <TextField
        title="days"
        value={selectedMonthDatesConfig?.dates}
        onChange={onMonthDatesConfigSetup}
        styles={broadTextFieldStyles}
        className="control-style"
      />
      <div>of every</div>
      <TextField
        title="monthly"
        value={selectedMonthDatesConfig?.frequency}
        onChange={onMonthFrequencyConfigSetup}
        styles={narrowTextFieldStyles}
        className="control-style"
      />
      month(s)
    </div>
  );
};

export default MonthlyView;
