import { getDeepCopy } from "../../../../../util/javascript-functions";
import { getSelectedDates } from "../../shared/util";

export function monthlyPatternControllerService(
  startDate: Date,
  endDate: Date,
  ruleStartDate: Date,
  selectedMonthDatesConfig: {frequency:string,dates:string}
) {
  let config = getDeepCopy(selectedMonthDatesConfig);
  let days: any[] = config.dates.split(",");
  let month = startDate.getMonth();
  let year = startDate.getFullYear();

  // base conditions
  if (config.frequency == 0 || config.frequency == "" || days.length == 0) {
    return [];
  }

  // if frequency =1, it is for every month
  // start and end date would be of a month or less
  if (config.frequency == 1) {
    return getSelectedDates(startDate, endDate, days);
  }

  // frequency greater than 1
  // we need rule start date as well, to determine if the filter month/year is present
  let ruleStartDateMonth = ruleStartDate.getMonth();
  let ruleStartDateYear = ruleStartDate.getFullYear();
  let firstDateOfStartMonth = new Date(year, month, 1);
  let eligible = new Date(ruleStartDateYear, ruleStartDateMonth, 1);
  eligible.setMonth(eligible.getMonth() + config.frequency - 1);

  while (eligible <= firstDateOfStartMonth) {
    if (eligible.toString() == firstDateOfStartMonth.toString()) {
      return getSelectedDates(startDate, endDate, days);
    } else {
      eligible.setMonth(eligible.getMonth() + parseInt(config.frequency));
    }
  }
  return [];
}
