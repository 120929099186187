import { ITextFieldStyles, Stack, TextField } from "@fluentui/react";
import React, { Component, useEffect } from "react";
import "./run-template-info.style.scss";
import {
  IRunTemplateInfoProps,
  IRunTemplateInfoLinkStateProps,
  IRunTemplateInfoLinkDispatchProps
} from "./models/IRunTemplateInfo";
import { DefaultEffects } from "@fluentui/theme";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import Checkbox from "../../../common/checkbox/checkbox.controller";
import { getDeepCopy } from "../../../../util/javascript-functions";
import { bindActionCreators } from "redux";
import { getRunTemplateDetails } from "../../../../redux/actions/run-template-actions";
import { IRunTemplateDetails } from "../../../../models/run-template";

type Props = IRunTemplateInfoProps & IRunTemplateInfoLinkStateProps & IRunTemplateInfoLinkDispatchProps ;

const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 350 },
};

export const RunTemplateInfo: React.FunctionComponent<Props> = (props) => {
  useEffect(() => {}, []);

  const updateFiscalYear = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedRunTemplateDetails: IRunTemplateDetails = getDeepCopy(
      props.runTemplateDetails
    );
    updatedRunTemplateDetails.FiscalYear = newValue ? parseInt(newValue, 10) : 0;
    props.getRunTemplateDetails(updatedRunTemplateDetails);
  };

  const updaterunTemplateName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.defaultPrevented = true;
    let updatedRunTemplateDetails: IRunTemplateDetails = getDeepCopy(
      props.runTemplateDetails
    );
    updatedRunTemplateDetails.RunTemplateName = newValue ? newValue : "";
    props.getRunTemplateDetails(updatedRunTemplateDetails);
  };

    const updateIsMasterCheckbox = (
      event?: React.FormEvent<HTMLElement | HTMLInputElement>, 
      checked?: boolean
    ) => {
        if (event != undefined)
            event.defaultPrevented = true;
        let updatedRunTemplateDetails: IRunTemplateDetails = getDeepCopy(
        props.runTemplateDetails
        );
        updatedRunTemplateDetails.IsMaster = checked ? checked : false;
        props.getRunTemplateDetails(updatedRunTemplateDetails);
    }


  if (props.runTemplateDetails !== undefined) {
    if (props.isEditMode) {
      return (
        <>
          <div
            style={{
              boxShadow: DefaultEffects.elevation16,
              padding: "10px",
              height: "30px",
            }}
          >
            <div>
              <div className="info-item">
                <b>Run Template Name: </b>
                <span style={{ display: "inline-block", width: "50%" }}>
                  <TextField
                    value={props.runTemplateDetails.RunTemplateName}
                    onChange={updaterunTemplateName}
                    ariaLabel = {"Run Template Name"}
                    disabled = {props.runTemplateDetails.RunTemplateID == 0 ? false : true}
                    //styles={{textFieldStyles}}
                  />
                </span>
              </div>
              <div className="info-item">
                <b>Fiscal Year: </b>
                <span style={{ display: "inline-block", width: "50%" }}>
                  <TextField
                    value={props.runTemplateDetails.FiscalYear + ""}
                    contentEditable={false}
                    readOnly={true}
                    onChange={updateFiscalYear}
                    ariaLabel= {"Fiscal Year"}
                    //styles={textFieldStyles}
                  />
                </span>
              </div>
              <div className="info-item">
                <b>Is Master: </b>
                <span style={{ display: "inline-block" }}>
                  <Checkbox
                    checked={props.runTemplateDetails.IsMaster}
                    onChange={updateIsMasterCheckbox}
                    title={"IsMaster"}
                  />
                </span>
              </div>
            </div>
        </div>       
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              boxShadow: DefaultEffects.elevation16,
              padding: "10px",
              height: "30px",
            }}
          >
            <div>
              <div className="info-item">
                <b>Run Template Name:</b>
                {" " + props.runTemplateDetails.RunTemplateName}
              </div>
              <div className="info-item">
                <b>Fiscal Year:</b>
                {" " + props.runTemplateDetails.FiscalYear}
              </div>
              <div className="info-item">
                <b>Is Master:</b>
                {props.runTemplateDetails.IsMaster ? " Yes" : " No"}
              </div>
            </div>
          </div>
        </>
      );
    }
  } else return <></>;
};

const mapStateToProps = (
  state: AppState,
  ownProps: IRunTemplateInfoProps
): IRunTemplateInfoLinkStateProps => {
  return {
    runTemplateDetails: state.runTemplate.runTemplateDetails,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunTemplateInfoProps
): IRunTemplateInfoLinkDispatchProps => ({
  getRunTemplateDetails: bindActionCreators(getRunTemplateDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunTemplateInfo);
