import { Dialog, DialogFooter, DefaultButton, DialogType, DirectionalHint, FontIcon, IColumn, Modal, PrimaryButton, SelectionMode, Shimmer, Stack, TextField, TooltipDelay, TooltipHost, ShimmerElementsGroup, ShimmerElementType, ActionButton, IDetailsRowProps, DetailsRow, getTheme, ColumnActionsMode, IContextualMenuProps, ContextualMenu, IContextualMenuItem, IDetailsColumnFilterIconProps } from "@fluentui/react";
import React from "react";
import AnimateHeight from "react-animate-height";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ITemplateRunDetails, ITemplateRunProcesses } from "../../../models/template-run";
import { UpdateHideNotScheduled, markProcessAsComplete, performTemplateRunAction, updateIsTemplateRunDetailsShown } from "../../../redux/actions/template-run-details-actions";
import { AppState } from "../../../redux/configureStore";
import { IAuthControls } from "../../../redux/reducers/user-profile-reducer";
import { AppActions } from "../../../redux/types/app-actions";
import { utcToFormattedDateTime } from "../../../util/time-zone-util";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import DetailsListController from "../../common/details-list/details-list.controller";
import { contentStyles, iconButtonStyles } from "../../common/modal/modal.style";
import { hostStyles } from "../run-details/run-details-grid/run-details-grid.helper";
import TemplateRunCommandBar from "./template-run-commandbar-controller";

const theme = getTheme();

interface ITemplateRunDetailsLinkStateProps {
  selectedTemplateDetails?: ITemplateRunDetails,
  showTemplateRunDetails: boolean,
  isLoading: boolean,
  hideNotScheduled: boolean,
  userProfile: {
    userName?: string,
    authControls?: IAuthControls
  },
}

interface ITemplateRunDetailsLinkDispatchProps {
  updateIsTemplateRunDetailsShown: (isShown: boolean) => void;
  markProcessAsComplete: (processId: number, runTemplateScheduleID: number) => void;
  performTemplateRunAction: (action: dialogActions, runTemplateScheduleID: number, reason: string, userAlias: string) => void;
  updateHideNotScheduled: (hideNotScheduled: boolean) => void;
}

interface ITemplateRunDetailsProps { }

type Props = ITemplateRunDetailsLinkStateProps
  & ITemplateRunDetailsLinkDispatchProps
  & ITemplateRunDetailsProps;

interface ITemplateRunDetailsState {
  currentDialogState: IDialogState | null,
  isDialogVisible: boolean,
  expandedProcesses: Set<number>,
  contextMenuProps: IContextualMenuProps | undefined,
}

interface IDialogState {
  title: string,
  subtext: string,
  showInput?: boolean,
  action?: () => void,
  reason?: string,
  showReasonError?: boolean,
}

export type dialogActions = "mark complete" | "retrigger" | "cancel" | "pause" | "resume";

export class TemplateRunDetails extends React.Component<Props, ITemplateRunDetailsState> {
  constructor(props: Props) {
    super(props);
    this.onRenderActions = this.onRenderActions.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.confirmDialog = this.confirmDialog.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
    this.markComplete = this.markComplete.bind(this);
    this.performTemplateRunAction = this.performTemplateRunAction.bind(this);
    this.onRenderRow = this.onRenderRow.bind(this);
    this.toggleIsExpanded = this.toggleIsExpanded.bind(this);
    this.onRenderShowChildrenToggle = this.onRenderShowChildrenToggle.bind(this);
    this.onColumnClick = this.onColumnClick.bind(this);
    this.getChildProcesses = this.getChildProcesses.bind(this);
    this.state = {
      currentDialogState: null,
      isDialogVisible: false,
      expandedProcesses: new Set<number>(),
      contextMenuProps: undefined,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<ITemplateRunDetailsState>, snapshot?: any): void {
    // Clear expandedProcesses when the selectedTemplateDetails changes, but not if an updated version of the same templateRun is still showing,
    // hence the id check instead of a referential check of the details themselves.
    if (prevProps.selectedTemplateDetails?.RunTemplateScheduleId !== this.props.selectedTemplateDetails?.RunTemplateScheduleId) {
      this.setState({
        expandedProcesses: new Set<number>(),
      });
    }
  }

  private getStatusDisplayText(executionStatus: string): string {
    // If the statusMap doesn't have the executionStatus, or the map entry
    // doesn't have a display text, use the executionStatus as the display text.
    return this.statusMap[executionStatus]?.displayText || executionStatus;
  }

  private statusMap: { [key: string]: { color: string, displayText?: string } } = {
    "Failed": { color: "#E42C06" },
    "In Progress": { color: "#006CFF" },
    "Cancelled": { color: "#7A7575" },
    "Completed": { color: "#42860B" },
    "Not Scheduled": { color: "#D3D3D3" },
    "Scheduled": { color: "#666666" },
    "Internal Process": { color: "#666666", displayText: "-" }
  }

  private getColumns(): IColumn[] {
    const currentHideNotScheduled = this.props.hideNotScheduled;
    return this.columns.map((column) => {
      column.isFiltered = column.key === "Status" && currentHideNotScheduled; // Add the filter icon to the Status column if filtering is enabled.
      return column;
    })
  }

  private columns: IColumn[] = [
    {
      key: "ProcessId",
      name: "",
      minWidth: 20,
      maxWidth: 20,
      onRender: (item) => this.onRenderShowChildrenToggle(item),
    },
    {
      key: "ProcessName",
      name: "Process Name",
      fieldName: "ProcessName",
      minWidth: 200,
      isResizable: true,
      onRender: (item: ITemplateRunProcesses) => {

        // Modify process name if the process is Internal Process
        let processName = item.IsInternalProcess ? item.ProcessName + " (Internal Process)" : item.ProcessName;

        let style: React.CSSProperties = {};
        if (this.statusMap[item.Status]?.color) {
          style.color = this.statusMap[item.Status]?.color;
        }
        return (<div style={style}>{processName}</div>)
      },
    },
    {
      key: "StartTime",
      name: "Start Time",
      fieldName: "StartTime",
      minWidth: 200,
      isResizable: true,
      onRender: (item) => utcToFormattedDateTime(item.StartTime) || "-",
    },
    {
      key: "EndTime",
      name: "End Time",
      fieldName: "EndTime",
      minWidth: 200,
      isResizable: true,
      onRender: (item) => utcToFormattedDateTime(item.EndTime) || "-",
    },
    {
      key: "Duration",
      name: "Duration",
      fieldName: "Duration",
      minWidth: 150,
      isResizable: true,
      onRender: (item: ITemplateRunProcesses) => {
        const startTime = new Date(item.StartTime);
        const endTime = new Date(item.EndTime);
        // Makes sure that item.StartTime and item.EndTime are valid. Captures null, undefined, and non-dates.
        if (item.StartTime && item.EndTime && !isNaN(startTime.valueOf()) && !isNaN(endTime.valueOf())) {
          let duration = ((endTime.valueOf() - startTime.valueOf()) / (1000 * 60)).toFixed(0);
          return (<>{duration} min</>)
        } else {
          return (<>-- min</>)
        }
      },
    },
    {
      key: "Status",
      name: "Status",
      fieldName: "Status",
      minWidth: 100,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onColumnClick: (...args) => this.onColumnClick(...args),
      onRenderFilterIcon: (props?: IDetailsColumnFilterIconProps, defaultRenderer?: (props?: IDetailsColumnFilterIconProps) => JSX.Element | null): JSX.Element | null => {
        return defaultRenderer ? defaultRenderer({ ...props, iconName: "GlobalNavButton"}) : <></>;
      },
      onRender: (item) => {
        return (<>
          {this.getStatusDisplayText(item.Status)}
          {item.Message &&
            <TooltipHost
              id={item.ProcessId + "_info"}
              delay={TooltipDelay.zero}
              styles={hostStyles}
              directionalHint={DirectionalHint.leftCenter}
              content={item.Message}
            >
              <FontIcon
                aria-label="Info"
                iconName="Info"
                style={{ fontSize: 16, verticalAlign: "bottom", margin: "0 5px" }}
                title="Info"
              />
            </TooltipHost>}
        </>)
      }
    },
    {
      key: "Actions",
      name: "Actions",
      minWidth: 300,
      isResizable: true,
      onRender: (item) => this.onRenderActions(item),
    }
  ];

  private dialogActionDetails: { [key in dialogActions]: IDialogState } = {
    "mark complete": {
      title: "Mark as Complete?",
      subtext: "Are you sure you want to mark this process as complete?"
    },
    "retrigger": {
      title: "Retrigger run?",
      subtext: "Are you sure you want to retrigger this template run?",
      showInput: true
    },
    "cancel": {
      title: "Cancel run?",
      subtext: "Are you sure you want to cancel this template run?",
      showInput: true
    },
    "pause": {
      title: "Pause run?",
      subtext: "Are you sure you want to pause this template run?",
      showInput: true
    },
    "resume": {
      title: "Resume run?",
      subtext: "Are you sure you want to resume this template run?"
    }
  }

  private markComplete(processId: number): void {
    const id = this.props.selectedTemplateDetails?.RunTemplateScheduleId;
    if (!id) {
      console.error("markComplete: Invalid state. Mark Complete called but RunTemplateScheduleId is not available.");
      return;
    }
    this.props.markProcessAsComplete(processId, id);
  }

  private async performTemplateRunAction(action: dialogActions): Promise<void> {
    const id = this.props.selectedTemplateDetails?.RunTemplateScheduleId;
    const username = this.props.userProfile.userName;
    const reason = this.state.currentDialogState?.reason || "";
    if (!id) {
      console.error(`${action}: Invalid state. RunTemplateScheduleId is not populated.`);
      return;
    }
    if (!username) {
      console.error(`${action}: Invalid state. userName is not populated.`);
      return;
    }
    this.props.performTemplateRunAction(action, id, reason, username);
  }

  private onRenderActions(item: ITemplateRunProcesses): JSX.Element {
    if (item.Status === 'Failed' && !item.IsSubProcess) {
      const shimmerElements = (
        <ShimmerElementsGroup
          shimmerElements={[
            { type: ShimmerElementType.line, width: 20, height: 20 },
            { type: ShimmerElementType.gap, width: 10, height: 20 },
            { type: ShimmerElementType.line, width: "100%", height: 15 }
          ]} />);

      const tooltip = this.props.userProfile?.authControls?.canRestartRetriggerCancel ? "" : "You do not have permissions to perform this action";
      return (
        <Shimmer
          isDataLoaded={!item.IsProcessingAction}
          ariaLabel="Loading Data"
          customElementsGroup={shimmerElements}
          height="21px"
          width="150px">
          <ActionButton
            title={tooltip}
            disabled={!this.props.userProfile?.authControls?.canRestartRetriggerCancel}
            style={{ height: "inherit" }}
            iconProps={{ iconName: "WindowEdit" }}
            text="Mark as Complete"
            onClick={() => this.showDialog(
              "mark complete",
              () => this.markComplete(item.ProcessId)
            )} />
        </Shimmer>
      )
    }
    return (<></>);
  }

  private toggleIsExpanded(processId: number): void {
    const setCopy = new Set(this.state.expandedProcesses);
    if (setCopy.has(processId)) {
      setCopy.delete(processId);
    } else {
      setCopy.add(processId);
    }
    this.setState({
      expandedProcesses: setCopy
    });
  }

  private onRenderShowChildrenToggle(item: ITemplateRunProcesses): JSX.Element {
    const isExpanded = this.state.expandedProcesses.has(item.ProcessId);

    if (item.ChildProcesses.length > 0) {
      return (<ActionButton
        title=""
        text=""
        iconProps={{ iconName: isExpanded ? "ChevronDown" : "ChevronRight" }}
        style={{ height: "inherit" }}
        onClick={() => this.toggleIsExpanded(item.ProcessId)}
      />)
    }
    return (<></>);
  }

  private onColumnClick(ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void {
    if (column.columnActionsMode !== ColumnActionsMode.hasDropdown) {
      return;
    }
    if (this.state.contextMenuProps) {
      this.setState({ contextMenuProps: undefined });
      return;
    }

    var items: IContextualMenuItem[] = [
      {
        key: 'hideNotScheduled',
        text: 'Hide Not Scheduled',
        canCheck: true,
        isChecked: this.props.hideNotScheduled,
        onClick: () => this.toggleHideNotScheduled()
      },
    ];
    var contextProps: IContextualMenuProps = {
      items,
      target: ev.currentTarget,
      onDismiss: () => this.setState({ contextMenuProps: undefined }),
      directionalHint: DirectionalHint.bottomLeftEdge
    }
    this.setState({ contextMenuProps: contextProps });
  }

  private toggleHideNotScheduled(): void {
    this.props.updateHideNotScheduled(!this.props.hideNotScheduled);
  }

  private onRenderRow(props: IDetailsRowProps | undefined): JSX.Element | null {
    if (!props) {
      return <></>;
    }
    const isExpanded = this.state.expandedProcesses.has(props.item.ProcessId);

    const style: React.CSSProperties = {
      borderTopColor: theme.palette.accent,
      borderTopStyle: "solid",
      borderTopWidth: 1,
      borderBottomColor: theme.palette.accent,
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
    };

    const columns = this.getColumns();

    return <>
      <DetailsRow {...props} />
      {props.item?.ChildProcesses.length > 0 &&
        <AnimateHeight
          duration={500}
          height={isExpanded ? "auto" : 0}
        >
          <div style={style}>
            <DetailsListController
              onRenderDetailsHeader={() => <></>}
              items={this.getChildProcesses(props.item)}
              columns={columns}
              selectionMode={SelectionMode.none}
            />
          </div>
        </AnimateHeight>
      }
    </>
  }

  private hideDialog(): void {
    this.setState({
      isDialogVisible: false
    });
  }

  private confirmDialog(): void {
    if (this.state.currentDialogState?.showInput && !this.state.currentDialogState?.reason) {
      // If the input is shown but hasn't been set, show the error message and return without closing the dialog.
      this.setState(prevState => ({
        currentDialogState: prevState.currentDialogState === null ? null : {
          ...prevState.currentDialogState,
          showReasonError: true
        }
      }));
      return;
    }
    if (this.state.currentDialogState?.action) {
      this.state.currentDialogState.action();
    }
    this.hideDialog();
  }

  private showDialog(action: dialogActions, confirmCallback: () => void): void {
    this.setState({
      isDialogVisible: true,
      currentDialogState: {
        ...this.dialogActionDetails[action],
        action: confirmCallback
      }
    });
  }

  private onReasonChange(e: React.FormEvent, newValue?: string) {
    // If currentDialogState is null, we just leave it null, otherwise we copy it and set reason.
    this.setState(prevState => ({
      currentDialogState: prevState.currentDialogState === null ? null : {
        ...prevState.currentDialogState,
        reason: newValue,
        showReasonError: false,
      }
    }))
  }

  private getProcesses() {
    var processes = (this.props.selectedTemplateDetails?.Processes || []).map(i => ({ ...i, isExpanded: this.state.expandedProcesses.has(i.ProcessId) }));
    if (this.props.hideNotScheduled) {
      processes = processes.filter(i => i.Status !== "Not Scheduled");
    }
    return processes;
  }

  private getChildProcesses(process: ITemplateRunProcesses): ITemplateRunProcesses[] {
    if (this.props.hideNotScheduled) {
      return process.ChildProcesses.filter(i => i.Status !== "Not Scheduled");
    }
    return process.ChildProcesses;
  }

  private getTemplateRunStatusDisplayText(executionStatus: string | undefined, isPaused: boolean | undefined): string | undefined {
    // undefined handles the case when selectedTemplateDetails with optional chaining operator results in undefined in line 366

    let displayText = (executionStatus === "InProgress") ? "In Progress" : executionStatus;

    // If the template run is paused, append '(Paused)' to status text.
    displayText += isPaused ? " (Paused)" : "";
    return displayText;
  }

  public render(): JSX.Element {
    const columns = this.getColumns();

    return (
      <Modal
        styles={{ main: { minWidth: "80%" } }}
        isOpen={this.props.showTemplateRunDetails}
        onDismiss={() => this.props.updateIsTemplateRunDetailsShown(false)}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <Shimmer
            style={{ width: '80%' }}
            isDataLoaded={!this.props.isLoading}
          >
            <span className={contentStyles.headerText}>
              {this.props.selectedTemplateDetails?.RunTemplateName}
            </span>
          </Shimmer>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={() => this.props.updateIsTemplateRunDetailsShown(false)}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <Shimmer
            style={{ width: '100%' }}
            isDataLoaded={!this.props.isLoading}
          >
            {!this.props.isLoading && (
              <>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                  tokens={{ childrenGap: 20 }}>
                  <div>
                    <b>Status:</b>&nbsp;
                    {this.getTemplateRunStatusDisplayText(this.props.selectedTemplateDetails?.ExecutionStatus, this.props.selectedTemplateDetails?.Paused)}
                  </div>
                  <div>
                    <b>Run Id:</b>&nbsp;
                    {this.props.selectedTemplateDetails?.RunId}
                  </div>
                  <div>
                    <b>Schedule Id:</b>&nbsp;
                    {this.props.selectedTemplateDetails?.RunTemplateScheduleId}
                  </div>
                  <div>
                    <b>Started:</b>&nbsp;
                    {utcToFormattedDateTime(this.props.selectedTemplateDetails?.StartTime) || "-"}
                  </div>
                  <TemplateRunCommandBar
                    TemplateRun={this.props.selectedTemplateDetails}
                    AuthControls={this.props.userProfile?.authControls}
                    onRetrigger={() => this.showDialog("retrigger", () => this.performTemplateRunAction("retrigger"))}
                    onCancel={() => this.showDialog("cancel", () => this.performTemplateRunAction("cancel"))}
                    onPause={() => this.showDialog("pause", () => this.performTemplateRunAction("pause"))}
                    onResume={() => this.showDialog("resume", () => this.performTemplateRunAction("resume"))}
                  />
                </Stack>
                <DetailsListController
                  items={this.getProcesses()}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  onRenderRow={this.onRenderRow}
                />
              </>
            )}
          </Shimmer>
        </div>
        <Dialog
          onDismiss={this.hideDialog}
          hidden={!this.state.isDialogVisible}
          dialogContentProps={{
            title: this.state.currentDialogState?.title || "Title",
            subText: this.state.currentDialogState?.subtext || "SubText",
            type: DialogType.close
          }}
        >
          {this.state.currentDialogState?.showInput && (
            <TextField
              placeholder="Enter IcM or Reason for action"
              required={this.state.currentDialogState?.showInput}
              onChange={this.onReasonChange}
              errorMessage={this.state.currentDialogState?.showReasonError ? "Reason is required" : undefined}
            />
          )}
          <DialogFooter>
            <PrimaryButton onClick={this.confirmDialog} text="Confirm" />
            <DefaultButton onClick={this.hideDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
        {this.state.contextMenuProps && (
          <ContextualMenu {...this.state.contextMenuProps} />
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (
  state: AppState
): ITemplateRunDetailsLinkStateProps => ({
  showTemplateRunDetails: state.templateRunDetails.showTemplateRunDetails,
  selectedTemplateDetails: state.templateRunDetails.selectedTemplateRunDetails,
  isLoading: state.templateRunDetails.isLoading,
  userProfile: state.userProfile,
  hideNotScheduled: state.templateRunDetails.hideNotScheduled
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): ITemplateRunDetailsLinkDispatchProps => ({
  updateIsTemplateRunDetailsShown: bindActionCreators(
    updateIsTemplateRunDetailsShown,
    dispatch
  ),
  markProcessAsComplete: bindActionCreators(
    markProcessAsComplete, dispatch
  ),
  performTemplateRunAction: bindActionCreators(performTemplateRunAction, dispatch),
  updateHideNotScheduled: bindActionCreators(UpdateHideNotScheduled, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(TemplateRunDetails);
