export const EXPAND_CLICK = "EXPAND_CLICK";
export const TOGGLE_CLICK = "TOGGLE_CLICK";

export const FETCH_CONNECTION_TYPES_REQUEST = "FETCH_CONNECTION_TYPES_REQUEST";
export const FETCH_CONNECTION_TYPES_SUCCESS = "FETCH_CONNECTION_TYPES_SUCCESS";
export const FETCH_CONNECTION_TYPES_FAILURE = "FETCH_CONNECTION_TYPES_FAILURE";

export const FETCH_CONNECTION_SAVE_SUCCESS = "FETCH_CONNECTION_SAVE_SUCCESS";
export const FETCH_CONNECTION_VALIDATION_SUCCESS =
  "FETCH_CONNECTION_VALIDATION_SUCCESS";
export const FETCH_CONNECTION_UPDATE_SUCCESS =
  "FETCH_CONNECTION_UPDATE_SUCCESS";
export const FETCH_VALIDATE_MSGS = "FETCH_VALIDATE_MSGS";
export const FETCH_UPDATE_MSGS = "FETCH_UPDATE_MSGS";
export const FETCH_SAVE_MSGS = "FETCH_SAVE_MSGS";

export const FETCH_AUTHENTICATION_OPTIONS_REQUEST =
  "FETCH_AUTHENTICATION_OPTIONS_REQUEST";
export const FETCH_AUTHENTICATION_OPTIONS_SUCCESS =
  "FETCH_AUTHENTICATION_OPTIONS_SUCCESS";
export const FETCH_AUTHENTICATION_OPTIONS_FAILURE =
    "FETCH_AUTHENTICATION_OPTIONS_FAILURE";
export const FETCH_FAILURE =
    "FETCH_FAILURE";
