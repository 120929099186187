import { Dispatch } from "redux";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as RunScheduleAPI from "../../../api/run-schedule-api";
import * as RunScheduleActions from "../../../redux/actions/run-schedule-actions";
import {
  IRunScheduleData,
  IRunScheduleFiltersData,
} from "../../../models/run-schedule";
import {
  utcToBrowserTimeZone,
  browserToUTCTimeZone,
} from "../../../util/time-zone-util";

const updateRunScheduleDataTimeZineToLocal = (stateData: IRunScheduleData) => {
  stateData.RunTemplateSchedule.forEach((schedule) => {
    schedule.ScheduleDateTime = utcToBrowserTimeZone(schedule.ScheduleDateTime);
    schedule.Processes.forEach((proc) => {
      if (proc.ProcessScheduleDateTime) {
        proc.ProcessScheduleDateTime = utcToBrowserTimeZone(
          proc.ProcessScheduleDateTime
        );
      }
    });
  });
  return stateData;
};

export const startGetRunTemplateScheduleData = (
  runScheduleFiltersData: IRunScheduleFiltersData,
  afterEffectFunction: any
) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(RunScheduleActions.isRunScheduleGridLoading(true));
    const data = RunScheduleAPI.getRunTemplateSchedule(runScheduleFiltersData)
      .then((value) => {
        // Consuming date in Browser Time Zone
        const stateData: IRunScheduleData = updateRunScheduleDataTimeZineToLocal(
          value.Result[0]
        );
        stateData.StartDateTime = runScheduleFiltersData.StartDate;
        stateData.EndDateTime = runScheduleFiltersData.EndDate;
        stateData.isDirty = false;
        dispatch(RunScheduleActions.getRunTemplateScheduleData(stateData));
        if (afterEffectFunction) afterEffectFunction();
      })
      .catch((error) => {
        dispatch(RunScheduleActions.isRunScheduleGridLoading(false));
        const err = error;
      });
  };
};

const updateRunScheduleDataTimeZoneToUTC = (stateData: IRunScheduleData) => {
  stateData.RunTemplateSchedule.forEach((schedule) => {
    schedule.ScheduleDateTime = browserToUTCTimeZone(schedule.ScheduleDateTime);
  });
  return stateData;
};

export const startSaveRunTemplateScheduleData = (
  runTemplateScheduleData: IRunScheduleData,
  startGetRunTemplateScheduleData: any
) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(RunScheduleActions.isRunTemplateScheduleSaving(true));

    // Saving back in UTC
    runTemplateScheduleData = updateRunScheduleDataTimeZoneToUTC(
      runTemplateScheduleData
    );

    RunScheduleAPI.saveRunTemplateSchedule(runTemplateScheduleData)
      .then((value) => {
        const runScheduleFilterData: IRunScheduleFiltersData = {
          FiscalYear: runTemplateScheduleData.FiscalYear,
          RunTemplateId: runTemplateScheduleData.RunTemplateId,
          RunTemplateName: runTemplateScheduleData.RunTemplateName,
          StartDate: new Date(runTemplateScheduleData.StartDateTime),
          EndDate: new Date(runTemplateScheduleData.EndDateTime),
        };
        window.alert("Changes saved successfully.");
        startGetRunTemplateScheduleData(runScheduleFilterData);
        // const stateData: IRunScheduleData = value.Result[0];
      })
      .catch((error) => {
        window.alert("An error occured. Please try again.");
        const err = error;
      });
  };
};

export const updateRunScheduleData = (data: any) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(RunScheduleActions.updateCompleteData(data));
  };
};
