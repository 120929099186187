import { Dispatch } from "redux";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as RecurrenceRuleAPI from "../../../api/recurrence-rule-api";
import * as RecurrenceRuleActions from "../../../redux/actions/recurrence-rule-actions";
import { IRecurrenceRule } from "../../../models/recurrence-rule";

export const startGetRecurrenceRuleList = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    RecurrenceRuleAPI.getRecurrenceRuleList()
      .then((value) => {
        const recurrenceRules: IRecurrenceRule[] = value.Result;
        dispatch(RecurrenceRuleActions.getRecurrenceRules(recurrenceRules));
      })
      .catch((error) => {
        // dispatch(RunScheduleActions.isRunScheduleGridLoading(false));
        const err = error;
      });
  };
};

export const startSaveOrUpdateRecurrenceRuleData = (
  recurrenceRuleData: IRecurrenceRule,
  startGetRecurrenceRuleList: any,
  onAddOrUpdateRuleSuccess: (selectedRule?: string) => void
) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    RecurrenceRuleAPI.saveorUpdateRecurrenceRule(recurrenceRuleData)
      .then((value) => {
        startGetRecurrenceRuleList();
        onAddOrUpdateRuleSuccess(recurrenceRuleData.RecurrenceRuleName);
      })
      .catch((error) => {
        window.alert("An error occured. Please try again.");
        const err = error;
      });
  };
};
