import React, { useState } from "react";
import "./preview.scss";
import {
  TextField,
  IDropdownOption,
  mergeStyles,
  Stack,
} from "@fluentui/react";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import { DateInput } from "../../../common/date-picker/Date";
import { ModalView } from "../../../common/modal/modal.view";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import {
  RunScheduleListLinkDispatchProps,
  IRunScheduleListProps,
  RunScheduleListLinkStateProps,
} from "../../run-schedule-list/models/IRunScheduleList";
import { bindActionCreators } from "redux";
import { updateRunTemplateScheduleColumnData } from "../../../../redux/actions/run-schedule-actions";
import { AppState } from "../../../../redux/configureStore";
import { IRunTemplateSchedule } from "../../../../models/run-schedule";
import { PreviewGrid } from "../preview-grid/preview-grid";
import {
  formatDateTime,
  formatDate,
  formatTime,
} from "../../../../util/time-zone-util";
import PrimaryButton from "../../../common/button/primary-button/primary-button.controller";
import { stackTokens } from "../../run-schedule.helper";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import { previewClickEvent } from "../../run-schedule.telemtetry-constants";

type PreviewProps = {
  startDate: Date;
  endDate: Date;
  selectedyear: number;
  selectedRunTemplateName: string;
};

type Props = PreviewProps &
  RunScheduleListLinkDispatchProps &
  RunScheduleListLinkStateProps;

const Preview = (props: Props) => {
  /********Modal controls********/
  var previewControl = () => {
    return (
      <PrimaryButton
        text="Preview"
        onClick={() => {
          TelemetryProvider._trackEvent(previewClickEvent);
          UpdateShowPreviewGrid(false);
        }}
      />
    );
  };

  var previewmodal = {
    title: "Preview Schedule for " + props.selectedRunTemplateName,
    control: previewControl(),
  };
  /************************/

  /********Function declarations********/
  let updateMapDateToRunScheduleList = () => {
    // Mapping run schedules to respective dates.
    props.runTemplateScheduleData.RunTemplateSchedule.forEach(
      (schedule: IRunTemplateSchedule) => {
        let runTemplateDate = formatDate(schedule.ScheduleDateTime);
        if (mapDateToRunScheduleList.get(runTemplateDate) == null) {
          var item: IRunTemplateSchedule[] = [];
          item.push(schedule);
          mapDateToRunScheduleList.set(runTemplateDate, item);
        } else {
          var existingRunTemplateScheduleList = mapDateToRunScheduleList.get(
            runTemplateDate
          );
          existingRunTemplateScheduleList?.push(schedule);
        }
      }
    );
  };

  let getInitialOptions = () => {
    if (props.runTemplateScheduleData.RunTemplateSchedule.length > 0) {
      var runSchedule = mapDateToRunScheduleList.get(
        formatDate(
          props.runTemplateScheduleData.RunTemplateSchedule[0].ScheduleDateTime
        )
      );
      var runobjects: any[] = [];
      runSchedule?.forEach((rSchedule) => {
        var time = formatTime(rSchedule.ScheduleDateTime);
        runobjects.push({
          key: rSchedule.RunTemplateScheduleId,
          text: time,
        });
      });
      return {
        initialTimeOptions: runobjects,
        initialSelectedData:
          props.runTemplateScheduleData.RunTemplateSchedule[0],
        initialSelectedDate:
          props.runTemplateScheduleData.RunTemplateSchedule[0].ScheduleDateTime,
      };
    } else {
      return {
        initialTimeOptions: [],
        initialSelectedData: {
          RunTemplateScheduleId: 0,
          ScheduleDateTime: new Date(),
          Processes: [
            {
              IsSubProcess: false,
              ProcessId: 0,
              ProcessName: "",
            },
          ],
        },
        initialSelectedDate: props.startDate,
      };
    }
  };

  const onDateSelection = (date: Date) => {
    UpdateSelectedDate(date);

    var runSchedule = mapDateToRunScheduleList.get(formatDate(date));
    var runobjects: any[] = [];
    runSchedule?.forEach((rSchedule) => {
      var time = formatTime(rSchedule.ScheduleDateTime);
      runobjects.push({
        key: rSchedule.RunTemplateScheduleId,
        text: time,
      });
    });
    UpdateTimeOptions(runobjects);

    var Schedule: any[] = props.runTemplateScheduleData.RunTemplateSchedule;
    var runScheduleRecord = Schedule.find(
      (a: any) => a.RunTemplateScheduleId == runobjects[0]?.key
    );

    UpdateSelectedData(runScheduleRecord);
    UpdateShowPreviewGrid(false);
  };

  const onStartTimeDropdownChange = (
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    var Schedule: any[] = props.runTemplateScheduleData.RunTemplateSchedule;
    var runScheduleRecord = Schedule.find(
      (a: any) => a.RunTemplateScheduleId == option?.key
    );
    UpdateSelectedData(runScheduleRecord);
    UpdateShowPreviewGrid(false);
  };
  /************************/

  /********Context setup********/
  let mapDateToRunScheduleList = new Map<string, IRunTemplateSchedule[]>();
  updateMapDateToRunScheduleList();

  const initialOptions = getInitialOptions();
  const [SelectedData, UpdateSelectedData] = useState(
    initialOptions.initialSelectedData
  );
  const [ShowPreviewGrid, UpdateShowPreviewGrid] = useState(false);
  const [SelectedDate, UpdateSelectedDate] = useState(
    initialOptions.initialSelectedDate
  );
  const [TimeOptions, UpdateTimeOptions] = useState(
    initialOptions.initialTimeOptions
  );
  /************************/

  /********Render********/
  var params = {
    label: "Select date",
    placeholder: "Select a date...",
    ariaLabel: "Select a date",
    StartDate: props.startDate,
    EndDate: props.endDate,
    year: props.selectedyear,
    value: SelectedDate,
    onSelectDate: onDateSelection,
  };
  return (
    <div className="preview-modal">
      <ModalView {...previewmodal}>
        <Stack
          className="preview-filters"
          horizontal
          tokens={stackTokens}
          horizontalAlign="space-between"
        >
          <DateInput {...params} ariaLabel = "Preview DateTime" />
          <Dropdown
            placeholder="Select a time"
            label="StartTime"
            onChange={onStartTimeDropdownChange}
            defaultSelectedKey={TimeOptions[0]?.key}
            disabled={TimeOptions.length === 0}
            options={TimeOptions}
          />
          <PrimaryButton
            text="View"
            onClick={() => {
              UpdateShowPreviewGrid(true);
            }}
            disabled={TimeOptions.length === 0}
            className={mergeStyles({
              alignSelf: "safe flex-end",
              marginLeft: 10,
              marginBottom: 15,
            })}
          />
        </Stack>
        <div className="preview-grid">
          {ShowPreviewGrid ? (
            <PreviewGrid runScheduledata={SelectedData} />
          ) : null}
        </div>
      </ModalView>
    </div>
  );
};
/************************/

/********Redux********/
const mapStateToProps = (
  state: AppState,
  ownProps: IRunScheduleListProps
): RunScheduleListLinkStateProps => {
  return {
    runTemplateScheduleData: state.runSchedule,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunScheduleListProps
): RunScheduleListLinkDispatchProps => ({
  updateRunTemplateScheduleData: bindActionCreators(
    updateRunTemplateScheduleColumnData,
    dispatch
  ),
});
/************************/

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
