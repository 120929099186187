import * as types from "../types/run-dashboard-v2-action-types";
import { Dispatch } from "redux"
import { getTemplateRunDetails } from "../../api/run-dashboard-api"
import { AppActions } from "../types/app-actions"
import { ITemplateRun } from "../../models/template-run";

export const fetchTemplateRuns = (executionStatus: number, count?: number, fiscalYear?: number) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(RequestTemplateRunData({
      executionStatus,
      count,
      fiscalYear
    }, executionStatus));
    try {
      const result = await getTemplateRunDetails(executionStatus, count, fiscalYear);
      return dispatch(ReceivedTemplateRunDataSuccess(result, executionStatus));
    } catch (ex) {
      return dispatch(ReceivedTemplateRunDataFailure(ex, executionStatus));
    }
  }
}

export const updateRunListCount = (executionStatus: number, count: number): AppActions => ({
  type: types.UPDATE_RUN_LIST_COUNT,
  count,
  executionStatus,
});

export const ReceivedTemplateRunDataFailure = (errorMessage: any, executionStatus: number): AppActions => ({
  type: types.RECEIVED_TEMPLATE_RUN_DATA_FAILURE,
  errorMessage,
  executionStatus,
});

export const ReceivedTemplateRunDataSuccess = (templateRuns: ITemplateRun[], executionStatus: number): AppActions => ({
  type: types.RECEIVED_TEMPLATE_RUN_DATA_SUCCESS,
  runData: templateRuns,
  executionStatus,
});

export const RequestTemplateRunData = (payload: any, executionStatus: number): AppActions => ({
  type: types.REQUEST_TEMPLATE_RUN_DATA,
  executionStatus,
  payload,
});

export const MarkRunListsAsDirty = (): AppActions => ({
  type: types.MARK_RUN_LISTS_DIRTY,
});

export const clearErrorAndSuccessMessage = (): AppActions =>  ({ 
  type: types.CLEAR_ERROR_AND_SUCCESS_MESSAGE,
});
