import * as React from "react";
import { Dispatch } from "react";
import { AppState } from "../../redux/configureStore";
import { AppActions } from "../../redux/types/app-actions";
import * as RunTemplateAPI from "../../api/run-template-api";
import * as RunTemplateActions from "../../redux/actions/run-template-actions";
import { utcToBrowserTimeZone } from "../../util/time-zone-util";
import { IRunTemplateDetails } from "../../models/run-template";

const updateRunScheduleDataTimeZineToLocal = (
  runTemplateList: IRunTemplateDetails[]
) => {
  runTemplateList.forEach((runTemplate) => {
    // if (activity.LastRunDateTimeStamp) {
    //   activity.LastRunDateTimeStamp = utcToBrowserTimeZone(
    //     activity.LastRunDateTimeStamp
    //   );
    // }
    runTemplate.CreatedOn = utcToBrowserTimeZone(runTemplate.CreatedOn);
    runTemplate.ModifiedOn = utcToBrowserTimeZone(runTemplate.ModifiedOn);
  });
  return runTemplateList;
};

const sortRunTemplateList = (runTemplateList: IRunTemplateDetails[]) => {
  // Default sort by FiscalYear DESC, RunTemplate Name ASC
  runTemplateList.sort((a, b) =>
    a.RunTemplateName > b.RunTemplateName
      ? 1
      : b.RunTemplateName > a.RunTemplateName
      ? -1
      : 0
  );
  runTemplateList.sort((a, b) =>
    a.FiscalYear < b.FiscalYear ? 1 : b.FiscalYear < a.FiscalYear ? -1 : 0
  );
};

export const startGetRunTemplateList = (fiscalYear: number) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    RunTemplateAPI.getRunTemplates(fiscalYear)
      .then((value) => {
        const runTemplateList: IRunTemplateDetails[] = updateRunScheduleDataTimeZineToLocal(
          value.Result
        );

        sortRunTemplateList(runTemplateList);

        dispatch(RunTemplateActions.getRunTemplateList(runTemplateList));
      })
      .catch((error) => {
        const err = error;
      });
  };
};

export const startDeleteRunTemplate = (
  runTemplateId: number,
  startDeleteRunTemplate: any
) => {
  RunTemplateAPI.deleteRunTemplate(runTemplateId)
    .then((value) => {
      window.alert("Run Template deleted successfully.");
      if (startDeleteRunTemplate) startDeleteRunTemplate();
    })
    .catch((error) => {
      window.alert("An error occured. Please try again.");
      const err = error;
    });
};
