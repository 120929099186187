import { ISourceOnboardingConnectionsList } from "../../components/onboarding/source-onboarding/model/ISourceOnboarding";

export const GET_ALL_CONNETIONS1 = "GET_ALL_CONNETIONS1";
export const DELETE_CONNECTION = "DELETE_CONNECTION";

export interface GetAllConnections1 {
    type: typeof GET_ALL_CONNETIONS1;
    totalConnections: ISourceOnboardingConnectionsList;
}

export interface DeleteConnection {
    type: typeof DELETE_CONNECTION;
    data: ISourceOnboardingConnectionsList;
}

export type SourceOnboardingActionTypes = GetAllConnections1 | DeleteConnection;
