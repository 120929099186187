import React from "react";
import DetailsListController from "../../common/details-list/details-list.controller";
import { IDetailsListPropsCustom } from "../../common/details-list/models/IDetailsList";
import {
  IRenderFunction,
  IDetailsHeaderProps,
  DetailsHeader,
  IDetailsColumnRenderTooltipProps,
  Sticky,
  StickyPositionType,
  IColumn,
  findIndex,
} from "@fluentui/react";
import AddDeleteHeader from "../../common/add-delete-header/add-delete-header.view";
import TimePicker from "../../common/time-picker/time-picker.controller";
import {
  IRunScheduleData,
  IRunTemplateSchedule,
} from "../../../models/run-schedule";
import {
  getDeepCopy,
  extractTimeInZuluFormat,
} from "../../../util/javascript-functions";
import { IRunScheduleColumn } from "../models/IRunSchedule";
import { AppState } from "../../../redux/configureStore";
import {
  IRunScheduleFiltersProps,
  RunScheduleFiltersLinkStateProps,
  RunScheduleFiltersLinkDispatchProps,
} from "../run-schedule-filters/models/IRunScheduleFilters";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { startGetRunScheduleFilters } from "../run-schedule-filters/run-schedule-filters.service";
import { connect } from "react-redux";
import { RunScheduleFilter } from "../run-schedule-filters/run-schedule-filters.controller";
import { updateRunTemplateScheduleColumnData } from "../../../redux/actions/run-schedule-actions";
import {
  IRunScheduleListProps,
  RunScheduleListLinkDispatchProps,
  IRunScheduleListState,
  RunScheduleListLinkStateProps,
} from "../run-schedule-list/models/IRunScheduleList";
import {
  createRunSchedule,
  getRandomIdForFirstRunScheduleOnThatDay,
  createRunScheduleWithEmptyProcess,
} from "../run-schedule-list/run-schedule-list.helper";
import { formatDate } from "../../../util/time-zone-util";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { timePickerClickEvent } from "../run-schedule.telemtetry-constants";

export interface IDetailListAddDeleteProps extends IDetailsListPropsCustom {
  onAddClick: any;
  onDeleteClick: any;
  onTagChange: any
}

type Props = IRunScheduleListProps &
  RunScheduleListLinkDispatchProps &
  RunScheduleFiltersLinkStateProps &
  IDetailListAddDeleteProps;
export class DetailListWithAddDelete extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}
  updateState(scheduleData: IRunTemplateSchedule[]) {
    this.props.updateRunTemplateScheduleData({
      RunTemplateSchedule: scheduleData,
    });
  }
  renderAddDeleteHeader: IRenderFunction<IDetailsHeaderProps> = (
    properties?: any
  ) => {
    var defaultTime: any = "";

    const renderCustomHeader = (
      data: IDetailsColumnRenderTooltipProps | any
    ) => {
      const params = {
        data: data,
        onAdd: this.props.onAddClick,
        onDelete: this.props.onDeleteClick,
        onTagChange: this.props.onTagChange,
        excludeKeyName: ["recurrenceRule", "processName"],
      };
      // Enabling today's checkboxes in case there is no schedule
      const isNoScheduleForToday =
        formatDate(data.column.runScheduleDateTime) ===
          formatDate(new Date()) && data.column?.runScheduleTime == "";
      const disableTimePicker = data.column.isPastDate && !isNoScheduleForToday;

      // changing redux state on time change
      const onTimeChange = (event?: React.FormEvent<HTMLInputElement>) => {
        TelemetryProvider._trackEvent(timePickerClickEvent);
        if (data.column.key == "processName") {
          defaultTime = event?.currentTarget.value;
        } else {
          var runTemplateScheduleData: IRunScheduleData = getDeepCopy(
            this.props.runTemplateScheduleData
          );
          var Schedule: any[] = runTemplateScheduleData.RunTemplateSchedule;

          //  runschedule not present for the day
          if (data.column?.runScheduleId == 0) {
            addRunSchedule(Schedule, data.column, event?.currentTarget.value);
            return;
          }

          var runScheduleRecord = Schedule.find(
            (a: any) => a.RunTemplateScheduleId == data.column?.runScheduleId
          );
          var updatedIndex = Schedule?.findIndex((a: IRunTemplateSchedule) => {
            return a.RunTemplateScheduleId == data.column?.runScheduleId;
          });
          let date = formatDate(Schedule[updatedIndex].ScheduleDateTime);
          var schdate = new Date(date + " " + event?.currentTarget.value!);
          var updatedscheduledtime = schdate;
          var newRunScheduleRecord = Object.assign(
            {},
            { ...runScheduleRecord, ScheduleDateTime: updatedscheduledtime }
          );
          Schedule[updatedIndex] = newRunScheduleRecord;
          this.updateState(Schedule);
        }
      };

      const addRunSchedule = (scheduleData: any, column: any, time: any) => {
        var runSchedule = createRunScheduleWithEmptyProcess(
          getRandomIdForFirstRunScheduleOnThatDay(column.name), //  random id
          extractTimeInZuluFormat(column?.runScheduleDateTime, time)
        );
        scheduleData.splice(0, 0, runSchedule);
        this.updateState(scheduleData);
      };

      return (
        <AddDeleteHeader {...params}>
          {data.column.key != "processName" && (
            <div style={{marginTop:'20px'}}>
            <TimePicker
              id={data.column.key + data.column.runScheduleId }
              disabled={disableTimePicker}
              value={
                data.column?.runScheduleTime == ""
                  ? defaultTime
                  : data.column?.runScheduleTime
                  
              }
              onChange={onTimeChange}
            />
            </div>
          )}
        </AddDeleteHeader>
      );
    };
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        <DetailsHeader
          {...properties}
          onRenderColumnHeaderTooltip={renderCustomHeader}
          ariaLabel={"Details Header"}
        />
      </Sticky>
    );
  };

  render() {
    return (
      <DetailsListController
        items={this.props.items}
        columns={this.props.columns}
        selectionMode={this.props.selectionMode}
        selectionPreservedOnEmptyClick={
          this.props.selectionPreservedOnEmptyClick
        }
        onItemInvoked={this.props.onItemInvoked}
        onRenderDetailsHeader={this.renderAddDeleteHeader}
        enableShimmer={this.props.enableShimmer}
        shimmerLines={this.props.shimmerLines}
      />
    );
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: IRunScheduleFiltersProps
): RunScheduleFiltersLinkStateProps => {
  return {
    filters: state.runScheduleFilter.filters,
    runTemplateScheduleData: state.runSchedule,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IRunScheduleListProps
): RunScheduleListLinkDispatchProps => ({
  updateRunTemplateScheduleData: bindActionCreators(
    updateRunTemplateScheduleColumnData,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailListWithAddDelete);
