import {
  IActivityValidations,
  IRule,
  IValidationMetadata,
} from "../../models/validation";
import * as types from "../types/validation-action-types";
import { ValidataionActionTypes } from "../types/validation-action-types";

interface IValidationReducer {
  validaionDetails?: IRule;
  validationRules?: IRule[];
  activityValidations?: IActivityValidations[];
  validationMetadata?: IValidationMetadata;
}

const validationReducerDefaultState: IValidationReducer = {};

const validationReducer = (
  state = validationReducerDefaultState,
  action: ValidataionActionTypes
): IValidationReducer => {
  switch (action.type) {
    case types.GET_VALIDATION_DETAILS:
      return Object.assign({}, state, {
        validaionDetails: action.validationDetails,
      });
    case types.GET_VALIDATION_LIST:
      return Object.assign({}, state, {
        validationRules: action.validationList,
      });
    case types.GET_ACTIVITY_VALIDATION_LIST:
      return Object.assign({}, state, {
        activityValidations: action.activityValidationList,
      });
    case types.GET_VALIDATION_METADATA:
      return Object.assign({}, state, {
        validationMetadata: action.validationMetadata,
      });
    default:
      return state;
  }
};

export default validationReducer;
