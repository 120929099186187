import { ITemplateRunDetails } from "../../models/template-run";
export const UPDATE_IS_TEMPLATE_RUN_DETAILS_SHOWN = "UPDATE_IS_TEMPLATE_RUN_DETAILS_SHOWN";
export const REQUEST_TEMPLATE_RUN_DETAILS_DATA = "REQUEST_TEMPLATE_RUN_DETAILS_DATA";
export const RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS = "RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS";
export const RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE = "RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE";
export const TEMPLATE_RUN_UPDATE_FAILURE = "RUN_TEMPLATE_UPDATE_FAILURE";
export const START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE = "START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE";
export const UPDATE_HIDE_NOT_SCHEDULED = "UPDATE_HIDE_NOT_SCHEDULED";

export interface UpdateIsTemplateRunDetailsShown {
	type: typeof UPDATE_IS_TEMPLATE_RUN_DETAILS_SHOWN;
	isShown: boolean;
}
export interface RequestTemplateRunDetailsData {
	type: typeof REQUEST_TEMPLATE_RUN_DETAILS_DATA;
	payload?: any;
}
export interface RecievedTemplateRunDetailsSuccess {
	type: typeof RECEIVED_TEMPLATE_RUN_DETAILS_DATA_SUCCESS;
	data: ITemplateRunDetails;
	successMessage: string;
}
export interface RecievedTemplateRunDetailsFailure {
	type: typeof RECEIVED_TEMPLATE_RUN_DETAILS_DATA_FAILURE;
	errorMessage: any;
}
export interface TemplateRunUpdateFailure {
	type: typeof TEMPLATE_RUN_UPDATE_FAILURE;
	errorMessage: any;
}
export interface StartMarkTemplateRunProcessComplete {
	type: typeof START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE;
	data: { processId: number, runTemplateScheduleId: number }
}
export interface StartMarkTemplateRunProcessComplete {
	type: typeof START_MARK_TEMPLATE_RUN_PROCESS_COMPLETE;
	data: { processId: number, runTemplateScheduleId: number }
}
export interface UpdateHideNotScheduled {
	type: typeof UPDATE_HIDE_NOT_SCHEDULED;
	hideNotScheduled: boolean;
}
export type TemplateRunDetailsActionTypes =
	| UpdateIsTemplateRunDetailsShown
	| RequestTemplateRunDetailsData
	| RecievedTemplateRunDetailsSuccess
	| RecievedTemplateRunDetailsFailure
	| TemplateRunUpdateFailure
	| StartMarkTemplateRunProcessComplete
	| UpdateHideNotScheduled;