import * as React from "react";
import { Dispatch } from "react";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import * as RunTemplateAPI from "../../../api/run-template-api";
import { IRunTemplateDetails } from "../../../models/run-template";
import * as RunTemplateActions from "../../../redux/actions/run-template-actions";

export const startGetRunTemplateDetails = (
  runTemplateId: number,
  updateIsFetchingDetails?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    RunTemplateAPI.getRunTemplateDetails(runTemplateId)
      .then((value) => {
        const _runTemplateDetails: IRunTemplateDetails = value.Result;
        _runTemplateDetails.Processes = _runTemplateDetails.Processes?.filter(
          (p) => !p.IsSubProcess
        );
        dispatch(RunTemplateActions.getRunTemplateDetails(_runTemplateDetails));
        if (updateIsFetchingDetails) updateIsFetchingDetails(false);
      })
      .catch((error) => {
      });
  };
};

export const startSaveRunTemplate = (
  runTemplateDetails: IRunTemplateDetails,
  saveRunTemplateCallback?: any,
  errorCallback?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    RunTemplateAPI.saveRunTemplate(runTemplateDetails)
      .then((value) => {
        if (value.Result) {
          window.alert(
            "Run Template '" +
              runTemplateDetails.RunTemplateName +
              "' saved successfully."
          );
          if (saveRunTemplateCallback) saveRunTemplateCallback();
        }
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback();
        }
      });
  };
};
