import * as React from "react";
import { Dispatch } from "react";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import * as ProcessAPI from "../../../api/process-api";
import {
  IProcessDetails,
  IProcessGroup,
  IProcessMetadata,
} from "../../../models/process";
import * as ProcessActions from "../../../redux/actions/process-actions";
import { IComboBoxOption } from "@fluentui/react";

const sortProcessGroupList = (processGroupList: IComboBoxOption[]) => {
  // Default sort by Process Group Name ASC
  processGroupList.sort((a: IComboBoxOption, b: IComboBoxOption) =>
    a.text.toUpperCase() > b.text.toUpperCase()
      ? 1
      : b.text.toUpperCase() > a.text.toUpperCase()
      ? -1
      : 0
  );
};

export const startGetProcessMetadata = (fiscalYear: number) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ProcessAPI.getProcessMetadata(fiscalYear)
      .then((value) => {
        const processMetadata: IProcessMetadata = value.Result;
        dispatch(ProcessActions.getProcessMetadata(processMetadata));
        // Add Process Group List to Redux Store
        let processGroupList: IComboBoxOption[] = [];
        //blank key,text for not selecting any option
        processGroupList.push({ key: "", text: "-None-" });
        processMetadata.ProcessGroupModel.forEach(
          (processGroup: IProcessGroup) => {
            processGroupList.push({
              key: processGroup.ProcessGroupId!,
              text: processGroup.ProcessGroupName!,
            });
          }
        );
        sortProcessGroupList(processGroupList);
        dispatch(ProcessActions.getProcessGroupList(processGroupList));
      })
      .catch((error) => {
      });
  };
};

export const startGetProcessDetails = (
  processId: number,
  updateIsFetchingDetails?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ProcessAPI.getProcessDetails(processId)
      .then((value) => {
        const _processDetails: IProcessDetails = value.Result;
        dispatch(ProcessActions.getProcessDetails(_processDetails));
        if (updateIsFetchingDetails) updateIsFetchingDetails(false);
      })
      .catch((error) => {
      });
  };
};

export const startSaveProcess = (
  processDetails: IProcessDetails,
  saveProcessCallback?: any,
  errorCallback?: any
) => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ProcessAPI.saveProcess(processDetails)
      .then((value) => {
        window.alert(
          "Process '" + processDetails.ProcessName + "' saved successfully."
        );
        if (saveProcessCallback) saveProcessCallback();
      })
      .catch((error) => {
        if (errorCallback) {
           errorCallback();
        }
      });
  };
};

export const startGetAllDependencyTypes = () => {
  return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
    ProcessAPI.getAllDependencyTypes()
      .then((value) => {
        const result: string[] = value.Result;
        var options: any[] = result.map((depTypeIdName: string, index: any) => {
          return {
            key: depTypeIdName.split(",")[0],
            text: depTypeIdName.split(",")[1],
          };
        });
        dispatch(ProcessActions.getDependencyTypesList(options));
      })
      .catch((error) => {
      });
  };
};
