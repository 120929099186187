import React from "react";
import "./activity-view.style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IActivity } from "../../../models/activity";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import {
  formatDateTime,
  formatDateTimeWithoutTimezone,
} from "../../../util/time-zone-util";
import Loading from "../../loading/loading";
import { getColorByStatus } from "../../run-dashboard-v2/run-dashboard-v2.helper";
import {
  startGetActivityDetails,
} from "./../activity.service";
import {
  IActivityViewLinkDispatchProps,
  IActivityViewLinkStateProps,
  IActivityViewProps,
  IActivityViewState,
} from "./models/IActivityView";
import { stackTokens } from "../../run-schedule/run-schedule.helper";
import { Modal, Stack } from "@fluentui/react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Checkbox from "../../common/checkbox/checkbox.controller";
import {
  contentStyles,
  iconButtonStyles,
} from "../../common/modal/modal.style";
import IconButton from "../../common/button/icon-button/icon-button.controller";

type Props = IActivityViewProps &
  IActivityViewLinkDispatchProps &
  IActivityViewLinkStateProps;

class ActivityDetails extends React.Component<Props, IActivityViewState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.props.startGetActivityDetails(this.props.activityId);
  }

  private getAttributesGrid() {
    if (
      this.props.activityDetails?.Attributes?.length == 0 ||
      this.props.activityDetails?.Attributes == undefined
    ) {
      return <></>;
    } else {
      let attributesColDefinition = this.getAttributesColDefinitions();
      let attributesRowData = this.getAttributesRowData(
        this.props.activityDetails!
      );
      return (
        <>
          <div>
            <b>Attributes:</b>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "200px",
              width: "100%",
              marginTop: "11px",
            }}
          >
            <AgGridReact
              columnDefs={attributesColDefinition}
              rowData={attributesRowData}
              // paginationPageSize={5}
              enableRangeSelection={true}
              // pagination={true}
              rowHeight={30}
            ></AgGridReact>
          </div>
        </>
      );
    }
  }

  private getParametersGrid() {
    if (
      this.props.activityDetails?.Parameters?.length == 0 ||
      this.props.activityDetails?.Parameters == undefined
    ) {
      return <></>;
    } else {
      let parametersColDefinition = this.getParametersColDefinitions();
      let parametersRowData = this.getParametersRowData(
        this.props.activityDetails!
      );
      return (
        <>
          <div>
            <b>Parameters:</b>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "200px",
              width: "100%",
              marginTop: "11px",
            }}
          >
            <AgGridReact
              columnDefs={parametersColDefinition}
              rowData={parametersRowData}
              // paginationPageSize={5}
              enableRangeSelection={true}
              // pagination={true}
              rowHeight={30}
            ></AgGridReact>
          </div>
        </>
      );
    }
  }

  private getAttributesRowData(activityList: IActivity) {
    let rowData: any[] = [];
    for (let i = 0; i < activityList.Attributes?.length!; i++) {
      var obj = activityList.Attributes![i];
      rowData.push({
        AttributeName: obj.AttributeName,
        AttributeValue: obj.ActivityAttributeValue,
      });
    }
    return rowData;
  }

  private getAttributesColDefinitions() {
    var columnDefs = [
      {
        headerName: "Attribute Name",
        field: "AttributeName",
        width: 125,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Attribute Value",
        field: "AttributeValue",
        width: 400,
        sortable: true,
        filter: true,
      },
    ];
    return columnDefs;
  }

  private getParametersRowData(activityList: IActivity) {
    let rowData: any[] = [];
    for (let i = 0; i < activityList.Parameters?.length!; i++) {
      var obj = activityList.Parameters![i];
      rowData.push({
        ParameterId: obj.ParameterId,
        ParameterName: obj.ParameterName,
        ParameterValue: obj.ParameterValue,
        MappedParameterName: obj.MappedParameterName,
        IsConfigurable: obj.IsConfigurable,
      });
    }
    return rowData;
  }

  private getParametersColDefinitions() {
    var columnDefs = [
      {
        headerName: "Parameter Name",
        field: "ParameterName",
        width: 125,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Parameter Value",
        field: "ParameterValue",
        width: 220,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Mapped Parameter",
        field: "MappedParameterName",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Is Configurable",
        cellRendererFramework: this.getIsConfigurableCheckbox,
        width: 125,
      },
      {
        headerName: "Info",
        width: 50,
      },
    ];
    return columnDefs;
  }

  private getIsConfigurableCheckbox(params: any) {
    return (
      <div style={{ width: "20%" }}>
        <Checkbox
          key={params.data.ParameterId}
          className="control-style"
          checked={params.data.IsConfigurable}
          disabled={true}
        />
      </div>
    );
  }

  private handleDismiss() {
    this.props.updateShowActivityDetailModal(false);
  }

  public render(): React.JSX.Element {
    if (
      this.props.activityDetails !== undefined &&
      this.props.activityDetails != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];

      columnDefs = this.getAttributesColDefinitions();
      var params = {
        columnDefs: columnDefs,
        rowData: rowData,
      };
      return (
        <>
          <Modal
            // isOpen={this.state.showModal}
            isOpen={this.props.showModal}
            onDismiss={this.handleDismiss}
            containerClassName={contentStyles.container}
          >
            <div className={contentStyles.header}>
              <span id="preview">{this.props.title}</span>
              <IconButton
                styles={iconButtonStyles}
                iconName="Cancel"
                title="Close Modal"
                fontSize={12}
                ariaLabel="Close popup modal"
                onClick={this.handleDismiss}
              />
            </div>
            <div id="children" className={contentStyles.body}>
              <Stack
                className="activity-details-modal"
                horizontal
                tokens={stackTokens}
                horizontalAlign="space-between"
              >
                <div>
                  <b>Activity Name:</b>{" "}
                  {this.props.activityDetails?.ActivityName}
                </div>
                <div></div>
                <div>
                  <b>Fiscal Year:</b> {this.props.activityDetails?.FiscalYear}
                </div>
                <div></div>
              </Stack>
              <br />
              <Stack
                className="activity-details-modal"
                horizontal
                tokens={stackTokens}
                horizontalAlign="space-between"
              >
                <div>
                  <b>Activity Type:</b>{" "}
                  {this.props.activityDetails?.ActivityTypeName}
                </div>
                <div></div>
                <div></div>
                <div></div>
              </Stack>
              <br />
              {this.getAttributesGrid()}
              <br />
              {this.getParametersGrid()}
            </div>
          </Modal>
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: IActivityViewProps
): IActivityViewLinkStateProps => {
  return {
    activityDetails: state.activity.activityDetails,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IActivityViewProps
): IActivityViewLinkDispatchProps => ({
  startGetActivityDetails: bindActionCreators(
    startGetActivityDetails,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetails);
