import React from "react";
import { ISideNavBarItem } from "../model/ISideNavBar";
import "./side-nav-bar-buttons.scss";
import { Link } from "react-router-dom";

const SideNavNavigateButton: React.FunctionComponent<ISideNavBarItem> = (
  data: ISideNavBarItem
) => {
  return (
      <a className="side-nav-button-link" href={data.url} target={data.target} rel="noreferrer">
        <button aria-label={data.name} className="side-nav-button" tabIndex={-1}>{data.name}</button>
      </a>
  );
};

export default SideNavNavigateButton;
