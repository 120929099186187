import React, { Fragment, useState } from "react";
import {
  Label,
  Separator,
  IChoiceGroupOption,
  ChoiceGroup,
  Stack,
  PrimaryButton,
  IStackTokens,
  DatePicker
} from "@fluentui/react";
import DailyView from "./patterns/daily/daily.view";
import MonthlyView from "./patterns/monthly/monthly.view";
import WeeklyView from "./patterns/weekly/weekly.view";
import YearlyView from "./patterns/yearly/yearly.view";
import { DateInput } from "../date-picker/Date";
import {
  setStartTimeForDate,
  setEndTimeForDate,
} from "../../../util/javascript-functions";
import {
  IRecurrenceRulePatternProps,
  IWeeklyProps,
  IMonthlyProps,
  IYearlyProps,
  IDailyProps,
  IRuleDate,
} from "./model/IRecurrenceRulePatternProps";

export const RecurrencePatternView: React.FunctionComponent<IRecurrenceRulePatternProps> = (
  props
) => {
  const {
    ruleStartDate,
    ruleEndDate,
    rangeStartDate,
    rangeEndDate,
    selectedRecurrenceOptionKey,
    setRecurrenceOptionKey,
    ruleStartDateSelection,
    ruleEndDateSelection,
  } = props;

  const daily = () => {
    var dailyProps = props as IDailyProps & IRuleDate;
    return <DailyView {...dailyProps} />;
  };
  const weekly = () => {
    var weekProps = props as IWeeklyProps;
    return <WeeklyView {...weekProps} />;
  };
  const monthly = () => {
    var monthlyParams = props as IMonthlyProps;
    return <MonthlyView {...monthlyParams} />;
  };
  const yearly = () => {
    var yearProps = props as IYearlyProps;
    return <YearlyView {...yearProps} />;
  };
  const selectedControl = () => {
    switch (selectedRecurrenceOptionKey) {
      case "Custom":
        return daily();
      case "Weekly":
        return weekly();
      case "Monthly":
        return monthly();
      case "Yearly":
        return yearly();
    }
  };

  const onChange = React.useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (option) {
        setRecurrenceOptionKey(option?.key);
      }
    },
    []
  );
  const pattern_options = () => {
    const options: IChoiceGroupOption[] = [
      { key: "Custom", text: "Custom" },
      { key: "Weekly", text: "Weekly" },
      { key: "Monthly", text: "Monthly" },
      { key: "Yearly", text: "Yearly" },
    ];
    return (
      <ChoiceGroup
        className="radio-controls"
        options={options}
        onChange={onChange}
        required={true}
        selectedKey={selectedRecurrenceOptionKey}
      />
    );
  };

  const onRuleStartDateSelection = (date: Date | null | undefined) => {
    if (date && date >= new Date()) {
      // if (date >= setStartTimeForDate(new Date())) {
      ruleStartDateSelection(setStartTimeForDate(date));
    }
  };

  const onRuleEndDateDateSelection = (date: Date | null | undefined) => {
    if (date && date >= new Date()) {
      ruleEndDateSelection(setStartTimeForDate(date));
    }
  };
  const rangeStartDateParams = {
    label: "Start date",
    placeholder: "Select a date...",
    ariaLabel: "Select a date",
    minDate: new Date(),
    // maxDate: rangeEndDate,
    year: new Date().getFullYear(),
    value: ruleStartDate,
    onSelectDate: onRuleStartDateSelection,
    showInitialDate: true,
  };
  const rangeEndDateParams = {
    label: "End date",
    placeholder: "Select a date...",
    ariaLabel: "Select a date",
    minDate: new Date(),
    // maxDate: rangeEndDate,
    year: new Date().getFullYear(),
    value: ruleEndDate,
    onSelectDate: onRuleEndDateDateSelection,
    showInitialDate: true,
  };
  const pattern_selection_control = () => {
    return (
      <Fragment>
        <div id="date-range" className="saved-rule">
          <DatePicker {...rangeStartDateParams} ariaLabel="StartDate" />
          <DatePicker {...rangeEndDateParams} ariaLabel="EndDate" />
        </div>

        <span>
          <b>Recurrence Pattern:</b>
        </span>
        <div className="recurrence-container">
          {pattern_options()}
          <div className="separator-container">
            <Separator vertical className="separator" />
          </div>
          <div className="selected-control-container">{selectedControl()}</div>
        </div>
        <br></br>
      </Fragment>
    );
  };

  return <>{pattern_selection_control()}</>;
};

export default RecurrencePatternView;
