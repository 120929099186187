import {
  IDropdownOption,
  IDropdownStyles,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { IActivity } from "../../../models/activity";
import DefaultButton from "../../common/button/default-button/default-button.controller";
import PrimaryButton from "../../common/button/primary-button/primary-button.controller";
import Dropdown from "../../common/dropdown/dropdown.controller";
import { stackTokens } from "../../run-schedule/run-schedule.helper";

type ActivityAddEditViewProps = {
  activityInfo: IActivity;
  activityTypeList: IDropdownOption[];
  updateActivtyName: any;
  onActivityTypeDropdownValueChange: any;
  getAttributes: any;
  getParametersGrid: any;
  onSaveActivityClick: any;
  onCancelClick: any;
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 200 },
};

export class ActivityAddEditView extends React.Component<
  ActivityAddEditViewProps
> {
  public render(): JSX.Element {
    return (
      <>
        <Stack
          className="activity-details-modal"
          horizontal
          tokens={stackTokens}
          horizontalAlign="space-between"
        >
          <div>
            <b>Activity Name:</b>
          </div>
          <TextField
            value={this.props.activityInfo.ActivityName}
            onChange={this.props.updateActivtyName}
            ariaLabel={"Activity Name"}
          />
          <div className="activity-type">
            <b>Activity Type:</b>{" "}
            <div className="activity-type-drodown">
              <Dropdown
                placeholder="Select Activity Type"
                options={this.props.activityTypeList}
                styles={dropdownStyles}
                onChange={this.props.onActivityTypeDropdownValueChange}
                selectedKey={this.props.activityInfo.ActivityTypeId}
                ariaLabel={"Activity Type"}
              />
            </div>
          </div>
        </Stack>
        <br />
        {this.props.getAttributes()}
        <br />
        {this.props.getParametersGrid()}
        <br />
        <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
          <PrimaryButton
            text="Save Activity"
            onClick={this.props.onSaveActivityClick}
            disabled={false}
          />
          <DefaultButton
            text="Cancel"
            onClick={this.props.onCancelClick}
            disabled={false}
          />
        </Stack>
      </>
    );
  }
}

export default ActivityAddEditView;
