import React from "react";
import { IRuleHooks } from "./models/IRecurrenceRuleModel";
import { IComboBoxOption } from "@fluentui/react";
import { getTimeIn24HourFormat } from "../../../util/time-zone-util";
import { setStartTimeForDate } from "../../../util/javascript-functions";
import {
  extractDatesFromString,
  weekDayFromStringToNumberArray,
  getMonthInNumber,
} from "./recurrence-rule.helper";
import { IWeekly } from "../../common/recurrence-pattern/patterns/weekly/IWeekly";
import { IMonthly } from "../../common/recurrence-pattern/patterns/monthly/IMonthly";
import { IYearly } from "../../common/recurrence-pattern/patterns/yearly/IYearly";
import { IRunSchedule, IRunScheduleData } from "../../../models/run-schedule";
import { IRecurrenceRule } from "../../../models/recurrence-rule";
import { RecurrenceRuleParameter } from "./recurrence-rule-parameters";

export class RuleConfigurationManager {
  public selectedRuleName: IComboBoxOption = { key: 0, text: "" };
  public startTimeOfProcess: string = "";
  public ruleScheduledAsPerRun: boolean = false;
  public ruleStartDate: Date = new Date();
  public ruleEndDate: Date = new Date();
  public selectedRecurrenceOptionKey: string = "";

  public selectedDailyDates: Date[] = [];
  public selectedWeekDays: IWeekly = { frequency: "", days: [] };
  public selectedMonthDatesConfig: IMonthly = { frequency: "", dates: "" };
  public selectedYearDatesConfig: IYearly = {
    frequency: "",
    dates: "",
    month: "",
  };
  private frequencyValue: string = "";
  private datesInString: string = "";

  private isDaily: boolean = false;
  private isWeekly: boolean = false;
  private isMonthly: boolean = false;
  private isYearly: boolean = false;
  private recurrencePattern: string = "";

  updateStateOnConfigChange = (hooks: IRuleHooks) => {
    hooks.onRuleNameSelection(this.selectedRuleName);
    hooks.onStartTimeChange(this.startTimeOfProcess);
    hooks.ruleScheduledAsPerRunSelection(this.ruleScheduledAsPerRun);
    hooks.ruleStartDateSelection(this.ruleStartDate);
    hooks.ruleEndDateSelection(this.ruleEndDate);

    let emptyDateObj: Date[] = [];
    let emptyWeekObj = {
      frequency: "",
      days: [],
    };

    let emptyMonthObj = {
      frequency: "",
      dates: "",
    };

    let emptyYearObj = {
      frequency: "",
      dates: "",
      month: "",
    };
    switch (this.recurrencePattern) {
      case "Custom":
        hooks.dailyDateSelection(this.selectedDailyDates);
        hooks.weekDaySelection(emptyWeekObj);
        hooks.monthlyDatesSelection(emptyMonthObj);
        hooks.yearlyDatesSelection(emptyYearObj);
        break;
      case "Weekly":
        hooks.dailyDateSelection(emptyDateObj);
        hooks.weekDaySelection(this.selectedWeekDays);
        hooks.monthlyDatesSelection(emptyMonthObj);
        hooks.yearlyDatesSelection(emptyYearObj);
        break;
      case "Monthly":
        hooks.dailyDateSelection(emptyDateObj);
        hooks.weekDaySelection(emptyWeekObj);
        hooks.monthlyDatesSelection(this.selectedMonthDatesConfig);
        hooks.yearlyDatesSelection(emptyYearObj);
        break;
      case "Yearly":
        hooks.dailyDateSelection(emptyDateObj);
        hooks.weekDaySelection(emptyWeekObj);
        hooks.monthlyDatesSelection(emptyMonthObj);
        hooks.yearlyDatesSelection(this.selectedYearDatesConfig);
        break;
    }
    hooks.setRecurrenceOptionKey(this.selectedRecurrenceOptionKey);
  };

  constructor(config: IRecurrenceRule) {
    {
      this.selectedRuleName = getRuleName();
      this.startTimeOfProcess = getStartTimeOfProcess() ?? "";
      this.ruleScheduledAsPerRun = config.IsAsPerRun ?? false;
      this.ruleStartDate = setStartTimeForDate(
        new Date(config.RecurrenceRuleStartDate.split("T")[0])
      );
      this.ruleEndDate = setStartTimeForDate(
        new Date(config.RecurrenceRuleEndDate.split("T")[0])
      );
      this.selectedRecurrenceOptionKey =
        config.RecurrenceRuleType.RecurrenceRuleTypeName;

      this.recurrencePattern = config.RecurrenceRuleType.RecurrenceRuleTypeName;

      var parameter = RecurrenceRuleParameter.find((a) => {
        return a.type == this.recurrencePattern;
      });

      switch (this.recurrencePattern) {
        case "Custom":
          this.selectedDailyDates = getSelectedDailyDates(
            true,
            parameter?.dates?.id
          );
          getSelectedWeekDays(false);
          getSelectedMonthDates(false);
          getYearDates(false);
          break;
        case "Weekly":
          getSelectedDailyDates(false);
          this.selectedWeekDays = getSelectedWeekDays(
            true,
            parameter?.frequency?.id,
            parameter?.days?.id
          );
          getSelectedMonthDates(false);
          getYearDates(false);
          break;
        case "Monthly":
          getSelectedDailyDates(false);
          getSelectedWeekDays(false);
          this.selectedMonthDatesConfig = getSelectedMonthDates(
            true,
            parameter?.frequency?.id,
            parameter?.dates?.id
          );
          getYearDates(false);
          break;
        case "Yearly":
          getSelectedDailyDates(false);
          getSelectedWeekDays(false);
          getSelectedMonthDates(false);
          this.selectedYearDatesConfig = getYearDates(
            true,
            parameter?.frequency?.id,
            parameter?.dates?.id,
            parameter?.month?.id
          );
          break;
      }
    }

    function getRuleName() {
      return {
        key: config.RecurrenceRuleId ?? 0,
        text: config.RecurrenceRuleName ?? "",
      };
    }

    function getStartTimeOfProcess() {
      return config.IsAsPerRun == true ? "" : config.ProcessStartTime;
    }

    function getParameterValue(parameterId: number): string {
      return (
        config.RecurrenceRuleParameters.find(
          (a) => a.RecurrenceRuleTypeParameterId == parameterId
        )?.RecurrenceRuleTypeParameterValue ?? ""
      );
    }

    function getSelectedDailyDates(isDaily: boolean, parameterId: number = 0) {
      return isDaily
        ? extractDatesFromString(getParameterValue(parameterId))
        : [];
    }

    function getSelectedWeekDays(
      isWeekly: boolean,
      frequencyParameterIdValue: number = 0,
      daysParameterId: number = 0
    ) {
      return {
        frequency: isWeekly ? getParameterValue(frequencyParameterIdValue) : "",
        days: weekDayFromStringToNumberArray(
          isWeekly ? getParameterValue(daysParameterId) : ""
        ),
      };
    }

    function getSelectedMonthDates(
      isMonthly: boolean,
      frequencyParameterIdValue: number = 0,
      datesParmeterIdValue: number = 0
    ) {
      return {
        frequency: isMonthly
          ? getParameterValue(frequencyParameterIdValue)
          : "",
        dates: isMonthly ? getParameterValue(datesParmeterIdValue) : "",
      };
    }

    function getYearDates(
      isYearly: boolean,
      frequencyParameterIdValue: number = 0,
      datesParameterIdValue: number = 0,
      monthParameterId: number = 0
    ) {
      return {
        frequency: isYearly ? getParameterValue(frequencyParameterIdValue) : "",
        dates: isYearly ? getParameterValue(datesParameterIdValue) : "",
        month: isYearly ? getParameterValue(monthParameterId).toString() : "",
      };
    }
  }
}
